




































import { Component, Emit, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { Utils } from '@/utils';

@Component({
  computed: {
    ...mapGetters({
      configuration: 'getConfiguration',
      path: 'getPath',
      lastUpdated: 'getLastUpdated',
      user: 'getUserName',
      selectedUnit: 'getSelectedUnit',
      historyDays: 'getHistoryDays'
    })
  }
})
export default class Header extends Vue {
  public configuration!: any;
  public path!: any;
  public currentWeek: string = moment().format('w');
  public lastUpdated!: any;
  public user!: string;
  public selectedUnit!: any;
  public historyDays!: number;

  get widgetTitle() {
    if (this.configuration && this.configuration[this.path]) {
      return this.configuration[this.path].Title;
    }

    return null;
  }
  get environment() {
    return '';
    //return this.configuration[this.path].Database.includes('PAQA')?'QA':'Production';
  }
  get version() {
    return window.location.href.includes('webclient.epi.intra')
      ? `OAM Portal ${process.env.VUE_APP_VERSION}`
      : `OAM Portal ${process.env.VUE_APP_VERSION}`;
  }
  get lastUpdatedString() {
    return moment(this.lastUpdated).format('DD/MM/YYYY, HH:mm:ss');
  }

  get database() {
    return '';
    //return (this.user.includes('adm')) ? ' | ' + this.configuration[this.path].Database : '';
  }

  get isEnglish() {
    if (this.configuration && this.configuration[this.path]) {
      return this.configuration[this.path].LanguageID === '1';
    }
    return false;
  }

  async useEnglish() {
    if (this.configuration && this.configuration[this.path]) {
      // set value directly on state object and update to new reference.
      // Not the vue way but it works. Watch on App.vue is called.
      this.configuration[this.path].LanguageID = '1';
      const newConfig = Object.assign({}, this.configuration);
      this.$store.dispatch('setConfiguration', newConfig);

      const promises = [
        {
          method: 'getLossesForUnit',
          parameters: {
            Path: this.path,
            PUID: this.selectedUnit.puid,
            LanguageID: this.configuration[this.path].LanguageID,
            historyDays: this.historyDays
          }
        }
      ];
      for (let promise of promises) {
        await Utils.triggerBackend(promise, this);
      }
    }
  }

  @Emit('selected')
  selected() {}
}
