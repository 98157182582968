

































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Variable } from '../../classes/variable';
import { MetricsService } from '../../services/metricsService';
import * as moment from 'moment';
declare const Buffer;

@Component({
  computed: {
    ...mapGetters({
      path: 'getPath'
    })
  }
})
export default class FavoritesModal extends Vue {
  @Prop() readonly modalData!: any;
  @Ref('favorites-modal') readonly modal!: any;
  public selectedVariables!: Variable[];
  public selectedUnit!: any;
  public path!: any;

  sumbitBtn() {
    if (this.modalData.label) {
      this.modalData.type = 'Favorites';
      this.modalData.path = this.path;
      this.modalData.user = new Buffer(
        this.$store.getters.getUserName
      ).toString('hex');
      this.$store.dispatch('setFavoritesViews', this.modalData);
    }
    this.$emit('modal-closed');
  }

  openModal() {
    setTimeout(() => this.modal.show(), 0);
  }

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  modalTeardown() {
    MetricsService.newAction({
      type: 'event',
      kind: 'cancel_favorites_modal_event',
      typeFormated: 'Cancel favorites modal event',
      message: `Cancel favorites data modal event was fired`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$emit('modal-closed');
  }
}
