import { Trend } from '../../classes/trend';
import { Threshold } from '../../classes/threshold';
import { DynamicThreshold } from '../../classes/dynamicThreshold';

export default class ChartFunctions {
  public static getDynamicThresholdTrends(
    target: Trend[],
    thresholds: Threshold[]
  ): DynamicThreshold[] {
    const thresholdsNewToOld: Threshold[] = thresholds
      .map((t) => {
        t.timestamp = new Date(t.Timestamp);
        return t;
      })
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

    const targetOldToNew: Trend[] = target
      .map((t) => {
        t.timestamp = new Date(t.TimeStamp);
        return t;
      })
      .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

    const dynamicThresholds: DynamicThreshold[] = targetOldToNew.map((t) => {
      let threshold: any = thresholdsNewToOld[0];
      for (let i = 0; i < thresholdsNewToOld.length; i++) {
        if (t.timestamp.getTime() > thresholdsNewToOld[i].timestamp.getTime()) {
          threshold = thresholdsNewToOld[i];
          break;
        }
      }

      const U_Factor = threshold.U_Factor || 1;
      const U_Offset = threshold.U_Offset || 0;
      const L_Factor = threshold.L_Factor || 1;
      const L_Offset = threshold.L_Offset || 0;

      return {
        target: t.value,
        upper: Number(t.value) * Number(U_Factor) + Number(U_Offset),
        lower: Number(t.value) * Number(L_Factor) + Number(L_Offset),
        timestamp: t.TimeStamp
      };
    });

    return dynamicThresholds;
  }
}
