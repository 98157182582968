








































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Variable } from '../classes/variable';
import { mapGetters } from 'vuex';
import { Unit } from '../classes/unit';
import { LossData } from '../classes/lossData';
import { Alarm } from '../classes/alarm';
import { MetricsService } from '../services/metricsService';
import * as moment from 'moment';
// @ts-ignore
import JsonExcel from 'vue-json-excel';
import { debounce } from 'underscore';
import { Utils } from './../utils';

const methods: any = {
  methods: {
    debounceInput: debounce(function (this: any, e: any) {
      const that: any = this as any;
      console.log(`Looking for tags with filter ${that.filter}`);
      Utils.triggerBackend(
        {
          method: 'getHistorianTags',
          parameters: {
            filter: `*${that.filter}*`,
            maxTags: 100
          }
        },
        that
      );
    }, 1000)
  }
};

@Component({
  components: {
    JsonExcel
  },
  computed: {
    ...mapGetters({
      variables: 'getVariable',
      selectedUnit: 'getSelectedUnit',
      selectedVariables: 'getSelectedVariables',
      alarms: 'getAlarms',
      selectedLossData: 'getSelectedLossData',
      selectedLossIndices: 'getSelectedLossIndices',
      isTagsActive: 'getIsTagsActive',
      historianTags: 'getHistorianTags',
      variablesOfUnit: 'getVariablesOfSelectedUnit',
      mesVariablesOfUnit: 'getMesVariablesOfSelectedUnit',
      isTagManagerActive: 'getIsTagManagerActive',
      configuration: 'getConfiguration',
      path: 'getPath',
      historyDays: 'getHistoryDays'
    })
  },
  ...methods
})
export default class VariableSelector extends Vue {
  public colorPaths: string[] = [
    'blue',
    'red',
    'orange',
    'purple',
    'green',
    'black',
    'pink',
    'sandybrown',
    'brown',
    'darkgoldenrod',
    'lightgreen',
    'grey',
    'greenyellow',
    'turquoise',
    'olive'
  ];
  public variables!: Variable[];
  public selectedUnit!: Unit;
  public selectedVariables!: Variable[];
  public alarms!: Alarm[];
  public selectedLossData!: LossData[];
  public selectedLossIndices!: any;
  public isTagsActive!: boolean;
  public isNewTag: boolean = false;
  public historianTags!: string[];
  public filter: string = '';
  public variablesOfUnit: Variable[];
  public mesVariablesOfUnit: any[];
  public isTagManagerActive!: boolean;
  public configuration!: any;
  public path!: string;
  public historyDays!: number;

  hasAlarm(variable) {
    return this.alarms.some(
      (a: any) =>
        a.Unit === variable.unit &&
        a.Variable === variable.label &&
        JSON.parse(a.Alarm)
    );
  }

  @Watch('filter')
  filterChanged() {
    //@ts-ignore
    this.debounceInput();
  }

  @Watch('historianTags')
  tagsChanged() {
    if (this.historianTags.length === 1) {
      this.filter = this.historianTags[0];
    }
  }

  get buttonProps() {
    if (!this.isNewTag) {
      return { text: '+', class: 'btn-primary' };
    } else if (this.historianTags.length === 1) {
      return { text: 'Add', class: 'btn-success' };
    } else {
      return { text: 'Search...', class: '' };
    }
  }

  get fields() {
    return {
      ['Variable Name']: 'variableName',
      ['Variable Tag']: 'variableTag'
    };
  }

  get exportVariables() {
    return this.variablesOfUnit.map((varUnit) => {
      return {
        variableName: varUnit.label,
        variableTag: varUnit.Tag
      };
    });
  }

  toggleTags() {
    this.$store.dispatch('toggleTags');
  }

  toggleTagManager() {
    let isTagManagerActive = this.$store.state.isTagManagerActive;
    MetricsService.newAction({
      type: 'click',
      kind: 'tagmanager_toggled',
      typeFormated: 'Tag Manager Toggled',
      message: `Tag Manager ${isTagManagerActive ? 'closed' : 'opened'}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.state.isTagManagerActive = !isTagManagerActive;
    if (isTagManagerActive && this.variablesOfUnit.length) {
      for (let promise of [
        ...Utils.refreshUnit(this, 1),
        ...Utils.refreshUnit(this, this.historyDays)
      ]) {
        Utils.triggerBackend(promise, this);
      }
    }
  }

  newTag() {
    if (this.buttonProps.text === 'Add') {
      this.$store.dispatch('addCustomVariable', this.filter);
      this.isNewTag = false;
      this.filter = '';
    } else {
      this.isNewTag = true;
    }
  }

  copyToCliboard(variable) {
    const input = document.createElement('input');
    input.setAttribute('value', variable.Tag);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  checkMarkColor(variable: Variable): string {
    return this.colorPaths.indexOf(variable.color) > -1
      ? variable.color
      : 'rgb';
  }

  clicked(e: any, variable: any): void {
    if (e.target) {
      MetricsService.newAction({
        type: 'click',
        kind: 'variable_clicked',
        typeFormated: 'Variable Clicked',
        message: `Variable ${variable.label} clicked`,
        time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
      });
    }

    if (e.ctrlKey || e.metaKey) {
      if (
        variable.selected &&
        this.variablesOfUnit.filter((v) => v.selected === true).length !== 1
      ) {
        this.$store.dispatch('unselectVariable', variable);
      } else if (!variable.selected) {
        this.$store.dispatch('selectVariable', variable);
      }
    } else {
      this.$store.dispatch('selectOnlyThisVariable', variable);
    }

    this.$store.dispatch(
      'variableClicked',
      this.variablesOfUnit.filter((v) => v.selected)
    );
    this.$store.dispatch('setAutoRefresh', false);
  }

  @Watch('selectedUnit')
  selectedUnitChanged() {
    if (this.selectedUnit && this.variablesOfUnit.length) {
      this.clicked({}, this.variablesOfUnit[0]);
    }
  }
}
