







import Plotly from 'plotly.js-dist/plotly.js';
import { ChartService } from '../services/chartService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Trend } from '../classes/trend';
import { Threshold } from '../classes/threshold';
import { Variable } from '../classes/variable';
import { Unit } from '../classes/unit';

@Component({
  components: {
    Plotly
  },
  computed: {
    ...mapGetters({
      trendData: 'getTrendData',
      thresholdData: 'getThresholdData',
      chartLocation: 'getChartPosition',
      selectedVariables: 'getSelectedVariables',
      selectedShift: 'getSelectedShift',
      selectedUnit: 'getSelectedUnit',
      timeRange: 'getTimeRange',
      shiftChartVisible: 'getShiftChartVisible',
      chartWidth: 'getChartWidth',
      mesTrends: 'getMesTrends'
    })
  }
})
export default class ShiftProductionChart extends Vue {
  public trendData!: Trend[];
  public thresholdData!: Threshold[];
  public chartLocation!: any;
  public selectedVariables!: Variable[];
  public shiftChartVisible!: boolean;
  public selectedUnit!: Unit;
  public chartWidth!: number;
  public selectedShift!: any;
  public timeRange!: Date[];
  public mesTrends: any[];

  public plotConfig: any = {
    scrollZoom: true,
    modeBarButtonsToRemove: [
      'toggleSpikelines',
      'resetScale2d',
      'toImage',
      'sendDataToCloud',
      'hoverCompareCartesian',
      'hoverClosestCartesian',
      'lasso2d',
      'select2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d'
    ],
    displaylogo: false,
    displayModeBar: false
  };

  get selectedTrendValues() {
    if (this.selectedVariables.length && this.trendData.length) {
      return this.selectedVariables.map((variable) => {
        if (variable.VarType !== 'MES') {
          return this.trendData
            .filter((trend) => {
              return variable.id in trend;
            })
            .map((trend) => {
              return trend[variable.id];
            });
        } else {
          return this.mesTrends
            .filter((t) => t.Var_ID.toString() === variable.Input_Tag)
            .map((v) => parseFloat(v.Result));
        }
      });
    }
    return [];
  }

  get selectedTrendDates(): any[] {
    if (this.selectedVariables.length && this.trendData) {
      return this.selectedVariables.map((variable: Variable) => {
        if (variable.VarType !== 'MES') {
          return this.trendData
            .filter((trend: Trend) => variable.id in trend)
            .map((trend: Trend) => new Date(trend.TimeStamp));
        } else {
          return this.mesTrends
            .filter((t) => t.Var_ID.toString() === variable.Input_Tag)
            .map((v) => v.Result_On);
        }
      });
    } else {
      return [];
    }
  }

  get shiftProductionDataForPlot(): any {
    return ChartService.secondChartData(this);
  }

  get plotData(): any {
    if (!this.selectedUnit) {
      return;
    }

    return this.shiftProductionDataForPlot;
  }

  get plotLayout(): any {
    const layout: any = {
      showlegend: false,
      xaxis: {
        domain: [0.05 * (this.selectedVariables.length - 1), 1],
        range: this.timeRange,
        showgrid: false
      },
      width: this.chartWidth,
      height: 200,
      margin: {
        t: 20,
        l: 40,
        r: 10,
        b: 60
      },
      dragmode: false
    };

    if (this.selectedVariables.length === 1) {
      const min = Math.min(...this.plotData[0].y);
      const max = Math.round(Math.max(...this.plotData[0].y));
      let plusSymbol = max >= 1 ? 1 : 0.5;
      layout.yaxis = {
        range: [min > 0 ? 0 : min - plusSymbol, max + plusSymbol]
      };
    }

    if (this.selectedVariables.length === 2) {
      layout.yaxis = {
        range: [
          Math.round(Math.min(...this.plotData[0].y)),
          Math.round(Math.max(...this.plotData[0].y))
        ],
        position: 0.05 * (this.selectedVariables.length - 1)
      };
    }

    return layout;
  }

  @Watch('chartLocation')
  chartLocationChanged() {
    if (
      this.chartLocation &&
      this.plotData &&
      this.plotData[0] &&
      this.shiftChartVisible
    ) {
      let plotMethod = (this.$el as any).data ? 'react' : 'newPlot';
      const plotData = ChartService.secondChartData(this);
      const yAxisChanges: any = {};
      if (this.selectedVariables.length === 1) {
        const min = Math.min(...plotData[0].y);
        const max = Math.round(Math.max(...plotData[0].y));
        let plusSymbol = max >= 1 ? 1 : 0.5;
        yAxisChanges.yaxis = {
          range: [min > 0 ? 0 : min - plusSymbol, max + plusSymbol]
        };
      }

      if (this.selectedVariables.length === 2) {
        yAxisChanges.yaxis = {
          range: [
            Math.round(Math.min(...plotData[0].y)),
            Math.round(Math.max(...plotData[0].y))
          ]
        };
      }
      Plotly[plotMethod](
        this.$el,
        plotData,
        { ...this.plotLayout, ...yAxisChanges },
        this.plotConfig
      );
    }
  }

  @Watch('shiftChartVisible')
  shiftChartVisibleChanged() {
    if (this.shiftChartVisible) {
      setTimeout(() => {
        let plotMethod = (this.$el as any).data ? 'react' : 'newPlot';
        const plotData = ChartService.secondChartData(this);
        Plotly[plotMethod](
          this.$el,
          plotData,
          this.plotLayout,
          this.plotConfig
        );
      }, 500);
    }
  }

  @Watch('selectedTrendValues')
  selectedTrendValuesChanged() {
    if (this.selectedTrendValues.length && this.shiftChartVisible) {
      let plotMethod = (this.$el as any).data ? 'react' : 'newPlot';
      if (!this.plotData || !this.plotData[0]) {
        if (plotMethod === 'react') {
          Plotly.purge(this.$el);
        }
        return;
      }
      Plotly[plotMethod](
        this.$el,
        this.plotData,
        this.plotLayout,
        this.plotConfig
      );
    }
  }
}
