















import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { MetricsService } from '../services/metricsService';
import * as moment from 'moment';

@Component({
  computed: {
    ...mapGetters({
      sidebarOpen: 'avgSidebarIsOpen',
      activeRightSidebar: 'getActiveRightSidebar',
      unreadMessagesTotal: 'getUnreadMessagesTotal'
    })
  }
})
export default class ToggleChat extends Vue {
  public sidebarOpen!: boolean;
  public unreadMessagesTotal!: number;
  public activeRightSidebar!: string;

  toggleSidebar(): void {
    MetricsService.newAction({
      type: 'click',
      kind: 'stats_button_clicked',
      typeFormated: 'Stats button was clicked',
      message: `Stats sidebar is ${this.sidebarOpen ? 'Collapsed' : 'Open'}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.dispatch('toggleAvgSidebar', 'chat');
  }
}
