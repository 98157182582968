import _, { isEmpty } from 'underscore';
import moment from 'moment';
import axios from 'axios';

export class Utils {
  private static backendJobs: string[] = [];
  private static MorningColor: string = '#ccdfff';
  private static DayColor: string = 'rgb(153, 192, 255)';
  private static NightColor: string = 'rgb(204, 223, 255)';
  private static shiftColourMap: any = [
    Utils.MorningColor,
    Utils.DayColor,
    Utils.NightColor
  ];

  public static showLogs(user: string): boolean {
    return 'ohniram_mdaapnworb_mdajytsapap_mdaretarag_mda'
      .split('')
      .reverse()
      .join('')
      .includes(user);
  }

  public static dst(dateString: any, timezoneOffset: number): Date {
    let corrected: Date;
    if (dateString) {
      let date: Date = new Date(dateString.toString());
      // Checks if date is before/after Daylight Saving Time
      // (new Date("2020-03-29T01:00:00.000Z")).getTime() === 1585443600000
      let dstOffset: number = date.getTime() > 1585443600000 ? 0 : -3.6e6;
      corrected = new Date(date.getTime() + dstOffset + timezoneOffset * 3.6e6);
    } else {
      corrected = new Date();
    }
    return corrected;
  }

  public static getShiftBackgroundColour(shift: number): string {
    if (Utils.shiftColourMap[shift]) {
      return Utils.shiftColourMap[shift];
    }

    // default
    return 'rgb(0, 0, 0)';
  }

  public static async triggerBackend(output, ctx): Promise<any> {
    const dispatch = ctx.$store.dispatch;
    // const thingworxUrl = window.location.href.includes('webclient.epi.intra') ? 'webclient.epi.intra' : 'webclientdev.epi.intra';
    const url = '/api/company';
    // const url = (process.env.NODE_ENV === 'development') ? '/api/company' : `https://webapp-dxxx5ehlmsr5u.azurewebsites.net/api/company`;
    try {
      const response = await axios.post(url, output, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          appKey: "48e0126a-25d1-48e9-ad7c-d59617a1525d"
        }
      });

      if (
        response.data &&
        response.data.rows &&
        response.data.rows[0] &&
        response.data.rows[0].result
      ) {
        dispatch("newInputJSON", {
          stringifiedData: response.data.rows[0].result,
          ctx
        });
      }
    } catch (error) {
      if (error.response.data.message?.[0]) {
        ctx.$bvToast.toast(error.response.data.message[0].message, {
          title: "Validation Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
          toastClass: "widget-rootwidget"
        });
      }
    }
  }

  public static async customVariableApi(ctx: any, path, filter): Promise<any> {
    // const thingworxUrl = window.location.href.includes('webclient.epi.intra') ? 'webclient.epi.intra' : 'webclientdev.epi.intra';
    // const url = `https://${thingworxUrl}/Thingworx/Things/OAMUXPortalDataLoader.Thing/Services/Main`;
    const url = '/api/company';
    const response = await axios.post(
      url,
      {
        method: 'getHistorianOrMesVariables',
        parameters: {
          username: ctx.$store.state.userName,
          Path: path,
          filter: filter,
          maxTags: 100
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
        }
      }
    );

    return response.data.rows[0].result.rows;
  }

  public static async autoRefresh(
    ctx: any,
    path: string,
    refreshRate: number,
    historyDays: any
  ): Promise<void> {
    const autoRefreshEnabled: boolean = ctx.$store.state.autoRefreshEnabled;
    if (!autoRefreshEnabled) {
      return;
    }

    let now = moment().valueOf();
    let lastUpdated = moment(ctx.$store.state.lastUpdated).valueOf();
    if (!lastUpdated) {
      setTimeout(
        () => Utils.autoRefresh(ctx, path, refreshRate, historyDays),
        refreshRate
      );
      // console.log('Next refresh in: ', refreshRate / 1000)
    } else if (now - lastUpdated - refreshRate > 0) {
      let requests = [
        ...this.refreshUnit(ctx, historyDays, true),
        this.getUnspecifiedLosses(path, 1)
      ];
      if (!ctx.$store.state.selectedUnit.isBatch) {
        requests = [...requests, this.getAlarms(path)];
      }
      for (let req of requests) {
        await Utils.triggerBackend(req, ctx);
      }
      setTimeout(
        () => Utils.autoRefresh(ctx, path, refreshRate, historyDays),
        refreshRate
      );
      // console.log('Next refresh in: ', refreshRate / 1000)
    } else {
      setTimeout(
        () => Utils.autoRefresh(ctx, path, refreshRate, historyDays),
        refreshRate - now + lastUpdated
      );
      // console.log('Next refresh in: ', (refreshRate - now + lastUpdated) / 1000)
    }
  }

  public static refreshUnit(
    ctx: any,
    historyDays: number = 30,
    fromAutoRefresh: boolean = false
  ): any[] {
    ctx.$store.dispatch('setAutoRefresh', fromAutoRefresh);
    if (!fromAutoRefresh) {
      ctx.$store.dispatch('setLoading', true);
      ctx.$store.dispatch('setDatabyDate', false);
    }
    const path = ctx.$store.getters.getPath;
    const selectedUnit = ctx.$store.getters.getSelectedUnit;
    const configuration = ctx.$store.getters.getConfiguration;

    const requests: any[] = [
      {
        method: 'getLossesForUnit',
        parameters: {
          Path: path,
          PUID: selectedUnit.puid,
          LanguageID: configuration[path].LanguageID,
          historyDays: historyDays
        }
      }
    ];

    if (selectedUnit.isBatch) {
      requests.push({
        method: 'getStepsAndBatches',
        parameters: {
          Path: path,
          puid: selectedUnit.puid,
          startTime: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
          stepsToIgnore: 'Ready to Use,Batch Start'
        }
      });
    } else {
      requests.push({
        method: 'getMaterialInformation',
        parameters: {
          Path: path,
          PUID: selectedUnit.puid,
          historyDays: historyDays
        }
      });
      requests.push(
        {
          method: 'getShifts',
          parameters: {
            Path: path,
            PUID: selectedUnit.puid,
            historyDays: historyDays
          }
        },
        {
          method: 'getThresholdsForUnit',
          parameters: {
            Path: path,
            PUID: selectedUnit.puid,
            Type: selectedUnit.type,
            historyDays: historyDays
          }
        }
      );
    }

    const variablesOfUnit =
      ctx.$store.getters.getVariablesOfSelectedUnit.filter(
        (v) => v.VarType === 'HISTORIAN'
      );
    let historianTags = variablesOfUnit.map((v) => v.Tag);
    let historianTagVars = variablesOfUnit.map((v) => v.var);

    const targetVariables = ctx.$store.state.targetVariables.filter(
      (t) => t.unit === selectedUnit.label
    );
    let targetTags = targetVariables.map((t) => t.Tag);
    let targetTagVars = targetVariables.map((t) => t.var);

    const mesVariablesOfUnit = ctx.$store.getters.getMesVariablesOfSelectedUnit;
    let mesTags = mesVariablesOfUnit.map((v) => v.Input_Tag).join(',');

    if (historianTags.length) {
      requests.push({
        method: 'getTrendsForVariables',
        parameters: {
          Path: path,
          Tags: [...historianTags, ...targetTags].join(','),
          VarNums: [...historianTagVars, ...targetTagVars].join(','),
          trendResolution: '0.2',
          historyDays: historyDays
        }
      });
    }
    if (mesTags.length) {
      requests.push({
        method: 'getMesTrend',
        parameters: {
          Path: path,
          Var_IDs: mesTags,
          StartTime: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          EndTime: moment().add(1, 'days').format('YYYY-MM-DD')
        }
      });
    }
    return requests;
  }

  public static getDataByDate(ctx: any, startTime: any, endTime: any): any[] {
    ctx.$store.dispatch('setAutoRefreshEnabled', false);
    ctx.$store.dispatch('setDatabyDate', true);
    ctx.$store.dispatch('setLoading', true);
    let requests: any[];

    requests = [
      {
        method: 'getLossesForUnit',
        parameters: {
          Path: ctx.path,
          PUID: ctx.selectedUnit.puid,
          LanguageID: ctx.configuration[ctx.path].LanguageID,
          StartTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          EndTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss')
        }
      },
      {
        method: 'getThresholdsForUnit',
        parameters: {
          Path: ctx.path,
          PUID: ctx.selectedUnit.puid,
          Type: ctx.selectedUnit.type,
          StartTime: moment(startTime).toDate(),
          EndTime: moment(endTime).toDate()
        }
      }
    ];

    if (!ctx.selectedUnit.isBatch) {
      requests.push({
        method: 'getShifts',
        parameters: {
          Path: ctx.path,
          PUID: ctx.selectedUnit.puid,
          StartTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          EndTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss')
        }
      });
    } else {
      requests.push({
        method: 'getStepsAndBatches',
        parameters: {
          Path: ctx.path,
          puid: ctx.selectedUnit.puid,
          startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
          stepsToIgnore: 'Ready to Use,Batch Start'
        }
      });
    }

    // let historianTags = ctx.variablesOfUnit.map((v) => v.Tag).join(',');
    // let mesTags = ctx.mesVariablesOfUnit.map((v) => v.Input_Tag).join(',');
    const selectedUnit = ctx.$store.getters.getSelectedUnit;
    const variablesOfUnit =
      ctx.$store.getters.getVariablesOfSelectedUnit.filter(
        (v) => v.VarType === 'HISTORIAN'
      );
    let historianTags = variablesOfUnit.map((v) => v.Tag);
    let historianTagVars = variablesOfUnit.map((v) => v.var);

    const targetVariables = ctx.$store.state.targetVariables.filter(
      (t) => t.unit === selectedUnit.label
    );
    let targetTags = targetVariables.map((t) => t.Tag);
    let targetTagVars = targetVariables.map((t) => t.var);

    const mesVariablesOfUnit = ctx.$store.getters.getMesVariablesOfSelectedUnit;
    let mesTags = mesVariablesOfUnit.map((v) => v.Input_Tag).join(',');

    if (historianTags.length) {
      requests.push({
        method: 'getTrendsForVariables',
        parameters: {
          Path: ctx.path,
          Tags: [...historianTags, ...targetTags].join(','),
          VarNums: [...historianTagVars, ...targetTagVars].join(','),
          trendResolution: '0.2',
          StartTime: moment(startTime).toDate(),
          EndTime: moment(endTime).toDate()
        }
      });
    }
    if (mesTags.length) {
      requests.push({
        method: 'getMesTrend',
        parameters: {
          Path: ctx.path,
          Var_IDs: mesTags,
          StartTime: moment(startTime).format('YYYY-MM-DD'),
          EndTime: moment(endTime).format('YYYY-MM-DD')
        }
      });
    }

    return requests;
  }

  public static initData(ctx: any, path: string): any[] {
    return [
      {
        method: 'readConfiguration',
        parameters: {
          foo: 'bar'
        }
      },
      {
        method: 'getAccess',
        parameters: {
          Path: path
        }
      },
      {
        method: 'readTagData',
        parameters: { userName: ctx.$store.state.userName }
      },
      {
        method: 'getSession',
        parameters: { foo: 'bar' }
      },
      {
        method: 'getUtcTime',
        parameters: {
          Path: path
        }
      },
      {
        method: 'getVariables',
        parameters: {
          Path: path
        }
      },
      {
        method: 'getUnitTypes',
        parameters: {
          Path: path
        }
      }
    ];
  }

  public static getAlarms(path: string): any {
    return {
      method: 'getAlarms',
      parameters: {
        Path: path
      }
    };
  }

  public static getUnspecifiedLosses(path: string, historyDays: number): any {
    return {
      method: 'getUnspecifiedLosses',
      parameters: {
        Path: path,
        historyDays: historyDays
      }
    };
  }

  public static fnLocationFlatten(fnLocationEvent, functionalLocation) {
    const recursiveChildren = (obj, title, parent, input) => {
      const output: any = {
        child: title,
        parent: parent,
        description: obj.description
      };
      if (!isEmpty(obj.children)) {
        for (let child in obj.children) {
          input.push(
            recursiveChildren(obj.children[child], child, title, input)
          );
        }
      }
      return output;
    };
    const output: any[] = [];
    for (let row in functionalLocation) {
      output.push(
        recursiveChildren(
          functionalLocation[row],
          row,
          fnLocationEvent.id,
          output
        )
      );
    }

    return output;
  }

  public static buildFunctionalLocationTree(
    data: any,
    ID_KEY: any,
    PARENT_KEY: any,
    getSelectedUnit: string = 'root'
  ): any {
    const genNode = (description: any): any => {
      return {
        description,
        children: {}
      };
    };
    let pr = -1;
    const buildList = (
      data: any,
      currentParents: any = {},
      tree: any = {}
    ): any => {
      let remaining: any = [],
        nextParents: any = {};

      for (let i = 0, length = data.length; i < length; i++) {
        let id = data[i][ID_KEY],
          parentId = data[i][PARENT_KEY],
          description = data[i].description;

        if (_.isEmpty(currentParents) && parentId === getSelectedUnit) {
          tree[id] = genNode(description);
          nextParents[id] = tree[id];
        } else if (currentParents[parentId]) {
          nextParents[id] = currentParents[parentId].children[id] =
            genNode(description);
        } else if (parentId !== undefined) {
          remaining.push(data[i]);
        }
      }

      if (remaining.length && pr !== remaining.length) {
        pr = remaining.length;
        return buildList(remaining, nextParents, tree);
      } else {
        return tree;
      }
    };

    // recursive function builds tree
    return buildList(data);
  }

  public static buildReasonTree(treeRows: any): any {
    const genNode = (id: any, text: any, ERTD_Id): any => {
      return {
        id,
        // filter out line breaks at the end
        text: text.split(/\r?\n/)[0],
        ERTD_Id,
        type: 'row',
        count: 0,
        children: {}
      };
    };
    // recursive function
    const buildTree = (
      remainingRows: any,
      depth: number = 1,
      tree: any = {}
    ): any => {
      let remaining: any[] = [];
      let prev_remaining = remainingRows.length;
      remainingRows.forEach((row: any) => {
        if (row[`er${depth + 1}_Id`] === undefined) {
          // Defines Category for the first time
          if (tree[row.Cat_Id] === undefined) {
            tree[row.Cat_Id] = genNode(
              row.Cat_Id,
              row.Category,
              row.Event_Reason_Tree_Data_Id
            );
          }
          let parent = tree[row.Cat_Id];
          let depthLevel = 0;
          // nests entry in its corresponding place of the tree
          while (++depthLevel < depth) {
            parent = parent.children[row[`er${depthLevel}_Id`]];
          }
          if (!parent || !parent.children) {
            console.warn(`${row.Category} doesnt have any children`);
          }
          parent.children[row[`er${depth}_Id`]] = genNode(
            row.id,
            row[`er${depth}_Name`],
            row.Event_Reason_Tree_Data_Id
          );
        } else {
          remaining.push(row);
        }
      });
      if (remaining.length === prev_remaining) {
        console.warn(
          `Issues building reason tree. ${prev_remaining} rows not processed.`
        );
        return tree;
      } else if (remaining.length) {
        return buildTree(remaining, ++depth, tree);
      } else {
        return tree;
      }
    };
    // recursive function builds tree
    return buildTree(treeRows);
  }

  public static resolveBackend(method) {
    const index = Utils.backendJobs.indexOf(method);
    if (index > -1) {
      Utils.backendJobs.splice(index, 1);
    }
  }

  private static splitDPPEMQ(DPPEMQ: any) {
    let [downtime, speedloss, pem] = DPPEMQ.split(',');
  }

  private static listToTree(data: any, options: any): any {
    options = options || {};
    let ID_KEY: string = options.idKey || 'id',
      PARENT_KEY: string = options.parentKey || 'parent',
      CHILDREN_KEY: string = options.childrenKey || 'children',
      tree: any[] = [],
      childrenOf: any = {},
      item: any,
      id: any,
      parentId: any;

    for (let i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || 0;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId != 0) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }

    return tree;
  }

  private static checkIfDone(method: string): boolean | any {
    if (Utils.backendJobs.indexOf(method) > -1) {
      return setTimeout(() => Utils.checkIfDone(method), 200);
    }
    return true;
  }
}
