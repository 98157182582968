var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeRightSidebar === "reports"
    ? _c(
        "div",
        {
          staticClass: "settings-sidebar fixed end",
          class: { expanded: _vm.sidebarOpen, collapsed: !_vm.sidebarOpen }
        },
        [
          _c(
            "div",
            { staticClass: "main-container main-container-fixed" },
            [
              _c(
                "perfect-scrollbar",
                { staticStyle: { "max-height": "100% !important" } },
                [
                  _c("div", { staticClass: "scrollable" }, [
                    _c("div", { staticClass: "buttons-wrapper" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.links, function(link, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "bullet",
                                attrs: { icon: "circle" }
                              }),
                              _c(
                                "a",
                                {
                                  staticClass: "btn-link btn text-left",
                                  attrs: { target: "_blank", href: link.href },
                                  on: {
                                    click: function($event) {
                                      return _vm.linkClicked(link)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(link.title) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }