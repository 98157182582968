var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "time-range-buttons" },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-flex", "margin-right": "5px" } },
            [
              _c("label", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v("Auto Refresh")
              ]),
              _c("label", { staticClass: "switch" }, [
                _c("input", {
                  attrs: { type: "checkbox" },
                  domProps: { checked: _vm.isAutoRefreshEnabled },
                  on: { click: _vm.toggleAutoRefresh }
                }),
                _c("span", { staticClass: "slider round" })
              ])
            ]
          ),
          _vm.range.startDate
            ? _c("date-range-picker", {
                ref: "dataRangePicker",
                attrs: {
                  autoApply: true,
                  "locale-data": _vm.localeData,
                  ranges: _vm.ranges,
                  timePicker: false,
                  opens: "left"
                },
                on: { toggle: _vm.pickerToggled, update: _vm.setRange },
                model: {
                  value: _vm.range,
                  callback: function($$v) {
                    _vm.range = $$v
                  },
                  expression: "range"
                }
              })
            : _vm._e(),
          _vm.selectedUnit &&
          _vm.selectedUnit.puid &&
          _vm.selectedUnit.allowManualLosses
            ? _c(
                "b-dropdown",
                {
                  attrs: { id: "add-loss", size: "sm", variant: "primary" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: "plus" }
                            }),
                            _vm._v(" Loss ")
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3744134751
                  )
                },
                [
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openModal("downtime")
                        }
                      }
                    },
                    [_vm._v("Downtime ")]
                  ),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openModal("performance")
                        }
                      }
                    },
                    [_vm._v("Performance ")]
                  ),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openModal("quality")
                        }
                      }
                    },
                    [_vm._v("Quality ")]
                  ),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openModal("pem")
                        }
                      }
                    },
                    [_vm._v("PEM ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-dropdown",
            {
              attrs: { id: "grid-items", size: "sm", variant: "primary" },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("font-awesome-icon", { attrs: { icon: "chart-line" } })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { active: _vm.showGrid === null },
                  on: {
                    click: function($event) {
                      return _vm.setGrid(null)
                    }
                  }
                },
                [_vm._v("None ")]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { active: _vm.showGrid === "vertical_horizontal" },
                  on: {
                    click: function($event) {
                      return _vm.setGrid("vertical_horizontal")
                    }
                  }
                },
                [_vm._v("Vertical & Horizontal ")]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { active: _vm.showGrid === "vertical" },
                  on: {
                    click: function($event) {
                      return _vm.setGrid("vertical")
                    }
                  }
                },
                [_vm._v("Vertical ")]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { active: _vm.showGrid === "horizontal" },
                  on: {
                    click: function($event) {
                      return _vm.setGrid("horizontal")
                    }
                  }
                },
                [_vm._v("Horizontal ")]
              )
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              attrs: { title: "Screenshot" },
              on: { click: _vm.takeImage }
            },
            [_c("font-awesome-icon", { attrs: { icon: "camera" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              class: {
                "btn-danger": _vm.shiftChartVisible,
                "btn-primary": !_vm.shiftChartVisible
              },
              attrs: { title: "Accumulated shift chart" },
              on: { click: _vm.toggleShiftChartArea }
            },
            [_c("font-awesome-icon", { attrs: { icon: "chart-area" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              class: {
                "btn-primary": !_vm.trendChartYAxisLock,
                "btn-danger": _vm.trendChartYAxisLock
              },
              attrs: { title: "Lock Y Axis" },
              on: { click: _vm.toggleTrendChartYAxisLock }
            },
            [_c("font-awesome-icon", { attrs: { icon: "lock" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              class: { active: _vm.dragMode === "select" },
              attrs: { title: "Select Box" },
              on: {
                click: function($event) {
                  return _vm.setDragMode("select")
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "icon",
                  attrs: {
                    height: "1em",
                    viewBox: "0 0 1000 1000",
                    width: "1em"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "m0 850l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m285 0l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m-857-286l0-143 143 0 0 143-143 0z m857 0l0-143 143 0 0 143-143 0z m-857-285l0-143 143 0 0 143-143 0z m857 0l0-143 143 0 0 143-143 0z m-857-286l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m285 0l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z",
                      transform: "matrix(1 0 0 -1 0 850)"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              class: { active: _vm.dragMode === "lasso" },
              attrs: { title: "Lasso" },
              on: {
                click: function($event) {
                  return _vm.setDragMode("lasso")
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "icon",
                  attrs: {
                    height: "1em",
                    viewBox: "0 0 1031 1000",
                    width: "1em"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "m1018 538c-36 207-290 336-568 286-277-48-473-256-436-463 10-57 36-108 76-151-13-66 11-137 68-183 34-28 75-41 114-42l-55-70 0 0c-2-1-3-2-4-3-10-14-8-34 5-45 14-11 34-8 45 4 1 1 2 3 2 5l0 0 113 140c16 11 31 24 45 40 4 3 6 7 8 11 48-3 100 0 151 9 278 48 473 255 436 462z m-624-379c-80 14-149 48-197 96 42 42 109 47 156 9 33-26 47-66 41-105z m-187-74c-19 16-33 37-39 60 50-32 109-55 174-68-42-25-95-24-135 8z m360 75c-34-7-69-9-102-8 8 62-16 128-68 170-73 59-175 54-244-5-9 20-16 40-20 61-28 159 121 317 333 354s407-60 434-217c28-159-121-318-333-355z",
                      transform: "matrix(1 0 0 -1 0 850)"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              class: { active: _vm.dragMode === "zoom" },
              attrs: { title: "Zoom" },
              on: {
                click: function($event) {
                  return _vm.setDragMode("zoom")
                }
              }
            },
            [_c("font-awesome-icon", { attrs: { icon: "search-plus" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              class: { active: _vm.dragMode === "pan" },
              attrs: { title: "Pan" },
              on: {
                click: function($event) {
                  return _vm.setDragMode("pan")
                }
              }
            },
            [_c("font-awesome-icon", { attrs: { icon: "expand-arrows-alt" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              class: { active: _vm.isProductChartActive === true },
              attrs: { title: "Product Chart" },
              on: {
                click: function($event) {
                  return _vm.toggleProductChart()
                }
              }
            },
            [_c("font-awesome-icon", { attrs: { icon: "flask" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn-primary btn btn-sm",
              on: {
                click: function($event) {
                  return _vm.refresh(true)
                }
              }
            },
            [_c("font-awesome-icon", { attrs: { icon: "sync" } })],
            1
          )
        ],
        1
      ),
      _c("AddLossModal", {
        ref: "modal",
        attrs: { "loss-type": _vm.lossType },
        on: {
          onClose: function($event) {
            _vm.lossType = null
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }