var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeRightSidebar === "chat"
    ? _c(
        "div",
        {
          staticClass: "settings-sidebar fixed end",
          class: { expanded: _vm.sidebarOpen, collapsed: !_vm.sidebarOpen }
        },
        [
          _c(
            "div",
            { staticClass: "main-container main-container-fixed" },
            [
              _c("div", { staticClass: "tab-btns text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm",
                    class: {
                      "btn-primary": _vm.btnSelected === "chat",
                      "btn-secondary": _vm.btnSelected !== "chat"
                    },
                    on: {
                      click: function($event) {
                        return _vm.setBtn("chat")
                      }
                    }
                  },
                  [_vm._v(" Chat ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm",
                    class: {
                      "btn-primary": _vm.btnSelected === "wishlist",
                      "btn-secondary": _vm.btnSelected !== "wishlist"
                    },
                    on: {
                      click: function($event) {
                        return _vm.setBtn("wishlist")
                      }
                    }
                  },
                  [_vm._v(" Wishlist ")]
                )
              ]),
              _c(
                "perfect-scrollbar",
                { staticStyle: { "max-height": "100% !important" } },
                [
                  _c("div", { staticClass: "scrollable" }, [
                    _vm.btnSelected === "chat"
                      ? _c(
                          "div",
                          [
                            _vm.chatView === "list"
                              ? _c("ChatListing", {
                                  on: {
                                    goToConversation: _vm.goToConversation,
                                    goToNewGroup: _vm.goToNewGroup
                                  }
                                })
                              : _vm._e(),
                            _vm.chatView === "chat" && _vm.conversation
                              ? _c("ChatConversation", {
                                  attrs: { conversation: _vm.conversation },
                                  on: {
                                    goToList: _vm.goToList,
                                    newMessage: _vm.onNewMessage
                                  }
                                })
                              : _vm._e(),
                            _vm.chatView === "newConversation"
                              ? _c("NewConversationList", {
                                  attrs: { "users-list": _vm.users },
                                  on: { back: _vm.back, newGroup: _vm.newGroup }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.btnSelected === "wishlist"
                      ? _c(
                          "div",
                          [
                            _vm.chatView === "list"
                              ? _c("WishListing", {
                                  on: {
                                    newWish: _vm.newWish,
                                    goToConversation: _vm.goToConversation
                                  }
                                })
                              : _vm._e(),
                            _vm.chatView === "chat"
                              ? _c("ChatConversation", {
                                  attrs: { conversation: _vm.conversation },
                                  on: {
                                    goToList: _vm.goToList,
                                    newMessage: _vm.onNewMessage
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }