var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "favorites-settings-modal",
          attrs: {
            id: "favorites-settings-modal",
            "no-fade": true,
            title: "Favorites Settings Modal",
            size: "lg",
            "ok-title": _vm.$t("lossModal.submit")
          },
          on: {
            shown: _vm.modalShown,
            hidden: _vm.modalTeardown,
            ok: _vm.sumbitBtn
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          staticStyle: { margin: "0 5px" },
                          attrs: { variant: "primary" },
                          on: { click: _vm.sumbitBtn }
                        },
                        [_vm._v(" Save ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          staticStyle: { margin: "0 5px" },
                          attrs: { variant: "danger" },
                          on: { click: _vm.deleteView }
                        },
                        [_vm._v(" Delete ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          staticStyle: { margin: "0 5px" },
                          attrs: { variant: "secondary" },
                          on: { click: _vm.hideModal }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "widget-rootwidget" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c(
                  "ul",
                  { staticClass: "nav flex-column" },
                  _vm._l(_vm.favoritesViews, function(view, index) {
                    return _c(
                      "li",
                      {
                        staticClass: "nav-item view-list",
                        class: { active: _vm.editIndex === index },
                        on: {
                          click: function($event) {
                            return _vm.setViewForUpdate(view, index)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(view.label) + " ")]
                    )
                  }),
                  0
                )
              ]),
              _vm.modalData
                ? _c("div", { staticClass: "col-md-8" }, [
                    _c("table", { staticStyle: { width: "100%" } }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Name")]),
                          _c("th", [_vm._v("Unit")])
                        ])
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", { attrs: { width: "50%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.modalData.label,
                                  expression: "modalData.label"
                                }
                              ],
                              staticClass: "form-control input-sm",
                              staticStyle: { "max-width": "70%" },
                              attrs: { type: "text" },
                              domProps: { value: _vm.modalData.label },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.modalData,
                                    "label",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("td", { attrs: { width: "50%" } }, [
                            _vm._v(_vm._s(_vm.modalData.data.unit.label))
                          ])
                        ])
                      ])
                    ]),
                    _c(
                      "table",
                      { staticStyle: { width: "100%", "margin-top": "20px" } },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Variables")]),
                            _c("th", [_vm._v("Y axis")])
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.modalData.data.variables, function(
                            variable,
                            index
                          ) {
                            return _c("tr", [
                              _c("td", { attrs: { width: "50%" } }, [
                                _vm._v(" " + _vm._s(variable.label) + " ")
                              ]),
                              _c(
                                "td",
                                { attrs: { width: "50%" } },
                                [
                                  _c(
                                    "b-form-radio-group",
                                    {
                                      attrs: {
                                        name: "radio-sub-component_" + index
                                      },
                                      model: {
                                        value: variable.yaxis,
                                        callback: function($$v) {
                                          _vm.$set(variable, "yaxis", $$v)
                                        },
                                        expression: "variable.yaxis"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-form-radio",
                                        { attrs: { value: "manual" } },
                                        [
                                          _vm._v(
                                            "[" +
                                              _vm._s(variable.range[0]) +
                                              ", " +
                                              _vm._s(variable.range[1]) +
                                              "] "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        { attrs: { value: "auto" } },
                                        [_vm._v("auto")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }