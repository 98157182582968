export default {
  en: {
    app: {
      no_access: 'User has no access to',
      access_denied: 'Access Denied'
    },
    sidebar: {
      labels: {
        productionUnit: 'Production unit',
        variables: 'Variables'
      }
    },

    timeRange: {
      month: 'month',
      week: 'week',
      day: 'day'
    },
    auditTrail: {
      modalTitle: 'Audit Trail for',
      close: 'Close',
      noResultsFound: 'No results found!',
      updateTime: 'Update Time',
      updateUser: 'Update User',
      startTime: 'Start Time',
      endTime: 'End Time',
      duration: 'Duration',
      cause1: 'Cause1',
      cause2: 'Cause2',
      cause3: 'Cause3',
      cause4: 'Cause4',
      timestamp: 'Timestamp',
      productionEvent: 'Production Event',
      amountParameter: 'Amount Parameter',
      enteredIn: 'Entered In',
      location: 'Location',
      noEndTime: 'No End Time',
      fault: 'Fault'
    },

    lossTableAUM: {
      headings: {
        stepName: 'Step Name',
        rawMaterial: 'Raw Material',
        event_Num: 'Batch Num',
        finalProduct_Desc: 'Product Name',
        maintenanceNotificationNumber: 'Maintenance Notification Number',
        cost: 'Cost',
        lossMinutes: 'Loss Minutes',
        lossQuantity: 'Loss Quantity',
        OAMCategory: 'OAM Category',
        OAMFactor: 'OAM Factor',
        localReason: 'Local Reason',
        startTime: 'Start Time',
        lossSource: 'Loss Type',
        functionalLocation: 'Functional Location',
        endTime: 'End Time',
        RCPSStatus: 'RCPS Status',
        RCPSComment: 'RCPS Comment',
        comment: 'Comment',
        actualDuration: 'Step Duration',
        stepMac: 'Step MAC',
        localReason2: 'Local Reason 2',
        localReason3: 'Local Reason 3',
        actions: 'Actions',
        auditTrail: 'Audit Trail',
        categoriseEvent: 'Categorise Event'
      }
    },
    addLoss: {
      save: 'Save',
      startTime: 'Start Time',
      startTimeSearch: 'Search Event Start Time',
      endTime: 'End Time',
      endTimeSearch: 'Search Event End Time',
      enterDate: 'Enter Date',
      noTimeSelected: 'No Time Selected',
      eventNumber: 'Event Number',
      timestamp: 'Timestamp',
      materialCode: 'Material Code',
      materialDescription: 'Material Description',
      pleaseSelectDates: 'Select Start and End time first!',
      notNeeded: 'Not needed for downtime events',
      noResultsFound: 'No Results Found!',
      material: 'Material',
      producedQuantity: 'Produced Quantity',
      materialEmpty: 'Please select an option',
      lossQuantity: 'Loss Quantity',
      cancelEvent: 'Cancel Event',
      createEvent: 'Create Event',
      rawFormulation: 'Formulation',
      rawMaterialCode: 'Raw Material/Code',
      rawQuantity: 'Std Qty',
      rawUnitOfMeasures: 'Unit Of Measure',
      endTimeBeforeStart: 'End time cannot be before start time',
      biggerOrSmallerThanZero:
        'Loss quantity should be smaller or bigger than 0'
    },
    lossModal: {
      open: 'Open',
      closed: 'Closed',
      reason: 'Reason',
      rcps: 'RCPS',
      functionalLocation: 'Functional Location',
      equipment: 'Equipment',
      description: 'Description',
      technicalId: 'Technical ID',
      sortField: 'Sort Field',
      submit: 'Submit',
      cancel: 'Cancel',
      comment: 'Comment',
      rcpsComment: 'RCPS Comment',
      search: 'Search',
      splitDuration: 'Split Loss',
      splitDurationInvalidInput: 'Split duration cannot be greater than loss minutes',
      splitQuantityNoEndTimeInvalidInput: 'Can not split by quantity for unfinished events',
      splitQuantityInvalidInput: 'Split quantity cannot be greater than loss quantity',
      min: 'min',
      hour: 'hour',
      delete: 'Delete',
      confirmDelete: 'Please confirm that you want to delete this loss.',
      confirmTitle: 'Delete Loss',
      maintenanceNotification: {
        title: 'Maintenance Notification',
        findButton: 'Find Maintenance Notification',
        clearSelectedButton: 'Clear Selected',
        tableNumber: 'Notification',
        tableDate: 'Date',
        tableType: 'Type',
        tableDescription: 'Description',
        tableDescriptionLong: 'Description Long',
        tableFunctionalLocationName: 'Functional Location',
        tableEquipmentName: 'Equipment',
        tableEquipmentTechnicalId: 'Equipment Technical ID',
        filterLastXDays: 'Last X Days',
        filterLastXDaysMessage: 'If either Date From or Date To is provided then Last X Days is ignored. If Last X Days is not provided it will default to Last 7 Days.',
        filterDateFrom: 'Date From',
        filterDateTo: 'Date To',
        filterNumber: 'Maint. Notif.',
        filterType: 'Type',
        filterFunctionalLocation: 'Func. Loc.',
        filterDescription: 'Description',
        filterEquipmentTechnicalId: 'Equip. Tech. ID',
      }
    },
    trendChart: {
      thresholdNames: {
        GainTolerence: 'Gain',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        MarketDemand: 'Planned Speed',
        LossTolerence: 'Loss Tolerance',
        RCPS: 'Adhoc',
        Target: 'Target'
      }
    },
    timeRangeButtons: {
      today: 'Today',
      last3days: 'Last 3 Days',
      last7days: 'Last 7 Days',
      last30days: 'Last 30 Days',
      apply: 'Apply',
      cancel: 'Cancel'
    }
  },
  pt: {
    sidebar: {
      labels: {
        productionUnit: 'Unidade de Produção',
        variables: 'Variáveis'
      }
    },

    lossTableAUM: {
      headings: {
        stepName: 'Tipo de perda',
        event_Num: 'número de lote',
        finalProduct_Desc: 'nome do produto',
        maintenanceNotificationNumber: 'Maintenance Notification Number',
        cost: 'Custo',
        lossMinutes: 'Minutos de perda',
        lossQuantity: 'Quantidade de perda',
        OAMCategory: 'Categoria OAM',
        OAMFactor: 'Fator OAM',
        localReason: 'Razão Local',
        startTime: 'Inicio',
        endTime: 'Fim',
        comment: 'Comentário',
        ActualDuration: 'Step Duration',
        StepMac: 'Step MAC'
      }
    },
    lossModal: {
      open: 'Aberto',
      closed: 'Fechado',
      reason: 'Razão',
      rcps: 'RCPS',
      functionalLocation: 'Localização Funcional',
      equipment: 'Equipamento',
      description: 'Descrição',
      submit: 'Enviar',
      cancel: 'Cancelar',
      comment: 'Comentário',
      rcpsComment: 'Comentário RCPS',
      search: 'Buscar',
      min: 'min',
      hour: 'hour'
    },
    timeRangeButtons: {
      today: 'Hoje',
      last3days: 'Últimos 3 Dias',
      last7days: 'Últimos 7 Dias',
      last30days: 'Últimos 30 Dias',
      apply: 'Aplicar',
      cancel: 'Cancelar'
    },

    timeRange: {
      month: 'mês',
      week: 'semana',
      day: 'dia'
    },

    trendChart: {
      thresholdNames: {
        GainTolerence: 'Gain',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        MarketDemand: 'Demanda de Mercado',
        LossTolerence: 'Limites Operacionais',
        RCPS: 'Adhoc',
        Target: 'Target'
      }
    }
  },
  fr: {
    sidebar: {
      labels: {
        productionUnit: 'Unité de production',
        variables: 'Variables'
      }
    },

    timeRange: {
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour'
    },

    lossTableAUM: {
      headings: {
        stepName: 'Type de perte',
        cost: 'Coût',
        lossMinutes: 'Perte en minutes',
        lossQuantity: 'Perte en quantité',
        OAMCategory: 'OAM Catégorie',
        OAMFactor: 'OAM facteur',
        localReason: 'Raison Locale',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        comment: 'Commentaires',
        ActualDuration: 'Step Duration',
        StepMac: 'Step MAC',
        min: 'min',
        hour: 'hour'
      }
    },

    lossModal: {
      open: 'Ouvert',
      closed: 'Fermé',
      reason: 'Raison',
      rcps: 'RCPS',
      functionalLocation: 'Localisation fonctionnel',
      maintenanceNotification: 'Maintenance Notification',
      equipment: 'Équipement',
      description: 'Description',
      submit: 'Soumettre',
      comment: 'Commentaire',
      rcpsComment: 'RCPS Commentaire'
    },
    trendChart: {
      thresholdNames: {
        'Tolérance sur le gain': 'Gain',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        'Demande du marché': 'Demande du marché',
        'Tolérance sur la perte': 'Tolérance sur la perte',
        RCPS: 'Adhoc',
        Cible: 'Cible'
      }
    }
  },
  sv: {
    sidebar: {
      labels: {
        productionUnit: 'Produktionsenhet',
        variables: 'Variabler'
      }
    },

    timeRange: {
      month: 'månad',
      week: 'vecka',
      day: 'dag'
    },

    lossTableAUM: {
      headings: {
        stepName: 'Typ av förlust',
        cost: 'Kostnad',
        lossMinutes: 'Förlust minuter',
        lossQuantity: 'Förlust kvantitet',
        OAMCategory: 'OAM kategori',
        OAMFactor: 'OAM faktor',
        localReason: 'Lokal orsak',
        startTime: 'Starttid',
        endTime: 'Sluttid',
        comment: 'Kommentar',
        ActualDuration: 'Step Duration',
        StepMac: 'Step MAC'
      }
    },

    lossModal: {
      open: 'Öppen',
      closed: 'Stängd',
      reason: 'Orsak',
      rcps: 'RCPS',
      functionalLocation: 'Plats',
      maintenanceNotification: 'Maintenance Notification',
      equipment: 'Utrustning',
      description: 'Beskrivning',
      submit: 'Godkänna',
      min: 'min',
      hour: 'hour'
    },
    trendChart: {
      thresholdNames: {
        GainTolerence: 'Gränsvärde vinst',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        MarketDemand: 'Marknadsbehov',
        LossTolerence: 'Gränsvärden',
        RCPS: 'Adhoc',
        Target: 'Mål'
      }
    },
    timeRangeButtons: {
      today: 'I dag',
      last3days: '3 dagar',
      last7days: '7 dagar',
      last30days: '30 dagar',
      apply: 'Apply',
      cancel: 'Cancel'
    }
  },
  de: {
    app: {
      no_access: 'User has no access to',
      access_denied: 'Access Denied'
    },
    sidebar: {
      labels: {
        productionUnit: 'Betriebseinheit',
        variables: 'Variable'
      }
    },

    timeRange: {
      month: 'Monat',
      week: 'Woche',
      day: 'Tag'
    },

    lossTableAUM: {
      headings: {
        stepName: 'Verlustart',
        cost: 'Kosten (€)',
        lossMinutes: 'Verlustdauer (min)',
        lossQuantity: 'Verlust (t)',
        OAMCategory: '1. Kategorie',
        OAMFactor: '2. Unterkategorie',
        localReason: '3. Beschreibung',
        startTime: 'Verlust Beginn',
        lossSource: 'Loss Type',
        functionalLocation: 'FLOC',
        endTime: 'Verlust Ende',
        RCPSStatus: 'RCPS Status',
        RCPSComment: 'RCPS Kommentar',
        comment: 'Kommentar',
        ActualDuration: 'Step Duration',
        StepMac: 'Step MAC'
      }
    },

    lossModal: {
      open: 'Offen',
      closed: 'Bearbeitet',
      reason: 'Ursache',
      rcps: 'RCPS',
      functionalLocation: 'FLOC',
      maintenanceNotification: 'Maintenance Notification',
      equipment: 'Equipment',
      description: 'Beschreibung',
      submit: 'Bestätigung',
      cancel: 'Cancel',
      comment: 'Kommentar',
      rcpsComment: 'RCPS Komemntar',
      search: 'Search',
      splitDuration: 'Split Loss',
      splitDurationInvalidInput: 'Split duration cannot be greater than loss minutes',
      splitQuantityNoEndTimeInvalidInput: 'Can not split by quantity for unfinished events',
      splitQuantityInvalidInput: 'Split quantity cannot be greater than loss quantity',
      min: 'min',
      hour: 'hour'
    },
    trendChart: {
      thresholdNames: {
        GainTolerence: 'Gewinn',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        MarketDemand: 'Planung',
        LossTolerence: 'Betriebsfenster',
        RCPS: 'RCPS Grenze',
        Target: 'Ziel'
      }
    },
    timeRangeButtons: {
      today: 'Heute',
      last3days: 'Letzte 3 Tage',
      last7days: 'Letzte 7 Tage',
      last30days: 'Letzte 30 Tage',
      apply: 'Ok',
      cancel: 'Cancel'
    }
  },
  fi: {
    app: {
      no_access: 'Käyttäjällä ei ole käyttöoikeuksia',
      access_denied: 'Pääsy evätty'
    },
    sidebar: {
      labels: {
        productionUnit: 'Tuotantoyksikkö',
        variables: 'Muuttujat'
      }
    },

    timeRange: {
      month: 'Kuukausi',
      week: 'Viikko',
      day: 'Päivä'
    },

    lossTableAUM: {
      headings: {
        stepName: 'Tapahtuma',
        rawMaterial: 'Raaka-aine',
        event_Num: 'Eränumero',
        finalProduct_Desc: 'Tuotteen nimi',
        maintenanceNotificationNumber: 'Maintenance Notification Number',
        cost: 'Kustannus',
        lossMinutes: 'Häviö minuuteissa',
        cpk: 'CpK',
        mean: 'μ',
        sig: '3σ',
        lossQuantity: 'Häviö määrä',
        OAMCategory: 'Kategoria',
        OAMFactor: 'Syy 1',
        localReason: 'Syy 2',
        startTime: 'Aloitusaika',
        lossSource: 'Häviö tyyppi',
        functionalLocation: 'Toimintopaikka',
        endTime: 'Loppu aika',
        RCPSStatus: 'RCPS-tila',
        RCPSComment: 'RCPS-kommentti',
        comment: 'Kommentti',
        actualDuration: 'Askeleen kesto',
        stepMac: 'Askeleen MAC'
      }
    },

    lossModal: {
      open: 'Avoinna',
      closed: 'Suljettu',
      reason: 'Syy',
      rcps: 'RCPS',
      datetimepicker: 'Päivämäärä ja aika',
      specialCause: 'Erityissyyn aloitus- ja lopetusaika',
      functionalLocation: 'Toimintopaikka',
      maintenanceNotification: 'Maintenance Notification',
      equipment: 'Laite',
      description: 'Kuvaus',
      technicalId: 'Tekninen tunnus',
      sortField: 'Lajittelu kenttä',
      submit: 'Lähetä',
      cancel: 'Peruuta',
      comment: 'Kommentti',
      rcpsComment: 'RCPS-kommentti',
      search: 'Hae',
      splitDuration: 'Jaettu häviö',
      splitDurationInvalidInput: 'Split duration cannot be greater than loss minutes',
      splitQuantityNoEndTimeInvalidInput: 'Can not split by quantity for unfinished events',
      splitQuantityInvalidInput: 'Split quantity cannot be greater than loss quantity',
      min: 'min',
      hour: 'hour'
    },
    trendChart: {
      thresholdNames: {
        GainTolerence: 'Hyöty',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        MarketDemand: 'Tilauskanta',
        LossTolerence: 'Häviötoleranssi',
        RCPS: 'Adhoc',
        Target: 'Tavoite'
      }
    },
    timeRangeButtons: {
      today: 'Tänään',
      last3days: 'Viimeiset 3 päivää',
      last7days: 'Viimeiset 7 päivää',
      last30days: 'Viimeiset 30 päivää',
      apply: 'Aseta',
      cancel: 'Peruuta'
    }
  },
  da: {
    app: {
      no_access: 'Brugeren har ikke adgang',
      access_denied: 'Adgang nægtet'
    },
    sidebar: {
      labels: {
        productionUnit: 'Produktionsenhed',
        variables: 'Variabler'
      }
    },

    timeRange: {
      month: 'måned',
      week: 'uge',
      day: 'dag'
    },

    lossTableAUM: {
      headings: {
        stepName: 'Type',
        rawMaterial: 'Råmateriale',
        event_Num: 'Batch Nr',
        finalProduct_Desc: 'Produktnavn',
        maintenanceNotificationNumber: 'Maintenance Notification Number',
        cost: 'Tab Omkostninger',
        lossMinutes: 'Tab Minutter',
        lossQuantity: 'Tab Mængde',
        OAMCategory: 'OAM Kategori',
        OAMFactor: 'OAM Faktor',
        localReason: 'Tabskoder',
        startTime: 'Start',
        lossSource: 'Tab Type',
        functionalLocation: 'Functional Location',
        endTime: 'Slut',
        RCPSStatus: 'RCPS Status',
        RCPSComment: 'RCPS Kommentar',
        comment: 'Kommentar',
        actualDuration: 'Varighed',
        stepMac: 'MAC'
      }
    },

    lossModal: {
      open: 'Åben',
      closed: 'Lukket',
      reason: 'Forklaring',
      rcps: 'RCPS',
      functionalLocation: 'Functional Location',
      maintenanceNotification: 'Maintenance Notification',
      equipment: 'Udstyr',
      description: 'Beskrivelse',
      technicalId: 'Teknisk ID',
      sortField: 'Sorteringsfelt',
      submit: 'Send',
      cancel: 'Annuller',
      comment: 'Kommentar',
      rcpsComment: 'RCPS Kommentar',
      search: 'Søg',
      splitDuration: 'Opdel Tab',
      splitDurationInvalidInput: 'Split duration cannot be greater than loss minutes',
      splitQuantityNoEndTimeInvalidInput: 'Can not split by quantity for unfinished events',
      splitQuantityInvalidInput: 'Split quantity cannot be greater than loss quantity',
      min: 'min',
      hour: 'time'
    },
    trendChart: {
      thresholdNames: {
        GainTolerence: 'Gain',
        MAC: 'MAC',
        CurrentAssetCapability: 'CAC',
        MarketDemand: 'Planlagt Produktion',
        LossTolerence: 'Tabstolerance',
        RCPS: 'RCPS',
        Target: 'Produktionsmål'
      }
    },
    timeRangeButtons: {
      today: 'I dag',
      last3days: 'Seneste 3 dage',
      last7days: 'Seneste 7 dage',
      last30days: 'Seneste 30 dage',
      apply: 'Anvendæ',
      cancel: 'Annuller'
    }
  }
};
