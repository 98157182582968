var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "trend-graph-container",
      attrs: { id: "trend-graph-container" }
    },
    [
      _vm.isProductChartActive
        ? _c("div", { staticClass: "current-product" }, [
            _vm._v(" " + _vm._s(_vm.currentProduct) + " ")
          ])
        : _vm._e(),
      _c("CommentDialog", {
        ref: "commentDialog",
        on: { "modal-submitted": _vm.createComment }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }