








































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import moment from 'moment';
import { Variable } from '../../classes/variable';
import { Unit } from '../../classes/unit';
import * as _ from 'underscore';
import { ChatApi } from '../../services/api/chatApi';
import to from '../../services/to';
import axios from 'axios';
import { Config } from './../../config';

@Component({
  components: {
    PerfectScrollbar
  },
  computed: {
    ...mapGetters({
      selectedVariables: 'getSelectedVariables',
      selectedUnit: 'getSelectedUnit',
      timeRange: 'getTimeRange',
      selectedLossIndices: 'getSelectedLossIndices',
      selectedShiftIndex: 'getSelectedShiftIndex',
      selectedShift: 'getSelectedShift'
    })
  }
})
export default class ChatConversation extends Vue {
  @Prop() readonly conversation: any;
  @Ref('scrollbar') readonly scrollbar!: any;
  public message: any = null;
  public selectedVariables!: Variable[];
  public timeRange!: Date[];
  public selectedShift!: any;
  public selectedShiftIndex!: number;
  public selectedLossIndices!: any;
  public selectedUnit!: Unit;
  public cancelToken: any;

  mounted() {
    const CancelToken = axios.CancelToken;
    this.cancelToken = CancelToken.source();
    this.scrollbar.$el.scrollTop = this.scrollbar.$el.scrollHeight;
    document.getElementById('message-box').focus();
    this.longPollMessages();
    // const socketService = SocketService.getSocket(this.$store.getters.getUserName);
    // socketService.onEvent('refresh-message').subscribe(() => this.$emit('newMessage'));
    // socketService.onEvent('refresh-wishlist').subscribe(() => this.$emit('newMessage'));
  }

  async longPollMessages() {
    let err, newMessages;

    console.log(this.conversation);

    [err, newMessages] = await to(
      axios.post(
        `${Config.prefixUrl}`,
        {
          InputJson: JSON.stringify({
            method: 'chatLongPollMessages',
            parameters: {
              Group_ID: this.conversation.conversation_id,
              lastMessageID:
                this.conversation.messages[
                  this.conversation.messages.length - 1
                ].id
            }
          })
        },
        {
          cancelToken: this.cancelToken.token,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
          }
        }
      )
    );

    if (err) {
      throw new Error(err);
    }

    newMessages = JSON.parse(newMessages.data.rows[0].result).rows;

    const messages = newMessages.map((msg) => {
      return {
        id: msg.Message_ID,
        message: msg.Content,
        username: msg.Username,
        createdAt: msg.Created_On
      };
    });

    this.$emit('newMessage', messages);
    setTimeout(() => {
      document.getElementById('message-box').focus();
      this.scrollbar.$el.scrollTop = this.scrollbar.$el.scrollHeight;
    }, 500);
    this.longPollMessages();
  }

  goToState(comment) {
    console.log(comment.data);
    this.$store.dispatch('setFromSavedState', comment.data);
  }

  goToList() {
    ChatApi.lastAccessed();
    this.$emit('goToList');
  }

  getHumanDate(date) {
    return `${moment(date).format('HH:mm')} | ${moment(date).fromNow()}`;
  }

  commentUser(username) {
    return username === this.$store.getters.getUserName
      ? 'cm float-right text-right'
      : 'cm float-left text-left';
  }

  sendState() {
    const chart: any = document.getElementById('trend-graph-container');
    const layout: any = chart.layout;
    const vars = _.clone(this.selectedVariables);
    // this.wish.comments.push({
    //     title: 'Added a new state',
    //     date: moment(),
    //     userName: new Buffer(this.$store.getters.getUserName).toString('hex'),
    //     userid: 1,
    //     isLink: true,
    //     linkType: 'state',
    //     data: {
    //         variables: _.clone(vars.map((variable: Variable, i: number) => {
    //             const axis = (i === 0) ? _.clone(layout.yaxis) : _.clone(layout[`yaxis${i + 1}`]);
    //             const vr = _.clone(variable);
    //             return {
    //                 ...vr,
    //                 yaxis: 'manual',
    //                 range: axis.range.map(rn => Number(rn.toFixed(2)))
    //             }
    //         })),
    //         selectedShiftIndex: _.clone(this.selectedShiftIndex),
    //         selectedShift: _.clone(this.selectedShift),
    //         selectedLossIndices: _.clone(this.selectedLossIndices),
    //         timeRange: _.clone(this.timeRange),
    //         unit: _.clone(this.selectedUnit)
    //     }
    // });
    setTimeout(() => {
      this.scrollbar.$el.scrollTop = this.scrollbar.$el.scrollHeight;
    }, 500);
  }

  async onEnter(e) {
    if (e && e.which === 13 && e.shiftKey) {
      return;
    }
    await ChatApi.sendMessage(
      {
        message: this.message,
        isLink: false,
        user: this.$store.getters.getUserName
      },
      this.$store.getters.getPath
    );
    this.message = null;
    await this.cancelToken.cancel('Operation canceled by the user.');
    const CancelToken = axios.CancelToken;
    this.cancelToken = CancelToken.source();
    this.longPollMessages();
    this.$emit('newMessage');
    setTimeout(() => {
      document.getElementById('message-box').focus();
      this.scrollbar.$el.scrollTop = this.scrollbar.$el.scrollHeight;
    }, 500);
  }

  getTitle(conversation) {
    if (
      conversation &&
      conversation.conversationUsers &&
      conversation.conversationUsers.length === 2
    ) {
      const splitedSubject = conversation.subject.split(', ');
      splitedSubject.splice(
        splitedSubject.indexOf(this.$store.getters.getUserName),
        1
      );
      return splitedSubject[0];
    }

    return conversation.subject;
  }

  @Watch('conversation')
  conversationChanged() {
    setTimeout(
      () => (this.scrollbar.$el.scrollTop = this.scrollbar.$el.scrollHeight),
      500
    );
  }
}
