




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { debounce } from 'underscore';

const methods: any = {
  methods: {
    debounceInput: debounce(function (this: any, e: any) {
      const that: any = this as any;
      that.$store.dispatch('setChatSearch', e.target.value);
    }, 300)
  }
};

@Component({
  computed: {
    ...mapGetters({
      conversationList: 'getConversations'
    })
  },
  ...methods
})
export default class ChatListing extends Vue {
  public conversationList!: any[];
  public filter: string = '';

  goToNewGroup() {
    this.$emit('goToNewGroup');
  }

  getTitle(conversation) {
    if (conversation.conversationUsers.length === 2) {
      const splitedSubject = conversation.subject.split(', ');
      splitedSubject.splice(
        splitedSubject.indexOf(this.$store.getters.getUserName),
        1
      );
      return splitedSubject[0];
    }

    return conversation.subject;
  }

  goToConversation(conversation) {
    this.$emit('goToConversation', conversation);
  }
}
