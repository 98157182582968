var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modalb",
          attrs: { id: "modal-prevent-closing", title: "Submit Your Comment" },
          on: {
            show: _vm.resetModal,
            shown: _vm.modalShown,
            hidden: _vm.resetModal,
            ok: _vm.handleOk
          }
        },
        [
          _c(
            "form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.commentState,
                    label: "Comment",
                    "label-for": "comment-input",
                    "invalid-feedback": "Comment is required"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "comment-input",
                      state: _vm.commentState,
                      required: ""
                    },
                    model: {
                      value: _vm.comment,
                      callback: function($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }