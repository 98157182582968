export class TransformChat {
  public static transformToConversationGroup(obj) {
    return {
      conversation_id: obj.Group_ID,
      subject: obj.Subject,
      type: 'chat',
      conversationUsers: [
        {
          conversation_id: obj.Group_ID,
          username: obj.Username
        }
      ],
      messages: [
        {
          message: obj.Last_Message
        }
      ],
      unread: obj.Notifications
    };
  }
}
