var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        {
          staticClass: "m-md-2",
          attrs: { size: "sm" },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function() {
                return [_vm._v(" Select Favorite View ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._l(_vm.favoritesViewsFiltered, function(view, index) {
            return _c(
              "b-dropdown-item-button",
              {
                key: index,
                on: {
                  click: function($event) {
                    return _vm.setSelected(view)
                  }
                }
              },
              [_vm._v(_vm._s(view.label) + " ")]
            )
          }),
          _c(
            "b-dropdown-item-button",
            { staticClass: "btn-blue", on: { click: _vm.saveCurrentView } },
            [_vm._v("Save Current View")]
          ),
          _vm.favoritesViews.length
            ? _c(
                "b-dropdown-item-button",
                { staticClass: "btn-blue", on: { click: _vm.openSettings } },
                [_vm._v("Settings")]
              )
            : _vm._e()
        ],
        2
      ),
      _c("FavoritesModal", {
        ref: "modal",
        attrs: { "modal-data": _vm.modalData },
        on: { "modal-closed": _vm.modalClosed }
      }),
      _vm.openSettingsModal
        ? _c("SettingsModal", {
            ref: "modalSettings",
            on: { "modal-closed": _vm.settingsModalClosed }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }