export const users = [
  {
    username: 'adm_hedendaj',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_yuyongr',
    userDesc: 'GE IP'
  },
  {
    username: 'adm_damerp',
    userDesc: 'GE IP'
  },
  {
    username: 'adm_arveseh',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_arvidssj',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_bergerof',
    userDesc: 'GE IP'
  },
  {
    username: 'adm_costap',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_daviaue',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_eklundp',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_ekstromt',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_erlandsj',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_hesslem',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_johansj7',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_klubbm',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_larssog4',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_slk',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_matsonm',
    userDesc: 'GE IP'
  },
  {
    username: 'adm_pettersj',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_stridho',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_svenssom1',
    userDesc: 'GE Novotek Support'
  },
  {
    username: 'adm_thorenm',
    userDesc: 'Industrial IT'
  },
  {
    username: 'adm_wongs',
    userDesc: 'GE IP'
  },
  {
    username: 'adm_vlachap',
    userDesc: 'adm_vlachap'
  },
  {
    username: 'ADM_JohanssonTX',
    userDesc: 'ADM_JohanssonTX'
  },
  {
    username: 'adm_Wipro',
    userDesc: 'adm_Wipro'
  },
  {
    username: 'adm_rosent',
    userDesc: 'adm_rosent'
  },
  {
    username: 'adm_carl',
    userDesc: 'adm_carl'
  },
  {
    username: 'adm_bjerregj',
    userDesc: 'adm_bjerregj'
  },
  {
    username: 'adm_molinj',
    userDesc: 'adm_molinj'
  },
  {
    username: 'adm_wallint',
    userDesc: 'adm_wallint'
  },
  {
    username: 'adm_hjerpep',
    userDesc: 'adm_hjerpep'
  },
  {
    username: 'adm_nilssonh',
    userDesc: 'adm_nilssonh'
  },
  {
    username: 'adm_jacobsj',
    userDesc: 'adm_jacobsj'
  },
  {
    username: 'adm_juschina',
    userDesc: 'adm_juschina'
  },
  {
    username: 'adm_kasturiv',
    userDesc: 'adm_kasturiv'
  },
  {
    username: 'adm_hovend',
    userDesc: 'adm_hovend'
  },
  {
    username: 'adm_agarm',
    userDesc: 'adm_agarm'
  },
  {
    username: 'adm_hud',
    userDesc: 'adm_hud'
  },
  {
    username: 'adm_xiulis',
    userDesc: 'adm_xiulis'
  },
  {
    username: 'adm_wangbe',
    userDesc: 'adm_wangbe'
  },
  {
    username: 'adm_amtfjorr',
    userDesc: 'adm_amtfjorr'
  },
  {
    username: 'adm_naumanr',
    userDesc: 'adm_naumanr'
  },
  {
    username: 'adm_beaumij',
    userDesc: 'adm_beaumij'
  },
  {
    username: 'adm_wangp',
    userDesc: 'adm_wangp'
  },
  {
    username: 'adm_harvalm',
    userDesc: 'adm_harvalm'
  },
  {
    username: 'adm_dizayeem',
    userDesc: 'adm_dizayeem'
  },
  {
    username: 'adm_morkings',
    userDesc: 'adm_morkings'
  },
  {
    username: 'adm_keereweer',
    userDesc: 'adm_keereweer'
  },
  {
    username: 'adm_SandstrP',
    userDesc: 'adm_SandstrP'
  },
  {
    username: 'adm_AlexandW',
    userDesc: 'adm_AlexandW'
  },
  {
    username: 'adm_overduia',
    userDesc: 'adm_overduia'
  },
  {
    username: 'adm_dahlann',
    userDesc: 'adm_dahlann'
  },
  {
    username: 'adm_sentancc',
    userDesc: 'adm_sentancc'
  },
  {
    username: 'adm_backstrm1',
    userDesc: 'adm_backstrm1'
  },
  {
    username: 'adm_zhangq',
    userDesc: 'adm_zhangq'
  },
  {
    username: 'adm_maresi',
    userDesc: 'adm_maresi'
  },
  {
    username: 'adm_deadyj',
    userDesc: 'adm_deadyj'
  },
  {
    username: 'adm_cahoonm',
    userDesc: 'adm_cahoonm'
  },
  {
    username: 'adm_chil',
    userDesc: 'adm_chil'
  },
  {
    username: 'adm_potdarv',
    userDesc: 'adm_potdarv'
  },
  {
    username: 'adm_abbottm',
    userDesc: 'adm_abbottm'
  },
  {
    username: 'adm_shaww',
    userDesc: 'adm_shaww'
  },
  {
    username: 'TardifM',
    userDesc: 'Marc.Tardif@nouryon.com'
  },
  {
    username: 'wandahla',
    userDesc: 'Annica.Wandahl@nouryon.com'
  },
  {
    username: 'GamachA',
    userDesc: 'alain.Gamache@nouryon.com'
  },
  {
    username: 'BernarH',
    userDesc: 'helene.Bernard@nouryon.com'
  },
  {
    username: 'espelanm',
    userDesc: 'Mikael.Espeland@nouryon.com'
  },
  {
    username: 'haaralac',
    userDesc: 'Carina.Haarala@nouryon.com'
  },
  {
    username: 'kamstens',
    userDesc: 'stefan.kamsten@nouryon.com'
  },
  {
    username: 'carl',
    userDesc: 'carl.nordlander@nouryon.com'
  },
  {
    username: 'kajsa',
    userDesc: 'Kajsa.Lake@nouryon.com'
  },
  {
    username: 'Fanp',
    userDesc: 'paul.fan@nouryon.com'
  },
  {
    username: 'adm_dubreils',
    userDesc: 'adm_dubreils'
  },
  {
    username: 'JohnsonB',
    userDesc: 'Billy.Johnson@nouryon.com'
  },
  {
    username: 'stabergh',
    userDesc: 'henrik.staberg@nouryon.com'
  },
  {
    username: 'hammerr',
    userDesc: 'roy.hammer-olsen@nouryon.com'
  },
  {
    username: 'sandlinj',
    userDesc: 'Jennifer.Sandlin@nouryon.com'
  },
  {
    username: 'hedendaj',
    userDesc: 'Joakim.Hedendahl@nouryon.com'
  },
  {
    username: 'adm_wattamws',
    userDesc: 'adm_wattamws'
  },
  {
    username: 'adm_hallj2',
    userDesc: 'adm_hallj2'
  },
  {
    username: 'adm_castrop',
    userDesc: 'adm_castrop'
  },
  {
    username: 'lindqvs1',
    userDesc: 'Stefan.Lindqvist.Karlsson@nouryon.com'
  },
  {
    username: 'thorenm',
    userDesc: 'Mikael.Thoren@nouryon.com'
  },
  {
    username: 'malmstes',
    userDesc: 'Stefan.Malmsten@nouryon.com'
  },
  {
    username: 'clicher',
    userDesc: 'richard.Cliche@nouryon.com'
  },
  {
    username: 'PoulinM',
    userDesc: 'Martin.Poulin@nouryon.com'
  },
  {
    username: 'savards',
    userDesc: 'Serge.Savard@nouryon.com'
  },
  {
    username: 'bouchay',
    userDesc: 'yves.Bouchard@nouryon.com'
  },
  {
    username: 'laverdr',
    userDesc: 'Regent.Laverdiere@nouryon.com'
  },
  {
    username: 'levesqa',
    userDesc: 'Alain.Levesque@nouryon.com'
  },
  {
    username: 'martincl',
    userDesc: 'claudia.martin@nouryon.com'
  },
  {
    username: 'tremblr',
    userDesc: 'Rock.Tremblay@nouryon.com'
  },
  {
    username: 'opermog',
    userDesc: 'operMOG@nouryon.com'
  },
  {
    username: 'sikese',
    userDesc: 'Edward.Sikes@nouryon.com'
  },
  {
    username: 'therouc',
    userDesc: 'Carl.Theroux@nouryon.com'
  },
  {
    username: 'royd',
    userDesc: 'daniel.roy@nouryon.com'
  },
  {
    username: 'raidersj',
    userDesc: 'Jim.Raiders@nouryon.com'
  },
  {
    username: 'bergerg',
    userDesc: 'guy.Bergeron@nouryon.com'
  },
  {
    username: 'audetr',
    userDesc: 'rejean.Audet@nouryon.com'
  },
  {
    username: 'draket',
    userDesc: 'Travis.Drake@nouryon.com'
  },
  {
    username: 'Smithr2',
    userDesc: 'Ron.Smith@nouryon.com'
  },
  {
    username: 'MimmieJ',
    userDesc: 'John.Mimmie@nouryon.com'
  },
  {
    username: 'autottea',
    userDesc: 'Alexandre.Autotte@nouryon.com'
  },
  {
    username: 'riggenbs',
    userDesc: 'Shawn.Riggenbach@nouryon.com'
  },
  {
    username: 'burdettd',
    userDesc: 'David.Burdette@nouryon.com'
  },
  {
    username: 'WALDENR',
    userDesc: 'reggie.walden@nouryon.com'
  },
  {
    username: 'merriwed',
    userDesc: 'dwight.merriweather@nouryon.com'
  },
  {
    username: 'garrisod',
    userDesc: 'Daryll.Garrison@nouryon.com'
  },
  {
    username: 'holtg',
    userDesc: 'greg.holt@nouryon.com'
  },
  {
    username: 'fanningd',
    userDesc: 'Derrick.Fanning@nouryon.com'
  },
  {
    username: 'sheadr',
    userDesc: 'Roger.Shead@nouryon.com'
  },
  {
    username: 'bergstt1',
    userDesc: 'Tony.Bergstrom@nouryon.com'
  },
  {
    username: 'majeaulo',
    userDesc: 'Olivier.MajeauLussier@nouryon.com'
  },
  {
    username: 'maurics',
    userDesc: 'sebastien.Maurice@nouryon.com'
  },
  {
    username: 'adm_KarlssoM3',
    userDesc: 'adm_KarlssoM3'
  },
  {
    username: 'adm_bonouvrr',
    userDesc: 'adm_bonouvrr'
  },
  {
    username: 'sniderj',
    userDesc: 'john.snider@nouryon.com'
  },
  {
    username: 'wilhelmj',
    userDesc: 'johan.wilhelmsson@nouryon.com'
  },
  {
    username: 'adm_maaslanj',
    userDesc: 'adm_maaslanj'
  },
  {
    username: 'jacksona',
    userDesc: 'Aaron.Jackson@nouryon.com'
  },
  {
    username: 'demersm',
    userDesc: 'Marc-Olivier.Demers@nouryon.com'
  },
  {
    username: 'adm_mccurlec',
    userDesc: ''
  },
  {
    username: 'adm_jaiswals',
    userDesc: 'adm_jaiswals'
  },
  {
    username: 'choun',
    userDesc: 'Nina.Chou@nouryon.com'
  },
  {
    username: 'adm_stabergh',
    userDesc: 'Industrial IT'
  },
  {
    username: 'Chudzial',
    userDesc: 'Lothar.Chudziak@nouryon.com'
  },
  {
    username: 'Knorschl',
    userDesc: 'Lutz.Knorscheidt@nouryon.com'
  },
  {
    username: 'WiegandM',
    userDesc: 'Michaela.Wiegand@nouryon.com'
  },
  {
    username: 'andersu1',
    userDesc: 'Ulf.Andersson@nouryon.com'
  },
  {
    username: 'changl',
    userDesc: 'lisa.chang@nouryon.com'
  },
  {
    username: 'liul1',
    userDesc: 'Lana.Liu@nouryon.com'
  },
  {
    username: 'weijiaba',
    userDesc: 'Weijiang.Bao@nouryon.com'
  },
  {
    username: 'wur1',
    userDesc: 'Ray.Wu@nouryon.com'
  },
  {
    username: 'erikssok',
    userDesc: 'Keith.Eriksson@nouryon.com'
  },
  {
    username: 'penzm',
    userDesc: 'Michael.Penz@nouryon.com'
  },
  {
    username: 'kransj',
    userDesc: 'Jenny.Leion@nouryon.com'
  },
  {
    username: 'ohlssonk',
    userDesc: 'Karin.Ohlsson@nouryon.com'
  },
  {
    username: 'KanW',
    userDesc: 'Willy.Kan@nouryon.com'
  },
  {
    username: 'chungs',
    userDesc: 'Shadow.Chung@nouryon.com'
  },
  {
    username: 'chenm16',
    userDesc: 'Mike.Chen@nouryon.com'
  },
  {
    username: 'wua1',
    userDesc: 'abby.wu@nouryon.com'
  },
  {
    username: 'hsine',
    userDesc: 'emma.hsin@nouryon.com'
  },
  {
    username: 'olofssonm',
    userDesc: 'mats.olofsson@nouryon.com'
  },
  {
    username: 'goyettj',
    userDesc: 'Jean.Goyette@nouryon.com'
  },
  {
    username: 'chens20',
    userDesc: 'Simon.Chen@nouryon.com'
  },
  {
    username: 'linb2',
    userDesc: 'Bill.Lin@nouryon.com'
  },
  {
    username: 'chunge1',
    userDesc: 'Evan.Chung@nouryon.com'
  },
  {
    username: 'leec17',
    userDesc: 'City.Lee@nouryon.com'
  },
  {
    username: 'wangt18',
    userDesc: 'Tony.Wang@nouryon.com'
  },
  {
    username: 'leec16',
    userDesc: 'Ching.Lee@nouryon.com'
  },
  {
    username: 'kuoh',
    userDesc: 'Hotdog.Kuo@nouryon.com'
  },
  {
    username: 'mahr2',
    userDesc: 'Reed.Mah@nouryon.com'
  },
  {
    username: 'Ling3',
    userDesc: 'Gand.Lin@nouryon.com'
  },
  {
    username: 'yangy21',
    userDesc: 'Yada.Yang@nouryon.com'
  },
  {
    username: 'leel8',
    userDesc: 'Lion.Lee@nouryon.com'
  },
  {
    username: 'adm_navettar',
    userDesc: 'adm_navettar'
  },
  {
    username: 'jedeniuf',
    userDesc: 'Fredrik.Jedenius@nouryon.com'
  },
  {
    username: 'adm_palsdote',
    userDesc: 'adm_palsdote'
  },
  {
    username: 'axelssoa',
    userDesc: 'annette.axelsson@nouryon.com'
  },
  {
    username: 'borsp',
    userDesc: 'peter.bors@nouryon.com'
  },
  {
    username: 'EalyP',
    userDesc: 'Pat.Ealy@nouryon.com'
  },
  {
    username: 'Seguroj',
    userDesc: 'Juan-Jose.Seguro-Moreno@nouryon.com'
  },
  {
    username: 'stridho',
    userDesc: 'Oskar.Stridh@nouryon.com'
  },
  {
    username: 'hedlunde',
    userDesc: 'ewa.hedlund@nouryon.com'
  },
  {
    username: 'tongs',
    userDesc: 'Sophia.Tong@nouryon.com'
  },
  {
    username: 'dingd',
    userDesc: 'Derek.Ding@nouryon.com'
  },
  {
    username: 'qiuj',
    userDesc: 'Jiali.Qiu@nouryon.com'
  },
  {
    username: 'choudhud',
    userDesc: 'Dieter.Choudhuri@nouryon.com'
  },
  {
    username: 'pappaj',
    userDesc: 'Juliana.Pappa@nouryon.com'
  },
  {
    username: 'arvidssona',
    userDesc: 'anders.arvidsson@nouryon.com'
  },
  {
    username: 'dahlstek',
    userDesc: 'Karin.Dahlstedt@nouryon.com'
  },
  {
    username: 'carlssoj',
    userDesc: 'Jonas.Carlsson@nouryon.com'
  },
  {
    username: 'adm_abrahaoh',
    userDesc: 'adm_abrahaoh'
  },
  {
    username: 'filipssonj',
    userDesc: 'jan.filipsson@nouryon.com'
  },
  {
    username: 'adm_choun',
    userDesc: ''
  },
  {
    username: 'mendeh',
    userDesc: 'Herbert.Mende@nouryon.com'
  },
  {
    username: 'siverstm',
    userDesc: 'Mikael.Siverstrom@nouryon.com'
  },
  {
    username: 'hant',
    userDesc: 'Tony.Han@nouryon.com'
  },
  {
    username: 'keelanm',
    userDesc: 'Michael.Keelan@nouryon.com'
  },
  {
    username: 'adm_KarlssoM1',
    userDesc: 'adm_KarlssoM1'
  },
  {
    username: 'lovenm',
    userDesc: 'Mia.Loven@nouryon.com'
  },
  {
    username: 'malamana',
    userDesc: 'Adele.Malaman@nouryon.com'
  },
  {
    username: 'ataym',
    userDesc: 'Murat.Atay@nouryon.com'
  },
  {
    username: 'mullerj1',
    userDesc: 'Juergen.Mueller@nouryon.com'
  },
  {
    username: 'topph',
    userDesc: 'Hans-Joachim.Topp@nouryon.com'
  },
  {
    username: 'LermR',
    userDesc: 'Ralf.Lerm@nouryon.com'
  },
  {
    username: 'svenssm2',
    userDesc: 'Margaretha.Svensson@nouryon.com'
  },
  {
    username: 'rogerior',
    userDesc: 'Rodrigo.Rogerio@nouryon.com'
  },
  {
    username: 'Busleim',
    userDesc: 'Michael.Buslei@nouryon.com'
  },
  {
    username: 'kaczmarr',
    userDesc: 'Robert.Kaczmarzyk@nouryon.com'
  },
  {
    username: 'barbierp',
    userDesc: 'Paulo.Barbieri@nouryon.com'
  },
  {
    username: 'ersoyu',
    userDesc: 'Umut.Ersoy@nouryon.com'
  },
  {
    username: 'LudwigM',
    userDesc: 'Marcus.Ludwig@nouryon.com'
  },
  {
    username: 'adm_keichopk',
    userDesc: 'adm_keichopk'
  },
  {
    username: 'ADM_vanoorj_to delete',
    userDesc: 'ADM_vanoorj_to delete'
  },
  {
    username: 'adm_vannoorj',
    userDesc: 'GE IP'
  },
  {
    username: 'husss',
    userDesc: 'Sara.Huss@nouryon.com'
  },
  {
    username: 'silvavs',
    userDesc: 'Valtair.Silva@nouryon.com'
  },
  {
    username: 'lacerdar',
    userDesc: 'Rodrigo.Lacerda@nouryon.com'
  },
  {
    username: 'lisu1',
    userDesc: 'Sunny.Li@nouryon.com'
  },
  {
    username: 'johansl3',
    userDesc: 'Lars-Ake.Johansson@nouryon.com'
  },
  {
    username: 'aba',
    userDesc: 'Ashura.BarakaBangens@nouryon.com'
  },
  {
    username: 'sundberc',
    userDesc: 'Christian.Sundberg@nouryon.com'
  },
  {
    username: 'faeh',
    userDesc: 'Henric.Fae@nouryon.com'
  },
  {
    username: 'forsbem1',
    userDesc: 'maria.forsberg@nouryon.com'
  },
  {
    username: 'andersu2',
    userDesc: 'Ulrika.Andersson@nouryon.com'
  },
  {
    username: 'lundskom',
    userDesc: 'Mona.Lundskog@nouryon.com'
  },
  {
    username: 'bjorna',
    userDesc: 'bjorn.spelberg@nouryon.com'
  },
  {
    username: 'lundahln',
    userDesc: 'niclas.lundahl@nouryon.com'
  },
  {
    username: 'nilssol3',
    userDesc: 'Luba.Nilsson@nouryon.com'
  },
  {
    username: 'gramatij',
    userDesc: 'jole.gramatikofski@nouryon.com'
  },
  {
    username: 'ehrlingh',
    userDesc: 'Helene.Ehrling@nouryon.com'
  },
  {
    username: 'PerssonA1',
    userDesc: 'Andreas.Persson1@nouryon.com'
  },
  {
    username: 'JanssonC1',
    userDesc: 'Christer.Jansson1@nouryon.com'
  },
  {
    username: 'johansm6',
    userDesc: 'Martin.Johansson2@nouryon.com'
  },
  {
    username: 'adm_SaenzB',
    userDesc: 'adm_SaenzB'
  },
  {
    username: 'FrellerS',
    userDesc: 'Sascha.Freller@nouryon.com'
  },
  {
    username: 'karstrol',
    userDesc: 'Lizette.Karstrom@nouryon.com'
  },
  {
    username: 'Banasikh',
    userDesc: 'Heinrich.Banasik@nouryon.com'
  },
  {
    username: 'mundta',
    userDesc: 'Achim.Mundt@nouryon.com'
  },
  {
    username: 'scheuchw',
    userDesc: 'Wolfgang.Scheucher@nouryon.com'
  },
  {
    username: 'brockr',
    userDesc: 'Ruediger.Brock@nouryon.com'
  },
  {
    username: 'bastiank',
    userDesc: 'Klaus.Bastian@nouryon.com'
  },
  {
    username: 'andreasg',
    userDesc: 'Gun.Andreasson@nouryon.com'
  },
  {
    username: 'johansj7',
    userDesc: 'Jesper.Johansson@nouryon.com'
  },
  {
    username: 'Brandkaa',
    userDesc: 'Andreas.Brandkamp@nouryon.com'
  },
  {
    username: 'LacroixR',
    userDesc: 'Rene.Lacroix@nouryon.com'
  },
  {
    username: 'StepanJ',
    userDesc: 'Juergen.Stepan@nouryon.com'
  },
  {
    username: 'tschornr',
    userDesc: 'Rainer.Tschorn@nouryon.com'
  },
  {
    username: 'buechelp',
    userDesc: 'Peter.Buechel@nouryon.com'
  },
  {
    username: 'zahna',
    userDesc: 'Andreas.Zahn@nouryon.com'
  },
  {
    username: 'jonassonb',
    userDesc: 'britt-marie.jonasson@nouryon.com'
  },
  {
    username: 'jyrkinej',
    userDesc: 'Janne.Jyrkinen@nouryon.com'
  },
  {
    username: 'svenssonp',
    userDesc: 'par.svensson@nouryon.com'
  },
  {
    username: 'lifj',
    userDesc: 'Johan.Lif@nouryon.com'
  },
  {
    username: 'perssob1',
    userDesc: 'Borje.S.Persson@nouryon.com'
  },
  {
    username: 'limef',
    userDesc: 'fredrik.lime@nouryon.com'
  },
  {
    username: 'jonzon',
    userDesc: 'Mats.Jonzon@nouryon.com'
  },
  {
    username: 'FranzkeJ',
    userDesc: 'Jens.Franzke@nouryon.com'
  },
  {
    username: 'desbienn',
    userDesc: 'Nicolas.Desbiens@nouryon.com'
  },
  {
    username: 'bremertk',
    userDesc: 'Katarina.Bremert-Jirholm@nouryon.com'
  },
  {
    username: 'halll',
    userDesc: 'Lars-Ake.Hall@nouryon.com'
  },
  {
    username: 'kalischb',
    userDesc: 'Bruno.Kalisch@nouryon.com'
  },
  {
    username: 'hogblomj',
    userDesc: 'joakim.hogblom@nouryon.com'
  },
  {
    username: 'uelpernc',
    userDesc: 'Christopher.Uelpernich@nouryon.com'
  },
  {
    username: 'pranjicj',
    userDesc: 'Jasminka.Pranjic@nouryon.com'
  },
  {
    username: 'meisr',
    userDesc: 'Rainer.Meis@nouryon.com'
  },
  {
    username: 'drieschd',
    userDesc: 'Dietmar-Armin.Drieschner@nouryon.com'
  },
  {
    username: 'albihnj',
    userDesc: 'Jan.Albihn@nouryon.com'
  },
  {
    username: 'lindellm',
    userDesc: 'Maria.Lindell@nouryon.com'
  },
  {
    username: 'huttnerf',
    userDesc: 'Frida.Huettner@nouryon.com'
  },
  {
    username: 'sonessom',
    userDesc: 'Marten.Sonesson@nouryon.com'
  },
  {
    username: 'Taskilaj',
    userDesc: 'jarkko.taskila@nouryon.com'
  },
  {
    username: 'pukitisp',
    userDesc: 'Peter.Pukitis@nouryon.com'
  },
  {
    username: 'warnhom1',
    userDesc: 'Micael.Warnholtz@nouryon.com'
  },
  {
    username: 'barjasid',
    userDesc: 'Dennis.Barjasic@nouryon.com'
  },
  {
    username: 'liljaj',
    userDesc: 'Joakim.Lilja@nouryon.com'
  },
  {
    username: 'BrockmaH',
    userDesc: 'Helmut.Brockmann@nouryon.com'
  },
  {
    username: 'stjernsj',
    userDesc: 'Jan.Stjernstroem@nouryon.com'
  },
  {
    username: 'petterj2',
    userDesc: 'johan.pettersson@nouryon.com'
  },
  {
    username: 'adm_muellerj',
    userDesc: ''
  },
  {
    username: 'friggerj',
    userDesc: 'Jonas.Friggerdal@nouryon.com'
  },
  {
    username: 'ingemarp',
    userDesc: 'Patrik.Ingemarsson@nouryon.com'
  },
  {
    username: 'ostrena',
    userDesc: 'anders.ostren@nouryon.com'
  },
  {
    username: 'karlssok',
    userDesc: 'kenny.karlsson@nouryon.com'
  },
  {
    username: 'adm_strombeh',
    userDesc: 'adm_strombeh'
  },
  {
    username: 'adm_svenssm2',
    userDesc: 'adm_svenssm2'
  },
  {
    username: 'adm_espelanm',
    userDesc: 'adm_espelanm'
  },
  {
    username: 'henriqud',
    userDesc: 'douglas.oliveira@nouryon.com'
  },
  {
    username: 'hubbardc',
    userDesc: 'Charletha.Hubbard@nouryon.com'
  },
  {
    username: 'lindstrk',
    userDesc: 'Kristina.Lindstrand@nouryon.com'
  },
  {
    username: 'jagerhor',
    userDesc: 'Ronny.Jagerholt@nouryon.com'
  },
  {
    username: 'lofgrens',
    userDesc: 'Stefan.Lofgren@nouryon.com'
  },
  {
    username: 'janssonm',
    userDesc: 'Martin.Jansson@nouryon.com'
  },
  {
    username: 'ericssol',
    userDesc: 'Lennart.Ericsson@nouryon.com'
  },
  {
    username: 'olssonb',
    userDesc: 'Bengt-Arne.Olsson@nouryon.com'
  },
  {
    username: 'stjernsp',
    userDesc: 'Per.Stjernstrom@nouryon.com'
  },
  {
    username: 'degerbor',
    userDesc: 'roger.degerbo@nouryon.com'
  },
  {
    username: 'erikssonp',
    userDesc: 'patrik.eriksson@nouryon.com'
  },
  {
    username: 'rimesla',
    userDesc: 'anders.rimeslatten@nouryon.com'
  },
  {
    username: 'widenfar',
    userDesc: 'rebecca.widenfalk@nouryon.com'
  },
  {
    username: 'lindstrp',
    userDesc: 'Par.Lindstrom@nouryon.com'
  },
  {
    username: 'strandbergl',
    userDesc: 'Lars-Magnus.Strandberg@nouryon.com'
  },
  {
    username: 'Larssonst',
    userDesc: 'stefan.larsson@nouryon.com'
  },
  {
    username: 'hjortk',
    userDesc: 'karin.hjort@nouryon.com'
  },
  {
    username: 'sundqvisth',
    userDesc: 'hans-erik.sundqvist@nouryon.com'
  },
  {
    username: 'aslundc',
    userDesc: 'christer.aslund@nouryon.com'
  },
  {
    username: 'bergkvii',
    userDesc: 'Ingrid.Bergkvist@nouryon.com'
  },
  {
    username: 'johanssonc',
    userDesc: 'Conny.CJ.Johansson@nouryon.com'
  },
  {
    username: 'agstamm',
    userDesc: 'mikael.agstam@nouryon.com'
  },
  {
    username: 'erikssont',
    userDesc: 'Torbjoern.Eriksson@nouryon.com'
  },
  {
    username: 'obergj',
    userDesc: 'Joergen.Oeberg@nouryon.com'
  },
  {
    username: 'asplunda',
    userDesc: 'anna.asplund@nouryon.com'
  },
  {
    username: 'melvilld',
    userDesc: 'Dave.Melville@nouryon.com'
  },
  {
    username: 'palssonu',
    userDesc: 'urban.palsson@nouryon.com'
  },
  {
    username: 'norbergk',
    userDesc: 'kjell.norberg@nouryon.com'
  },
  {
    username: 'palssonc',
    userDesc: 'Christina.Palsson@nouryon.com'
  },
  {
    username: 'backstromb',
    userDesc: 'bengt-erik.backstrom@nouryon.com'
  },
  {
    username: 'olanderh',
    userDesc: 'hans.olander@nouryon.com'
  },
  {
    username: 'perssonl',
    userDesc: 'leif.persson2@nouryon.com'
  },
  {
    username: 'nilssos1',
    userDesc: 'stefan.nilsson@nouryon.com'
  },
  {
    username: 'adm_papastyj',
    userDesc: 'adm_papastyj'
  },
  {
    username: 'hjorta',
    userDesc: 'anders.hjort@nouryon.com'
  },
  {
    username: 'wangj10',
    userDesc: 'John.Wang@nouryon.com'
  },
  {
    username: 'nordstroms',
    userDesc: 'stig-ake.nordstrom@nouryon.com'
  },
  {
    username: 'BadtkeM',
    userDesc: 'mark.badtke@nouryon.com'
  },
  {
    username: 'brueschp',
    userDesc: 'Pete.Bruesch@nouryon.com'
  },
  {
    username: 'dombroskim',
    userDesc: 'marsha.dombroski@nouryon.com'
  },
  {
    username: 'dorns',
    userDesc: 'Scott.Dorn@nouryon.com'
  },
  {
    username: 'elliej',
    userDesc: 'Jason.Ellie@nouryon.com'
  },
  {
    username: 'renierm',
    userDesc: 'melissa.renier@nouryon.com'
  },
  {
    username: 'sannee',
    userDesc: 'erik.sanne@nouryon.com'
  },
  {
    username: 'santosth',
    userDesc: 'Thiago.Luiz.Santos@nouryon.com'
  },
  {
    username: 'vandenbt',
    userDesc: 'Todd.VanDenBosch@nouryon.com'
  },
  {
    username: 'vandyckj',
    userDesc: 'jeff.vandyck@nouryon.com'
  },
  {
    username: 'vandyckm',
    userDesc: 'Mike.VanDyck@nouryon.com'
  },
  {
    username: 'wuj',
    userDesc: 'Jenny.Wu@nouryon.com'
  },
  {
    username: 'KuhnertA',
    userDesc: 'Anka.Kuhnert@nouryon.com'
  },
  {
    username: 'MuenchD',
    userDesc: 'Detlef.Munch@nouryon.com'
  },
  {
    username: 'Vaisanee',
    userDesc: 'erja.vaisanen@nouryon.com'
  },
  {
    username: 'nordlanderl',
    userDesc: 'lars-gunnar.nordlander@nouryon.com'
  },
  {
    username: 'changc5',
    userDesc: 'Claire1.Chang@nouryon.com'
  },
  {
    username: 'wikmanf',
    userDesc: 'Fredrik.Wikman@nouryon.com'
  },
  {
    username: 'anderssj1',
    userDesc: 'Joakim.Andersson@nouryon.com'
  },
  {
    username: 'eklindm',
    userDesc: 'marianne.eklind@nouryon.com'
  },
  {
    username: 'ocklinda',
    userDesc: 'albert.ocklind@nouryon.com'
  },
  {
    username: 'nordstromk',
    userDesc: 'karl-arne.nordstrom@nouryon.com'
  },
  {
    username: 'LovenS',
    userDesc: 'Stefan.Loven@nouryon.com'
  },
  {
    username: 'tolft',
    userDesc: 'tomas.tolf@nouryon.com'
  },
  {
    username: 'thorm',
    userDesc: 'Maria.Thor@nouryon.com'
  },
  {
    username: 'adm_HaugeM',
    userDesc: 'adm_HaugeM'
  },
  {
    username: 'adm_kangv',
    userDesc: 'adm_kangv'
  },
  {
    username: 'ostl',
    userDesc: 'Lars.Ost@nouryon.com'
  },
  {
    username: 'dalbergp',
    userDesc: 'Pernilla.Dalberg@nouryon.com'
  },
  {
    username: 'gobelm',
    userDesc: 'Mats.Gobel@nouryon.com'
  },
  {
    username: 'sjogrenp',
    userDesc: 'patrik.sjogren@nouryon.com'
  },
  {
    username: 'johanssont',
    userDesc: 'thord.johansson@nouryon.com'
  },
  {
    username: 'qvarforl',
    userDesc: 'Liselotte.Qvarfordt@nouryon.com'
  },
  {
    username: 'GardJ',
    userDesc: 'Johan.Gard@nouryon.com'
  },
  {
    username: 'larssog2',
    userDesc: 'goran.larsson4@nouryon.com'
  },
  {
    username: 'hellmanm',
    userDesc: 'Mats.Hellman@nouryon.com'
  },
  {
    username: 'fortunaj',
    userDesc: 'Jorge.Fortunato@nouryon.com'
  },
  {
    username: 'yarbrous',
    userDesc: 'Sonny.Yarbrough@nouryon.com'
  },
  {
    username: 'hanssonf',
    userDesc: 'freddie.hansson@nouryon.com'
  },
  {
    username: 'guoo1',
    userDesc: 'Operator.Guo@nouryon.com'
  },
  {
    username: 'abdallaz',
    userDesc: 'Zeinab.Abdallah@nouryon.com'
  },
  {
    username: 'yangd7',
    userDesc: 'Delin.Yang@nouryon.com'
  },
  {
    username: 'berglundt',
    userDesc: 'tomas.berglund@nouryon.com'
  },
  {
    username: 'souzaw',
    userDesc: 'Waldir.Souza@nouryon.com'
  },
  {
    username: 'petersec',
    userDesc: 'Catarina.Petersen@nouryon.com'
  },
  {
    username: 'wikdahls',
    userDesc: 'Sven.Wikdahl@nouryon.com'
  },
  {
    username: 'adm_sidagamk',
    userDesc: 'adm_sidagamk'
  },
  {
    username: 'olssoa1',
    userDesc: 'anna.hallberg@nouryon.com'
  },
  {
    username: 'nybergo',
    userDesc: 'olof.nyberg@nouryon.com'
  },
  {
    username: 'silvacle',
    userDesc: 'CleberAmaroda.Silva@nouryon.com'
  },
  {
    username: 'lundahlk',
    userDesc: 'kristina.lundahl@nouryon.com'
  },
  {
    username: 'BurnsidJ',
    userDesc: 'Jim.Burnside@nouryon.com'
  },
  {
    username: 'Tijerinet',
    userDesc: 'Elias.Tijerina@nouryon.com'
  },
  {
    username: 'BeiermaF',
    userDesc: 'Fred.Beierman@nouryon.com'
  },
  {
    username: 'EscamilA',
    userDesc: 'Armando.Escamilla@nouryon.com'
  },
  {
    username: 'krossene',
    userDesc: 'Eddie.Krossen@nouryon.com'
  },
  {
    username: 'McAlavyC',
    userDesc: 'Clark.McAlavy@nouryon.com'
  },
  {
    username: 'McCurtyT',
    userDesc: 'Tony.McCurty@nouryon.com'
  },
  {
    username: 'ScottD',
    userDesc: 'Danny.Scott@nouryon.com'
  },
  {
    username: 'Steinerr',
    userDesc: 'Rune.Steiner@nouryon.com'
  },
  {
    username: 'adm_keelanm',
    userDesc: 'adm_keelanm'
  },
  {
    username: 'nordberd',
    userDesc: 'David.Nordberg@nouryon.com'
  },
  {
    username: 'adm_perronv',
    userDesc: 'adm_perronv'
  },
  {
    username: 'leel5',
    userDesc: 'Larry.Lee@nouryon.com'
  },
  {
    username: 'strandbergm',
    userDesc: 'mikael.strandberg@nouryon.com'
  },
  {
    username: 'adm_torstensj',
    userDesc: 'adm_torstensj'
  },
  {
    username: 'Edstromj',
    userDesc: 'Joakim.Edstrom@nouryon.com'
  },
  {
    username: 'LofstraA',
    userDesc: 'AnnSofie.Lofstrand@nouryon.com'
  },
  {
    username: 'pierre',
    userDesc: 'Pierre.Bygdell@nouryon.com'
  },
  {
    username: 'klubbm',
    userDesc: 'Mattias.Klubb@nouryon.com'
  },
  {
    username: 'adm_Lundberma',
    userDesc: 'adm_Lundberma'
  },
  {
    username: 'garcias',
    userDesc: 'SergioVieira.Garcia@nouryon.com'
  },
  {
    username: 'timners',
    userDesc: 'sara.timner@nouryon.com'
  },
  {
    username: 'thomas',
    userDesc: 'Thomas.Ahlinder@nouryon.com'
  },
  {
    username: 'harmonn',
    userDesc: 'Nick.Harmon@nouryon.com'
  },
  {
    username: 'huntercm',
    userDesc: 'cindy.hunter@nouryon.com'
  },
  {
    username: 'golsant',
    userDesc: 'Tommy.Golsan@nouryon.com'
  },
  {
    username: 'lamisonr',
    userDesc: 'Richard.Lamison@nouryon.com'
  },
  {
    username: 'caillam',
    userDesc: 'medhi.caillaud@nouryon.com'
  },
  {
    username: 'mansoub',
    userDesc: 'Baroudi.Mansour@nouryon.com'
  },
  {
    username: 'formicf',
    userDesc: 'franck.formicula@nouryon.com'
  },
  {
    username: 'pascuth',
    userDesc: 'herve.pascutto@nouryon.com'
  },
  {
    username: 'bessoub',
    userDesc: 'bruno.bessou@nouryon.com'
  },
  {
    username: 'vugiere',
    userDesc: 'eric.vugier@nouryon.com'
  },
  {
    username: 'fauchej',
    userDesc: 'jeanyves.faucher@nouryon.com'
  },
  {
    username: 'ADM_Pengy',
    userDesc: 'ADM_Pengy'
  },
  {
    username: 'redbrint',
    userDesc: 'thomas.redbrink@nouryon.com'
  },
  {
    username: 'lothet',
    userDesc: 'Turid.Lothe@nouryon.com'
  },
  {
    username: 'svensso2',
    userDesc: 'Rickard.Svensson2@nouryon.com'
  },
  {
    username: 'kahsays',
    userDesc: 'samson.kahsay@nouryon.com'
  },
  {
    username: 'carlssoz',
    userDesc: 'zander.carlsson@nouryon.com'
  },
  {
    username: 'krookh',
    userDesc: 'henrik.krook@nouryon.com'
  },
  {
    username: 'nilsenc',
    userDesc: 'Camilla.Aarnes-Nilsen@nouryon.com'
  },
  {
    username: 'eliassoa',
    userDesc: 'Anders.Eliasson@nouryon.com'
  },
  {
    username: 'adm_zhagfari',
    userDesc: 'adm_zhagfari'
  },
  {
    username: 'agelvisa',
    userDesc: 'Antonio.Agelvis@nouryon.com'
  },
  {
    username: 'sannaw',
    userDesc: 'sanna.weidman@nouryon.com'
  },
  {
    username: 'darmayh',
    userDesc: 'herve.darmayan@nouryon.com'
  },
  {
    username: 'adm_hant',
    userDesc: 'adm_hant'
  },
  {
    username: 'adm_pazj',
    userDesc: 'adm_pazj'
  },
  {
    username: 'adm_yangd7',
    userDesc: 'adm_yangd7'
  },
  {
    username: 'larssog1',
    userDesc: 'goran.u.larsson@nouryon.com'
  },
  {
    username: 'fellesp',
    userDesc: 'kontrollrom.RKN@nouryon.com'
  },
  {
    username: 'pengr',
    userDesc: 'Rita.Peng@nouryon.com'
  },
  {
    username: 'mnt',
    userDesc: 'morgan.nyqvist@nouryon.com'
  },
  {
    username: 'himmeld1',
    userDesc: 'Dick.Himmelmann@nouryon.com'
  },
  {
    username: 'berggrem',
    userDesc: 'Marina.Berggren@nouryon.com'
  },
  {
    username: 'sokolc',
    userDesc: 'claudia.sokol@nouryon.com'
  },
  {
    username: 'hultm',
    userDesc: 'Martin.Hult@nouryon.com'
  },
  {
    username: 'maneckej',
    userDesc: 'Joerg.Manecke@nouryon.com'
  },
  {
    username: 'davesa',
    userDesc: 'Atwell.Daves@nouryon.com'
  },
  {
    username: 'HarringD',
    userDesc: 'Debbie.Harrington2@nouryon.com'
  },
  {
    username: 'DaleA',
    userDesc: 'Andy.Dale@nouryon.com'
  },
  {
    username: 'andersk1',
    userDesc: 'Karl-Erik.Andersson@nouryon.com'
  },
  {
    username: 'melodio',
    userDesc: 'Diogo.Melo@nouryon.com'
  },
  {
    username: 'edmondss',
    userDesc: 'Scott.Edmonds@nouryon.com'
  },
  {
    username: 'ballotf',
    userDesc: 'Fabrice.Ballot@nouryon.com'
  },
  {
    username: 'andersj7',
    userDesc: 'Johan.Andersson2@nouryon.com'
  },
  {
    username: 'petterj1',
    userDesc: 'Janne.Pettersson@nouryon.com'
  },
  {
    username: 'langs',
    userDesc: 'sven-erik.lang@nouryon.com'
  },
  {
    username: 'wallenia',
    userDesc: 'Alexander.Wallenius@nouryon.com'
  },
  {
    username: 'mac9',
    userDesc: 'celia.ma@nouryon.com'
  },
  {
    username: 'Petersenm',
    userDesc: 'Martin.Petersen@nouryon.com'
  },
  {
    username: 'gunleikg',
    userDesc: 'GretheNordby.Gunleiksrud@nouryon.com'
  },
  {
    username: 'astranda',
    userDesc: 'ann.astrand@nouryon.com'
  },
  {
    username: 'Kuhnertt',
    userDesc: 'Tino.Kuhnert@nouryon.com'
  },
  {
    username: 'costag',
    userDesc: 'Geber.Costa@nouryon.com'
  },
  {
    username: 'kennedb1',
    userDesc: 'Bob.Kennedy@nouryon.com'
  },
  {
    username: 'schuberte',
    userDesc: 'Eberhard.Schubert@nouryon.com'
  },
  {
    username: 'tunge',
    userDesc: 'Eleaner.Tung@nouryon.com'
  },
  {
    username: 'cabreraf',
    userDesc: 'Francisco.Cabrera@nouryon.com'
  },
  {
    username: 'williab1',
    userDesc: 'Bryan.Williams@nouryon.com'
  },
  {
    username: 'wittkec',
    userDesc: 'chris.wittke@nouryon.com'
  },
  {
    username: 'silvae3',
    userDesc: 'Eder.Silva@nouryon.com'
  },
  {
    username: 'roys2',
    userDesc: 'Serge.Roy@nouryon.com'
  },
  {
    username: 'murphyd1',
    userDesc: 'Daniel.Murphy@nouryon.com'
  },
  {
    username: 'oliveirg',
    userDesc: 'Gerson.Oliveira@nouryon.com'
  },
  {
    username: 'flemingw',
    userDesc: 'Walter.Fleming@nouryon.com'
  },
  {
    username: 'silvaed',
    userDesc: 'Edilson.Silva@nouryon.com'
  },
  {
    username: 'adm_Chudzial',
    userDesc: 'adm_Chudzial'
  },
  {
    username: 'warrenm',
    userDesc: 'mike.warren@nouryon.com'
  },
  {
    username: 'runnekaas',
    userDesc: 'Solveig.Runnekaas@nouryon.com'
  },
  {
    username: 'jesusc',
    userDesc: 'Christyan.Jesus@nouryon.com'
  },
  {
    username: 'degertch',
    userDesc: 'Christophe.Degert@nouryon.com'
  },
  {
    username: 'fachimg',
    userDesc: 'Gabriel.Fachim@nouryon.com'
  },
  {
    username: 'bruckerp',
    userDesc: 'Pierre.Bruckert@nouryon.com'
  },
  {
    username: 'adm_olofssoj',
    userDesc: 'adm_olofssoj'
  },
  {
    username: 'sundstrh',
    userDesc: 'hans-goran.sundstrom@nouryon.com'
  },
  {
    username: 'thompsob',
    userDesc: 'bryce.thompson@nouryon.com'
  },
  {
    username: 'andersl5',
    userDesc: 'linda.andersson@nouryon.com'
  },
  {
    username: 'ingela',
    userDesc: 'Ingela.Eriksson@nouryon.com'
  },
  {
    username: 'peterf',
    userDesc: 'Peter.Forslund@nouryon.com'
  },
  {
    username: 'garciar',
    userDesc: 'ricardo.antonio.garcia@nouryon.com'
  },
  {
    username: 'hjorte',
    userDesc: 'Erika.Hjort@nouryon.com'
  },
  {
    username: 'abrahama',
    userDesc: 'Ann.Abrahamsson@nouryon.com'
  },
  {
    username: 'larssou1',
    userDesc: 'Urban.Larsson@nouryon.com'
  },
  {
    username: 'pmskift',
    userDesc: 'pmskift@nouryon.com'
  },
  {
    username: 'johansy1',
    userDesc: 'Yvonne.Johansson@nouryon.com'
  },
  {
    username: 'DahlsteH',
    userDesc: 'Hampus.Dahlstedt@nouryon.com'
  },
  {
    username: 'Sangl',
    userDesc: 'Mike.Sang@nouryon.com'
  },
  {
    username: 'matsonm',
    userDesc: 'mike.matson@nouryon.com'
  },
  {
    username: 'unievesu',
    userDesc: 'Uhlan.Nieves@nouryon.com'
  },
  {
    username: 'bergback',
    userDesc: 'Kari.Bergbacka@nouryon.com'
  },
  {
    username: 'Hayesk',
    userDesc: 'Keith.Hayes@nouryon.com'
  },
  {
    username: 'ReevesD',
    userDesc: 'Donnie.Reeves@nouryon.com'
  },
  {
    username: 'Shelnutd',
    userDesc: 'Demus.Shelnut@nouryon.com'
  },
  {
    username: 'hollisr',
    userDesc: 'Ricky.Hollis@nouryon.com'
  },
  {
    username: 'RoutleyC',
    userDesc: 'Curtis.Routley@nouryon.com'
  },
  {
    username: 'JonesT',
    userDesc: 'Terry.Jones@nouryon.com'
  },
  {
    username: 'carrutha',
    userDesc: 'Andy.Carruth@nouryon.com'
  },
  {
    username: 'LawrencS',
    userDesc: 'Scott.Lawrence@nouryon.com'
  },
  {
    username: 'ThompsoA',
    userDesc: 'Alan.Thompson@nouryon.com'
  },
  {
    username: 'trullj',
    userDesc: 'jonathan.trull@nouryon.com'
  },
  {
    username: 'DavidsoJ',
    userDesc: 'Jeff.Davidson@nouryon.com'
  },
  {
    username: 'bowlesd',
    userDesc: 'Donnie.Bowles@nouryon.com'
  },
  {
    username: 'RayC',
    userDesc: 'Calvin.Ray@nouryon.com'
  },
  {
    username: 'wyersm',
    userDesc: 'Michael.Wyers@nouryon.com'
  },
  {
    username: 'GeorgeW',
    userDesc: 'William.George@nouryon.com'
  },
  {
    username: 'ramosja',
    userDesc: 'jacques.ramos@nouryon.com'
  },
  {
    username: 'laicy',
    userDesc: 'chengyou.lai@nouryon.com'
  },
  {
    username: 'xuym',
    userDesc: 'yuanming.xu@nouryon.com'
  },
  {
    username: 'linxj',
    userDesc: 'xijie.lin@nouryon.com'
  },
  {
    username: 'pomroyd',
    userDesc: 'Don.Pomroy@nouryon.com'
  },
  {
    username: 'dongjh',
    userDesc: 'Jianhong.Dong@nouryon.com'
  },
  {
    username: 'evas1',
    userDesc: 'eva.stenberg@nouryon.com'
  },
  {
    username: 'taina',
    userDesc: 'taina.tollgren@nouryon.com'
  },
  {
    username: 'brodinma',
    userDesc: 'Mattias.Brodin@nouryon.com'
  },
  {
    username: 'adm_fanp',
    userDesc: 'adm_fanp'
  },
  {
    username: 'yaoyj',
    userDesc: 'yanjiang.yao@nouryon.com'
  },
  {
    username: 'sandstrm',
    userDesc: 'mikael.sandstrom@nouryon.com'
  },
  {
    username: 'wikmanm',
    userDesc: 'MARI.AHLINDER@nouryon.com'
  },
  {
    username: 'pengcl',
    userDesc: 'chaoliang.peng@nouryon.com'
  },
  {
    username: 'soderbem',
    userDesc: 'magnus.soderberg@nouryon.com'
  },
  {
    username: 'koponenn',
    userDesc: 'Nina.Koponen@nouryon.com'
  },
  {
    username: 'obergben',
    userDesc: 'bengt-erik.oberg@nouryon.com'
  },
  {
    username: 'ringqvib',
    userDesc: 'bert-ove.ringqvist@nouryon.com'
  },
  {
    username: 'bjorkg',
    userDesc: 'Goran.Bjork@nouryon.com'
  },
  {
    username: 'kaiserp',
    userDesc: 'Patric.Kaiser@nouryon.com'
  },
  {
    username: 'jorgen',
    userDesc: 'jorgen.thelander@nouryon.com'
  },
  {
    username: 'hakanspe',
    userDesc: 'Peter.Hakansson2@nouryon.com'
  },
  {
    username: 'pedrogle',
    userDesc: 'glenn.pedro@nouryon.com'
  },
  {
    username: 'jonsson1',
    userDesc: 'Torbjorn.Jonsson@nouryon.com'
  },
  {
    username: 'christina',
    userDesc: 'Christina.Nyholm@nouryon.com'
  },
  {
    username: 'nylandek',
    userDesc: 'kristoffer.nylander@nouryon.com'
  },
  {
    username: 'runsten',
    userDesc: 'Mikael.Runsten@nouryon.com'
  },
  {
    username: 'hoglundf',
    userDesc: 'fredrik.hoglund@nouryon.com'
  },
  {
    username: 'bergstel',
    userDesc: 'Leif.Bergsten@nouryon.com'
  },
  {
    username: 'leanruml',
    userDesc: ''
  },
  {
    username: 'salesro',
    userDesc: 'rogerio.sales@nouryon.com'
  },
  {
    username: 'engblomd',
    userDesc: 'David.Engblom@nouryon.com'
  },
  {
    username: 'norbergt1',
    userDesc: 'Timothy.Norberg@nouryon.com'
  },
  {
    username: 'opmepal',
    userDesc: 'opmepal@nouryon.com'
  },
  {
    username: 'johanssoj',
    userDesc: 'johnny.johansson2@nouryon.com'
  },
  {
    username: 'hakanssj',
    userDesc: 'johan.hakansson@nouryon.com'
  },
  {
    username: 'backlunu',
    userDesc: 'Ulrika.Backlund@nouryon.com'
  },
  {
    username: 'marw',
    userDesc: 'Marie.Wallin@nouryon.com'
  },
  {
    username: 'ehnbjorv',
    userDesc: 'viktoria.ehn-bjork@nouryon.com'
  },
  {
    username: 'segundoa',
    userDesc: 'AlcimarioAlvesBonfim.Segundo@nouryon.com'
  },
  {
    username: 'walterse',
    userDesc: 'Evelina.Waltersson@nouryon.com'
  },
  {
    username: 'larsson6',
    userDesc: 'michael.larsson1@nouryon.com'
  },
  {
    username: 'dahlinn',
    userDesc: 'Nils.Dahlin@nouryon.com'
  },
  {
    username: 'grauersm',
    userDesc: 'Markus.Grauers@nouryon.com'
  },
  {
    username: 'sandino',
    userDesc: 'Olof.Sandin@nouryon.com'
  },
  {
    username: 'vestinj',
    userDesc: 'Jonas.Vestin@nouryon.com'
  },
  {
    username: 'NylandeI',
    userDesc: 'Ingrid.Nylander@nouryon.com'
  },
  {
    username: 'dumaser',
    userDesc: 'eric.dumas@nouryon.com'
  },
  {
    username: 'DahlandN',
    userDesc: 'Niklas.Dahlander@nouryon.com'
  },
  {
    username: 'andreasbo',
    userDesc: 'bo.andreasson@nouryon.com'
  },
  {
    username: 'nagyj',
    userDesc: 'Jeff.Nagy@nouryon.com'
  },
  {
    username: 'bergmanf',
    userDesc: 'fredrik.bergman@nouryon.com'
  },
  {
    username: 'nybergle',
    userDesc: 'leif.nyberg@nouryon.com'
  },
  {
    username: 'johan',
    userDesc: 'johan.lindgren@nouryon.com'
  },
  {
    username: 'bodina',
    userDesc: 'Ann-Louise.Bodin@nouryon.com'
  },
  {
    username: 'jonsson',
    userDesc: 'Magnus.Jonsson@nouryon.com'
  },
  {
    username: 'rudinn',
    userDesc: 'nicholas.rudin@nouryon.com'
  },
  {
    username: 'sture',
    userDesc: 'sture.nilsson@nouryon.com'
  },
  {
    username: 'bergenuh',
    userDesc: 'Helena.Bergenudd@nouryon.com'
  },
  {
    username: 'lauterbk',
    userDesc: 'Kerstin.Lauterbach@nouryon.com'
  },
  {
    username: 'eriksson',
    userDesc: 'Maria.Eriksson2@nouryon.com'
  },
  {
    username: 'svenssp1',
    userDesc: 'Peter.Svensson@nouryon.com'
  },
  {
    username: 'vaguiarv',
    userDesc: 'Victor.Aguiar@nouryon.com'
  },
  {
    username: 'melladoj',
    userDesc: 'Jhon.Mellado@nouryon.com'
  },
  {
    username: 'rodrigur',
    userDesc: 'Rolando.Rodriguez@nouryon.com'
  },
  {
    username: 'pulidof',
    userDesc: 'Fanny.Pulido@nouryon.com'
  },
  {
    username: 'ErikssoA',
    userDesc: 'Asa.Eriksson1@nouryon.com'
  },
  {
    username: 'wijtmanr',
    userDesc: 'Roel.Wijtmans@nouryon.com'
  },
  {
    username: 'karhumaj',
    userDesc: 'jyrki.karhumaa@nouryon.com'
  },
  {
    username: 'adm_raidersj',
    userDesc: 'adm_raidersj'
  },
  {
    username: 'bydenjan',
    userDesc: 'Jan.Byden@nouryon.com'
  },
  {
    username: 'pala',
    userDesc: 'annika.pal@nouryon.com'
  },
  {
    username: 'adm_atwoodj',
    userDesc: 'adm_atwoodj'
  },
  {
    username: 'lambila',
    userDesc: 'antoine.lambilliotte@nouryon.com'
  },
  {
    username: 'adm_stierste',
    userDesc: 'adm_stierste'
  },
  {
    username: 'adm_andersu2',
    userDesc: 'adm_andersu2'
  },
  {
    username: 'erikssm3',
    userDesc: 'Mikael.Eriksson@nouryon.com'
  },
  {
    username: 'karkij',
    userDesc: 'jussi.karki@nouryon.com'
  },
  {
    username: 'karppij1',
    userDesc: 'jouni.karppinen@nouryon.com'
  },
  {
    username: 'lahtineo',
    userDesc: 'okko.lahtinen@nouryon.com'
  },
  {
    username: 'muellea3',
    userDesc: 'Andreas.Mueller2@nouryon.com'
  },
  {
    username: 'heikkint',
    userDesc: 'Tapio.Heikkinen@nouryon.com'
  },
  {
    username: 'browna',
    userDesc: 'annette.sherrod@nouryon.com'
  },
  {
    username: 'teslowr',
    userDesc: 'Rick.Teslow@nouryon.com'
  },
  {
    username: 'PikkaraH1',
    userDesc: 'Harri.Pikkarainen1@nouryon.com'
  },
  {
    username: 'karkkaij',
    userDesc: 'Juha.Kaerkkaeinen@nouryon.com'
  },
  {
    username: 'KoskelaM',
    userDesc: 'miikael.koskela@nouryon.com'
  },
  {
    username: 'hanhelah',
    userDesc: 'heikki.hanhela@nouryon.com'
  },
  {
    username: 'saloh',
    userDesc: 'Henri.Salo@nouryon.com'
  },
  {
    username: 'adm_pengr',
    userDesc: 'adm_pengr'
  },
  {
    username: 'hoglundm',
    userDesc: 'Magnus.Hoglund@nouryon.com'
  },
  {
    username: 'overaasb',
    userDesc: 'birgith.overaas@nouryon.com'
  },
  {
    username: 'torstenj',
    userDesc: 'Johan.Torstensson@nouryon.com'
  },
  {
    username: 'niemelaj',
    userDesc: 'Jari.Niemela@nouryon.com'
  },
  {
    username: 'puolakar',
    userDesc: 'Raimo.Puolakanaho@nouryon.com'
  },
  {
    username: 'ohrbergd',
    userDesc: 'Dennis.Ohrberg@nouryon.com'
  },
  {
    username: 'kesslerr',
    userDesc: 'Reik.Kessler@nouryon.com'
  },
  {
    username: 'gloecknert',
    userDesc: 'Thomas.Gloeckner@nouryon.com'
  },
  {
    username: 'nahkalah',
    userDesc: 'harri.nahkala@nouryon.com'
  },
  {
    username: 'KurunlaS',
    userDesc: 'Seppo.Kurunlahti@nouryon.com'
  },
  {
    username: 'hoppa',
    userDesc: 'Andreas.Hopp@nouryon.com'
  },
  {
    username: 'vaisanep',
    userDesc: 'Petri.Vaisanen@nouryon.com'
  },
  {
    username: 'nordstrp',
    userDesc: 'peter.nordstrom@nouryon.com'
  },
  {
    username: 'ea10',
    userDesc: 'ea10@nouryon.com'
  },
  {
    username: 'hofmannm',
    userDesc: 'Mario.Hofmann@nouryon.com'
  },
  {
    username: 'LahtineN1',
    userDesc: 'niko.lahtinen1@nouryon.com'
  },
  {
    username: 'RamczykT',
    userDesc: 'tobias.ramczyk@nouryon.com'
  },
  {
    username: 'SchmidtJ',
    userDesc: 'Juliane.Schmidt@nouryon.com'
  },
  {
    username: 'garciad',
    userDesc: 'David.Garcia@nouryon.com'
  },
  {
    username: 'bhsbma',
    userDesc: ''
  },
  {
    username: 'PoserJ1',
    userDesc: 'Janine.Poser@nouryon.com'
  },
  {
    username: 'HenttonV',
    userDesc: 'ville.henttonen@nouryon.com'
  },
  {
    username: 'KeranenO',
    userDesc: 'Ossi.Keranen@nouryon.com'
  },
  {
    username: 'olofssob',
    userDesc: 'Borje.Olofsson@nouryon.com'
  },
  {
    username: 'caldwelm',
    userDesc: 'Mark.Caldwell@nouryon.com'
  },
  {
    username: 'hurtk',
    userDesc: 'kim.hurt@nouryon.com'
  },
  {
    username: 'adm_timners',
    userDesc: 'adm_timners'
  },
  {
    username: 'Schlegeh',
    userDesc: 'Hartmut.Schlegel@nouryon.com'
  },
  {
    username: 'selinm',
    userDesc: 'Mikael.Selin@nouryon.com'
  },
  {
    username: 'crabbr',
    userDesc: 'Robert.Crabb@nouryon.com'
  },
  {
    username: 'adm_erikssok',
    userDesc: 'adm_erikssok'
  },
  {
    username: 'olssonk',
    userDesc: 'Kjell.Olsson@nouryon.com'
  },
  {
    username: 'BackstrT',
    userDesc: 'Thomas.Backstrom@nouryon.com'
  },
  {
    username: 'dahlint',
    userDesc: 'tomas.dahlin@nouryon.com'
  },
  {
    username: 'wheelerj',
    userDesc: 'Jason.Wheeler@nouryon.com'
  },
  {
    username: 'SellgreC',
    userDesc: 'Christoffer.Sellgren@nouryon.com'
  },
  {
    username: 'BernhaO1',
    userDesc: 'Olof.Bernhardsson@nouryon.com'
  },
  {
    username: 'erikssono',
    userDesc: 'Oerjan.Eriksson@nouryon.com'
  },
  {
    username: 'FromM1',
    userDesc: 'Malin.From1@nouryon.com'
  },
  {
    username: 'vickersl',
    userDesc: 'Lee.Vickers@nouryon.com'
  },
  {
    username: 'breitkrr',
    userDesc: 'Rikard.Breitkreuz@nouryon.com'
  },
  {
    username: 'bortolj',
    userDesc: 'jerome.bortolato@nouryon.com'
  },
  {
    username: 'mcmonaglee',
    userDesc: 'Ed.McMonagle@nouryon.com'
  },
  {
    username: 'JonssoJ1',
    userDesc: 'Jan-Erik.Jonsson1@nouryon.com'
  },
  {
    username: 'rautiot',
    userDesc: 'Tuija.Rautio@nouryon.com'
  },
  {
    username: 'wilbonn',
    userDesc: 'nikki.wilbon@nouryon.com'
  },
  {
    username: 'hedenstk',
    userDesc: 'Kristoffer.Hedenstedt@nouryon.com'
  },
  {
    username: 'uptond1',
    userDesc: 'Dewayne.Upton1@nouryon.com'
  },
  {
    username: 'KruegeT1',
    userDesc: 'Tom.Krueger@nouryon.com'
  },
  {
    username: 'ADM_sangl',
    userDesc: 'ADM_sangl'
  },
  {
    username: 'adm_dongjh',
    userDesc: 'adm_dongjh'
  },
  {
    username: 'mahr1',
    userDesc: 'Richard.Mah@nouryon.com'
  },
  {
    username: 'sellina',
    userDesc: 'Annicka.Sellin@nouryon.com'
  },
  {
    username: 'changn',
    userDesc: 'nike.chang@nouryon.com'
  },
  {
    username: 'shelnul',
    userDesc: 'lance.shelnut@nouryon.com'
  },
  {
    username: 'ErikssJ1',
    userDesc: 'jan.eriksson1@nouryon.com'
  },
  {
    username: 'andersl4',
    userDesc: 'Lasse.Andersson@nouryon.com'
  },
  {
    username: 'sandstrp',
    userDesc: 'Per.Sandstrom@nouryon.com'
  },
  {
    username: 'stolte',
    userDesc: 'Evalotta.Stolt@nouryon.com'
  },
  {
    username: 'ivarssob',
    userDesc: 'Brita.Ivarsson@nouryon.com'
  },
  {
    username: 'BrandbeV',
    userDesc: 'Victoria.Brandberg@nouryon.com'
  },
  {
    username: 'belluem',
    userDesc: 'michael.bellue@nouryon.com'
  },
  {
    username: 'bourbonf',
    userDesc: 'Francois.Bourbon@nouryon.com'
  },
  {
    username: 'mindaym',
    userDesc: 'Mark.Minday@nouryon.com'
  },
  {
    username: 'AntonssM',
    userDesc: 'Marcus.Antonsson@nouryon.com'
  },
  {
    username: 'hidestao',
    userDesc: 'Olle.Hidestal@nouryon.com'
  },
  {
    username: 'adm_husss',
    userDesc: 'adm_husss'
  },
  {
    username: 'gignacm',
    userDesc: 'Manon.Gignac@nouryon.com'
  },
  {
    username: 'gunnarsm',
    userDesc: 'magnus.gunnarsson@nouryon.com'
  },
  {
    username: 'rosal',
    userDesc: 'Lucas.Rosa@nouryon.com'
  },
  {
    username: 'johansa6',
    userDesc: 'AndersO.Johansson@nouryon.com'
  },
  {
    username: 'mayot',
    userDesc: 'tim.mayo@nouryon.com'
  },
  {
    username: 'liangjam',
    userDesc: 'james.liang@nouryon.com'
  },
  {
    username: 'bessettc',
    userDesc: 'cody.bessett@nouryon.com'
  },
  {
    username: 'robinsor',
    userDesc: 'Robbie.Robinson@nouryon.com'
  },
  {
    username: 'backstrm1',
    userDesc: 'Magnus.Backstrom@nouryon.com'
  },
  {
    username: 'duranrd',
    userDesc: 'ralph.duran@nouryon.com'
  },
  {
    username: 'jonassot',
    userDesc: 'Tobias.Jonasson@nouryon.com'
  },
  {
    username: 'airesm',
    userDesc: 'Maria.Aires@nouryon.com'
  },
  {
    username: 'petterm2',
    userDesc: 'Magnus.Pettersson@nouryon.com'
  },
  {
    username: 'tst_BASC_EPI_1',
    userDesc: ''
  },
  {
    username: 'alab',
    userDesc: 'alab@nouryon.com'
  },
  {
    username: 'tst_BASC_EPI_4',
    userDesc: ''
  },
  {
    username: 'tst_BASC_EPI_2',
    userDesc: ''
  },
  {
    username: 'risok',
    userDesc: 'Katarina.Riso@nouryon.com'
  },
  {
    username: 'backmam1',
    userDesc: 'minna.backman@nouryon.com'
  },
  {
    username: 'tst_BASC_EPI_8',
    userDesc: ''
  },
  {
    username: 'HB',
    userDesc: 'Helena.Bergenholm@nouryon.com'
  },
  {
    username: 'janssoni',
    userDesc: 'Inger.Jansson@nouryon.com'
  },
  {
    username: 'LundqviA',
    userDesc: 'Anna.Lundqvist@nouryon.com'
  },
  {
    username: 'johansc1',
    userDesc: 'christer.johansson1@nouryon.com'
  },
  {
    username: 'PelkoneN',
    userDesc: 'Nina.Pelkonen@nouryon.com'
  },
  {
    username: 'haggstrk',
    userDesc: 'kimona.haggstrom@nouryon.com'
  },
  {
    username: 'andersm1',
    userDesc: 'Magnus.Andersson1@nouryon.com'
  },
  {
    username: 'BrandsJ1',
    userDesc: 'Jonas.Brandstrom1@nouryon.com'
  },
  {
    username: 'muniztrr',
    userDesc: 'Rodrigo.MunizTrassato@nouryon.com'
  },
  {
    username: 'SamuelsJ1',
    userDesc: 'Johannes.Samuelsson@nouryon.com'
  },
  {
    username: 'OnderwaK',
    userDesc: 'kristan.onderwaater@nouryon.com'
  },
  {
    username: 'araujor',
    userDesc: 'Rodrigo.Araujo1@nouryon.com'
  },
  {
    username: 'mohlenj1',
    userDesc: 'Joel.Mohlen@nouryon.com'
  },
  {
    username: 'baltsenh',
    userDesc: 'Hans.Baltsen@nouryon.com'
  },
  {
    username: 'adm_borsp',
    userDesc: 'adm_borsp'
  },
  {
    username: 'ericssong',
    userDesc: 'gert.ericsson@nouryon.com'
  },
  {
    username: 'UhlinM',
    userDesc: 'Mikael.Uhlin@nouryon.com'
  },
  {
    username: 'JonssonM1',
    userDesc: 'mikael.jonsson@nouryon.com'
  },
  {
    username: 'restorpp',
    userDesc: 'per.restorp@nouryon.com'
  },
  {
    username: 'azevedo1',
    userDesc: 'marcelo.azevedo@nouryon.com'
  },
  {
    username: 'anderssl2',
    userDesc: 'lena.andersson2@nouryon.com'
  },
  {
    username: 'GregoryS',
    userDesc: 'Steven.Gregory@nouryon.com'
  },
  {
    username: 'rittet01',
    userDesc: 'tim.ritter@nouryon.com'
  },
  {
    username: 'adm_KarunaaN',
    userDesc: 'adm_KarunaaN'
  },
  {
    username: 'waltheru',
    userDesc: 'Ulla.Walther@nouryon.com'
  },
  {
    username: 'jahj',
    userDesc: 'Jan.Hjalmarsson@nouryon.com'
  },
  {
    username: 'frojc1',
    userDesc: 'Cecilia.Froejd@nouryon.com'
  },
  {
    username: 'BackmanH',
    userDesc: 'Hakan.Backman@nouryon.com'
  },
  {
    username: 'crawforc1',
    userDesc: 'Chris.Crawford@nouryon.com'
  },
  {
    username: 'adm_onderwaaterk',
    userDesc: 'adm_onderwaaterk'
  },
  {
    username: 'kraulanm',
    userDesc: 'Martin.Krauland@nouryon.com'
  },
  {
    username: 'korhonep',
    userDesc: 'Pertti.Korhonen@nouryon.com'
  },
  {
    username: 'yadl',
    userDesc: 'Yad.Lundback@nouryon.com'
  },
  {
    username: 'gerhartb',
    userDesc: 'bert.gerhart@nouryon.com'
  },
  {
    username: 'erikssc1',
    userDesc: 'carina.eriksson@nouryon.com'
  },
  {
    username: 'OgrenM',
    userDesc: 'mattias.ogren2@nouryon.com'
  },
  {
    username: 'engberga',
    userDesc: 'andreas.engberg@nouryon.com'
  },
  {
    username: 'carlbere',
    userDesc: 'Eie.Carlberg@nouryon.com'
  },
  {
    username: 'toer',
    userDesc: 'tobias.ericsson@nouryon.com'
  },
  {
    username: 'mogenh',
    userDesc: 'Hans-Oistein.Mogen@nouryon.com'
  },
  {
    username: 'wallsted',
    userDesc: 'Daniel.Wallsten@nouryon.com'
  },
  {
    username: 'adm_bergstt1',
    userDesc: 'adm_bergstt1'
  },
  {
    username: 'Adm_wandahla',
    userDesc: 'Adm_wandahla'
  },
  {
    username: 'zatu',
    userDesc: 'Zana.Tipuric@nouryon.com'
  },
  {
    username: 'adm_rozanol',
    userDesc: 'adm_rozanol'
  },
  {
    username: 'AwK',
    userDesc: 'Anders.Winkler@nouryon.com'
  },
  {
    username: 'adm_lagerhg',
    userDesc: 'adm_lagerhg'
  },
  {
    username: 'adm_wolfgrat',
    userDesc: 'adm_wolfgrat'
  },
  {
    username: 'VillanuD',
    userDesc: 'Diego.Villanueva@nouryon.com'
  },
  {
    username: 'dupouya',
    userDesc: 'Arabelle.Dupouy@nouryon.com'
  },
  {
    username: 'strandinb',
    userDesc: 'bjorn.strandin@nouryon.com'
  },
  {
    username: 'POTRELC',
    userDesc: 'Cecile.POTREL@nouryon.com'
  },
  {
    username: 'sanfordg',
    userDesc: 'gentry.sanford@nouryon.com'
  },
  {
    username: 'nascima1',
    userDesc: 'Antonio.Nascimento@nouryon.com'
  },
  {
    username: 'adm_keerippa',
    userDesc: 'adm_keerippa'
  },
  {
    username: 'adm_LagerstK',
    userDesc: 'adm_LagerstK'
  },
  {
    username: 'adm_murarov',
    userDesc: 'adm_murarov'
  },
  {
    username: 'yuyongr',
    userDesc: 'Randel.Yu@nouryon.com'
  },
  {
    username: 'OstrenM',
    userDesc: 'marcus.ostren@nouryon.com'
  },
  {
    username: 'adm_kashyaps',
    userDesc: 'adm_kashyaps'
  },
  {
    username: 'adm_shahuls',
    userDesc: 'adm_shahuls'
  },
  {
    username: 'LeinoneK',
    userDesc: 'kati.leinonen@nouryon.com'
  },
  {
    username: 'kyriakid',
    userDesc: 'despina.kyriakidou@nouryon.com'
  },
  {
    username: 'Svensso1',
    userDesc: 'Elin.Svensson2@nouryon.com'
  },
  {
    username: 'rozanol',
    userDesc: 'Luis.Rozano@nouryon.com'
  },
  {
    username: 'dufvenbd',
    userDesc: 'dennis.dufvenberg@nouryon.com'
  },
  {
    username: 'belchior',
    userDesc: 'Rodrigo.Belchior@nouryon.com'
  },
  {
    username: 'jonssoS1',
    userDesc: 'stefan.jonsson2@nouryon.com'
  },
  {
    username: 'soderstT',
    userDesc: 'thomas.soderstrom2@nouryon.com'
  },
  {
    username: 'crookg',
    userDesc: 'gerald.crook@nouryon.com'
  },
  {
    username: 'lidins',
    userDesc: 'sven-erik.lidin@nouryon.com'
  },
  {
    username: 'bergklit',
    userDesc: 'thomas.bergklint@nouryon.com'
  },
  {
    username: 'sjolundP',
    userDesc: 'Peter.Sjoelund@nouryon.com'
  },
  {
    username: 'Anderss4',
    userDesc: 'Cecilia.Andersson@nouryon.com'
  },
  {
    username: 'carlssa1',
    userDesc: 'Anders.Carlsson@nouryon.com'
  },
  {
    username: 'myllylam',
    userDesc: 'Marjo.Myllyla@nouryon.com'
  },
  {
    username: 'mikaels1',
    userDesc: 'stefan.mikaelsson@nouryon.com'
  },
  {
    username: 'anderssk',
    userDesc: 'katrin.andersson@nouryon.com'
  },
  {
    username: 'WestmanJ',
    userDesc: 'Jon.Westman@nouryon.com'
  },
  {
    username: 'bergstr2',
    userDesc: 'roger.bergstrom2@nouryon.com'
  },
  {
    username: 'lindhk',
    userDesc: 'kenneth.lindh@nouryon.com'
  },
  {
    username: 'WounderS',
    userDesc: 'susanne.wounder@nouryon.com'
  },
  {
    username: 'andrem',
    userDesc: 'Michael.Andre@nouryon.com'
  },
  {
    username: 'wiedenbp',
    userDesc: 'Pablo.Wiedenbrug@nouryon.com'
  },
  {
    username: 'GradinA',
    userDesc: 'Anna.Gradin@nouryon.com'
  },
  {
    username: 'LarssoT1',
    userDesc: 'thomas.l.larsson@nouryon.com'
  },
  {
    username: 'EkstalJ',
    userDesc: 'Jesper.Ekstal@nouryon.com'
  },
  {
    username: 'wennmanm',
    userDesc: 'mikael.wennman@nouryon.com'
  },
  {
    username: 'akerlunj',
    userDesc: 'johan.akerlund2@nouryon.com'
  },
  {
    username: 'bonnstrp',
    userDesc: 'peter.bonnstrom2@nouryon.com'
  },
  {
    username: 'kallbert',
    userDesc: 'thomas.kallberg2@nouryon.com'
  },
  {
    username: 'sjoboml',
    userDesc: 'lars.sjobom2@nouryon.com'
  },
  {
    username: 'adm_ravik',
    userDesc: 'adm_ravik'
  },
  {
    username: 'YpmaM',
    userDesc: 'Marco.Ypma@nouryon.com'
  },
  {
    username: 'sviktf',
    userDesc: 'fredrik.svikt@nouryon.com'
  },
  {
    username: 'torncroa',
    userDesc: 'Anders.Toerncrona@nouryon.com'
  },
  {
    username: 'flinkc',
    userDesc: 'calle.flink@nouryon.com'
  },
  {
    username: 'nilssom1',
    userDesc: 'mattias.nilsson2@nouryon.com'
  },
  {
    username: 'guoqc',
    userDesc: 'Qc_Eka.Guo@nouryon.com'
  },
  {
    username: 'adm_bersonf',
    userDesc: 'adm_bersonf'
  },
  {
    username: 'forslung',
    userDesc: 'goran.forslund2@nouryon.com'
  },
  {
    username: 'tst_basc_epi_10',
    userDesc: ''
  },
  {
    username: 'tst_basc_epi_25',
    userDesc: ''
  },
  {
    username: 'tst_basc_epi_26',
    userDesc: 'tst_basc_epi_26'
  },
  {
    username: 'tst_basc_epi_18',
    userDesc: ''
  },
  {
    username: 'tst_basc_epi_20',
    userDesc: ''
  },
  {
    username: 'tst_basc_epi_19',
    userDesc: ''
  },
  {
    username: 'knophe',
    userDesc: 'Evy.Knoph@nouryon.com'
  },
  {
    username: 'adm_SamuelsJ1',
    userDesc: 'adm_SamuelsJ1'
  },
  {
    username: 'sandstrs',
    userDesc: 'sven-erik.sandstrom2@nouryon.com'
  },
  {
    username: 'assisn',
    userDesc: 'Nelson.Assis@nouryon.com'
  },
  {
    username: 'schmidt3',
    userDesc: 'therese.dyrfelt@nouryon.com'
  },
  {
    username: 'haginsk',
    userDesc: 'Keith.Hagins@nouryon.com'
  },
  {
    username: 'asjo',
    userDesc: 'Annica.Sjodin@nouryon.com'
  },
  {
    username: 'xcaj',
    userDesc: 'Caroline.Hulten@nouryon.com'
  },
  {
    username: 'pernsted',
    userDesc: 'Dan.Pernsteiner@nouryon.com'
  },
  {
    username: 'hedderp',
    userDesc: 'Patrick.Hedderman@nouryon.com'
  },
  {
    username: 'rsc_bhssp',
    userDesc: ''
  },
  {
    username: 'mackld01',
    userDesc: 'dave.mackling@nouryon.com'
  },
  {
    username: 'SantosLS',
    userDesc: 'luciano.santos1@nouryon.com'
  },
  {
    username: 'whitinga',
    userDesc: 'alex.whiting@nouryon.com'
  },
  {
    username: 'adm_muthusas',
    userDesc: 'adm_muthusas'
  },
  {
    username: 'adm_pallikan',
    userDesc: 'adm_pallikan'
  },
  {
    username: 'manzolie',
    userDesc: 'Emilli.Manzoli@nouryon.com'
  },
  {
    username: 'sanders',
    userDesc: 'MIchael.Sanderson@nouryon.com'
  },
  {
    username: 'dentsa',
    userDesc: 'S.A.Dent@nouryon.com'
  },
  {
    username: 'domingot',
    userDesc: 'Thais.Domingos@nouryon.com'
  },
  {
    username: 'adm_risok',
    userDesc: 'adm_risok'
  },
  {
    username: 'Silvap01',
    userDesc: 'patricia.tostes@nouryon.com'
  },
  {
    username: 'santoale',
    userDesc: 'Alexandre.Santos@nouryon.com'
  },
  {
    username: 'lopesrs',
    userDesc: 'ronaldo.lopes@nouryon.com'
  },
  {
    username: 'wildlocm',
    userDesc: 'Mats.Wildlock@nouryon.com'
  },
  {
    username: 'BerglunA',
    userDesc: 'BerglunA'
  },
  {
    username: 'SellbomJ',
    userDesc: 'Johan.Sellbom@nouryon.com'
  },
  {
    username: 'forselij',
    userDesc: 'Johan.Forselius@nouryon.com'
  },
  {
    username: 'HanksL',
    userDesc: 'lynne.hanks@nouryon.com'
  },
  {
    username: 'adm_jedeniuf',
    userDesc: 'adm_jedeniuf'
  },
  {
    username: 'Lco',
    userDesc: 'Lena.Cooper@nouryon.com'
  },
  {
    username: 'adm_tardifm',
    userDesc: 'adm_tardifm'
  },
  {
    username: 'adm_peterf',
    userDesc: 'adm_peterf'
  },
  {
    username: 'xrosa',
    userDesc: 'Roine.Satterstrom@nouryon.com'
  },
  {
    username: 'sjostra1',
    userDesc: 'asa.sjostrom@nouryon.com'
  },
  {
    username: 'munizm',
    userDesc: 'Neide.Muniz@nouryon.com'
  },
  {
    username: 'NygrenR',
    userDesc: 'Rebecca.Nygren@nouryon.com'
  },
  {
    username: 'LundinE',
    userDesc: 'Emma.Lundin@nouryon.com'
  },
  {
    username: 'JevremC',
    userDesc: 'Carolina.Jevrem@nouryon.com'
  },
  {
    username: 'adm_gaarensr',
    userDesc: 'adm_gaarensr'
  },
  {
    username: 'LundstM',
    userDesc: 'Monika.Lundstrom@nouryon.com'
  },
  {
    username: 'Lagaliep',
    userDesc: 'Pauline.Lagalie@nouryon.com'
  },
  {
    username: 'bc1379',
    userDesc: 'alain.forgeron@nouryon.com'
  },
  {
    username: 'zhoux3',
    userDesc: 'xinzhao.zhou@nouryon.com'
  },
  {
    username: 'MereditW',
    userDesc: 'rick.meredith@nouryon.com'
  },
  {
    username: 'floresa',
    userDesc: 'Anderson.Floresti@nouryon.com'
  },
  {
    username: 'BC1380',
    userDesc: 'frederic.renon@nouryon.com'
  },
  {
    username: 'knausl',
    userDesc: 'Louis.Knaus@nouryon.com'
  },
  {
    username: 'armgrenr',
    userDesc: 'Rasmus.Armgren@nouryon.com'
  },
  {
    username: 'manoelm',
    userDesc: 'Marcio.Manoel@nouryon.com'
  },
  {
    username: 'filhoc',
    userDesc: 'Celso.Filho@nouryon.com'
  },
  {
    username: 'naitzkec',
    userDesc: 'Claudemir.Naitzke@nouryon.com'
  },
  {
    username: 'nascimel',
    userDesc: 'Lizzahara.Nascimento@nouryon.com'
  },
  {
    username: 'beckerjr',
    userDesc: 'Joao.Becker@nouryon.com'
  },
  {
    username: 'passosr',
    userDesc: 'Rodrigo.Passos@nouryon.com'
  },
  {
    username: 'fonsecal',
    userDesc: 'Alex.Fonseca@nouryon.com'
  },
  {
    username: 'HambergJ',
    userDesc: 'Johan.Hamberg@nouryon.com'
  },
  {
    username: 'juniorjo',
    userDesc: 'JoseCastro.Junior@nouryon.com'
  },
  {
    username: 'carrilhe',
    userDesc: 'Emerson.Carrilho@nouryon.com'
  },
  {
    username: 'BC1271',
    userDesc: 'Flavio.Andriolo@nouryon.com'
  },
  {
    username: 'bc1391',
    userDesc: 'Kevin.Clais@nouryon.com'
  },
  {
    username: 'fiorenzl',
    userDesc: 'Lazaro.Fiorenzi@nouryon.com'
  },
  {
    username: 'pereira5',
    userDesc: 'Thiago.Pereira1@nouryon.com'
  },
  {
    username: 'sousam',
    userDesc: 'Misael.Sousa@nouryon.com'
  },
  {
    username: 'rollbuht',
    userDesc: 'Tom.Rollbuhler@nouryon.com'
  },
  {
    username: 'MedeiroB',
    userDesc: 'Bruno.Medeiro@nouryon.com'
  },
  {
    username: 'santoswe',
    userDesc: 'Wellingtton.Santos@nouryon.com'
  },
  {
    username: 'albuquea',
    userDesc: 'Alessandro.Albuquerque@nouryon.com'
  },
  {
    username: 'Vilasbol',
    userDesc: 'Lucas.VilasBoas@nouryon.com'
  },
  {
    username: 'camposg',
    userDesc: 'Gervasio.Campos@nouryon.com'
  },
  {
    username: 'henriqm1',
    userDesc: 'Mauricio.Henriques0@nouryon.com'
  },
  {
    username: 'NilssonO',
    userDesc: 'Oskar.Nilsson@nouryon.com'
  },
  {
    username: 'santanae',
    userDesc: 'Eucildes.Santana@nouryon.com'
  },
  {
    username: 'nordstru',
    userDesc: 'ulf.nordstrom@nouryon.com'
  },
  {
    username: 'contrerc',
    userDesc: 'carla.contreras@nouryon.com'
  },
  {
    username: 'limadan',
    userDesc: 'daniel.lima@nouryon.com'
  },
  {
    username: 'goisb',
    userDesc: 'BrunoSoaresdaSilva.Gois@nouryon.com'
  },
  {
    username: 'bc1134',
    userDesc: 'Serge.Depret@nouryon.com'
  },
  {
    username: 'salamonb',
    userDesc: 'Benjamin.Salamone@nouryon.com'
  },
  {
    username: 'LuisiA01',
    userDesc: 'Alexandre.Luisier@nouryon.com'
  },
  {
    username: 'silvaa3',
    userDesc: 'alexandre.rodrigues@nouryon.com'
  },
  {
    username: 'rigoloc',
    userDesc: 'Carlos.Rigolo@nouryon.com'
  },
  {
    username: 'costamc',
    userDesc: 'Manoela.Costa@nouryon.com'
  },
  {
    username: 'adm_silvaa3',
    userDesc: 'adm_silvaa3'
  },
  {
    username: 'PierinM',
    userDesc: 'Marcio.Pierina@nouryon.com'
  },
  {
    username: 'teodoror',
    userDesc: 'Renan.Teodoro@nouryon.com'
  },
  {
    username: 'oliveiju',
    userDesc: 'Juliano.Oliveira@nouryon.com'
  },
  {
    username: 'freitasd',
    userDesc: 'Valdner.Freitas@nouryon.com'
  },
  {
    username: 'nc3241',
    userDesc: 'Erik.Kuppen@nouryon.com'
  },
  {
    username: 'heg1',
    userDesc: 'George.He@nouryon.com'
  },
  {
    username: 'adm_gaok2',
    userDesc: 'adm_gaok2'
  },
  {
    username: 'adm_degerc',
    userDesc: 'adm_degerc'
  },
  {
    username: 'adm_suw',
    userDesc: 'adm_suw'
  },
  {
    username: 'bc1410',
    userDesc: 'Joris.Ruelle@nouryon.com'
  },
  {
    username: 'robertp2',
    userDesc: 'Patrick.Robert@nouryon.com'
  },
  {
    username: 'BC1363',
    userDesc: 'Santiago.ReguerasdelaFuente@nouryon.com'
  },
  {
    username: 'adm_burnsidj',
    userDesc: 'adm_burnsidj'
  },
  {
    username: 'silvaba',
    userDesc: 'Bartolomeu.Silva@nouryon.com'
  },
  {
    username: 'barbosma',
    userDesc: 'Marcelo.Barbosa@nouryon.com'
  },
  {
    username: 'adm_bhatta',
    userDesc: 'adm_bhatta'
  },
  {
    username: 'tjernbeb',
    userDesc: 'Bo.Tjernberg@nouryon.com'
  },
  {
    username: 'NilssoR1',
    userDesc: 'Rickard.Nilsson@nouryon.com'
  },
  {
    username: 'HuZ',
    userDesc: 'ZiWen.Hu@nouryon.com'
  },
  {
    username: 'hedberg',
    userDesc: 'Ake.Hedberg@nouryon.com'
  },
  {
    username: 'mauricaj',
    userDesc: 'Aldair.Mauricio@nouryon.com'
  },
  {
    username: 'PendemeR',
    userDesc: 'Rachidou.Pendeme@nouryon.com'
  },
  {
    username: 'SmithD25',
    userDesc: 'Dan.Smith2@nouryon.com'
  },
  {
    username: 'kingj06',
    userDesc: 'jennifer.traylor@nouryon.com'
  },
  {
    username: 'hiattj01',
    userDesc: 'Jeff.Hiatt@nouryon.com'
  },
  {
    username: 'renC',
    userDesc: 'coco.ren@nouryon.com'
  },
  {
    username: 'lindah',
    userDesc: 'Linda.Hansson@nouryon.com'
  },
  {
    username: 'renaudgj',
    userDesc: 'Jonathan.RenaudGagnon@nouryon.com'
  },
  {
    username: 'ZasadowD',
    userDesc: 'Dariusz.Zasadowski@nouryon.com'
  },
  {
    username: 'adm_mohans1',
    userDesc: 'adm_mohans1'
  },
  {
    username: 'karlssoa1',
    userDesc: 'anders.karlsson1@nouryon.com'
  },
  {
    username: 'AssumpcN',
    userDesc: 'Natalia.Assumpcao@nouryon.com'
  },
  {
    username: 'adm_SternsK',
    userDesc: 'adm_SternsK'
  },
  {
    username: 'adm_StearnsK',
    userDesc: 'adm_StearnsK'
  },
  {
    username: 'adm_hanksl',
    userDesc: 'adm_hanksl'
  },
  {
    username: 'gbr',
    userDesc: 'Gunilla.Branden@nouryon.com'
  },
  {
    username: 'adm_Gut1',
    userDesc: 'Tim Gu'
  },
  {
    username: 'shawk',
    userDesc: 'Kolt.Shaw@nouryon.com'
  },
  {
    username: 'zhanglj',
    userDesc: 'liujie.zhang@nouryon.com'
  },
  {
    username: 'miho',
    userDesc: 'Mikael.Horlin@nouryon.com'
  },
  {
    username: 'kln',
    userDesc: 'Kerstin.Larsson@nouryon.com'
  },
  {
    username: 'SaoL',
    userDesc: 'Sara.Olsson@nouryon.com'
  },
  {
    username: 'MayerL',
    userDesc: 'Loren.Mayer@nouryon.com'
  },
  {
    username: 'vieiramz',
    userDesc: 'Marina.Vieira@nouryon.com'
  },
  {
    username: 'Dua1',
    userDesc: 'Apple.Du@nouryon.com'
  },
  {
    username: 'qianjs',
    userDesc: 'Jinsong.Qian@nouryon.com'
  },
  {
    username: 'lvn',
    userDesc: 'navy.lv@nouryon.com'
  },
  {
    username: 'MoghadA',
    userDesc: 'abol.moghaddam@nouryon.com'
  },
  {
    username: 'wangcd',
    userDesc: 'cedong.wang@nouryon.com'
  },
  {
    username: 'yanga5',
    userDesc: 'Ada.yang@nouryon.com'
  },
  {
    username: 'schmitt1',
    userDesc: 'josh.schmitt@nouryon.com'
  },
  {
    username: 'nij1',
    userDesc: 'Jim.Ni@nouryon.com'
  },
  {
    username: 'abrahma',
    userDesc: 'Mattias.Abrahamsson@nouryon.com'
  },
  {
    username: 'eval',
    userDesc: 'eva.landen@nouryon.com'
  },
  {
    username: 'KohlerDC',
    userDesc: 'Catrin.KohlerDecker@nouryon.com'
  },
  {
    username: 'RautiaiM',
    userDesc: 'Merja.Rautiainen@nouryon.com'
  },
  {
    username: 'FLYEC',
    userDesc: 'CORY.FLYE@nouryon.com'
  },
  {
    username: 'JohanAnd',
    userDesc: 'andreas.johansson@nouryon.com'
  },
  {
    username: 'anderssm1',
    userDesc: 'Mikael.Andersson1@nouryon.com'
  },
  {
    username: 'pama',
    userDesc: 'Per-Anders.Martensson@nouryon.com'
  },
  {
    username: 'zhua',
    userDesc: 'Alina.Zhu@nouryon.com'
  },
  {
    username: 'hey1',
    userDesc: 'Yu.He@nouryon.com'
  },
  {
    username: 'adm_as1',
    userDesc: 'adm_as1'
  },
  {
    username: 'ADM_kumarm5',
    userDesc: 'ADM_kumarm5'
  },
  {
    username: 'hultine',
    userDesc: 'Eva.NilssonHultin@nouryon.com'
  },
  {
    username: 'thol',
    userDesc: 'Thomas.Olausson@nouryon.com'
  },
  {
    username: 'spetza',
    userDesc: 'Anders.Spetz@nouryon.com'
  },
  {
    username: 'alexanj1',
    userDesc: 'johnny.alexandersson@nouryon.com'
  },
  {
    username: 'DaeK',
    userDesc: 'David.Ek@nouryon.com'
  },
  {
    username: 'hoffd',
    userDesc: 'Dan.Hoff@nouryon.com'
  },
  {
    username: 'xgni',
    userDesc: 'Glenn.Nilsen@nouryon.com'
  },
  {
    username: 'SkoglunL',
    userDesc: 'Lennart.Skoglund@nouryon.com'
  },
  {
    username: 'pebo',
    userDesc: 'Petra.Bohlin@nouryon.com'
  },
  {
    username: 'nikb',
    userDesc: 'Niklas.Bergqvist@nouryon.com'
  },
  {
    username: 'jobo',
    userDesc: 'Jonas.Bogren@nouryon.com'
  },
  {
    username: 'carlssoc',
    userDesc: 'Christian.Carlsson@nouryon.com'
  },
  {
    username: 'NikA',
    userDesc: 'Niklas.Akerman@nouryon.com'
  },
  {
    username: 'Aspenlik',
    userDesc: 'Karin.Aspenlid@nouryon.com'
  },
  {
    username: 'fondenp',
    userDesc: 'Patrik.Fonden@nouryon.com'
  },
  {
    username: 'mardp',
    userDesc: 'Pia.Mard@nouryon.com'
  },
  {
    username: 'adm_hansent',
    userDesc: 'adm_hansent'
  },
  {
    username: 'davidssm',
    userDesc: 'Magnus.Davidsson@nouryon.com'
  },
  {
    username: 'baiyw',
    userDesc: 'yangwei.bai@nouryon.com'
  },
  {
    username: 'LIUSLN',
    userDesc: 'selana.liu@nouryon.com'
  },
  {
    username: 'liuy6',
    userDesc: 'yong6.liu@nouryon.com'
  },
  {
    username: 'makg',
    userDesc: 'Kaiguo.ma@nouryon.com'
  },
  {
    username: 'Roderhum',
    userDesc: 'Maria.Roderhult@nouryon.com'
  },
  {
    username: 'zhangz4',
    userDesc: 'Zhen.Zhang@nouryon.com'
  },
  {
    username: 'hongf2',
    userDesc: 'Feng.hong@nouryon.com'
  },
  {
    username: 'zhouwh',
    userDesc: 'Wenhan.zhou@nouryon.com'
  },
  {
    username: 'lixl',
    userDesc: 'Xinlai.li@nouryon.com'
  },
  {
    username: 'mas2',
    userDesc: 'Shuanhong.Ma@nouryon.com'
  },
  {
    username: 'dingsf',
    userDesc: 'shunfeng.ding@nouryon.com'
  },
  {
    username: 'luoym1',
    userDesc: 'yaming.luo@nouryon.com'
  },
  {
    username: 'tianyb',
    userDesc: 'yubao.tian@nouryon.com'
  },
  {
    username: 'liwd',
    userDesc: 'wandong.li@nouryon.com'
  },
  {
    username: 'WANGF4',
    userDesc: 'Feng4.Wang@nouryon.com'
  },
  {
    username: 'wuy3',
    userDesc: 'Yong.Wu2@nouryon.com'
  },
  {
    username: 'chenxf',
    userDesc: 'xiangfu.chen@nouryon.com'
  },
  {
    username: 'maj2',
    userDesc: 'Joanna.Ma@nouryon.com'
  },
  {
    username: 'daics',
    userDesc: 'Cangsu.Dai@nouryon.com'
  },
  {
    username: 'KvammenC',
    userDesc: 'camilla.kvammen@nouryon.com'
  },
  {
    username: 'jbjuve',
    userDesc: 'Jonathan.Bjuvefors@nouryon.com'
  },
  {
    username: 'frwi',
    userDesc: 'Fredrik.Wijk@nouryon.com'
  },
  {
    username: 'roen',
    userDesc: 'Robert.Engblom@nouryon.com'
  },
  {
    username: 'roli',
    userDesc: 'Ronny.Lindblom@nouryon.com'
  },
  {
    username: 'bol',
    userDesc: 'Bengt.Olovsson@nouryon.com'
  },
  {
    username: 'GustaF',
    userDesc: 'fredrik.gustafsson@nouryon.com'
  },
  {
    username: 'adm_liqp',
    userDesc: 'adm_liqp'
  },
  {
    username: 'BergmanH',
    userDesc: 'Hans.Bergman@nouryon.com'
  },
  {
    username: 'kadia',
    userDesc: 'AliNadir.Kadi@nouryon.com'
  },
  {
    username: 'lixg',
    userDesc: 'xiaogang.li@nouryon.com'
  },
  {
    username: 'anpe',
    userDesc: 'annika.persson@nouryon.com'
  },
  {
    username: 'wuwy',
    userDesc: 'Wenying.wu@nouryon.com'
  },
  {
    username: 'grahne',
    userDesc: 'elin.grahn@nouryon.com'
  },
  {
    username: 'joaquimm',
    userDesc: 'Marcio.Joaquim@nouryon.com'
  },
  {
    username: 'berntssa',
    userDesc: 'Andreas.Berntsson@nouryon.com'
  },
  {
    username: 'falthf',
    userDesc: 'fredrik.falth@nouryon.com'
  },
  {
    username: 'liulh1',
    userDesc: 'liehai.liu@nouryon.com'
  },
  {
    username: 'BlomH',
    userDesc: 'hanna.blom@nouryon.com'
  },
  {
    username: 'Hentschm',
    userDesc: 'Mathias.Hentschel@nouryon.com'
  },
  {
    username: 'mpo',
    userDesc: 'Mats.Olsson@nouryon.com'
  },
  {
    username: 'ijw',
    userDesc: 'Ingegerd.Jarl-Wigstrand@nouryon.com'
  },
  {
    username: 'limark',
    userDesc: 'mark.li@nouryon.com'
  },
  {
    username: 'kimih',
    userDesc: 'Kimi.Hu@nouryon.com'
  },
  {
    username: 'Longf',
    userDesc: 'Fengying.Long@nouryon.com'
  },
  {
    username: 'liqp',
    userDesc: 'Qiangping.Li@nouryon.com'
  },
  {
    username: 'wangs6',
    userDesc: 'Steven1.Wang@nouryon.com'
  },
  {
    username: 'adm_nelsonk',
    userDesc: 'adm_nelsonk'
  },
  {
    username: 'fangkl',
    userDesc: 'kanlei.fang@nouryon.com'
  },
  {
    username: 'anderss1',
    userDesc: 'Johannes.Andersson@nouryon.com'
  },
  {
    username: 'jajo',
    userDesc: 'Jan.G.Johansson@nouryon.com'
  },
  {
    username: 'sandquis',
    userDesc: 'Stefan.Sandquist@nouryon.com'
  },
  {
    username: 'Larssch',
    userDesc: 'christian.larsson@nouryon.com'
  },
  {
    username: 'peol',
    userDesc: 'Peter.Olsson@nouryon.com'
  },
  {
    username: 'getl',
    userDesc: 'Tili.ge@nouryon.com'
  },
  {
    username: 'thsv',
    userDesc: 'thomas.l.svensson@nouryon.com'
  },
  {
    username: 'adm_xiap',
    userDesc: 'adm_xiap'
  },
  {
    username: 'Aulinp',
    userDesc: 'Peter.Aulin@nouryon.com'
  },
  {
    username: 'frsw',
    userDesc: 'Fredrik.Andersson@nouryon.com'
  },
  {
    username: 'TafakkoM',
    userDesc: 'milad.tafakkori@nouryon.com'
  },
  {
    username: 'anni',
    userDesc: 'Anne.Johnsson@nouryon.com'
  },
  {
    username: 'edlerje',
    userDesc: 'jessica.edler@nouryon.com'
  },
  {
    username: 'gejo',
    userDesc: 'Gert.Johansson@nouryon.com'
  },
  {
    username: 'huaw',
    userDesc: 'huaw'
  },
  {
    username: 'luy1',
    userDesc: 'luy1'
  },
  {
    username: 'jiq',
    userDesc: 'qing.ji@nouryon.com'
  },
  {
    username: 'OlegardL',
    userDesc: 'Laila.Olegard@nouryon.com'
  },
  {
    username: 'khka',
    userDesc: 'Karl-Henric.Karlsson@nouryon.com'
  },
  {
    username: 'hanics',
    userDesc: 'stefan.hanic@nouryon.com'
  },
  {
    username: 'maol',
    userDesc: 'Mathias.Olsson@nouryon.com'
  },
  {
    username: 'OlofssoA',
    userDesc: 'Anne.Olofsson@nouryon.com'
  },
  {
    username: 'HendriJ1',
    userDesc: 'Jimi.Hendriksen@nouryon.com'
  },
  {
    username: 'cojo',
    userDesc: 'Conny.l.Johansson@nouryon.com'
  },
  {
    username: 'tivholme',
    userDesc: 'ellinor.tivholm@nouryon.com'
  },
  {
    username: 'bergenhD',
    userDesc: 'daniel.bergenhus@nouryon.com'
  },
  {
    username: 'milsta',
    userDesc: 'Milorad.Stojanovic@nouryon.com'
  },
  {
    username: 'Mengl',
    userDesc: 'Liu.Meng@nouryon.com'
  },
  {
    username: 'adm_ss4',
    userDesc: 'adm_ss4'
  },
  {
    username: 'adm_WinqvisM_prao',
    userDesc: 'adm_WinqvisM_prao'
  },
  {
    username: 'hanssonp',
    userDesc: 'Peter.Hansson@nouryon.com'
  },
  {
    username: 'norbergj',
    userDesc: 'Jonny.Norberg@nouryon.com'
  },
  {
    username: 'erssonj',
    userDesc: 'jenny.ersson@nouryon.com'
  },
  {
    username: 'nil',
    userDesc: 'Lionel.Ni@nouryon.com'
  },
  {
    username: 'BrandtD',
    userDesc: 'Dirk.Brandt@nouryon.com'
  },
  {
    username: 'griffioe',
    userDesc: 'erik-jan.griffioen@nouryon.com'
  },
  {
    username: 'adm_chenge',
    userDesc: 'adm_chenge'
  },
  {
    username: 'TakeoV',
    userDesc: 'Ver.Takeo@nouryon.com'
  },
  {
    username: 'yangcl',
    userDesc: 'Chunlong.Yang@nouryon.com'
  },
  {
    username: 'adm_CalzolaniA',
    userDesc: 'adm_CalzolaniA'
  },
  {
    username: 'chenz2',
    userDesc: 'Zheng.chen@nouryon.com'
  },
  {
    username: 'changhug',
    userDesc: 'Geng.Changhu@nouryon.com'
  },
  {
    username: 'hellp',
    userDesc: 'peter.hell@nouryon.com'
  },
  {
    username: 'chend2',
    userDesc: 'david1.chen@nouryon.com'
  },
  {
    username: 'yanghy',
    userDesc: 'huayun.yang@nouryon.com'
  },
  {
    username: 'FranzenM',
    userDesc: 'Mathias.Franzen@nouryon.com'
  },
  {
    username: 'zhangj4',
    userDesc: 'ji.zhang@nouryon.com'
  },
  {
    username: 'LacasseF',
    userDesc: 'Francis.Lacasse@nouryon.com'
  },
  {
    username: 'panw2',
    userDesc: 'Wenxue.Pan@nouryon.com'
  },
  {
    username: 'mawi',
    userDesc: 'Markus.Wiklund@nouryon.com'
  },
  {
    username: 'KonradsJ',
    userDesc: 'Jens.Konradsson@nouryon.com'
  },
  {
    username: 'carvalha',
    userDesc: 'Ana.Carvalho@nouryon.com'
  },
  {
    username: 'silverta',
    userDesc: 'Augustin.Silvert@nouryon.com'
  },
  {
    username: 'LagoD',
    userDesc: 'Darlan.Lago@nouryon.com'
  },
  {
    username: 'adm_kumarsi',
    userDesc: 'adm_kumarsi'
  },
  {
    username: 'leekb',
    userDesc: 'Bob.vanderLeek@nouryon.com'
  },
  {
    username: 'ppe',
    userDesc: 'Patrik.Persson@nouryon.com'
  },
  {
    username: 'adm_celikmt',
    userDesc: 'adm_celikmt'
  },
  {
    username: 'CurrensN',
    userDesc: 'Nathan.Currens@nouryon.com'
  },
  {
    username: 'limm1',
    userDesc: 'mingming1.li@nouryon.com'
  },
  {
    username: 'HarsjojE',
    userDesc: 'jennie.lundh@nouryon.com'
  },
  {
    username: 'bertolur',
    userDesc: 'Rafael.Bertolucci@nouryon.com'
  },
  {
    username: 'lees5',
    userDesc: 'Scofield.Lee@nouryon.com'
  },
  {
    username: 'TorgnysA',
    userDesc: 'Annsofie.Torgnysdotter@nouryon.com'
  },
  {
    username: 'puronpaj',
    userDesc: 'Jari.Puronpaa@nouryon.com'
  },
  {
    username: 'adm_KaddourM',
    userDesc: 'adm_KaddourM'
  },
  {
    username: 'xhelo',
    userDesc: 'henrik.lofqvist@nouryon.com'
  },
  {
    username: 'rokr',
    userDesc: 'robert.kronman@nouryon.com'
  },
  {
    username: 'XmagF',
    userDesc: 'Marcus.Fridlund@nouryon.com'
  },
  {
    username: 'FromA',
    userDesc: 'anna.ekmarker@nouryon.com'
  },
  {
    username: 'GuoS1',
    userDesc: 'Steven.Guo@nouryon.com'
  },
  {
    username: 'MaSt',
    userDesc: 'Maria.Sten@nouryon.com'
  },
  {
    username: 'bkm',
    userDesc: 'Bo.Karstrom@nouryon.com'
  },
  {
    username: 'duyzh',
    userDesc: 'yingzhen.du@nouryon.com'
  },
  {
    username: 'zhouwq',
    userDesc: 'weiqiang.zhou@nouryon.com'
  },
  {
    username: 'hukj',
    userDesc: 'kunjun.hu@nouryon.com'
  },
  {
    username: 'chendl',
    userDesc: 'Dilang.Chen@nouryon.com'
  },
  {
    username: 'huangjb',
    userDesc: 'jianbo.huang@nouryon.com'
  },
  {
    username: 'HallasR',
    userDesc: 'Robbie.Hallas@nouryon.com'
  },
  {
    username: 'shenjk',
    userDesc: 'jiankai.shen@nouryon.com'
  },
  {
    username: 'wangxx',
    userDesc: 'xiaoxiang.wang@nouryon.com'
  },
  {
    username: 'ruisy',
    userDesc: 'siyuan.rui@nouryon.com'
  },
  {
    username: 'adm_eval',
    userDesc: 'adm_eval'
  },
  {
    username: 'adm_trn',
    userDesc: 'adm_trn'
  },
  {
    username: 'liuk',
    userDesc: 'kan.liu@nouryon.com'
  },
  {
    username: 'dubreils',
    userDesc: 'Sandra.Dubreil@nouryon.com'
  },
  {
    username: 'adm_abrahma',
    userDesc: 'adm_abrahma'
  },
  {
    username: 'zhouxy',
    userDesc: 'xiaoyang.zhou@nouryon.com'
  },
  {
    username: 'xingyh',
    userDesc: 'yinhui.xing@nouryon.com'
  },
  {
    username: 'WANGYF',
    userDesc: 'yufei.wang@nouryon.com'
  },
  {
    username: 'shiqd',
    userDesc: 'qidong.shi@nouryon.com'
  },
  {
    username: 'bianjg',
    userDesc: 'jinggen.bian@nouryon.com'
  },
  {
    username: 'JohnssoB',
    userDesc: 'Bo.Johnsson@nouryon.com'
  },
  {
    username: 'shanggt',
    userDesc: 'guotao.shang@nouryon.com'
  },
  {
    username: 'Tangx2',
    userDesc: 'Xiong.Tang@nouryon.com'
  },
  {
    username: 'wuwb',
    userDesc: 'wenbo.wu@nouryon.com'
  },
  {
    username: 'IngemarI',
    userDesc: 'Inger.Ingemarsson@nouryon.com'
  },
  {
    username: 'FryxeliA',
    userDesc: 'Abram.Fryxelius@nouryon.com'
  },
  {
    username: 'wanghb',
    userDesc: 'haibo.wang@nouryon.com'
  },
  {
    username: 'wuxf',
    userDesc: 'Wu.Xiaofeng@nouryon.com'
  },
  {
    username: 'adm_neumannh',
    userDesc: 'adm_neumannh'
  },
  {
    username: 'yangjzh',
    userDesc: 'jinzhao.yang@nouryon.com'
  },
  {
    username: 'NC3527',
    userDesc: 'Willem.Appelman@nouryon.com'
  },
  {
    username: 'wangxh',
    userDesc: 'Xuhua.Wang@nouryon.com'
  },
  {
    username: 'adm_roxnasm',
    userDesc: 'adm_roxnasm'
  },
  {
    username: 'zengj4',
    userDesc: 'jian.zeng@nouryon.com'
  },
  {
    username: 'chuv',
    userDesc: 'Vivian.Chu@nouryon.com'
  },
  {
    username: 'KleverP',
    userDesc: 'philipp.gross@nouryon.com'
  },
  {
    username: 'qianyl',
    userDesc: 'Yongle.Qian@nouryon.com'
  },
  {
    username: 'wangm',
    userDesc: 'ming.wang@nouryon.com'
  },
  {
    username: 'zhanghw1',
    userDesc: 'Hengwu.Zhang@nouryon.com'
  },
  {
    username: 'liuzw1',
    userDesc: 'Zhangwu.Liu@nouryon.com'
  },
  {
    username: 'chensf',
    userDesc: 'Shanfei.Chen@nouryon.com'
  },
  {
    username: 'xiehp',
    userDesc: 'haiping.xie@nouryon.com'
  },
  {
    username: 'nc3548',
    userDesc: 'Louis.Roubroeks@nouryon.com'
  },
  {
    username: 'liujw',
    userDesc: 'junwei.liu@nouryon.com'
  },
  {
    username: 'yangdx',
    userDesc: 'daxin.yang@nouryon.com'
  },
  {
    username: 'qinl',
    userDesc: 'Lei.Qin@nouryon.com'
  },
  {
    username: 'xiangq1',
    userDesc: 'qing.xiang@nouryon.com'
  },
  {
    username: 'zhongxc',
    userDesc: 'Xiaocheng.zhong@nouryon.com'
  },
  {
    username: 'pangk',
    userDesc: 'kai.pang@nouryon.com'
  },
  {
    username: 'zhalb',
    userDesc: 'Libing.Zha@nouryon.com'
  },
  {
    username: 'zhouy',
    userDesc: 'Yuanna.Zhou@nouryon.com'
  },
  {
    username: 'wud',
    userDesc: 'Danling.Wu@nouryon.com'
  },
  {
    username: 'chenge',
    userDesc: 'Eileen.Cheng@nouryon.com'
  },
  {
    username: 'aa034',
    userDesc: 'Raymond.Vaessen@nouryon.com'
  },
  {
    username: 'danigof',
    userDesc: 'Fabrice.Danigo@nouryon.com'
  },
  {
    username: 'walkert',
    userDesc: 'tommy.walker@nouryon.com'
  },
  {
    username: 'xanpa',
    userDesc: 'Anders.Paalberg@nouryon.com'
  },
  {
    username: 'NordinO',
    userDesc: 'Ove.Nordin@nouryon.com'
  },
  {
    username: 'RenstrM1',
    userDesc: 'Mikael.Renstrom@nouryon.com'
  },
  {
    username: 'tanwm',
    userDesc: 'wenmin.tan@nouryon.com'
  },
  {
    username: 'adm_trepanierf',
    userDesc: 'adm_trepanierf'
  },
  {
    username: 'ganttm',
    userDesc: 'megan.gantt@nouryon.com'
  },
  {
    username: 'limat',
    userDesc: 'Thiago.Lima@nouryon.com'
  },
  {
    username: 'santosnb',
    userDesc: 'Nerivaldo.Santos@nouryon.com'
  },
  {
    username: 'adm_morkinS',
    userDesc: 'adm_morkinS'
  },
  {
    username: 'hogane',
    userDesc: 'Eric.Hogan@nouryon.com'
  },
  {
    username: 'RylandeE',
    userDesc: 'Erik.rylander@nouryon.com'
  },
  {
    username: 'boutsk1',
    userDesc: 'Koen.Bouts@nouryon.com'
  },
  {
    username: 'gaarensr',
    userDesc: 'rory.gaarenstroom@nouryon.com'
  },
  {
    username: 'GregoryA',
    userDesc: 'audwin.gregory@nouryon.com'
  },
  {
    username: 'berglua',
    userDesc: 'Andreas.Berglund@nouryon.com'
  },
  {
    username: 'adm_nikeusv',
    userDesc: 'adm_nikeusv'
  },
  {
    username: 'adm_DanielsM',
    userDesc: 'adm_DanielsM'
  },
  {
    username: 'krantzh',
    userDesc: 'Heidi.Krantz@nouryon.com'
  },
  {
    username: 'jonssos2',
    userDesc: 'simon.sandegren@nouryon.com'
  },
  {
    username: 'hin',
    userDesc: 'Hakan.Isaksson@nouryon.com'
  },
  {
    username: 'und',
    userDesc: 'Ulf.Nordlund@nouryon.com'
  },
  {
    username: 'ohn',
    userDesc: 'Orjan.Hildingsson@nouryon.com'
  },
  {
    username: 'LindbeS2',
    userDesc: 'Stefan.Lindberg2@nouryon.com'
  },
  {
    username: 'LoegdalJ',
    userDesc: 'Juha.Loegdal@nouryon.com'
  },
  {
    username: 'usd',
    userDesc: 'Urban.Sjolund@nouryon.com'
  },
  {
    username: 'mosl',
    userDesc: 'Mats-Ola.Schenell3@nouryon.com'
  },
  {
    username: 'DahlinF',
    userDesc: 'Frida.Dahlin@nouryon.com'
  },
  {
    username: 'htm',
    userDesc: 'Hakan.Tjernstrom@nouryon.com'
  },
  {
    username: 'nide',
    userDesc: 'Nils.Degerlund@nouryon.com'
  },
  {
    username: 'jiwi',
    userDesc: 'Jimmie.Wingstedt@nouryon.com'
  },
  {
    username: 'ljn',
    userDesc: 'lars.johansson@nouryon.com'
  },
  {
    username: 'JohanstO',
    userDesc: 'tomas.n.johansson@nouryon.com'
  },
  {
    username: 'NilssonL',
    userDesc: 'LeifErik.Nilsson@nouryon.com'
  },
  {
    username: 'bjol',
    userDesc: 'Bjorn.Olsson@nouryon.com'
  },
  {
    username: 'heny',
    userDesc: 'henrik.nyberg@nouryon.com'
  },
  {
    username: 'log',
    userDesc: 'Lennart.Ostberg@nouryon.com'
  },
  {
    username: 'xmj',
    userDesc: 'Mikael.A.Johansson@nouryon.com'
  },
  {
    username: 'gug',
    userDesc: 'Glenn.Uppling@nouryon.com'
  },
  {
    username: 'bad',
    userDesc: 'bo.asenlund@nouryon.com'
  },
  {
    username: 'miki',
    userDesc: 'michael.kihlstrom@nouryon.com'
  },
  {
    username: 'Bergstrb',
    userDesc: 'Bjorn.Bergstrom@nouryon.com'
  },
  {
    username: 'nsn',
    userDesc: 'Niklas.Selin@nouryon.com'
  },
  {
    username: 'anno',
    userDesc: 'Anders.Nordlund@nouryon.com'
  },
  {
    username: 'lajn',
    userDesc: 'Lars-Ake.X.Johansson@nouryon.com'
  },
  {
    username: 'tursteda',
    userDesc: 'Anders.Turstedt@nouryon.com'
  },
  {
    username: 'DFN',
    userDesc: 'Dick.Floresten@nouryon.com'
  },
  {
    username: 'NorbergM',
    userDesc: 'Martin.Norberg@nouryon.com'
  },
  {
    username: 'krso',
    userDesc: 'Krister.Soderman@nouryon.com'
  },
  {
    username: 'ang',
    userDesc: 'ake.nyberg@nouryon.com'
  },
  {
    username: 'landerM',
    userDesc: 'Michael.Lander@nouryon.com'
  },
  {
    username: 'lar',
    userDesc: 'Lars-Ake.Ronnqvist@nouryon.com'
  },
  {
    username: 'Azhars',
    userDesc: 'Shoaib.Azhar@nouryon.com'
  },
  {
    username: 'hanningl',
    userDesc: 'Ludwig.Hanning@nouryon.com'
  },
  {
    username: 'ubg',
    userDesc: 'Urban.Brannberg@nouryon.com'
  },
  {
    username: 'NybergC',
    userDesc: 'Carl.Nyberg@nouryon.com'
  },
  {
    username: 'strandba',
    userDesc: 'ann-sofi.strandberg@nouryon.com'
  },
  {
    username: 'nordlins',
    userDesc: 'Sara.Nordling@nouryon.com'
  },
  {
    username: 'Gravelep',
    userDesc: 'Peter.Graveleij@nouryon.com'
  },
  {
    username: 'raczkowd',
    userDesc: 'Derek.Raczkowski@nouryon.com'
  },
  {
    username: 'Andersb2',
    userDesc: 'Bill.Andersson@nouryon.com'
  },
  {
    username: 'pike',
    userDesc: 'Enis.Piknjac@nouryon.com'
  },
  {
    username: 'ErikssoE',
    userDesc: 'Emil.Eriksson@nouryon.com'
  },
  {
    username: 'NC3263',
    userDesc: 'Wim.Baan@nouryon.com'
  },
  {
    username: 'kjell',
    userDesc: 'kjell.akerlund@nouryon.com'
  },
  {
    username: 'NorbergH',
    userDesc: 'Hannah.Norberg@nouryon.com'
  },
  {
    username: 'buenos2',
    userDesc: 'Soraya.Bueno@nouryon.com'
  },
  {
    username: 'xinb',
    userDesc: 'biao.xin@nouryon.com'
  },
  {
    username: 'kaol',
    userDesc: 'Kjell-Ake.Olsson@nouryon.com'
  },
  {
    username: 'GrubbstA',
    userDesc: 'Alexander.Grubbstrom@nouryon.com'
  },
  {
    username: 'hlund',
    userDesc: 'Anders.Hammarlund@nouryon.com'
  },
  {
    username: 'NC2941',
    userDesc: 'Co.vandenBerg@nouryon.com'
  },
  {
    username: 'adm_schulzm',
    userDesc: 'adm_schulzm'
  },
  {
    username: 'josefssa',
    userDesc: 'Anders.Josefsson@nouryon.com'
  },
  {
    username: 'Liuz9',
    userDesc: 'Zhenjian.Liu@nouryon.com'
  },
  {
    username: 'fanxy',
    userDesc: 'Xiaoying.Fan@nouryon.com'
  },
  {
    username: 'xiaoy',
    userDesc: 'ya.xiao@nouryon.com'
  },
  {
    username: 'baoj1',
    userDesc: 'jing.bao@nouryon.com'
  },
  {
    username: 'dongy',
    userDesc: 'yangang.Dong@nouryon.com'
  },
  {
    username: 'liugq',
    userDesc: 'Guoqiang.Liu@nouryon.com'
  },
  {
    username: 'yuanpp',
    userDesc: 'Pengpeng.Yuan@nouryon.com'
  },
  {
    username: 'Guox',
    userDesc: 'Xuan.Guo@nouryon.com'
  },
  {
    username: 'WANGW',
    userDesc: 'Wei.Wang@nouryon.com'
  },
  {
    username: 'leeandyx',
    userDesc: 'Andy.Lee@nouryon.com'
  },
  {
    username: 'duanlq',
    userDesc: 'Liqi.Duan@nouryon.com'
  },
  {
    username: 'YEV',
    userDesc: 'viking.ye@nouryon.com'
  },
  {
    username: 'yiwr',
    userDesc: 'weiran.yi@nouryon.com'
  },
  {
    username: 'ligp',
    userDesc: 'Guiping.Li@nouryon.com'
  },
  {
    username: 'zhangl4',
    userDesc: 'Leo.Zhang@nouryon.com'
  },
  {
    username: 'yuj3',
    userDesc: 'jacky.yu@nouryon.com'
  },
  {
    username: 'adm_bertolur',
    userDesc: 'adm_bertolur'
  },
  {
    username: 'meijersb',
    userDesc: 'Bram.Meijers@nouryon.com'
  },
  {
    username: 'zhengzy',
    userDesc: 'Zhongying.Zheng@nouryon.com'
  },
  {
    username: 'lim6',
    userDesc: 'Mingliang.Li@nouryon.com'
  },
  {
    username: 'nilssos3',
    userDesc: 'nilssos3'
  },
  {
    username: 'xieb1',
    userDesc: 'Bin1.Xie@nouryon.com'
  },
  {
    username: 'fuzl',
    userDesc: 'zhuolin.fu@nouryon.com'
  },
  {
    username: 'zhanggy',
    userDesc: 'guanyin.zhang@nouryon.com'
  },
  {
    username: 'caubld01',
    userDesc: 'deatrice.cauble@nouryon.com'
  },
  {
    username: 'zhoufp',
    userDesc: 'Fupeng.Zhou@nouryon.com'
  },
  {
    username: 'zhanghj',
    userDesc: 'haijun.zhang3@nouryon.com'
  },
  {
    username: 'renh',
    userDesc: 'Hui.Ren@nouryon.com'
  },
  {
    username: 'gallasj',
    userDesc: 'Jose.Gallasch@nouryon.com'
  },
  {
    username: 'Xiaow1',
    userDesc: 'Wei.Xiao@nouryon.com'
  },
  {
    username: 'furlanj',
    userDesc: 'Juarez.Furlan@nouryon.com'
  },
  {
    username: 'liuw1',
    userDesc: 'Wendong.Liu@nouryon.com'
  },
  {
    username: 'wangj8',
    userDesc: 'Jinhai.Wang@nouryon.com'
  },
  {
    username: 'zhengcol',
    userDesc: 'colin.zheng@nouryon.com'
  },
  {
    username: 'tianmx',
    userDesc: 'Maoxiang.Tian@nouryon.com'
  },
  {
    username: 'chenl2',
    userDesc: 'li1.chen@nouryon.com'
  },
  {
    username: 'yangsbe',
    userDesc: 'Shuangbao.yang@nouryon.com'
  },
  {
    username: 'sjoop',
    userDesc: 'Patrik.sjoo@nouryon.com'
  },
  {
    username: 'ksa',
    userDesc: 'Kenneth.Sandahl@nouryon.com'
  },
  {
    username: 'pazj',
    userDesc: 'Jose.Paz@nouryon.com'
  },
  {
    username: 'wangbk',
    userDesc: 'Baike.Wang@nouryon.com'
  },
  {
    username: 'wangt2',
    userDesc: 'Terry.Wang@nouryon.com'
  },
  {
    username: 'Carvalh2',
    userDesc: 'Carvalh2'
  },
  {
    username: 'XUJ2',
    userDesc: 'James.Xu@nouryon.com'
  },
  {
    username: 'camargo1',
    userDesc: 'Carolina.Camargo@nouryon.com'
  },
  {
    username: 'luwb',
    userDesc: 'Wenbin.Lu@nouryon.com'
  },
  {
    username: 'zhangtt',
    userDesc: 'Taotao.Zhang3@nouryon.com'
  },
  {
    username: 'adm_Batista',
    userDesc: 'adm_Batista'
  },
  {
    username: 'adm_Rocha',
    userDesc: 'adm_Rocha'
  },
  {
    username: 'olbo',
    userDesc: 'Ola.Borjesson@nouryon.com'
  },
  {
    username: 'SLIPPE01',
    userDesc: 'Eric.Slipp@nouryon.com'
  },
  {
    username: 'freitakc',
    userDesc: 'Kleber.Freitas@nouryon.com'
  },
  {
    username: 'weiy3',
    userDesc: 'Yongqiang.Wei@nouryon.com'
  },
  {
    username: 'tanz1',
    userDesc: 'Zhende.Tan@nouryon.com'
  },
  {
    username: 'goncali',
    userDesc: 'Ivi.Goncalves@nouryon.com'
  },
  {
    username: 'CarlosdaR',
    userDesc: 'Carlos.daRocha@nouryon.com'
  },
  {
    username: 'caiy',
    userDesc: 'Yefeng.Cai@nouryon.com'
  },
  {
    username: 'wangly',
    userDesc: 'Liuyi.Wang@nouryon.com'
  },
  {
    username: 'wangs33',
    userDesc: 'sy.wang@nouryon.com'
  },
  {
    username: 'araujoac',
    userDesc: 'Antonio.Araujo@nouryon.com'
  },
  {
    username: 'lip2',
    userDesc: 'Peng2.Li@nouryon.com'
  },
  {
    username: 'bizettia',
    userDesc: 'Alexandre.Bizetti@nouryon.com'
  },
  {
    username: 'Silvai3',
    userDesc: 'Ismael.Silva@nouryon.com'
  },
  {
    username: 'RaimunU',
    userDesc: 'Ulisses.Raimundini@nouryon.com'
  },
  {
    username: 'mcgeec01',
    userDesc: 'carlos.mcgee@nouryon.com'
  },
  {
    username: 'hammarlp',
    userDesc: 'per-ola.hammarlund@nouryon.com'
  },
  {
    username: 'nommek',
    userDesc: 'KristinSontvedt.Nomme@nouryon.com'
  },
  {
    username: 'lundqvid',
    userDesc: 'Daniel.Lundqvist@nouryon.com'
  },
  {
    username: 'LbE',
    userDesc: 'Lennart.Berg@nouryon.com'
  },
  {
    username: 'HUANGSM',
    userDesc: 'shanming.huang@nouryon.com'
  },
  {
    username: 'AugustsE',
    userDesc: 'Eric.Augustsson@nouryon.com'
  },
  {
    username: 'walkerb',
    userDesc: 'Brandon.Walker@nouryon.com'
  },
  {
    username: 'wangwc',
    userDesc: 'Wenchao.wang@nouryon.com'
  },
  {
    username: 'grauej',
    userDesc: 'Josefine.Oehmichen@nouryon.com'
  },
  {
    username: 'youngh01',
    userDesc: 'helen.young@nouryon.com'
  },
  {
    username: 'wangd5',
    userDesc: 'dan.wang@nouryon.com'
  },
  {
    username: 'liulukex',
    userDesc: 'Luke.Xipeng.Liu@nouryon.com'
  },
  {
    username: 'niw',
    userDesc: 'Wenxing.Ni@nouryon.com'
  },
  {
    username: 'Gaol2',
    userDesc: 'Gaol2'
  },
  {
    username: 'larssog4',
    userDesc: 'goran.v.larsson@nouryon.com'
  },
  {
    username: 'wibergc',
    userDesc: 'Carl.Wiberg@nouryon.com'
  },
  {
    username: 'vidottol',
    userDesc: 'Lucas.Vidotto@nouryon.com'
  },
  {
    username: 'mohriv',
    userDesc: 'Veronica.Mohri@nouryon.com'
  },
  {
    username: 'palmec01',
    userDesc: 'chris.palmer@nouryon.com'
  },
  {
    username: 'adm_NordenVJ',
    userDesc: 'adm_NordenVJ'
  },
  {
    username: 'adm_NoordenVJ',
    userDesc: 'adm_NoordenVJ'
  },
  {
    username: 'martinis',
    userDesc: 'Shanna.Martini@nouryon.com'
  },
  {
    username: 'hsuj1',
    userDesc: 'JungHsin.Hsu@nouryon.com'
  },
  {
    username: 'tsengc',
    userDesc: 'ChaoSheng.Tseng@nouryon.com'
  },
  {
    username: 'amorimm',
    userDesc: 'Marcos.Amorim@nouryon.com'
  },
  {
    username: 'lenderap',
    userDesc: 'Alan.Lenderman@nouryon.com'
  },
  {
    username: 'juniorjaa',
    userDesc: 'Jose.Junior@nouryon.com'
  },
  {
    username: 'macedoaa',
    userDesc: 'Adriano.Macedo@nouryon.com'
  },
  {
    username: 'nc3161',
    userDesc: 'Aart.Groenewegen@nouryon.com'
  },
  {
    username: 'souzaeb',
    userDesc: 'Eneias.Souza@nouryon.com'
  },
  {
    username: 'moraese',
    userDesc: 'Ezequiel.Moraes@nouryon.com'
  },
  {
    username: 'elgh',
    userDesc: 'hans-erik.elg@nouryon.com'
  },
  {
    username: 'asmy',
    userDesc: 'Asa.Soderlund@nouryon.com'
  },
  {
    username: 'adm_morrisoG',
    userDesc: 'adm_morrisoG'
  },
  {
    username: 'emensc',
    userDesc: 'Chris.Emens@nouryon.com'
  },
  {
    username: 'vieiras2',
    userDesc: 'Sineide.Vieira@nouryon.com'
  },
  {
    username: 'zanchetf',
    userDesc: 'flavio.zancheta@nouryon.com'
  },
  {
    username: 'kongjy',
    userDesc: 'jinyuan.kong@nouryon.com'
  },
  {
    username: 'beax',
    userDesc: 'Bengt.Axelsson@nouryon.com'
  },
  {
    username: 'jonjo',
    userDesc: 'Jonas.Johnsson@nouryon.com'
  },
  {
    username: 'finngaam',
    userDesc: 'Mikael.Finngaard@nouryon.com'
  },
  {
    username: 'adm_ReinertD',
    userDesc: 'adm_ReinertD'
  },
  {
    username: 'adm_LutzF',
    userDesc: 'adm_LutzF'
  },
  {
    username: 'araujorm',
    userDesc: 'Rafael.Araujo@nouryon.com'
  },
  {
    username: 'hape',
    userDesc: 'Hanna.Perzon@nouryon.com'
  },
  {
    username: 'sunjs1',
    userDesc: 'junsheng.sun@nouryon.com'
  },
  {
    username: 'vivotv',
    userDesc: 'Vivian.Vivot@nouryon.com'
  },
  {
    username: 'nihe',
    userDesc: 'Niclas.Hedin@nouryon.com'
  },
  {
    username: 'OlovssoE',
    userDesc: 'Emil.Olovsson@nouryon.com'
  },
  {
    username: 'adm_mercien',
    userDesc: 'adm_mercien'
  },
  {
    username: 'zhengz',
    userDesc: 'Zhijun.Zheng@nouryon.com'
  },
  {
    username: 'schollms',
    userDesc: 'Stefan.Schollmeier@nouryon.com'
  },
  {
    username: 'chenx1',
    userDesc: 'Xu.Chen@nouryon.com'
  },
  {
    username: 'maggierm',
    userDesc: 'MariaGabrielaAmaral.Maggieri@nouryon.com'
  },
  {
    username: 'lic6',
    userDesc: 'caihong.li@nouryon.com'
  },
  {
    username: 'chenj8',
    userDesc: 'jun.chen@nouryon.com'
  },
  {
    username: 'zacchelg',
    userDesc: 'Gustavo.Zacchello@nouryon.com'
  },
  {
    username: 'munhosl',
    userDesc: 'LeonardoLopes.Munhos@nouryon.com'
  },
  {
    username: 'scottkj',
    userDesc: 'Kathy.Scott@nouryon.com'
  },
  {
    username: 'medinaa11',
    userDesc: 'Adriano.Medina@nouryon.com'
  },
  {
    username: 'Damhv',
    userDesc: 'Hendrik.vanDam@nouryon.com'
  },
  {
    username: 'ohlssok1',
    userDesc: 'Karin.Ohlsson1@nouryon.com'
  },
  {
    username: 'adm_olanderj',
    userDesc: 'adm_olanderj'
  },
  {
    username: 'wuy16',
    userDesc: 'Ying.Wu@nouryon.com'
  },
  {
    username: 'MAKOURO',
    userDesc: 'Ouiza.Makour@nouryon.com'
  },
  {
    username: 'mathiset',
    userDesc: 'Trond.Mathisen@nouryon.com'
  },
  {
    username: 'afri',
    userDesc: 'andreas.friborg@nouryon.com'
  },
  {
    username: 'Anders10',
    userDesc: 'Mattias.Andersson2@nouryon.com'
  },
  {
    username: 'liangxh',
    userDesc: 'Xiaohu.Liang@nouryon.com'
  },
  {
    username: 'fernando',
    userDesc: 'OLIVIER.FERNANDEZ@nouryon.com'
  },
  {
    username: 'cgu',
    userDesc: 'Christian.Gustafson@nouryon.com'
  },
  {
    username: 'zanig',
    userDesc: 'Gilberto.Zani@nouryon.com'
  },
  {
    username: 'silvalcs',
    userDesc: 'Luana.Silva@nouryon.com'
  },
  {
    username: 'quesads',
    userDesc: 'quesads'
  },
  {
    username: 'Gep',
    userDesc: 'Ping.Ge@nouryon.com'
  },
  {
    username: 'loj',
    userDesc: 'Leif-Ove.Jonsson@nouryon.com'
  },
  {
    username: 'kuadak',
    userDesc: 'Kengi.Kuada@nouryon.com'
  },
  {
    username: 'drummosd',
    userDesc: 'Scott.Drummond@nouryon.com'
  },
  {
    username: 'Backstrj',
    userDesc: 'Jessica.Backstrom@nouryon.com'
  },
  {
    username: 'costada',
    userDesc: 'costada'
  },
  {
    username: 'MOLANDEM',
    userDesc: 'Martin.Molander@nouryon.com'
  },
  {
    username: 'adm_AxelssoB',
    userDesc: 'adm_AxelssoB'
  },
  {
    username: 'adm_erikssont',
    userDesc: 'adm_erikssont'
  },
  {
    username: 'nc3140',
    userDesc: 'Jurjen.Reit@nouryon.com'
  },
  {
    username: 'lindstea',
    userDesc: 'Alexander.Lindstein@nouryon.com'
  },
  {
    username: 'dennhedp',
    userDesc: 'Patrik.Dennheden@nouryon.com'
  },
  {
    username: 'pedreirc',
    userDesc: 'CristianoBastian.Pedreira@nouryon.com'
  },
  {
    username: 'louberec',
    userDesc: 'CHRISTOPHE.LOUBERE@nouryon.com'
  },
  {
    username: 'hernanm2',
    userDesc: 'Mario.Hernandez@nouryon.com'
  },
  {
    username: 'lamacchc',
    userDesc: 'Christian.Lamacchia@nouryon.com'
  },
  {
    username: 'perssod1',
    userDesc: 'daniel.persson1@nouryon.com'
  },
  {
    username: 'larssoa1',
    userDesc: 'Ante.Larsson@nouryon.com'
  },
  {
    username: 'bergekc',
    userDesc: 'charlotte.bergek@nouryon.com'
  },
  {
    username: 'westholb',
    userDesc: 'benny.westholm@nouryon.com'
  },
  {
    username: 'reismc',
    userDesc: 'Mariano.Reis@nouryon.com'
  },
  {
    username: 'adm_WhiteR4',
    userDesc: 'Ron White'
  },
  {
    username: 'anderssonm',
    userDesc: 'Martina.Andersson@nouryon.com'
  },
  {
    username: 'bjorkstm',
    userDesc: 'bjorkstm'
  },
  {
    username: 'anderssoa',
    userDesc: 'Hans.Andersson02@nouryon.com'
  },
  {
    username: 'eriksss1',
    userDesc: 'svante.eriksson@nouryon.com'
  },
  {
    username: 'backlunp',
    userDesc: 'par.backlund@nouryon.com'
  },
  {
    username: 'johanssu',
    userDesc: 'Ulf.S.Johansson@nouryon.com'
  },
  {
    username: 'oliveilt',
    userDesc: 'Luciana.Oliveira@nouryon.com'
  },
  {
    username: 'JanssoM2',
    userDesc: 'Mikael.Jansson@nouryon.com'
  },
  {
    username: 'adm_EPItest',
    userDesc: 'adm_EPItest'
  },
  {
    username: 'westbera',
    userDesc: 'Annica.Westberg@nouryon.com'
  },
  {
    username: 'FreitaA',
    userDesc: 'Amanda.Freitas@nouryon.com'
  },
  {
    username: 'saldanam',
    userDesc: 'Miguel.Saldana@nouryon.com'
  },
  {
    username: 'jordanj1',
    userDesc: 'James.Jordan@nouryon.com'
  },
  {
    username: 'makinenp',
    userDesc: 'Peter.Makinen@nouryon.com'
  },
  {
    username: 'ForsstrK',
    userDesc: 'Kristoffer.Forsstrom@nouryon.com'
  },
  {
    username: 'rydinc',
    userDesc: 'Cecilia.Rydin@nouryon.com'
  },
  {
    username: 'mantovae',
    userDesc: 'eduardo.mantovani@nouryon.com'
  },
  {
    username: 'hogerma',
    userDesc: 'marcus.hogberg@nouryon.com'
  },
  {
    username: 'sangg',
    userDesc: 'Guandong.Sang@nouryon.com'
  },
  {
    username: 'adm_BouwensP',
    userDesc: 'adm_BouwensP'
  },
  {
    username: 'OliveiHS',
    userDesc: 'Marcelo.Oliveira@nouryon.com'
  },
  {
    username: 'bc1144',
    userDesc: 'Frederic.Brissez@nouryon.com'
  },
  {
    username: 'barbiers',
    userDesc: 'Sergio.Barbieri@nouryon.com'
  },
  {
    username: 'fumarola',
    userDesc: 'alessandro.fumarola@nouryon.com'
  },
  {
    username: 'bc1382',
    userDesc: 'Nicolas.Delfosse@nouryon.com'
  },
  {
    username: 'biaginim',
    userDesc: 'Matteo.Biagini@nouryon.com'
  },
  {
    username: 'bertouib',
    userDesc: 'Benoit.Bertouille@nouryon.com'
  },
  {
    username: 'adm_dudorovm',
    userDesc: 'adm_dudorovm'
  },
  {
    username: 'granatod',
    userDesc: 'Daniele.Granato@nouryon.com'
  },
  {
    username: 'Santosan',
    userDesc: 'anibal.santos@nouryon.com'
  },
  {
    username: 'ferreicg',
    userDesc: 'Cleiton.Ferreira@nouryon.com'
  },
  {
    username: 'bengtssn',
    userDesc: 'Nicklas.Bengtsson@nouryon.com'
  },
  {
    username: 'silvam29',
    userDesc: 'mari.rodrigues@nouryon.com'
  },
  {
    username: 'diasd',
    userDesc: 'David.Dias@nouryon.com'
  },
  {
    username: 'marquej',
    userDesc: 'Julio.Marquez@nouryon.com'
  },
  {
    username: 'GoncalRS',
    userDesc: 'Rodrigo.SilvaGoncalves@nouryon.com'
  },
  {
    username: 'lyvenj',
    userDesc: 'Jan.Lyven@nouryon.com'
  },
  {
    username: 'pianad',
    userDesc: 'Diego.Piana@nouryon.com'
  },
  {
    username: 'peitzsch',
    userDesc: 'Heiko.Peitzsch@nouryon.com'
  },
  {
    username: 'kyllih',
    userDesc: 'Herkko.Kylli@nouryon.com'
  },
  {
    username: 'wermeln1',
    userDesc: 'Niklas.Wermelin@nouryon.com'
  },
  {
    username: 'nascimm2',
    userDesc: 'Marcos.landi@nouryon.com'
  },
  {
    username: 'VenturaL',
    userDesc: 'Leandro.Ventura@nouryon.com'
  },
  {
    username: 'silvasa',
    userDesc: 'Sidnei.Silva2@nouryon.com'
  },
  {
    username: 'medeirom',
    userDesc: 'Marcio.Medeiros@nouryon.com'
  },
  {
    username: 'oliveirts',
    userDesc: 'Tiago.Oliveira@nouryon.com'
  },
  {
    username: 'dish',
    userDesc: 'Diana.Sherif@nouryon.com'
  },
  {
    username: 'harriss1',
    userDesc: 'Scott.Harris1@nouryon.com'
  },
  {
    username: 'donizetr',
    userDesc: 'Ricardo.Donizete@nouryon.com'
  },
  {
    username: 'santosom',
    userDesc: 'Otavio.Santos@nouryon.com'
  },
  {
    username: 'silvacd',
    userDesc: 'Claudinei.Silva@nouryon.com'
  },
  {
    username: 'araujosw',
    userDesc: 'WalysonCarlos.SantosAraujo@nouryon.com'
  },
  {
    username: 'cypriaf',
    userDesc: 'Fernando.Cypriano@nouryon.com'
  },
  {
    username: 'silvaio',
    userDesc: 'Ismak.Silva@nouryon.com'
  },
  {
    username: 'Quintimc',
    userDesc: 'Marcelo.Quintino@nouryon.com'
  },
  {
    username: 'bitencoa',
    userDesc: 'Andre.Bitencourt@nouryon.com'
  },
  {
    username: 'santosr8',
    userDesc: 'RafaelAlvesDos.Santos@nouryon.com'
  },
  {
    username: 'oliveim1',
    userDesc: 'marcelo.deoliveira@nouryon.com'
  },
  {
    username: 'satory',
    userDesc: 'renato.sato@nouryon.com'
  },
  {
    username: 'strijkem',
    userDesc: 'Maaike.Strijker@nouryon.com'
  },
  {
    username: 'PereirRS',
    userDesc: 'Rogerio.Pereira@nouryon.com'
  },
  {
    username: 'roqueER',
    userDesc: 'Edgard.Roque@nouryon.com'
  },
  {
    username: 'huangk',
    userDesc: 'Kangle.Huang@nouryon.com'
  },
  {
    username: 'silvaj11',
    userDesc: 'james.silva@nouryon.com'
  },
  {
    username: 'santosaa',
    userDesc: 'Airton.Santos@nouryon.com'
  },
  {
    username: 'Broqvis1',
    userDesc: 'Sandra.Broqvist@nouryon.com'
  },
  {
    username: 'camilov',
    userDesc: 'vanderlei.camilo@nouryon.com'
  },
  {
    username: 'AdenilsD',
    userDesc: 'Adenilson.Dias@nouryon.com'
  },
  {
    username: 'GuimaraJ',
    userDesc: 'juarez.guimaraes@nouryon.com'
  },
  {
    username: 'limaf',
    userDesc: 'franciano.lima@nouryon.com'
  },
  {
    username: 'sbt',
    userDesc: 'Stefan.Bergqvist@nouryon.com'
  },
  {
    username: 'chapmh01',
    userDesc: 'harold.chapman@nouryon.com'
  },
  {
    username: 'hacklerj',
    userDesc: 'John.Hackler@nouryon.com'
  },
  {
    username: 'younts01',
    userDesc: 'sherry.yount@nouryon.com'
  },
  {
    username: 'ae1174',
    userDesc: 'Jos.Berkien@nouryon.com'
  },
  {
    username: 'smithj100',
    userDesc: 'James.Smith@nouryon.com'
  },
  {
    username: 'SilvaDM',
    userDesc: 'Diego.M.Silva@nouryon.com'
  },
  {
    username: 'taylob01',
    userDesc: 'brad.taylor@nouryon.com'
  },
  {
    username: 'hunsur01',
    userDesc: 'Rick.Hunsucker@nouryon.com'
  },
  {
    username: 'lansie01',
    userDesc: 'eugene.lansigan@nouryon.com'
  },
  {
    username: 'baileb02',
    userDesc: 'bobby.bailey@nouryon.com'
  },
  {
    username: 'macedoo',
    userDesc: 'Osmar.Macedo@nouryon.com'
  },
  {
    username: 'simonc06',
    userDesc: 'chris.simon@nouryon.com'
  },
  {
    username: 'oliveiar',
    userDesc: 'Alfredo.Oliveira@nouryon.com'
  },
  {
    username: 'lamberj',
    userDesc: 'Jamil.Lambert@nouryon.com'
  },
  {
    username: 'hortonj',
    userDesc: 'jim.horton@nouryon.com'
  },
  {
    username: 'samuele2',
    userDesc: 'Emily.Samuelsson@nouryon.com'
  },
  {
    username: 'astroma',
    userDesc: 'Andreas.Astrom@nouryon.com'
  },
  {
    username: 'footem',
    userDesc: 'michael.foote@nouryon.com'
  },
  {
    username: 'luketd01',
    userDesc: 'donna.luketic@nouryon.com'
  },
  {
    username: 'azevedoa',
    userDesc: 'Alexandre.Azevedo@nouryon.com'
  },
  {
    username: 'millem01',
    userDesc: 'matthew.miller@nouryon.com'
  },
  {
    username: 'robine1',
    userDesc: 'Eliassen.Robin@nouryon.com'
  },
  {
    username: 'martensd',
    userDesc: 'doug.martens@nouryon.com'
  },
  {
    username: 'magdsjop',
    userDesc: 'ParOlof.Magdsjo@nouryon.com'
  },
  {
    username: 'podgrusj',
    userDesc: 'Jagoda.Podgruszecka@nouryon.com'
  },
  {
    username: 'lindea1',
    userDesc: 'Albin.Linde@nouryon.com'
  },
  {
    username: 'mst',
    userDesc: 'Mats.Stenberg@nouryon.com'
  },
  {
    username: 'AssisV',
    userDesc: 'Vitor.Assis@nouryon.com'
  },
  {
    username: 'youngm',
    userDesc: 'Mike.Young@nouryon.com'
  },
  {
    username: 'longoa',
    userDesc: 'Anthony.Longo@nouryon.com'
  },
  {
    username: 'salendma',
    userDesc: 'marco.salenda@nouryon.com'
  },
  {
    username: 'eduardo1',
    userDesc: 'Cassio.Eduardo@nouryon.com'
  },
  {
    username: 'guy3',
    userDesc: 'Yinlong.Gu1@nouryon.com'
  },
  {
    username: 'brandtes',
    userDesc: 'Eder.Brandt@nouryon.com'
  },
  {
    username: 'chenj3',
    userDesc: 'Junfeng.Chen@nouryon.com'
  },
  {
    username: 'hurstc01',
    userDesc: 'cheryl.mendez@nouryon.com'
  },
  {
    username: 'leonab01',
    userDesc: 'beverly.leonard@nouryon.com'
  },
  {
    username: 'prinse',
    userDesc: 'elisabeth.prins@nouryon.com'
  },
  {
    username: 'chjo',
    userDesc: 'Christer.Johansson@nouryon.com'
  },
  {
    username: 'johnsa01',
    userDesc: 'aaron.johnson@nouryon.com'
  },
  {
    username: 'pereirrm',
    userDesc: 'Raphael.Pereira@nouryon.com'
  },
  {
    username: 'PiresG',
    userDesc: 'geraldo.pires@nouryon.com'
  },
  {
    username: 'shorem02',
    userDesc: 'monty.shores@nouryon.com'
  },
  {
    username: 'GILBEB01',
    userDesc: 'brian.gilbert@nouryon.com'
  },
  {
    username: 'godfreys',
    userDesc: 'Shelli.Godfrey@nouryon.com'
  },
  {
    username: 'zoial',
    userDesc: 'Loris.Zoia@nouryon.com'
  },
  {
    username: 'stillj01',
    userDesc: 'james.stiller@nouryon.com'
  },
  {
    username: 'silvaat',
    userDesc: 'Alexandre.Silva@nouryon.com'
  },
  {
    username: 'santosjcm',
    userDesc: 'Jose.Santos4@nouryon.com'
  },
  {
    username: 'ahlinm',
    userDesc: 'Malin.Ahlin@nouryon.com'
  },
  {
    username: 'gottfrie',
    userDesc: 'Elin.Gottfridsson@nouryon.com'
  },
  {
    username: 'harrim05',
    userDesc: 'melanie.harris@nouryon.com'
  },
  {
    username: 'morganmo',
    userDesc: 'monica.morgan@nouryon.com'
  },
  {
    username: 'przyba03',
    userDesc: 'aaron.przybyszewski@nouryon.com'
  },
  {
    username: 'brancagn',
    userDesc: 'Nathalia.Brancagliao@nouryon.com'
  },
  {
    username: 'plummj01',
    userDesc: 'joshua.plummer@nouryon.com'
  },
  {
    username: 'cavalcaa',
    userDesc: 'Ademir.Cavalcanti@nouryon.com'
  },
  {
    username: 'lafold01',
    userDesc: 'debbie.lafollette@nouryon.com'
  },
  {
    username: 'wangf10',
    userDesc: 'Fairy.Wang@nouryon.com'
  },
  {
    username: 'NisT',
    userDesc: 'Nina.Stugell@nouryon.com'
  },
  {
    username: 'RaaijmaM',
    userDesc: 'Michiel.Raaijmakers@nouryon.com'
  },
  {
    username: 'moslas01',
    userDesc: 'Shawn.Moslak@nouryon.com'
  },
  {
    username: 'souzahe',
    userDesc: 'Hercilio.Souza@nouryon.com'
  },
  {
    username: 'parksj03',
    userDesc: 'john.d.parks@nouryon.com'
  },
  {
    username: 'devlid01',
    userDesc: 'denise.devlin@nouryon.com'
  },
  {
    username: 'brookt04',
    userDesc: 'tamara.brooks@nouryon.com'
  },
  {
    username: 'bostj',
    userDesc: 'jason.bost@nouryon.com'
  },
  {
    username: 'keurm01',
    userDesc: 'matt.keur@nouryon.com'
  },
  {
    username: 'fralej01',
    userDesc: 'jeremy.fraley@nouryon.com'
  },
  {
    username: 'BURKHK01',
    userDesc: 'keith.d.burkhart@nouryon.com'
  },
  {
    username: 'childw01',
    userDesc: 'wesley.childers@nouryon.com'
  },
  {
    username: 'amberg01',
    userDesc: 'glenn.ambers@nouryon.com'
  },
  {
    username: 'paulalhp',
    userDesc: 'Luiz.Paula@nouryon.com'
  },
  {
    username: 'bonouvrr',
    userDesc: 'r.bonouvrie@nouryon.com'
  },
  {
    username: 'abernd02',
    userDesc: 'david.abernathy1@nouryon.com'
  },
  {
    username: 'phaml',
    userDesc: 'Lynda.Pham@nouryon.com'
  },
  {
    username: 'bc1311',
    userDesc: 'Sylvain.Quardel@nouryon.com'
  },
  {
    username: 'kebj',
    userDesc: 'Kerstin.Bjorkman@nouryon.com'
  },
  {
    username: 'costat2',
    userDesc: 'Thiago.Costa@nouryon.com'
  },
  {
    username: 'loul',
    userDesc: 'Leslie.Lou@nouryon.com'
  },
  {
    username: 'bc1319',
    userDesc: 'Gaetano.Cusumano@nouryon.com'
  },
  {
    username: 'francos',
    userDesc: 'Sergio.Franco@nouryon.com'
  },
  {
    username: 'rizkb',
    userDesc: 'Bruno.Rizk@nouryon.com'
  },
  {
    username: 'peresa',
    userDesc: 'Alessandro.Peres@nouryon.com'
  },
  {
    username: 'zakc1',
    userDesc: 'Christophe.ZAK@nouryon.com'
  },
  {
    username: 'hagstrm1',
    userDesc: 'magnus.hagstrom@nouryon.com'
  },
  {
    username: 'Jareda01',
    userDesc: 'jared.anthony@nouryon.com'
  },
  {
    username: 'SCHULW01',
    userDesc: 'william.a.schultz@nouryon.com'
  },
  {
    username: 'bc1054',
    userDesc: 'Francesco.Salamone@nouryon.com'
  },
  {
    username: 'connod04',
    userDesc: 'darlene.connor@nouryon.com'
  },
  {
    username: 'bergenm1',
    userDesc: 'bergenm1'
  },
  {
    username: 'bc1249',
    userDesc: 'Sandy.Tiston@nouryon.com'
  },
  {
    username: 'lemeh',
    userDesc: 'horacio.leme@nouryon.com'
  },
  {
    username: 'FarinaCP',
    userDesc: 'pablo.caveagna@nouryon.com'
  },
  {
    username: 'bc1277',
    userDesc: 'Patrick.Florin@nouryon.com'
  },
  {
    username: 'amx3',
    userDesc: 'Karina.Oberg@nouryon.com'
  },
  {
    username: 'bc1222',
    userDesc: 'Henri.Lux@nouryon.com'
  },
  {
    username: 'rosvallm',
    userDesc: 'Magnus.Rosvall@nouryon.com'
  },
  {
    username: 'maskec03',
    userDesc: 'carisa.maske@nouryon.com'
  },
  {
    username: 'Sechrm01',
    userDesc: 'mark.sechrest@nouryon.com'
  },
  {
    username: 'hewitc01',
    userDesc: 'chuck.hewitt@nouryon.com'
  },
  {
    username: 'butlec01',
    userDesc: 'contonya.butler@nouryon.com'
  },
  {
    username: 'morrot02',
    userDesc: 'todd.morrow@nouryon.com'
  },
  {
    username: 'bolinj',
    userDesc: 'Jacob.Bolin@nouryon.com'
  },
  {
    username: 'carreird',
    userDesc: 'Diego.Carreiro@nouryon.com'
  },
  {
    username: 'bc1328',
    userDesc: 'David.Bruno@nouryon.com'
  },
  {
    username: 'bc1304',
    userDesc: 'Massimo.Pompilii@nouryon.com'
  },
  {
    username: 'andersk2',
    userDesc: 'Kenneth.Andersson@nouryon.com'
  },
  {
    username: 'bc1017',
    userDesc: 'Brigitte.Debart@nouryon.com'
  },
  {
    username: 'BC1364',
    userDesc: 'Sebastien.Perniaux@nouryon.com'
  },
  {
    username: 'ballaror',
    userDesc: 'Rodrigo.Ballarotti@nouryon.com'
  },
  {
    username: 'souzae',
    userDesc: 'Eduardo.Souza@nouryon.com'
  },
  {
    username: 'rowlaa01',
    userDesc: 'annette.rowland@nouryon.com'
  },
  {
    username: 'adm_carlbere',
    userDesc: 'adm_carlbere'
  },
  {
    username: 'robineta',
    userDesc: 'Alain.Robinet@nouryon.com'
  },
  {
    username: 'vereekem',
    userDesc: 'mathieu.vereeke@nouryon.com'
  },
  {
    username: 'bc1028',
    userDesc: 'Pierre.Feron@nouryon.com'
  },
  {
    username: 'murarov',
    userDesc: 'Vinicius.Muraro@nouryon.com'
  },
  {
    username: 'engerbyj',
    userDesc: 'Jennifer.Engerby@nouryon.com'
  },
  {
    username: 'ErikssM1',
    userDesc: 'Maria.Eriksson@nouryon.com'
  },
  {
    username: 'souzaa6',
    userDesc: 'Antonio.Souza@nouryon.com'
  },
  {
    username: 'bylundj2',
    userDesc: 'jennifer.clement@nouryon.com'
  },
  {
    username: 'andersm4',
    userDesc: 'Magnus.Andersson4@nouryon.com'
  },
  {
    username: 'SuT1',
    userDesc: 'Truckgruppen.STS-Surfactants@nouryon.com'
  },
  {
    username: 'Mullid01',
    userDesc: 'dale.l.mullis@nouryon.com'
  },
  {
    username: 'vanvorsm',
    userDesc: 'Matt.VanVorst@nouryon.com'
  },
  {
    username: 'bc1221',
    userDesc: 'Dany.Crepaldi@nouryon.com'
  },
  {
    username: 'corpend1',
    userDesc: 'Devan.Corpening1@nouryon.com'
  },
  {
    username: 'bc1198',
    userDesc: 'Jean-Michel.Kisiela@nouryon.com'
  },
  {
    username: 'bc1405',
    userDesc: 'Patrick.Guns@nouryon.com'
  },
  {
    username: 'bowmat01',
    userDesc: 'todd.bowman@nouryon.com'
  },
  {
    username: 'limerm03',
    userDesc: 'michael.s.limerick@nouryon.com'
  },
  {
    username: 'caseyj03',
    userDesc: 'jeremy.casey@nouryon.com'
  },
  {
    username: 'hardya01',
    userDesc: 'adam.hardy@nouryon.com'
  },
  {
    username: 'dilloj01',
    userDesc: 'joshua.dillon@nouryon.com'
  },
  {
    username: 'bc1165',
    userDesc: 'Dris.Benabdelouahed@nouryon.com'
  },
  {
    username: 'nogueirj',
    userDesc: 'Jose.Nogueira@nouryon.com'
  },
  {
    username: 'davisj1',
    userDesc: 'James.Davis@nouryon.com'
  },
  {
    username: 'zampab01',
    userDesc: 'brian.zamparelli@nouryon.com'
  },
  {
    username: 'adm_TonduL',
    userDesc: 'adm_TonduL'
  },
  {
    username: 'barnht01',
    userDesc: 'todd.barnhardt@nouryon.com'
  },
  {
    username: 'higgir02',
    userDesc: 'randy.higgins@nouryon.com'
  },
  {
    username: 'molinaab',
    userDesc: 'Arturo.Molina@nouryon.com'
  },
  {
    username: 'souzaja',
    userDesc: 'Jose.A.Souza@nouryon.com'
  },
  {
    username: 'wiktorsf',
    userDesc: 'Frida.Wiktorsson@nouryon.com'
  },
  {
    username: 'tilmantm',
    userDesc: 'marc.tilmant@nouryon.com'
  },
  {
    username: 'theotonb',
    userDesc: 'Beatriz.Theotonio@nouryon.com'
  },
  {
    username: 'janhomn',
    userDesc: 'Nonthawat.Janhom@nouryon.com'
  },
  {
    username: 'beaveg01',
    userDesc: 'greg.beaver@nouryon.com'
  },
  {
    username: 'svalstea',
    userDesc: 'arve.svalstedt@nouryon.com'
  },
  {
    username: 'hadem',
    userDesc: 'mathieu.hade@nouryon.com'
  },
  {
    username: 'trn',
    userDesc: 'Tomas.Rosen@nouryon.com'
  },
  {
    username: 'ronningt',
    userDesc: 'Tord.Ronning@nouryon.com'
  },
  {
    username: 'CummingJ',
    userDesc: 'josh.cummings@nouryon.com'
  },
  {
    username: 'bexellh',
    userDesc: 'Hakan.Bexell@nouryon.com'
  },
  {
    username: 'asbergj',
    userDesc: 'Jimmy.Asberg1@nouryon.com'
  },
  {
    username: 'branhk01',
    userDesc: 'kenneth.branham@nouryon.com'
  },
  {
    username: 'PLC6',
    userDesc: 'PLC6@nouryon.com'
  },
  {
    username: 'yanga3',
    userDesc: 'anthony.yang@nouryon.com'
  },
  {
    username: 'linr',
    userDesc: 'Rick.vanLin@nouryon.com'
  },
  {
    username: 'galliens',
    userDesc: 'Stefano.Galliena@nouryon.com'
  },
  {
    username: 'adm_parodim',
    userDesc: 'adm_parodim'
  },
  {
    username: 'gto',
    userDesc: 'Gosta.Torberntsson@nouryon.com'
  },
  {
    username: 'bc1339',
    userDesc: 'Nicolas.Marine@nouryon.com'
  },
  {
    username: 'Galiziar',
    userDesc: 'Roberto.Galizia@nouryon.com'
  },
  {
    username: 'adm_BrisseF',
    userDesc: 'adm_BrisseF'
  },
  {
    username: 'BC1331',
    userDesc: 'Olivier.Watte@nouryon.com'
  },
  {
    username: 'silvais',
    userDesc: 'Ismael.Lopes.Silva@nouryon.com'
  },
  {
    username: 'bc1305',
    userDesc: 'Michel.Durigneux@nouryon.com'
  },
  {
    username: 'adm_vachonm',
    userDesc: 'adm_vachonm'
  },
  {
    username: 'jenninp1',
    userDesc: 'Philip.Jennings@nouryon.com'
  },
  {
    username: 'lamles',
    userDesc: 'Stefan.Lamle@nouryon.com'
  },
  {
    username: 'jongmanm',
    userDesc: 'Mark.Jongmans@nouryon.com'
  },
  {
    username: 'bc1288',
    userDesc: 'Michel.Briche@nouryon.com'
  },
  {
    username: 'bc1396',
    userDesc: 'Antonino.Campagna@nouryon.com'
  },
  {
    username: 'santosa8',
    userDesc: 'ALLANANDREWDOS.SANTOS@nouryon.com'
  },
  {
    username: 'MollerCo',
    userDesc: 'conny.moller@nouryon.com'
  },
  {
    username: 'adm_svanluna',
    userDesc: 'adm_svanluna'
  },
  {
    username: 'yany3',
    userDesc: 'yuankun.yan@nouryon.com'
  },
  {
    username: 'anthol01',
    userDesc: 'Lynn.Anthony@nouryon.com'
  },
  {
    username: 'bc1326',
    userDesc: 'Frederic.Perreman@nouryon.com'
  },
  {
    username: 'ae613',
    userDesc: 'Frank.Winkel@nouryon.com'
  },
  {
    username: 'helvym1',
    userDesc: 'Mathew.Helvy@nouryon.com'
  },
  {
    username: 'HENDRICO',
    userDesc: 'olivier.hendrickx@nouryon.com'
  },
  {
    username: 'MariaA1',
    userDesc: 'antonio.maria@nouryon.com'
  },
  {
    username: 'dillog01',
    userDesc: 'gary.dillon@nouryon.com'
  },
  {
    username: 'adm_wuyun',
    userDesc: ''
  },
  {
    username: 'adm_brownpa',
    userDesc: ''
  },
  {
    username: 'sjolinms',
    userDesc: 'Sara.Sjolin@nouryon.com'
  },
  {
    username: 'robertj1',
    userDesc: 'jean-marc.robert@nouryon.com'
  },
  {
    username: 'sousava',
    userDesc: 'Valtey.Sousa@nouryon.com'
  },
  {
    username: 'erikal',
    userDesc: 'Erika.Larsson@nouryon.com'
  },
  {
    username: 'grahamd3',
    userDesc: 'Dominic.Graham@nouryon.com'
  },
  {
    username: 'MazerC',
    userDesc: 'Cesar.Mazer@nouryon.com'
  },
  {
    username: 'broomem',
    userDesc: 'michael.broome@nouryon.com'
  },
  {
    username: 'TartaglM',
    userDesc: 'Michele.Tartaglia@nouryon.com'
  },
  {
    username: 'jonest1',
    userDesc: 'Tiffany.Jones@nouryon.com'
  },
  {
    username: 'bergstrj',
    userDesc: 'joergen.bergstroem@nouryon.com'
  },
  {
    username: 'mattiag',
    userDesc: 'Gerardo.Mattia@nouryon.com'
  },
  {
    username: 'dufrannp',
    userDesc: 'Pierre.Dufranne@nouryon.com'
  },
  {
    username: 'siggelik',
    userDesc: 'Kjell.Siggelin@nouryon.com'
  },
  {
    username: 'adm_heuvela1',
    userDesc: 'adm_heuvela1'
  },
  {
    username: 'flemic03',
    userDesc: 'christopher.fleming@nouryon.com'
  },
  {
    username: 'sebastda',
    userDesc: 'Denilson.Sebastiao@nouryon.com'
  },
  {
    username: 'milled14',
    userDesc: 'dudley.miller@nouryon.com'
  },
  {
    username: 'NaleciA',
    userDesc: 'adair.nalecio@nouryon.com'
  },
  {
    username: 'ouz',
    userDesc: 'Zita.Ou@nouryon.com'
  },
  {
    username: 'tenfalto',
    userDesc: 'Oscar.Tenfalt@nouryon.com'
  },
  {
    username: 'dehavayg',
    userDesc: 'Gauthier.Dehavay@nouryon.com'
  },
  {
    username: 'bc1035',
    userDesc: 'Sergio.Scardino@nouryon.com'
  },
  {
    username: 'bc1303',
    userDesc: 'Jean.Dutat@nouryon.com'
  },
  {
    username: 'angervas',
    userDesc: 'Sara.Angervall@nouryon.com'
  },
  {
    username: 'AlvesL1',
    userDesc: 'luana.alves@nouryon.com'
  },
  {
    username: 'adm_wangs6',
    userDesc: 'adm_wangs6'
  },
  {
    username: 'andreasj',
    userDesc: 'jesper.andreasson@nouryon.com'
  },
  {
    username: 'sappj01',
    userDesc: 'james.sapp@nouryon.com'
  },
  {
    username: 'vrielinp',
    userDesc: 'Pieter.Vrieling@nouryon.com'
  },
  {
    username: 'norlingj',
    userDesc: 'Johan.Norling@nouryon.com'
  },
  {
    username: 'pengx1',
    userDesc: 'XiaoMeng.Peng@nouryon.com'
  },
  {
    username: 'NC3238',
    userDesc: 'Theo.vandeBeek@nouryon.com'
  },
  {
    username: 'kagovem1',
    userDesc: 'Mikael.Kagovere1@nouryon.com'
  },
  {
    username: 'maan',
    userDesc: 'Magnus.Anger@nouryon.com'
  },
  {
    username: 'sollaid',
    userDesc: 'Daniel.Sollai@nouryon.com'
  },
  {
    username: 'perssom2',
    userDesc: 'Mikael.Persson@nouryon.com'
  },
  {
    username: 'svedinb',
    userDesc: 'Bjorn.Svedin@nouryon.com'
  },
  {
    username: 'ulsl',
    userDesc: 'Ulrika.Schenell@nouryon.com'
  },
  {
    username: 'jovanelc',
    userDesc: 'Carolina.Jovanelle@nouryon.com'
  },
  {
    username: 'silvamo',
    userDesc: 'Maxuel.Silva@nouryon.com'
  },
  {
    username: 'buchinaj',
    userDesc: 'Jolene.Buchina@nouryon.com'
  },
  {
    username: 'frederij',
    userDesc: 'John.Frederick@nouryon.com'
  },
  {
    username: 'freemk02',
    userDesc: 'kyle.freeman@nouryon.com'
  },
  {
    username: 'vermij01',
    userDesc: 'joe.vermillion@nouryon.com'
  },
  {
    username: 'fossera',
    userDesc: 'Andreas.Fosser@nouryon.com'
  },
  {
    username: 'agliom',
    userDesc: 'Marcio.Aglio@nouryon.com'
  },
  {
    username: 'almeidp',
    userDesc: 'Pedro.Almeida@nouryon.com'
  },
  {
    username: 'soaresmt',
    userDesc: 'Marcio.Soares@nouryon.com'
  },
  {
    username: 'casagrad',
    userDesc: 'Diego.Casagrandi@nouryon.com'
  },
  {
    username: 'whitel05',
    userDesc: 'leonard.white@nouryon.com'
  },
  {
    username: 'iennesk',
    userDesc: 'Kleiton.Iennes@nouryon.com'
  },
  {
    username: 'molenal',
    userDesc: 'Laercio.Molena@nouryon.com'
  },
  {
    username: 'ohrmank',
    userDesc: 'Karin.Ohrman@nouryon.com'
  },
  {
    username: 'nederbef',
    userDesc: 'Fredrik.Nederberg@nouryon.com'
  },
  {
    username: 'munkbf1',
    userDesc: 'fredrik.munkby@nouryon.com'
  },
  {
    username: 'zuhlkep',
    userDesc: 'Pascal.Zuhlke@nouryon.com'
  },
  {
    username: 'santosra',
    userDesc: 'Rafael.Santos@nouryon.com'
  },
  {
    username: 'granlundm',
    userDesc: 'mikael.granlund@nouryon.com'
  },
  {
    username: 'vieiram',
    userDesc: 'Marcello.Vieira@nouryon.com'
  },
  {
    username: 'childerb',
    userDesc: 'Brian.Childers@nouryon.com'
  },
  {
    username: 'souzag',
    userDesc: 'gustavo.souza@nouryon.com'
  },
  {
    username: 'operador',
    userDesc: 'control.tno@nouryon.com'
  },
  {
    username: 'koolhofh',
    userDesc: 'Harrie.Koolhof@nouryon.com'
  },
  {
    username: 'BohlkenR',
    userDesc: 'Roderik.Bohlken@nouryon.com'
  },
  {
    username: 'possim',
    userDesc: 'Marcos.Possi@nouryon.com'
  },
  {
    username: 'adm_lundine',
    userDesc: 'adm_lundine'
  },
  {
    username: 'hylandm',
    userDesc: 'Michael.Hyland@nouryon.com'
  },
  {
    username: 'lofvinge',
    userDesc: 'Ellen.GarbergLofving@nouryon.com'
  },
  {
    username: 'neumanna',
    userDesc: 'Andreas.Neumann@nouryon.com'
  },
  {
    username: 'philipse',
    userDesc: 'Erik.Philipse@nouryon.com'
  },
  {
    username: 'oglesbyc',
    userDesc: 'Carey.Oglesby@nouryon.com'
  },
  {
    username: 'daca',
    userDesc: 'David.Ekeroth@nouryon.com'
  },
  {
    username: 'boerscda',
    userDesc: 'Douglas.Boerschig@nouryon.com'
  },
  {
    username: 'lebbind',
    userDesc: 'Dan.Lebbin@nouryon.com'
  },
  {
    username: 'sandbero',
    userDesc: 'Ola.Sandberg@nouryon.com'
  },
  {
    username: 'francal',
    userDesc: 'Leonardo.Franca@nouryon.com'
  },
  {
    username: 'jonssob3',
    userDesc: 'Bjarne.Jonsson@nouryon.com'
  },
  {
    username: 'AA144',
    userDesc: 'Bernard.Tuin@nouryon.com'
  },
  {
    username: 'youngb1',
    userDesc: 'Brian.Young@nouryon.com'
  },
  {
    username: 'verbenom',
    userDesc: 'Marcelo.Verbeno@nouryon.com'
  },
  {
    username: 'CaputoP',
    userDesc: 'Pietro.Caputo@nouryon.com'
  },
  {
    username: 'landerbl',
    userDesc: 'landerbl'
  },
  {
    username: 'holme',
    userDesc: 'erica.dolff@nouryon.com'
  },
  {
    username: 'sotoalba',
    userDesc: 'Alba.Soto3@nouryon.com'
  },
  {
    username: 'bomfimr',
    userDesc: 'Rosalvo.Bomfim@nouryon.com'
  },
  {
    username: 'marquest',
    userDesc: 'Thayana.Marques@nouryon.com'
  },
  {
    username: 'ellieb',
    userDesc: 'Bryan.Ellie@nouryon.com'
  },
  {
    username: 'ulrika',
    userDesc: 'Ulrika.Soederholm@nouryon.com'
  },
  {
    username: 'forsbef1',
    userDesc: 'Fredrik.Forsberg@nouryon.com'
  },
  {
    username: 'rodrigd2',
    userDesc: 'Danielle.Rodriguez@nouryon.com'
  },
  {
    username: 'yangh',
    userDesc: 'hua.yang1@nouryon.com'
  },
  {
    username: 'silvae10',
    userDesc: 'Emerson.silva@nouryon.com'
  },
  {
    username: 'lindl',
    userDesc: 'Lotta.Lind1@nouryon.com'
  },
  {
    username: 'adm_silvar4',
    userDesc: 'adm_silvar4'
  },
  {
    username: 'collinsb',
    userDesc: 'Bradley.Collins@nouryon.com'
  },
  {
    username: 'hamlinm',
    userDesc: 'Michael.Hamlin@nouryon.com'
  },
  {
    username: 'davisjs',
    userDesc: 'Scott.Davis@nouryon.com'
  },
  {
    username: 'malmberd',
    userDesc: 'Danielle.Malmberg@nouryon.com'
  },
  {
    username: 'quinteror',
    userDesc: 'Richard.R.Quintero@nouryon.com'
  },
  {
    username: 'axelssm2',
    userDesc: 'Marie.Axelsson@nouryon.com'
  },
  {
    username: 'quinterr',
    userDesc: 'Richard.Quintero@nouryon.com'
  },
  {
    username: 'cajkusik',
    userDesc: 'Kristina.Cajkusic@nouryon.com'
  },
  {
    username: 'bukt',
    userDesc: 'Timo.Buk@nouryon.com'
  },
  {
    username: 'taylorg',
    userDesc: 'Glenn.Taylor@nouryon.com'
  },
  {
    username: 'holmt1',
    userDesc: 'Tove.Holm@nouryon.com'
  },
  {
    username: 'juniore1',
    userDesc: 'Elcio.Junior@nouryon.com'
  },
  {
    username: 'laras',
    userDesc: 'Sebastian.Lara@nouryon.com'
  },
  {
    username: 'antonic1',
    userDesc: 'Clovis.Antonio@nouryon.com'
  },
  {
    username: 'lemieuxs',
    userDesc: 'Sandra.Lemieux@nouryon.com'
  },
  {
    username: 'ovsi',
    userDesc: 'Ove.Siggelin@nouryon.com'
  },
  {
    username: 'nilssont',
    userDesc: 'Thomas.Nilsson@nouryon.com'
  },
  {
    username: 'svanluna',
    userDesc: 'Anton.Svanlund@nouryon.com'
  },
  {
    username: 'gonzalel1',
    userDesc: 'luis.gonzalez1@nouryon.com'
  },
  {
    username: 'borjess1',
    userDesc: 'Sara.Borjesson@nouryon.com'
  },
  {
    username: 'emilss11',
    userDesc: 'Therese.Emilsson@nouryon.com'
  },
  {
    username: 'jny',
    userDesc: 'Jan.Nyman@nouryon.com'
  },
  {
    username: 'lama',
    userDesc: 'Lars.Mattiasson@nouryon.com'
  },
  {
    username: 'pinknere',
    userDesc: 'Raymond.Pinkney@nouryon.com'
  },
  {
    username: 'stoc',
    userDesc: 'Stefan.Oscarsson@nouryon.com'
  },
  {
    username: 'chje',
    userDesc: 'Christer.Jern@nouryon.com'
  },
  {
    username: 'YDEM',
    userDesc: 'Martial.YDE@nouryon.com'
  },
  {
    username: 'kangasji',
    userDesc: 'jimmie.kangas@nouryon.com'
  },
  {
    username: 'xmjoh',
    userDesc: 'Martin.Johansson1@nouryon.com'
  },
  {
    username: 'chevalih',
    userDesc: 'Hugue.Chevalier@nouryon.com'
  },
  {
    username: 'kollbraj',
    userDesc: 'Jonas.Kollbratt@nouryon.com'
  },
  {
    username: 'thoj',
    userDesc: 'Thorbjorn.Johansson@nouryon.com'
  },
  {
    username: 'HultmanJ',
    userDesc: 'Johan.Hultman@nouryon.com'
  },
  {
    username: 'qasems',
    userDesc: 'Samirali.Qasem@nouryon.com'
  },
  {
    username: 'albericc',
    userDesc: 'Charles.Alberico@nouryon.com'
  },
  {
    username: 'gofr',
    userDesc: 'Goran.Fransson@nouryon.com'
  },
  {
    username: 'krbe',
    userDesc: 'Krister.Benjaminsson@nouryon.com'
  },
  {
    username: 'miba',
    userDesc: 'Mikael.Backman@nouryon.com'
  },
  {
    username: 'Xjoni',
    userDesc: 'Jonas.nilsson5@nouryon.com'
  },
  {
    username: 'msm',
    userDesc: 'Mikael.Strom2@nouryon.com'
  },
  {
    username: 'bc1318',
    userDesc: 'Giuseppe.Chiarelli@nouryon.com'
  },
  {
    username: 'norberm1',
    userDesc: 'Mikael.Norberg@nouryon.com'
  },
  {
    username: 'mibe',
    userDesc: 'Michael.Berg@nouryon.com'
  },
  {
    username: 'coj',
    userDesc: 'Conny.Johansson@nouryon.com'
  },
  {
    username: 'lindqvif',
    userDesc: 'Fredrik.Lindqvist@nouryon.com'
  },
  {
    username: 'vagetegm',
    userDesc: 'Matz.Vageteg@nouryon.com'
  },
  {
    username: 'cbn',
    userDesc: 'camilla.fredriksson@nouryon.com'
  },
  {
    username: 'haol',
    userDesc: 'Hans.Olsson@nouryon.com'
  },
  {
    username: 'edvinssr',
    userDesc: 'Rolf.Edvinsson@nouryon.com'
  },
  {
    username: 'hendricr',
    userDesc: 'Randy.Hendricksen@nouryon.com'
  },
  {
    username: 'ekwalld',
    userDesc: 'Daniel.Ekwall@nouryon.com'
  },
  {
    username: 'luderera',
    userDesc: 'Andreas.Luderer@nouryon.com'
  },
  {
    username: 'operatoer',
    userDesc: 'operatoersrum@nouryon.com'
  },
  {
    username: 'gustafssona',
    userDesc: 'Albin.Gustafsson@nouryon.com'
  },
  {
    username: 'rimeslm1',
    userDesc: 'Magnus.Rimeslatten@nouryon.com'
  },
  {
    username: 'mnadilim',
    userDesc: 'MohamadAdib.Mnadilie@nouryon.com'
  },
  {
    username: 'jos',
    userDesc: 'Sara.Karlsson@nouryon.com'
  },
  {
    username: 'sjostrm2',
    userDesc: 'magnus.sjostrom@nouryon.com'
  },
  {
    username: 'lingobk',
    userDesc: 'Brian.Lingo@nouryon.com'
  },
  {
    username: 'johansfr',
    userDesc: 'Fredrik.Johansson1@nouryon.com'
  },
  {
    username: 'OlssoA',
    userDesc: 'Andreas.Olsson@nouryon.com'
  },
  {
    username: 'svensst1',
    userDesc: 'Ted.Svensson@nouryon.com'
  },
  {
    username: 'gevreusj',
    userDesc: 'Jimmy.Gevreus@nouryon.com'
  },
  {
    username: 'fossere',
    userDesc: 'Emil.Fosser@nouryon.com'
  },
  {
    username: 'thaqia',
    userDesc: 'Ardi.Thaqi@nouryon.com'
  },
  {
    username: 'cacaresc',
    userDesc: 'Christian.caceres@nouryon.com'
  },
  {
    username: 'carrigsj',
    userDesc: 'Sarah.Carriger@nouryon.com'
  },
  {
    username: 'blu',
    userDesc: 'Bengt.Lundberg@nouryon.com'
  },
  {
    username: 'lih10',
    userDesc: 'He.Li@nouryon.com'
  },
  {
    username: 'caizw',
    userDesc: 'zhiwei.cai@nouryon.com'
  },
  {
    username: 'cago',
    userDesc: 'Camilla.Gotmar@nouryon.com'
  },
  {
    username: 'xanca',
    userDesc: 'Andreas.Carlsson@nouryon.com'
  },
  {
    username: 'liangy2',
    userDesc: 'guoyi.lian@nouryon.com'
  },
  {
    username: 'peji',
    userDesc: 'Per.Jirhem@nouryon.com'
  },
  {
    username: 'guendeln',
    userDesc: 'niels.guendel@nouryon.com'
  },
  {
    username: 'frfe',
    userDesc: 'Fredrik.Fernholm@nouryon.com'
  },
  {
    username: 'danielm2',
    userDesc: 'Marcus.Daniel@nouryon.com'
  },
  {
    username: 'chenld',
    userDesc: 'lindong.chen@nouryon.com'
  },
  {
    username: 'xmikj',
    userDesc: 'Mikael.X.Johansson@nouryon.com'
  },
  {
    username: 'meekesj',
    userDesc: 'Joost.Meekes@nouryon.com'
  },
  {
    username: 'teagarpa',
    userDesc: 'Patrick.Teagarden@nouryon.com'
  },
  {
    username: 'thorntob',
    userDesc: 'tobias.thorn@nouryon.com'
  },
  {
    username: 'wikstrob',
    userDesc: 'Bjoern.Wikstroem@nouryon.com'
  },
  {
    username: 'adm_ankarj',
    userDesc: 'adm_ankarj'
  },
  {
    username: 'engr',
    userDesc: 'Rickard.Eng@nouryon.com'
  },
  {
    username: 'pant',
    userDesc: 'Tongkuai.Pan@nouryon.com'
  },
  {
    username: 'perezah',
    userDesc: 'Arturo.Perez@nouryon.com'
  },
  {
    username: 'johansst',
    userDesc: 'Tobias.Johansson1@nouryon.com'
  },
  {
    username: 'oliveie4',
    userDesc: 'Elianderson.Oliveira@nouryon.com'
  },
  {
    username: 'renj',
    userDesc: 'Jesse.Ren@nouryon.com'
  },
  {
    username: 'VenturaS',
    userDesc: 'simon.ventura@nouryon.com'
  },
  {
    username: 'bc1143',
    userDesc: 'Thierry.Donneaux@nouryon.com'
  },
  {
    username: 'jojo',
    userDesc: 'Jorgen.b.Johansson@nouryon.com'
  },
  {
    username: 'gomesa1',
    userDesc: 'Adriano.Gomes@nouryon.com'
  },
  {
    username: 'sheltonb',
    userDesc: 'Bryan.Shelton@nouryon.com'
  },
  {
    username: 'HenkelH',
    userDesc: 'Hans-Juergen.Henkel@nouryon.com'
  },
  {
    username: 'anm',
    userDesc: 'Andras.Mate@nouryon.com'
  },
  {
    username: 'asda',
    userDesc: 'Asa.Dagsberg@nouryon.com'
  },
  {
    username: 'holmgrea',
    userDesc: 'AnnSofie.Holmgren@nouryon.com'
  },
  {
    username: 'engblome',
    userDesc: 'emil.engblom@nouryon.com'
  },
  {
    username: 'souzacra',
    userDesc: 'Cesar.Souza@nouryon.com'
  },
  {
    username: 'PeltT',
    userDesc: 'Ton.vanPelt@nouryon.com'
  },
  {
    username: 'moorem2',
    userDesc: 'MaryAnne.Moore@nouryon.com'
  },
  {
    username: 'dengs12',
    userDesc: 'shengxin.deng@nouryon.com'
  },
  {
    username: 'baathl1',
    userDesc: 'lars.baath@nouryon.com'
  },
  {
    username: 'stenmanm',
    userDesc: 'Magnus.Stenman@nouryon.com'
  },
  {
    username: 'adm_torstenj',
    userDesc: 'adm_torstenj'
  },
  {
    username: 'gunnarss',
    userDesc: 'sara.gunnarsson@nouryon.com'
  },
  {
    username: 'hermansm',
    userDesc: 'Mauritz.Hermansson@nouryon.com'
  },
  {
    username: 'bc1365',
    userDesc: 'Logan.Tondu@nouryon.com'
  },
  {
    username: 'hols',
    userDesc: 'Hakan.Olsson2@nouryon.com'
  },
  {
    username: 'silvar4',
    userDesc: 'Ricardo.Silva@nouryon.com'
  },
  {
    username: 'roja',
    userDesc: 'Ronny.Jansson@nouryon.com'
  },
  {
    username: 'deprinss',
    userDesc: 'Sebastien.Deprins@nouryon.com'
  },
  {
    username: 'chaiw',
    userDesc: 'Weisin.Chai@nouryon.com'
  },
  {
    username: 'mccaskel',
    userDesc: 'labarron.mccaskey@nouryon.com'
  },
  {
    username: 'dossant',
    userDesc: 'Tony.DosSantos@nouryon.com'
  },
  {
    username: 'zhengb',
    userDesc: 'Benjamin.Zheng@nouryon.com'
  },
  {
    username: 'johnst09',
    userDesc: 'terrell.johnson@nouryon.com'
  },
  {
    username: 'diekem',
    userDesc: 'Martina.Dieke@nouryon.com'
  },
  {
    username: 'phillm04',
    userDesc: 'mark.phillips@nouryon.com'
  },
  {
    username: 'adm_chaiw',
    userDesc: 'adm_chaiw'
  },
  {
    username: 'alamia',
    userDesc: 'Ahmed.Alami@nouryon.com'
  },
  {
    username: 'hicksd02',
    userDesc: 'donnie.hicks@nouryon.com'
  },
  {
    username: 'BrownM5',
    userDesc: 'Margaret.Brown@nouryon.com'
  },
  {
    username: 'belfragh',
    userDesc: 'Henrik.Belfrage@nouryon.com'
  },
  {
    username: 'ADM_BJGR',
    userDesc: 'ADM_BJGR'
  },
  {
    username: 'holmlunt',
    userDesc: 'timmo.holmlund@nouryon.com'
  },
  {
    username: 'damianif',
    userDesc: 'Felipe.Damiani@nouryon.com'
  },
  {
    username: 'regu',
    userDesc: 'reidar.gustafson@nouryon.com'
  },
  {
    username: 'adm_aljundis',
    userDesc: 'adm_aljundis'
  },
  {
    username: 'svenssm1',
    userDesc: 'Mikael.ArntzenSvensson@nouryon.com'
  },
  {
    username: 'brackf',
    userDesc: 'Fredrik.Brack@nouryon.com'
  },
  {
    username: 'greend89',
    userDesc: 'derek.greene@nouryon.com'
  },
  {
    username: 'bergqvii',
    userDesc: 'Isabelle.Bergqvist@nouryon.com'
  },
  {
    username: 'nixr01',
    userDesc: 'Ronald.Nix@nouryon.com'
  },
  {
    username: 'fruslonq',
    userDesc: 'quentin.fruslon@nouryon.com'
  },
  {
    username: 'brostroi',
    userDesc: 'Ida.BrostromKristensson@nouryon.com'
  },
  {
    username: 'fuh1',
    userDesc: 'Hui.Fu@nouryon.com'
  },
  {
    username: 'wiigb',
    userDesc: 'Bengt.Wiig@nouryon.com'
  },
  {
    username: 'wellmea',
    userDesc: 'Andreas.Wellme@nouryon.com'
  },
  {
    username: 'schotie',
    userDesc: 'Eduardo.Schoti@nouryon.com'
  },
  {
    username: 'backberp',
    userDesc: 'pia.backberg@nouryon.com'
  },
  {
    username: 'bc1377',
    userDesc: 'Stefano.Fragapane@nouryon.com'
  },
  {
    username: 'nwabudet',
    userDesc: 'Tiffany.Nwabude@nouryon.com'
  },
  {
    username: 'bogaerta',
    userDesc: 'Arnaud.Bogaert@nouryon.com'
  },
  {
    username: 'hollemar',
    userDesc: 'ronald.holleman@nouryon.com'
  },
  {
    username: 'sloopt01',
    userDesc: 'tracy.sloop@nouryon.com'
  },
  {
    username: 'nilseng',
    userDesc: 'Glenn.H.Nilsen@nouryon.com'
  },
  {
    username: 'karlssor',
    userDesc: 'Rickard.Karlsson@nouryon.com'
  },
  {
    username: 'francea1',
    userDesc: 'ana.franceschini1@nouryon.com'
  },
  {
    username: 'larssoj3',
    userDesc: 'Johan.Larsson@nouryon.com'
  },
  {
    username: 'jepsenc',
    userDesc: 'Colin.Jepsen@nouryon.com'
  },
  {
    username: 'silvajs',
    userDesc: 'Jussara.Silva@nouryon.com'
  },
  {
    username: 'vidaldaf',
    userDesc: 'Filipe.Lopes@nouryon.com'
  },
  {
    username: 'laro',
    userDesc: 'Lars.Olsson@nouryon.com'
  },
  {
    username: 'hyrenpee',
    userDesc: 'Erik.HyrenPettersson@nouryon.com'
  },
  {
    username: 'RosenqvD',
    userDesc: 'Daniel.Rosenqvist@nouryon.com'
  },
  {
    username: 'joha',
    userDesc: 'Joakim.Hagnander@nouryon.com'
  },
  {
    username: 'andersoa',
    userDesc: 'Allison.Anderson@nouryon.com'
  },
  {
    username: 'rashida',
    userDesc: 'Abu.Rashid@nouryon.com'
  },
  {
    username: 'vaughnt',
    userDesc: 'Taylor.Vaughn@nouryon.com'
  },
  {
    username: 'erikssm4',
    userDesc: 'Mikael.Eriksson1@nouryon.com'
  },
  {
    username: 'zhanght',
    userDesc: 'Haitao.Zhang@nouryon.com'
  },
  {
    username: 'bolducy',
    userDesc: 'Yvan.Bolduc@nouryon.com'
  },
  {
    username: 'pafumic',
    userDesc: 'Claudio.Pafumi@nouryon.com'
  },
  {
    username: 'willcutg',
    userDesc: 'Gary.Willcutt@nouryon.com'
  },
  {
    username: 'zhangs22',
    userDesc: 'shubin.zhang@nouryon.com'
  },
  {
    username: 'lindlak1',
    userDesc: 'Kristian.LindLarsson@nouryon.com'
  },
  {
    username: 'chaffinp',
    userDesc: 'Phyllis.Chaffin@nouryon.com'
  },
  {
    username: 'noordenvj',
    userDesc: 'Jeroen.VanNoorden@nouryon.com'
  },
  {
    username: 'flemingg',
    userDesc: 'Greg.Fleming@nouryon.com'
  },
  {
    username: 'ericssot',
    userDesc: 'thomas.ericsson@nouryon.com'
  },
  {
    username: 'koosonok',
    userDesc: 'Kwadwo.KoosonoAmpem@nouryon.com'
  },
  {
    username: 'tst_BASC_EPI_5',
    userDesc: ''
  },
  {
    username: 'habetsa',
    userDesc: 'Luana.Habets@nouryon.com'
  },
  {
    username: 'hedmanh',
    userDesc: 'Henrik.Hedman@nouryon.com'
  },
  {
    username: 'queirozc',
    userDesc: 'cristiano.queiroz@nouryon.com'
  },
  {
    username: 'thedine',
    userDesc: 'Erik.Thedin@nouryon.com'
  },
  {
    username: 'santanj1',
    userDesc: 'Juliana.Santana@nouryon.com'
  },
  {
    username: 'chenz25',
    userDesc: 'zhihui.chen@nouryon.com'
  },
  {
    username: 'hagbergj',
    userDesc: 'jesper.hagberg@nouryon.com'
  },
  {
    username: 'adm_suenw',
    userDesc: 'adm_suenw'
  },
  {
    username: 'chiach',
    userDesc: 'Chris.Chia@nouryon.com'
  },
  {
    username: 'almazanm',
    userDesc: 'Manuel.Almazan@nouryon.com'
  },
  {
    username: 'adm_sohil',
    userDesc: 'adm_sohil'
  },
  {
    username: 'benedica',
    userDesc: 'Carlos.Benedito@nouryon.com'
  },
  {
    username: 'souzawe',
    userDesc: 'Wesley.Souza@nouryon.com'
  },
  {
    username: 'hermane3',
    userDesc: 'Eline.Hermans@nouryon.com'
  },
  {
    username: 'adm_dollt',
    userDesc: 'adm_dollt'
  },
  {
    username: 'LysaridS',
    userDesc: 'Stratos.Lysaridis@nouryon.com'
  },
  {
    username: 'brownpa',
    userDesc: 'Patrick.brown@nouryon.com'
  },
  {
    username: 'riets',
    userDesc: 'Stefan.VanDeRiet@nouryon.com'
  },
  {
    username: 'adm_LysaridS',
    userDesc: 'adm_LysaridS'
  },
  {
    username: 'nelhagep',
    userDesc: 'peter.nelhagen@nouryon.com'
  },
  {
    username: 'adm_VeltkamM',
    userDesc: 'adm_VeltkamM'
  },
  {
    username: 'VissersM',
    userDesc: 'Max.Vissers@nouryon.com'
  },
  {
    username: 'adm_liy',
    userDesc: ''
  },
  {
    username: 'WeversJ',
    userDesc: 'JeanPierre.Wevers@nouryon.com'
  },
  {
    username: 'adm_yinyinl',
    userDesc: ''
  },
  {
    username: 'wangd3',
    userDesc: 'Deliang.Wang@nouryon.com'
  },
  {
    username: 'adm_MartensD',
    userDesc: 'adm_MartensD'
  },
  {
    username: 'zhaol',
    userDesc: 'longzhong.zhao@nouryon.com'
  },
  {
    username: 'perssone',
    userDesc: 'Elin.Lagnemo@nouryon.com'
  },
  {
    username: 'Adm_MartinS',
    userDesc: 'Adm_MartinS'
  },
  {
    username: 'roberts',
    userDesc: 'Steve.Robert@nouryon.com'
  },
  {
    username: 'adm_roberts',
    userDesc: 'adm_roberts'
  },
  {
    username: 'michalst',
    userDesc: 'anthony.michalski@nouryon.com'
  },
  {
    username: 'adm_prinse',
    userDesc: 'adm_prinse'
  },
  {
    username: 'zhangf5',
    userDesc: 'feng.zhang5@nouryon.com'
  },
  {
    username: 'moralesj1',
    userDesc: 'juan.morales1@nouryon.com'
  },
  {
    username: 'kondoria',
    userDesc: 'afaride.kondori@nouryon.com'
  },
  {
    username: 'anderssh',
    userDesc: 'Hanna.Andersson@nouryon.com'
  },
  {
    username: 'osadoloo',
    userDesc: 'osagiealex.osadolor@nouryon.com'
  },
  {
    username: 'ludwigc',
    userDesc: 'christian.ludwig@nouryon.com'
  },
  {
    username: 'adm_dichantd',
    userDesc: 'adm_dichantd'
  },
  {
    username: 'adm_koemanj',
    userDesc: 'adm_koemanj'
  },
  {
    username: 'adm_nc3548',
    userDesc: 'adm_nc3548'
  },
  {
    username: 'nybergf',
    userDesc: 'fredrik.nyberg@nouryon.com'
  },
  {
    username: 'weisc',
    userDesc: 'shichang.wei@nouryon.com'
  },
  {
    username: 'zhud',
    userDesc: 'Davis.Zhu@nouryon.com'
  },
  {
    username: 'adm_wikmanm',
    userDesc: 'adm_wikmanm'
  },
  {
    username: 'adm_malhih',
    userDesc: 'adm_malhih'
  },
  {
    username: 'baileyw',
    userDesc: 'Wanda.Bailey@nouryon.com'
  },
  {
    username: 'adm_joshs',
    userDesc: 'adm_joshs'
  },
  {
    username: 'adm_lawk',
    userDesc: 'adm_lawk'
  },
  {
    username: 'adm_berkent',
    userDesc: 'adm_berkent'
  },
  {
    username: 'pommied',
    userDesc: 'Dave.Pommier@nouryon.com'
  },
  {
    username: 'VeltkamM',
    userDesc: 'Matthies.Veltkamp@nouryon.com'
  },
  {
    username: 'ibrahimj',
    userDesc: 'Jivan.Ibrahim@nouryon.com'
  },
  {
    username: 'bc1216',
    userDesc: 'Marika.Alifierakis@nouryon.com'
  },
  {
    username: 'tardifc',
    userDesc: 'Charles.Tardif@nouryon.com'
  },
  {
    username: 'larcheva',
    userDesc: 'anthony.larcheveque@nouryon.com'
  },
  {
    username: 'adm_tohj',
    userDesc: 'adm_tohj'
  },
  {
    username: 'tst_BASC_EPI13',
    userDesc: 'tst_BASC_EPI13'
  },
  {
    username: 'adm_mishram1',
    userDesc: 'adm_mishram1'
  },
  {
    username: 'adm_Wangeh',
    userDesc: 'adm_Wangeh'
  },
  {
    username: 'adm_wangd3',
    userDesc: 'adm_wangd3'
  },
  {
    username: 'lascun',
    userDesc: 'nicolae.lascu@nouryon.com'
  },
  {
    username: 'breenw',
    userDesc: 'Wilko.Breen@nouryon.com'
  },
  {
    username: 'bodingbj',
    userDesc: 'bodingbj'
  },
  {
    username: 'adebahrm',
    userDesc: 'Mattias.Adebahr@nouryon.com'
  },
  {
    username: 'grinwisp',
    userDesc: 'Peter.Grinwis@nouryon.com'
  },
  {
    username: 'perssong',
    userDesc: 'gerd.persson@nouryon.com'
  },
  {
    username: 'skoglunv',
    userDesc: 'victor.skoglund@nouryon.com'
  },
  {
    username: 'granlund',
    userDesc: 'daniella.granlund@nouryon.com'
  },
  {
    username: 'stenelij',
    userDesc: 'jonas.stenelind@nouryon.com'
  },
  {
    username: 'vangersm',
    userDesc: 'martin.vangerstrand@nouryon.com'
  },
  {
    username: 'adm_hedenstk',
    userDesc: 'adm_hedenstk'
  },
  {
    username: 'sellboms',
    userDesc: 'susanne.sellbom@nouryon.com'
  },
  {
    username: 'pages',
    userDesc: 'sonia.page@nouryon.com'
  },
  {
    username: 'adm_Haris',
    userDesc: 'adm_Haris'
  },
  {
    username: 'BC1293',
    userDesc: 'Christian.Dramaix@nouryon.com'
  },
  {
    username: 'adm_caleffig',
    userDesc: 'adm_caleffig'
  },
  {
    username: 'donohor',
    userDesc: 'richard.donoho@nouryon.com'
  },
  {
    username: 'forica1',
    userDesc: 'Alma.Foric@nouryon.com'
  },
  {
    username: 'upplingr',
    userDesc: 'robin.uppling@nouryon.com'
  },
  {
    username: 'palmmaro',
    userDesc: 'Oscar.PalmMartinez@nouryon.com'
  },
  {
    username: 'wangj87',
    userDesc: 'jimmy.wang1@nouryon.com'
  },
  {
    username: 'adm_netzell',
    userDesc: 'adm_netzell'
  },
  {
    username: 'naumanr',
    userDesc: 'Ralph.Naumann@nouryon.com'
  },
  {
    username: 'zhangx29',
    userDesc: 'zhangx29'
  },
  {
    username: 'erikssov',
    userDesc: 'veronica.eriksson@nouryon.com'
  },
  {
    username: 'piercyjl',
    userDesc: 'Jennifer.Queretaro@nouryon.com'
  },
  {
    username: 'thoernbw',
    userDesc: 'wilmer.thoernberg@nouryon.com'
  },
  {
    username: 'liuj48',
    userDesc: 'qiang.liu4@nouryon.com'
  },
  {
    username: 'biliro',
    userDesc: 'onder.bilir@nouryon.com'
  },
  {
    username: 'adm_heimdahf',
    userDesc: 'adm_heimdahf'
  },
  {
    username: 'PLC3',
    userDesc: 'PLC3@nouryon.com'
  },
  {
    username: 'morrisk',
    userDesc: 'Kevin.Morris@nouryon.com'
  },
  {
    username: 'anhaeusj',
    userDesc: 'Juergen.Anhaeuser@nouryon.com'
  },
  {
    username: 'moonl',
    userDesc: 'lonnie.moon@nouryon.com'
  },
  {
    username: 'lidstrod',
    userDesc: 'dennis.lidstroem@nouryon.com'
  },
  {
    username: 'villamai',
    userDesc: 'ivan.villamar@nouryon.com'
  },
  {
    username: 'sattlerm',
    userDesc: 'marco.sattler@nouryon.com'
  },
  {
    username: 'sjoelenl',
    userDesc: 'linus.sjoelen@nouryon.com'
  },
  {
    username: 'adm_garater',
    userDesc: 'adm_garater'
  },
  {
    username: 'MoreiraE',
    userDesc: 'Eduardo.Moreira@nouryon.com'
  },
  {
    username: 'favillar',
    userDesc: 'rachel.favilla@nouryon.com'
  },
  {
    username: 'zhangah',
    userDesc: 'Aihuai.Zhang@nouryon.com'
  },
  {
    username: 'ADM_biliro',
    userDesc: 'ADM_biliro'
  },
  {
    username: 'christet',
    userDesc: 'Thomas.Christensen@nouryon.com'
  },
  {
    username: 'villarre',
    userDesc: 'Elizabeth.Villarroel@nouryon.com'
  },
  {
    username: 'jensenp',
    userDesc: 'palle.jensen@nouryon.com'
  },
  {
    username: 'louisg',
    userDesc: 'geertjan.louis@nouryon.com'
  },
  {
    username: 'odhnerm',
    userDesc: 'markus.odhner@nouryon.com'
  },
  {
    username: 'jacobso1',
    userDesc: 'Hanna.JacobsonIngemyr@nouryon.com'
  },
  {
    username: 'jensenj3',
    userDesc: 'Jimmy.Jensen@nouryon.com'
  },
  {
    username: 'adm_floresa',
    userDesc: 'adm_floresa'
  },
  {
    username: 'rautakoj',
    userDesc: 'janne.rautakorpi@nouryon.com'
  },
  {
    username: 'silversd',
    userDesc: 'daniel.silversved@nouryon.com'
  },
  {
    username: 'oeberge',
    userDesc: 'elisabeth.oeberg@nouryon.com'
  },
  {
    username: 'adm_pazjl',
    userDesc: 'adm_pazjl'
  },
  {
    username: 'mcdanid',
    userDesc: 'david.McDaniel@nouryon.com'
  },
  {
    username: 'martihm1',
    userDesc: 'Hector.M.Martinez@nouryon.com'
  },
  {
    username: 'belkheit',
    userDesc: 'tallal.belkheiri@nouryon.com'
  },
  {
    username: 'lauzied1',
    userDesc: 'dave.lauziere1@nouryon.com'
  },
  {
    username: 'garciaj5',
    userDesc: 'Jorge.Garcia3@nouryon.com'
  },
  {
    username: 'myrvolde',
    userDesc: 'erik.myrvold@nouryon.com'
  },
  {
    username: 'tst_BASC_EPI16',
    userDesc: ''
  },
  {
    username: 'guerrere',
    userDesc: 'eibbed.guerrero@nouryon.com'
  },
  {
    username: 'hugginpm',
    userDesc: 'Paul.Huggins@nouryon.com'
  },
  {
    username: 'millersa',
    userDesc: 'Scott.A.Miller@nouryon.com'
  },
  {
    username: 'pugha',
    userDesc: 'alex.pugh@nouryon.com'
  },
  {
    username: 'toereska',
    userDesc: 'amanda.toereskog@nouryon.com'
  },
  {
    username: 'songmx',
    userDesc: 'minxian.song@nouryon.com'
  },
  {
    username: 'korusa',
    userDesc: 'annchristin.korus@nouryon.com'
  },
  {
    username: 'seesinka',
    userDesc: 'Arjon.Seesink@nouryon.com'
  },
  {
    username: 'kuhlr',
    userDesc: 'Rolf.Kuehl@nouryon.com'
  },
  {
    username: 'demirelf',
    userDesc: 'fatih.demirel@nouryon.com'
  },
  {
    username: 'brabandd',
    userDesc: 'daan.brabander@nouryon.com'
  },
  {
    username: 'claudinm',
    userDesc: 'mauro.claudino@nouryon.com'
  },
  {
    username: 'oerjestr',
    userDesc: 'robert.oerjestal@nouryon.com'
  },
  {
    username: 'bergqvd1',
    userDesc: 'Daniel.Bergqvist@nouryon.com'
  },
  {
    username: 'yountsp',
    userDesc: 'patsy.younts@nouryon.com'
  },
  {
    username: 'anspachj',
    userDesc: 'jesse.anspach@nouryon.com'
  },
  {
    username: 'jonssol1',
    userDesc: 'linn.jonsson@nouryon.com'
  },
  {
    username: 'hagstroa',
    userDesc: 'annacarin.hagstroem@nouryon.com'
  },
  {
    username: 'bragac',
    userDesc: 'Carolina.Braga@nouryon.com'
  },
  {
    username: 'randevm',
    userDesc: 'Marie.Randev@nouryon.com'
  },
  {
    username: 'hallgrej',
    userDesc: 'johan.hallgren@nouryon.com'
  },
  {
    username: 'barcarol',
    userDesc: 'Leandro.Barcaro@nouryon.com'
  },
  {
    username: 'baic',
    userDesc: 'xue.bai@nouryon.com'
  },
  {
    username: 'perong',
    userDesc: 'guillaume.peron@nouryon.com'
  },
  {
    username: 'landmant',
    userDesc: 'Tjarko.Landman@nouryon.com'
  },
  {
    username: 'behnkea',
    userDesc: 'andreas.behnke@nouryon.com'
  },
  {
    username: 'manleye',
    userDesc: 'eric.manley@nouryon.com'
  },
  {
    username: 'barnabep',
    userDesc: 'Pierre.Barnabe@nouryon.com'
  },
  {
    username: 'measi',
    userDesc: 'ceary.meas@nouryon.com'
  },
  {
    username: 'wangd38',
    userDesc: 'dexiong.wang@nouryon.com'
  },
  {
    username: 'limam13',
    userDesc: 'marcelo.lima@nouryon.com'
  },
  {
    username: 'perssod2',
    userDesc: 'daniel.persson2@nouryon.com'
  },
  {
    username: 'HesseliJ',
    userDesc: 'joakim.hesselius@nouryon.com'
  },
  {
    username: 'tst_basc_epi_17',
    userDesc: ''
  },
  {
    username: 'rodrigg3',
    userDesc: 'gabriela.rodriguez@nouryon.com'
  },
  {
    username: 'lindstrc',
    userDesc: 'Casper.Lindstrom@nouryon.com'
  },
  {
    username: 'liedmaj1',
    userDesc: 'johan.liedman1@nouryon.com'
  },
  {
    username: 'JongG',
    userDesc: 'Gerdien.deJong@nouryon.com'
  },
  {
    username: 'bernards',
    userDesc: 'sylvain.bernard@nouryon.com'
  },
  {
    username: 'youngk1',
    userDesc: 'kaylan.young@nouryon.com'
  },
  {
    username: 'anikinat',
    userDesc: 'tatiana.anikina@nouryon.com'
  },
  {
    username: 'hoegstrj',
    userDesc: 'jenny.hoegstroem@nouryon.com'
  },
  {
    username: 'hanssonr',
    userDesc: 'rebecca.hansson@nouryon.com'
  },
  {
    username: 'leali',
    userDesc: 'isabellafigueiredo.leal@nouryon.com'
  },
  {
    username: 'zhuc',
    userDesc: 'chunfen.zhu@nouryon.com'
  },
  {
    username: 'vellieus',
    userDesc: 'Stephane.Vellieux@nouryon.com'
  },
  {
    username: 'ostbergj',
    userDesc: 'Jimmy.Ostberg@nouryon.com'
  },
  {
    username: 'svedina',
    userDesc: 'anton.svedin@nouryon.com'
  },
  {
    username: 'deltae',
    userDesc: ''
  },
  {
    username: 'sabrisai',
    userDesc: 'sabri.sas@nouryon.com'
  },
  {
    username: 'wissinge',
    userDesc: 'erik.wissing@nouryon.com'
  },
  {
    username: 'fosterkk',
    userDesc: 'Kevin.Foster@nouryon.com'
  },
  {
    username: 'cablerml',
    userDesc: 'Matthew.Cabler@nouryon.com'
  },
  {
    username: 'mildhm',
    userDesc: 'mikael.mildh@nouryon.com'
  },
  {
    username: 'magalhad',
    userDesc: 'daniellydefranca.magalhaes@nouryon.com'
  },
  {
    username: 'ricklefr',
    userDesc: 'Ryan.Ricklefsen@nouryon.com'
  },
  {
    username: 'curtisr',
    userDesc: 'rebecca.curtis@nouryon.com'
  },
  {
    username: 'jonzonj2',
    userDesc: 'julia.jonzon2@nouryon.com'
  },
  {
    username: 'DC558',
    userDesc: 'Claus.Decker@nouryon.com'
  },
  {
    username: 'mihif',
    userDesc: 'fmarvule@nouryon.com'
  },
  {
    username: 'tst_BASC_EPI_9',
    userDesc: ''
  },
  {
    username: 'edlunds',
    userDesc: 'stefan.edlund@nouryon.com'
  },
  {
    username: 'stageed',
    userDesc: 'Erik.Stage@nouryon.com'
  },
  {
    username: 'kuestern',
    userDesc: 'nico.kuestermann@nouryon.com'
  },
  {
    username: 'djapjasz',
    userDesc: 'zoran.djapjas@nouryon.com'
  },
  {
    username: 'karvalla',
    userDesc: 'anncatrin.karvall@nouryon.com'
  },
  {
    username: 'AlvesM',
    userDesc: 'marcelo.alves@nouryon.com'
  },
  {
    username: 'pollackt',
    userDesc: 'tobias.pollack@nouryon.com'
  },
  {
    username: 'soerenn',
    userDesc: 'ninna.soerensen@nouryon.com'
  },
  {
    username: 'adm_jonssos2',
    userDesc: 'adm_jonssos2'
  },
  {
    username: 'dalbergj',
    userDesc: 'jonny.dalberg@nouryon.com'
  },
  {
    username: 'celmera',
    userDesc: 'Adam.Celmer@nouryon.com'
  },
  {
    username: 'dooleyr',
    userDesc: 'Rich.Dooley@nouryon.com'
  },
  {
    username: 'juniorgi',
    userDesc: 'GilbertoRibeirodaSilva.Junior@nouryon.com'
  },
  {
    username: 'adm_anspachj',
    userDesc: 'adm_anspachj'
  },
  {
    username: 'harrism',
    userDesc: 'Mike.Harris1@nouryon.com'
  },
  {
    username: 'rodrim21',
    userDesc: 'Melinda.Rodriguez@nouryon.com'
  },
  {
    username: 'regand',
    userDesc: 'Darius.Regan@nouryon.com'
  },
  {
    username: 'cachonim',
    userDesc: 'mariana.cachoni@nouryon.com'
  },
  {
    username: 'heimannm',
    userDesc: 'moritz.heimann@nouryon.com'
  },
  {
    username: 'randlesm',
    userDesc: 'Michael.Randles@nouryon.com'
  },
  {
    username: 'adm_rodrigg3',
    userDesc: ''
  },
  {
    username: 'kettelke',
    userDesc: 'Keith.Kettelson@nouryon.com'
  },
  {
    username: 'brandmik',
    userDesc: 'kirk.brandmire@nouryon.com'
  },
  {
    username: 'barclayb',
    userDesc: 'bruce.barclay@nouryon.com'
  },
  {
    username: 'randicks',
    userDesc: 'Sherice.Randick@nouryon.com'
  },
  {
    username: 'blairj2',
    userDesc: 'Jacob.Blair@nouryon.com'
  },
  {
    username: 'bc1327',
    userDesc: 'Mathieu.Iavicoli@nouryon.com'
  },
  {
    username: 'ivarssov',
    userDesc: 'viktor.ivarsson@nouryon.com'
  },
  {
    username: 'oswaldr',
    userDesc: 'ronny.oswald@nouryon.com'
  },
  {
    username: 'walthel1',
    userDesc: 'linnea.walther@nouryon.com'
  },
  {
    username: 'meyero',
    userDesc: 'olaf.meyer@nouryon.com'
  },
  {
    username: 'nc3183',
    userDesc: 'Jetty.Moes@nouryon.com'
  },
  {
    username: 'mosqueia',
    userDesc: 'alfredo.mosqueiro@nouryon.com'
  },
  {
    username: 'frembget',
    userDesc: 'travis.frembgen@nouryon.com'
  },
  {
    username: 'yinyinl',
    userDesc: 'li.yinyin@nouryon.com'
  },
  {
    username: 'anderj12',
    userDesc: 'anderj12'
  },
  {
    username: 'werthimr',
    userDesc: 'Ronja.Werthim@nouryon.com'
  },
  {
    username: 'adm_ricklefr',
    userDesc: ''
  },
  {
    username: 'lorentzj',
    userDesc: 'jan.lorentzon1@nouryon.com'
  },
  {
    username: 'adm_marinhoj',
    userDesc: 'adm_marinhoj'
  },
  {
    username: 'kuhnc',
    userDesc: 'cindy.kuhn@nouryon.com'
  },
  {
    username: 'smithe2',
    userDesc: 'elisabet.smith@nouryon.com'
  },
  {
    username: 'kerrr',
    userDesc: 'Rod.Kerr@nouryon.com'
  },
  {
    username: 'petterm5',
    userDesc: 'Martin.Pettersson@nouryon.com'
  },
  {
    username: 'russoe',
    userDesc: 'eric.russo@nouryon.com'
  },
  {
    username: 'maiorans',
    userDesc: 'Samantha.Maiorana@nouryon.com'
  },
  {
    username: 'chenw20',
    userDesc: 'hui.chen2@nouryon.com'
  },
  {
    username: 'zhangb03',
    userDesc: 'baohua.zhang@nouryon.com'
  },
  {
    username: 'ReinertD',
    userDesc: 'Donald.Reinert@nouryon.com'
  },
  {
    username: 'jongenew',
    userDesc: 'Willem.Jongeneel@nouryon.com'
  },
  {
    username: 'anthoniw',
    userDesc: 'wisdom.anthonio@nouryon.com'
  },
  {
    username: 'kagohard',
    userDesc: 'Dennis.Kagohara@nouryon.com'
  },
  {
    username: 'varaskv1',
    userDesc: 'vipul.varaskar1@nouryon.com'
  },
  {
    username: 'adm_pazj1',
    userDesc: 'adm_pazj1'
  },
  {
    username: 'plc12',
    userDesc: 'PLC12@nouryon.com'
  },
  {
    username: 'adm_ottesenl',
    userDesc: 'adm_ottesenl'
  },
  {
    username: 'gunnerla',
    userDesc: 'anna.gunnerling@nouryon.com'
  },
  {
    username: 'hlavatmj',
    userDesc: 'Michael.Hlavaty@nouryon.com'
  },
  {
    username: 'ferrel11',
    userDesc: 'lauderci.ferreira@nouryon.com'
  },
  {
    username: 'wangc27',
    userDesc: 'can.wang@nouryon.com'
  },
  {
    username: 'guj12',
    userDesc: 'jianxing.gu@nouryon.com'
  },
  {
    username: 'heb',
    userDesc: 'Barry.He@nouryon.com'
  },
  {
    username: 'zhangk2',
    userDesc: 'Ken.Zhang@nouryon.com'
  },
  {
    username: 'nilssof2',
    userDesc: 'filip.nilsson@nouryon.com'
  },
  {
    username: 'bergmanb',
    userDesc: 'Bart.Bergman@nouryon.com'
  },
  {
    username: 'marinhoj',
    userDesc: 'joel.marinho@nouryon.com'
  },
  {
    username: 'adm_anderssou',
    userDesc: 'adm_anderssou'
  },
  {
    username: 'jagelanp',
    userDesc: 'Per.Jageland@nouryon.com'
  },
  {
    username: 'ximenesr',
    userDesc: 'reinaldo.ximenes@nouryon.com'
  },
  {
    username: 'srirataa',
    userDesc: 'anousack.sriratanakoul@nouryon.com'
  },
  {
    username: 'martinea1',
    userDesc: 'Antonio.Martinez1@nouryon.com'
  },
  {
    username: 'johanse6',
    userDesc: 'emma.johansson2@nouryon.com'
  },
  {
    username: 'goldammm',
    userDesc: 'mario.goldammer@nouryon.com'
  },
  {
    username: 'bennassz',
    userDesc: 'zakaria.bennasser@nouryon.com'
  },
  {
    username: 'matost1',
    userDesc: 'tais-matos@nouryon.com'
  },
  {
    username: 'oliveirw',
    userDesc: 'wesley.oliveira@nouryon.com'
  },
  {
    username: 'toklucy1',
    userDesc: 'yeliz.toklucu1@nouryon.com'
  },
  {
    username: 'soudhamv',
    userDesc: 'venkataprabhakar.soudham@nouryon.com'
  },
  {
    username: 'vilchesa',
    userDesc: 'anapaola.vilches@nouryon.com'
  },
  {
    username: 'johanssv',
    userDesc: 'viktor.kroona@nouryon.com'
  },
  {
    username: 'buckoj',
    userDesc: 'juergen.bucko@nouryon.com'
  },
  {
    username: 'paivat1',
    userDesc: 'thaiscamillode.paiva@nouryon.com'
  },
  {
    username: 'adm_kringh',
    userDesc: ''
  },
  {
    username: 'adm_bourgaua',
    userDesc: ''
  },
  {
    username: 'adm_russm',
    userDesc: ''
  },
  {
    username: 'wangf23',
    userDesc: 'Freddie.Wang@nouryon.com'
  },
  {
    username: 'filhoj',
    userDesc: 'Joao.Filho@nouryon.com'
  },
  {
    username: 'zhouy18',
    userDesc: 'yiyun.zhou1@nouryon.com'
  },
  {
    username: 'bang',
    userDesc: 'Guanghui.Ban@nouryon.com'
  },
  {
    username: 'olssone1',
    userDesc: 'eva.olsson1@nouryon.com'
  },
  {
    username: 'kringh',
    userDesc: 'holger.kring@nouryon.com'
  },
  {
    username: 'martinr1',
    userDesc: 'russ.martin@nouryon.com'
  },
  {
    username: 'rabeiet',
    userDesc: 'tarag.rabeie@nouryon.com'
  },
  {
    username: 'matssonr',
    userDesc: 'robin.matsson@nouryon.com'
  },
  {
    username: 'hedbergc',
    userDesc: 'cathrine.hedberg@nouryon.com'
  },
  {
    username: 'adm_emensc',
    userDesc: 'adm_emensc'
  },
  {
    username: 'Adm_yuyongr1',
    userDesc: 'Adm_yuyongr1'
  },
  {
    username: 'molandm1',
    userDesc: 'marcus.molander@nouryon.com'
  },
  {
    username: 'overcasa',
    userDesc: 'Adam.Overcash@nouryon.com'
  },
  {
    username: 'botelhob',
    userDesc: 'bruna.botelho@nouryon.com'
  },
  {
    username: 'adm_heimannm',
    userDesc: 'adm_heimannm'
  },
  {
    username: 'adm_hollemar',
    userDesc: ''
  },
  {
    username: 'cabralej',
    userDesc: 'Jessica.Cabrales@nouryon.com'
  },
  {
    username: 'penteacm',
    userDesc: 'Cintia.Penteado@nouryon.com'
  },
  {
    username: 'backluna',
    userDesc: 'agneta.backlund@nouryon.com'
  },
  {
    username: 'adm_silvar5',
    userDesc: 'adm_silvar5'
  },
  {
    username: 'willifop',
    userDesc: 'Paul.Williford@nouryon.com'
  },
  {
    username: 'smithg10',
    userDesc: 'gregory.smith@nouryon.com'
  },
  {
    username: 'sizifrei',
    userDesc: ''
  },
  {
    username: 'adm_svanluna1',
    userDesc: ''
  },
  {
    username: 'tidenm',
    userDesc: 'michael.tiden@nouryon.com'
  },
  {
    username: 'adm_onderwak',
    userDesc: 'adm_onderwak'
  },
  {
    username: 'adm_youngk1',
    userDesc: 'adm_youngk1'
  },
  {
    username: 'JinY',
    userDesc: 'Yonghuan.Jin@nouryon.com'
  },
  {
    username: 'tst_basc_epi_23',
    userDesc: ''
  },
  {
    username: 'jumiskoj',
    userDesc: 'Janne.Jumisko@nouryon.com'
  },
  {
    username: 'lindegar',
    userDesc: 'Rasmus.Lindegaard@nouryon.com'
  },
  {
    username: 'sont',
    userDesc: 'Theo.vanSon@nouryon.com'
  },
  {
    username: 'nc3240',
    userDesc: 'Jaap.Mulder@nouryon.com'
  },
  {
    username: 'oostendp',
    userDesc: 'Patrick.vanOostende@nouryon.com'
  },
  {
    username: 'artsm',
    userDesc: 'merel.arts@nouryon.com'
  },
  {
    username: 'stokesn',
    userDesc: 'nicholas.stokes@nouryon.com'
  },
  {
    username: 'schoora',
    userDesc: 'Alex.vanderSchoor@nouryon.com'
  },
  {
    username: 'ramonedo',
    userDesc: 'Oscar.Ramoneda@nouryon.com'
  },
  {
    username: 'hogstadk',
    userDesc: 'Kay.Hogstad@nouryon.com'
  },
  {
    username: 'haastref',
    userDesc: 'Frank.vanHaastrecht@nouryon.com'
  },
  {
    username: 'JungschJ',
    userDesc: 'Jeroen.Jungschlaeger@nouryon.com'
  },
  {
    username: 'hildnera',
    userDesc: 'Andreas.Hildner@nouryon.com'
  },
  {
    username: 'jansent2',
    userDesc: 'tom.jansen@nouryon.com'
  },
  {
    username: 'nc2768',
    userDesc: 'John.vanderLinden@nouryon.com'
  },
  {
    username: 'melchcar',
    userDesc: 'Carlos.Melchor@nouryon.com'
  },
  {
    username: 'fureyd',
    userDesc: 'daniel.furey@nouryon.com'
  },
  {
    username: 'Sundellj',
    userDesc: 'johan.sundell@nouryon.com'
  },
  {
    username: 'remism',
    userDesc: 'Martin.Remis@nouryon.com'
  },
  {
    username: 'corderm',
    userDesc: 'Mauro.Cordero@nouryon.com'
  },
  {
    username: 'Pinedaj1',
    userDesc: 'joel.pineda@nouryon.com'
  },
  {
    username: 'vazqued2',
    userDesc: 'dulce.vazquez@nouryon.com'
  },
  {
    username: 'adm_mullerj1',
    userDesc: 'adm_mullerj1'
  },
  {
    username: 'adm_vellies1',
    userDesc: 'adm_vellies1'
  },
  {
    username: 'sjoestrj',
    userDesc: 'jerker.sjoestrand@nouryon.com'
  },
  {
    username: 'deraada',
    userDesc: 'Alex.deRaad@nouryon.com'
  },
  {
    username: 'yanezj',
    userDesc: 'Jessica.Yanez@nouryon.com'
  },
  {
    username: 'mazukir',
    userDesc: 'raymara.mazuki@nouryon.com'
  },
  {
    username: 'savietoi',
    userDesc: 'isabella.savieto@nouryon.com'
  },
  {
    username: 'domingud',
    userDesc: 'donajiberenice.dominguez@nouryon.com'
  },
  {
    username: 'bc1238',
    userDesc: 'Paulus.Dijkgraaf@nouryon.com'
  },
  {
    username: 'adm_jedeniusf',
    userDesc: 'adm_jedeniusf'
  },
  {
    username: 'Boonele',
    userDesc: 'Leendert.Boone@nouryon.com'
  },
  {
    username: 'IVA_Test',
    userDesc: ''
  },
  {
    username: 'merciem',
    userDesc: 'Michel.Mercier@nouryon.com'
  },
  {
    username: 'anna',
    userDesc: 'Anna.Kron@nouryon.com'
  },
  {
    username: 'aguiarw',
    userDesc: 'Wiltaker.Aguiar@nouryon.com'
  },
  {
    username: 'sumanr',
    userDesc: 'sumanr'
  },
  {
    username: 'ojerborj',
    userDesc: 'ojerborj'
  },
  {
    username: 'adm_lindqvs1',
    userDesc: 'adm_lindqvs1'
  },
  {
    username: 'barkleya',
    userDesc: 'adrian.barkley@nouryon.com'
  },
  {
    username: 'adm_veent',
    userDesc: 'adm_veent'
  },
  {
    username: 'thonellj',
    userDesc: 'jonas.thonell@nouryon.com'
  },
  {
    username: 'cooketm',
    userDesc: 'cooketm'
  },
  {
    username: 'fenneljk',
    userDesc: 'fenneljk'
  },
  {
    username: 'davisj4',
    userDesc: 'davisj4'
  },
  {
    username: 'rodrigt1',
    userDesc: 'rodrigt1'
  },
  {
    username: 'dresnebs',
    userDesc: 'dresnebs'
  },
  {
    username: 'avelinom',
    userDesc: 'avelinom'
  },
  {
    username: 'D60Test1',
    userDesc: 'D60Test1'
  },
  {
    username: 'camposm',
    userDesc: 'camposm'
  },
  {
    username: 'santosel',
    userDesc: 'santosel'
  },
  {
    username: 'gigom',
    userDesc: 'gigom'
  },
  {
    username: 'wastbjorkl',
    userDesc: 'wastbjorkl'
  },
  {
    username: 'zampierj',
    userDesc: 'zampierj'
  },
  {
    username: 'zonghl',
    userDesc: 'zonghl'
  },
  {
    username: 'nc2802',
    userDesc: 'nc2802'
  },
  {
    username: 'renr',
    userDesc: 'renr'
  },
  {
    username: 'ganbatd1',
    userDesc: 'ganbatd1'
  },
  {
    username: 'villard',
    userDesc: 'villard'
  },
  {
    username: 'brabert',
    userDesc: 'brabert'
  },
  {
    username: 'santom13',
    userDesc: 'santom13'
  },
  {
    username: 'ahlboml1',
    userDesc: 'ahlboml1'
  },
  {
    username: 'lindmarh',
    userDesc: 'lindmarh'
  },
  {
    username: 'ferrarel',
    userDesc: 'ferrarel'
  },
  {
    username: 'carvale2',
    userDesc: 'carvale2'
  },
  {
    username: 'bowdenb',
    userDesc: 'bowdenb'
  },
  {
    username: 'huffmanj1',
    userDesc: 'huffmanj1'
  },
  {
    username: 'correc02',
    userDesc: 'correc02'
  },
  {
    username: 'bispoe',
    userDesc: 'bispoe'
  },
  {
    username: 'recktenj',
    userDesc: 'recktenj'
  },
  {
    username: 'OliveiG2',
    userDesc: 'OliveiG2'
  },
  {
    username: 'fraleyj',
    userDesc: 'fraleyj'
  },
  {
    username: 'hokkas',
    userDesc: 'hokkas'
  },
  {
    username: 'tonetr',
    userDesc: 'tonetr'
  },
  {
    username: 'gilletg',
    userDesc: 'gilletg'
  },
  {
    username: 'desnoyem',
    userDesc: 'desnoyem'
  },
  {
    username: 'stackaj',
    userDesc: 'stackaj'
  },
  {
    username: 'HansenT',
    userDesc: 'HansenT'
  },
  {
    username: 'nancet',
    userDesc: 'nancet'
  },
  {
    username: 'perassov',
    userDesc: 'perassov'
  },
  {
    username: 'souzad',
    userDesc: 'souzad'
  },
  {
    username: 'duarter1',
    userDesc: 'duarter1'
  },
  {
    username: 'oliver19',
    userDesc: 'oliver19'
  },
  {
    username: 'krielh1',
    userDesc: 'krielh1'
  },
  {
    username: 'saidstaa',
    userDesc: 'saidstaa'
  },
  {
    username: 'knappk',
    userDesc: 'knappk'
  },
  {
    username: 'bendixb',
    userDesc: 'bendixb'
  },
  {
    username: 'corporae',
    userDesc: 'corporae'
  },
  {
    username: 'Adm_matsonm1',
    userDesc: 'Adm_matsonm1'
  },
  {
    username: 'xiaa',
    userDesc: 'xiaa'
  },
  {
    username: 'spychkn1',
    userDesc: 'spychkn1'
  },
  {
    username: 'lawsbw',
    userDesc: 'lawsbw'
  },
  {
    username: 'aldenme',
    userDesc: 'aldenme'
  },
  {
    username: 'wychers',
    userDesc: 'wychers'
  },
  {
    username: 'herrone',
    userDesc: 'herrone'
  },
  {
    username: 'mcdanibr',
    userDesc: 'mcdanibr'
  },
  {
    username: 'Townsef',
    userDesc: 'Townsef'
  },
  {
    username: 'abadj',
    userDesc: 'abadj'
  },
  {
    username: 'carpentc',
    userDesc: 'carpentc'
  },
  {
    username: 'bluntx',
    userDesc: 'bluntx'
  },
  {
    username: 'SotoVegS',
    userDesc: 'SotoVegS'
  },
  {
    username: 'arnbergl',
    userDesc: 'arnbergl'
  },
  {
    username: 'adm_calandrm1',
    userDesc: 'adm_calandrm1'
  },
  {
    username: 'adm_starnaum',
    userDesc: ''
  },
  {
    username: 'aljundis',
    userDesc: 'aljundis'
  },
  {
    username: 'sawarynk',
    userDesc: 'sawarynk'
  },
  {
    username: 'gervasij',
    userDesc: 'julien.gervasi@nouryon.com'
  },
  {
    username: 'oliveirr',
    userDesc: 'oliveirr'
  },
  {
    username: 'smedmans',
    userDesc: 'smedmans'
  },
  {
    username: 'rangela',
    userDesc: 'rangela'
  },
  {
    username: 'adm_takeov',
    userDesc: 'adm_takeov'
  },
  {
    username: 'tst_BASC_EPI_7',
    userDesc: 'tst_BASC_EPI_7'
  },
  {
    username: 'tst_BASC_EPI_24',
    userDesc: 'tst_BASC_EPI_24'
  },
  {
    username: 'jiangl2',
    userDesc: 'jiangl2'
  },
  {
    username: 'shia2',
    userDesc: 'shia2'
  },
  {
    username: 'adm_rodrigg31',
    userDesc: 'adm_rodrigg31'
  },
  {
    username: 'larsso1h',
    userDesc: 'larsso1h'
  },
  {
    username: 'liy13',
    userDesc: 'liy13'
  },
  {
    username: 'xum2',
    userDesc: 'xum2'
  },
  {
    username: 'olssona6',
    userDesc: 'olssona6'
  },
  {
    username: 'controlm',
    userDesc: 'controlm'
  },
  {
    username: 'adm_raiderj',
    userDesc: 'adm_raiderj'
  },
  {
    username: 'menzelk',
    userDesc: 'menzelk'
  },
  {
    username: 'edlundp2',
    userDesc: 'edlundp2'
  },
  {
    username: 'bergluj1',
    userDesc: 'bergluj1'
  },
  {
    username: 'rybankp1',
    userDesc: 'rybankp1'
  },
  {
    username: 'PlC1',
    userDesc: 'PlC1'
  },
  {
    username: 'adm_gervasij',
    userDesc: ''
  },
  {
    username: 'fua1',
    userDesc: 'fua1'
  },
  {
    username: 'yamilot1',
    userDesc: 'yamilot1'
  },
  {
    username: 'kuangx',
    userDesc: 'kuangx'
  },
  {
    username: 'poerhoh1',
    userDesc: 'poerhoh1'
  },
  {
    username: 'griffonj',
    userDesc: 'griffonj'
  },
  {
    username: 'davidsv1',
    userDesc: 'davidsv1'
  },
  {
    username: 'andreah2',
    userDesc: 'andreah2'
  },
  {
    username: 'wiltsr',
    userDesc: 'wiltsr'
  },
  {
    username: 'bjoerkr',
    userDesc: 'bjoerkr'
  },
  {
    username: 'radoval1',
    userDesc: 'radoval1'
  },
  {
    username: 'juniord',
    userDesc: 'juniord'
  },
  {
    username: 'perssoa8',
    userDesc: 'perssoa8'
  },
  {
    username: 'holmstrd',
    userDesc: 'holmstrd'
  },
  {
    username: 'josefsg1',
    userDesc: 'josefsg1'
  },
  {
    username: 'carpenjk',
    userDesc: 'carpenjk'
  },
  {
    username: 'davistl',
    userDesc: 'davistl'
  },
  {
    username: 'raolj',
    userDesc: 'raolj'
  },
  {
    username: 'vanderg',
    userDesc: 'vanderg'
  },
  {
    username: 'spychkan',
    userDesc: 'spychkan'
  },
  {
    username: 'overgaam',
    userDesc: 'overgaam'
  },
  {
    username: 'willir13',
    userDesc: 'willir13'
  },
  {
    username: 'smiths8',
    userDesc: 'smiths8'
  },
  {
    username: 'jacksoda',
    userDesc: 'jacksoda'
  },
  {
    username: 'lindberc',
    userDesc: 'lindberc'
  },
  {
    username: 'reisa',
    userDesc: 'reisa'
  },
  {
    username: 'maekite1',
    userDesc: 'maekite1'
  },
  {
    username: 'turessoe',
    userDesc: 'turessoe'
  },
  {
    username: 'harrells',
    userDesc: 'harrells'
  },
  {
    username: 'mockbemw',
    userDesc: 'mockbemw'
  },
  {
    username: 'garciao',
    userDesc: 'garciao'
  },
  {
    username: 'romoam',
    userDesc: 'romoam'
  },
  {
    username: 'sobrinhr',
    userDesc: 'sobrinhr'
  },
  {
    username: 'jacobssa',
    userDesc: 'jacobssa'
  },
  {
    username: 'dahlj',
    userDesc: 'dahlj'
  },
  {
    username: 'rezaeim',
    userDesc: 'rezaeim'
  },
  {
    username: 'ashd1',
    userDesc: 'ashd1'
  },
  {
    username: 'qasemy1',
    userDesc: 'qasemy1'
  },
  {
    username: 'gustavss',
    userDesc: 'gustavss'
  },
  {
    username: 'fisherde',
    userDesc: 'fisherde'
  },
  {
    username: 'tieua',
    userDesc: 'tieua'
  },
  {
    username: 'barriom',
    userDesc: 'barriom'
  },
  {
    username: 'johnsse3',
    userDesc: 'johnsse3'
  },
  {
    username: 'olofsss2',
    userDesc: 'olofsss2'
  },
  {
    username: 'tand6',
    userDesc: 'tand6'
  },
  {
    username: 'mckaypp',
    userDesc: 'mckaypp'
  },
  {
    username: 'oliveie8',
    userDesc: 'oliveie8'
  },
  {
    username: 'kraamerb',
    userDesc: 'kraamerb'
  },
  {
    username: 'freyj1',
    userDesc: 'freyj1'
  },
  {
    username: 'martens2',
    userDesc: 'martens2'
  },
  {
    username: 'vikenbc1',
    userDesc: 'vikenbc1'
  },
  {
    username: 'reedc1',
    userDesc: 'reedc1'
  },
  {
    username: 'kanskigr',
    userDesc: 'kanskigr'
  },
  {
    username: 'hagenh',
    userDesc: 'hagenh'
  },
  {
    username: 'melandl1',
    userDesc: 'melandl1'
  },
  {
    username: 'rayw',
    userDesc: 'rayw'
  },
  {
    username: 'beckmaf2',
    userDesc: 'beckmaf2'
  },
  {
    username: 'alvesm2',
    userDesc: 'alvesm2'
  },
  {
    username: 'grelssow',
    userDesc: 'grelssow'
  },
  {
    username: 'melandee',
    userDesc: 'melandee'
  },
  {
    username: 'xug14',
    userDesc: 'xug14'
  },
  {
    username: 'wahlqvd1',
    userDesc: 'wahlqvd1'
  },
  {
    username: 'rigolint',
    userDesc: 'rigolint'
  },
  {
    username: 'hajla',
    userDesc: 'hajla'
  },
  {
    username: 'adm_maroses',
    userDesc: 'adm_maroses'
  },
  {
    username: 'hernanvc',
    userDesc: 'hernanvc'
  },
  {
    username: 'hed1',
    userDesc: 'hed1'
  },
  {
    username: 'sappj',
    userDesc: 'sappj'
  },
  {
    username: 'clarkt',
    userDesc: 'clarkt'
  },
  {
    username: 'bauerv',
    userDesc: 'bauerv'
  },
  {
    username: 'farmerw1',
    userDesc: 'farmerw1'
  },
  {
    username: 'CRUZD',
    userDesc: 'CRUZD'
  },
  {
    username: 'gonzalp',
    userDesc: 'gonzalp'
  },
  {
    username: 'moraesm2',
    userDesc: 'moraesm2'
  },
  {
    username: 'menardr',
    userDesc: 'menardr'
  },
  {
    username: 'sanchezr1',
    userDesc: 'sanchezr1'
  },
  {
    username: 'adm_alamia',
    userDesc: 'adm_alamia'
  },
  {
    username: 'smithmi1',
    userDesc: 'smithmi1'
  },
  {
    username: 'olayiwoo',
    userDesc: 'olayiwoo'
  },
  {
    username: 'royd2',
    userDesc: 'royd2'
  },
  {
    username: 'zumelm',
    userDesc: 'zumelm'
  },
  {
    username: 'venegasf',
    userDesc: 'venegasf'
  },
  {
    username: 'venturab',
    userDesc: 'venturab'
  },
  {
    username: 'kretzerj',
    userDesc: 'kretzerj'
  },
  {
    username: 'kuehneg',
    userDesc: 'kuehneg'
  },
  {
    username: 'crockerk',
    userDesc: 'crockerk'
  },
  {
    username: 'hammonsj',
    userDesc: 'hammonsj'
  },
  {
    username: 'benedicj',
    userDesc: 'benedicj'
  },
  {
    username: 'ribeiro1',
    userDesc: 'ribeiro1'
  },
  {
    username: 'pateld01',
    userDesc: 'pateld01'
  },
  {
    username: 'hanssop1',
    userDesc: 'hanssop1'
  },
  {
    username: 'watsonp1',
    userDesc: 'watsonp1'
  },
  {
    username: 'kjeldsj1',
    userDesc: 'jonas.kjeldsen@nouryon.com'
  },
  {
    username: 'adm_toereska',
    userDesc: ''
  },
  {
    username: 'hernana6',
    userDesc: 'hernana6'
  },
  {
    username: 'sternis',
    userDesc: 'sternis'
  },
  {
    username: 'costap11',
    userDesc: 'costap11'
  },
  {
    username: 'dalesgil',
    userDesc: 'dalesgil'
  },
  {
    username: 'jovics',
    userDesc: 'jovics'
  },
  {
    username: 'adm_harrells',
    userDesc: 'adm_harrells'
  },
  {
    username: 'sus1',
    userDesc: 'sus1'
  },
  {
    username: 'dafonsel',
    userDesc: 'dafonsel'
  },
  {
    username: 'SchroyenL',
    userDesc: 'SchroyenL'
  },
  {
    username: 'yttervis',
    userDesc: 'yttervis'
  },
  {
    username: 'boerjej1',
    userDesc: 'boerjej1'
  },
  {
    username: 'BURKEB02',
    userDesc: 'BURKEB02'
  },
  {
    username: 'wassenih',
    userDesc: 'wassenih'
  },
  {
    username: 'baldoinl',
    userDesc: 'baldoinl'
  },
  {
    username: 'meconeh',
    userDesc: 'meconeh'
  },
  {
    username: 'marn',
    userDesc: 'Maria.Anger@nouryon.com'
  },
  {
    username: 'mber',
    userDesc: 'Maria.Berglund@nouryon.com'
  },
  {
    username: 'toel',
    userDesc: 'Thomas.Elfving@nouryon.com'
  },
  {
    username: 'eriksso1',
    userDesc: 'Marcus.Eriksson@nouryon.com'
  },
  {
    username: 'hake',
    userDesc: 'Hakan.Edin@nouryon.com'
  },
  {
    username: 'peda',
    userDesc: 'Per.Dahlback@nouryon.com'
  },
  {
    username: 'mobe',
    userDesc: 'Monica.Bergstrom@nouryon.com'
  },
  {
    username: 'xanbr',
    userDesc: 'Anders.Broman@nouryon.com'
  },
  {
    username: 'gued',
    userDesc: 'Gunnar.Edlund@nouryon.com'
  },
  {
    username: 'kali',
    userDesc: 'Katarina.Lindmark@nouryon.com'
  },
  {
    username: 'risj',
    userDesc: 'Richard.Sjoquist@nouryon.com'
  },
  {
    username: 'KTP_test',
    userDesc: 'KTP_test'
  },
  {
    username: 'sundbev2',
    userDesc: 'sundbev2'
  },
  {
    username: 'rydbers1',
    userDesc: 'rydbers1'
  },
  {
    username: 'burstrok',
    userDesc: 'burstrok'
  },
  {
    username: 'yngvessa',
    userDesc: 'yngvessa'
  },
  {
    username: 'spijkmanf',
    userDesc: 'spijkmanf'
  },
  {
    username: 'zubeirl',
    userDesc: 'zubeirl'
  },
  {
    username: 'venemanr',
    userDesc: 'venemanr'
  },
  {
    username: 'bartj',
    userDesc: 'bartj'
  },
  {
    username: 'haasea',
    userDesc: 'haasea'
  },
  {
    username: 'gustavd2',
    userDesc: 'gustavd2'
  },
  {
    username: 'rayfielj',
    userDesc: 'rayfielj'
  },
  {
    username: 'schneidj',
    userDesc: 'schneidj'
  },
  {
    username: 'adm_andersh6',
    userDesc: 'adm_andersh6'
  },
  {
    username: 'molenm',
    userDesc: 'molenm'
  },
  {
    username: 'porterm1',
    userDesc: 'porterm1'
  },
  {
    username: 'roeslerk',
    userDesc: 'roeslerk'
  },
  {
    username: 'alis15',
    userDesc: 'alis15'
  },
  {
    username: 'manlana',
    userDesc: 'manlana'
  },
  {
    username: 'hahnmena',
    userDesc: 'hahnmena'
  },
  {
    username: 'fonzor',
    userDesc: 'fonzor'
  },
  {
    username: 'mby',
    userDesc: 'mby'
  },
  {
    username: 'tiant2',
    userDesc: 'tiant2'
  },
  {
    username: 'lih30',
    userDesc: 'lih30'
  },
  {
    username: 'johana12',
    userDesc: 'johana12'
  },
  {
    username: 'danielso',
    userDesc: 'danielso'
  },
  {
    username: 'mortensj',
    userDesc: 'mortensj'
  },
  {
    username: 'heimenm',
    userDesc: 'heimenm'
  },
  {
    username: 'fornaria',
    userDesc: 'fornaria'
  },
  {
    username: 'TGC_Test',
    userDesc: 'TGC_Test'
  },
  {
    username: 'BC1349',
    userDesc: 'BC1349'
  },
  {
    username: 'arceneg',
    userDesc: 'arceneg'
  },
  {
    username: 'boscofir',
    userDesc: 'boscofir'
  },
  {
    username: 'kolmo',
    userDesc: 'kolmo'
  },
  {
    username: 'bc1197',
    userDesc: 'bc1197'
  },
  {
    username: 'paivaa',
    userDesc: 'paivaa'
  },
  {
    username: 'caetanm1',
    userDesc: 'caetanm1'
  },
  {
    username: 'brdara',
    userDesc: 'brdara'
  },
  {
    username: 'andersr7',
    userDesc: 'andersr7'
  },
  {
    username: 'TCG_TEST2',
    userDesc: 'TCG_TEST2'
  },
  {
    username: 'mccrarym',
    userDesc: 'mccrarym'
  },
  {
    username: 'edlundm',
    userDesc: 'edlundm'
  },
  {
    username: 'chengk',
    userDesc: 'chengk'
  },
  {
    username: 'kaueraus',
    userDesc: 'kaueraus'
  },
  {
    username: 'bc1342',
    userDesc: 'bc1342'
  },
  {
    username: 'avelarj',
    userDesc: 'avelarj'
  },
  {
    username: 'fariac1',
    userDesc: 'fariac1'
  },
  {
    username: 'hollm',
    userDesc: 'hollm'
  },
  {
    username: 'oliveicc',
    userDesc: 'oliveicc'
  },
  {
    username: 'alooa',
    userDesc: 'alooa'
  },
  {
    username: 'BC1317',
    userDesc: 'BC1317'
  },
  {
    username: 'simionaj',
    userDesc: 'simionaj'
  },
  {
    username: 'edstroea',
    userDesc: 'edstroea'
  },
  {
    username: 'eliassoj',
    userDesc: 'eliassoj'
  },
  {
    username: 'truexr01',
    userDesc: 'truexr01'
  },
  {
    username: 'adm_kjeldsenj',
    userDesc: 'adm_kjeldsenj'
  },
  {
    username: 'sutherlj',
    userDesc: 'sutherlj'
  },
  {
    username: 'adm_chenm20',
    userDesc: 'adm_chenm20'
  },
  {
    username: 'alexands',
    userDesc: 'alexands'
  },
  {
    username: 'sorensj',
    userDesc: 'sorensj'
  },
  {
    username: 'buntonb',
    userDesc: 'buntonb'
  },
  {
    username: 'spreitj',
    userDesc: 'spreitj'
  },
  {
    username: 'olsonr',
    userDesc: 'olsonr'
  },
  {
    username: 'gonzale3',
    userDesc: 'gonzale3'
  },
  {
    username: 'rutkowe',
    userDesc: 'rutkowe'
  },
  {
    username: 'watsonll',
    userDesc: 'watsonll'
  },
  {
    username: 'kerrm',
    userDesc: 'kerrm'
  },
  {
    username: 'shiseny',
    userDesc: 'shiseny'
  },
  {
    username: 'overs',
    userDesc: 'overs'
  },
  {
    username: 'svelandj',
    userDesc: 'svelandj'
  },
  {
    username: 'adm_padmanar',
    userDesc: ''
  },
  {
    username: 'adm_wallenia',
    userDesc: 'adm_wallenia'
  },
  {
    username: 'heldebya',
    userDesc: 'heldebya'
  },
  {
    username: 'lindstj4',
    userDesc: 'lindstj4'
  },
  {
    username: 'baudins',
    userDesc: 'baudins'
  },
  {
    username: 'whitetr',
    userDesc: 'whitetr'
  },
  {
    username: 'batch_test',
    userDesc: 'batch_test'
  },
  {
    username: 'marc.bore',
    userDesc: 'marc.bore@nouryon.com'
  },
  {
    username: 'asol',
    userDesc: 'asol'
  },
  {
    username: 'ingrams1',
    userDesc: 'ingrams1'
  },
  {
    username: 'adm_vazqued2',
    userDesc: ''
  },
  {
    username: 'caok4',
    userDesc: 'caok4'
  },
  {
    username: 'viksteh2',
    userDesc: 'viksteh2'
  },
  {
    username: 'wolfe',
    userDesc: 'wolfe'
  },
  {
    username: 'heni',
    userDesc: 'heni'
  },
  {
    username: 'tony',
    userDesc: 'tony'
  },
  {
    username: 'karlsss1',
    userDesc: 'karlsss1'
  },
  {
    username: 'tst_mbcvn066',
    userDesc: 'tst_mbcvn066'
  },
  {
    username: 'kara',
    userDesc: 'kara'
  },
  {
    username: 'erikssomi',
    userDesc: 'erikssomi'
  },
  {
    username: 'perrycs',
    userDesc: 'perrycs'
  },
  {
    username: 'banksm',
    userDesc: 'banksm'
  },
  {
    username: 'hughesga',
    userDesc: 'hughesga'
  },
  {
    username: 'bedforda',
    userDesc: 'bedforda'
  },
  {
    username: 'curlincr',
    userDesc: 'curlincr'
  },
  {
    username: 'normann',
    userDesc: 'normann'
  },
  {
    username: 'TribbleS',
    userDesc: 'TribbleS'
  },
  {
    username: 'borem',
    userDesc: 'borem'
  },
  {
    username: 'whiterj',
    userDesc: 'whiterj'
  },
  {
    username: 'desrochd',
    userDesc: 'desrochd'
  },
  {
    username: 'magalioy',
    userDesc: 'magalioy'
  },
  {
    username: 'garciam1',
    userDesc: 'garciam1'
  },
  {
    username: 'pittmac1',
    userDesc: 'pittmac1'
  },
  {
    username: 'mitchesb',
    userDesc: 'mitchesb'
  },
  {
    username: 'hernanrl',
    userDesc: 'hernanrl'
  },
  {
    username: 'thesmanr',
    userDesc: 'thesmanr'
  },
  {
    username: 'mowldsk',
    userDesc: 'mowldsk'
  },
  {
    username: 'westmanl',
    userDesc: 'westmanl'
  },
  {
    username: 'donnerms',
    userDesc: 'donnerms'
  },
  {
    username: 'luciafer',
    userDesc: 'luciafer'
  },
  {
    username: 'SCS',
    userDesc: 'SCS'
  },
  {
    username: 'neesh',
    userDesc: 'neesh'
  },
  {
    username: 'adm_antonic1',
    userDesc: 'adm_antonic1'
  },
  {
    username: 'heekec',
    userDesc: 'heekec'
  },
  {
    username: 'ipemar',
    userDesc: 'ipemar'
  },
  {
    username: 'wallmeys',
    userDesc: 'wallmeys'
  },
  {
    username: 'krsu',
    userDesc: 'krsu'
  },
  {
    username: 'bub1',
    userDesc: 'bub1'
  },
  {
    username: 'lundstrj',
    userDesc: 'lundstrj'
  },
  {
    username: 'adm_ojerborj',
    userDesc: 'adm_ojerborj'
  },
  {
    username: 'martia16',
    userDesc: 'martia16'
  },
  {
    username: 'groenvab',
    userDesc: 'groenvab'
  },
  {
    username: 'svc_EPIHist_FabSys',
    userDesc: 'svc_EPIHist_FabSys'
  },
  {
    username: 'veldermy',
    userDesc: 'veldermy'
  },
  {
    username: 'newmanb',
    userDesc: 'newmanb'
  },
  {
    username: 'adm_costap11',
    userDesc: 'adm_costap11'
  },
  {
    username: 'djervm1',
    userDesc: 'djervm1'
  },
  {
    username: 'florence',
    userDesc: 'florence'
  },
  {
    username: 'oliveirl',
    userDesc: 'oliveirl'
  },
  {
    username: 'OonincxJ',
    userDesc: 'OonincxJ'
  },
  {
    username: 'tst_hp',
    userDesc: 'tst_hp'
  },
  {
    username: 'anst',
    userDesc: 'anst'
  },
  {
    username: 'padmanar',
    userDesc: 'padmanar'
  },
  {
    username: 'ramgolar',
    userDesc: 'ramgolar'
  },
  {
    username: 'henriksn',
    userDesc: 'henriksn'
  },
  {
    username: 'grinsvej',
    userDesc: 'grinsvej'
  },
  {
    username: 'hillmerl',
    userDesc: 'hillmerl'
  },
  {
    username: 'mobleym',
    userDesc: 'mobleym'
  },
  {
    username: 'landgref',
    userDesc: 'landgref'
  },
  {
    username: 'chenm20',
    userDesc: 'chenm20'
  },
  {
    username: 'wartmanm',
    userDesc: 'wartmanm'
  },
  {
    username: 'adm_choudhud',
    userDesc: 'adm_choudhud'
  },
  {
    username: 'xuq',
    userDesc: 'xuq'
  },
  {
    username: 'kantze',
    userDesc: 'kantze'
  },
  {
    username: 'ThelandE',
    userDesc: 'ThelandE'
  },
  {
    username: 'xiel1',
    userDesc: 'xiel1'
  },
  {
    username: 'chend',
    userDesc: 'chend'
  },
  {
    username: 'sauerm',
    userDesc: 'sauerm'
  },
  {
    username: 'ptthoffr',
    userDesc: 'ptthoffr'
  },
  {
    username: 'skiepkog',
    userDesc: 'skiepkog'
  },
  {
    username: 'dehmell',
    userDesc: 'dehmell'
  },
  {
    username: 'laseckib',
    userDesc: 'laseckib'
  },
  {
    username: 'mzykr',
    userDesc: 'mzykr'
  },
  {
    username: 'bachnern',
    userDesc: 'bachnern'
  },
  {
    username: 'kuredae',
    userDesc: 'kuredae'
  },
  {
    username: 'rouhanij',
    userDesc: 'rouhanij'
  },
  {
    username: 'bartoloz',
    userDesc: 'bartoloz'
  },
  {
    username: 'greitzkw',
    userDesc: 'greitzkw'
  },
  {
    username: 'piwla',
    userDesc: 'piwla'
  },
  {
    username: 'jasv',
    userDesc: 'jasv'
  },
  {
    username: 'adm_pintoagj',
    userDesc: 'adm_pintoagj'
  },
  {
    username: 'ehlersi',
    userDesc: 'ehlersi'
  },
  {
    username: 'mathiek',
    userDesc: 'mathiek'
  },
  {
    username: 'elnesh',
    userDesc: 'elnesh'
  },
  {
    username: 'paulom',
    userDesc: 'paulom'
  },
  {
    username: 'burmant',
    userDesc: 'burmant'
  },
  {
    username: 'patriks1',
    userDesc: 'patriks1'
  },
  {
    username: 'AfU1',
    userDesc: 'AfU1'
  },
  {
    username: 'chl',
    userDesc: 'chl'
  },
  {
    username: 'wadsteda',
    userDesc: 'wadsteda'
  },
  {
    username: 'ferreii1',
    userDesc: 'ferreii1'
  },
  {
    username: 'naesetr',
    userDesc: 'naesetr'
  },
  {
    username: 'osborned',
    userDesc: 'osborned'
  },
  {
    username: 'tegerotg',
    userDesc: 'tegerotg'
  },
  {
    username: 'wittmann',
    userDesc: 'wittmann'
  },
  {
    username: 'xmisa',
    userDesc: 'xmisa'
  },
  {
    username: 'adm_crutchm',
    userDesc: 'adm_crutchm'
  },
  {
    username: 'holmau',
    userDesc: 'holmau'
  },
  {
    username: 'bruemmej',
    userDesc: 'bruemmej'
  },
  {
    username: 'odueyuns',
    userDesc: 'odueyuns'
  },
  {
    username: 'bremerd',
    userDesc: 'bremerd'
  },
  {
    username: 'adm_jugan',
    userDesc: ''
  },
  {
    username: 'EPI_AUG',
    userDesc: 'EPI_AUG'
  },
  {
    username: 'TrepaniJ',
    userDesc: 'TrepaniJ'
  },
  {
    username: 'sghairim',
    userDesc: 'sghairim'
  },
  {
    username: 'jacobsec',
    userDesc: 'jacobsec'
  },
  {
    username: 'johansi2',
    userDesc: 'johansi2'
  },
  {
    username: 'stno',
    userDesc: 'stno'
  },
  {
    username: 'MinI',
    userDesc: 'MinI'
  },
  {
    username: 'anderj13',
    userDesc: 'anderj13'
  },
  {
    username: 'silvav2',
    userDesc: 'silvav2'
  },
  {
    username: 'lakaevm2',
    userDesc: 'lakaevm2'
  },
  {
    username: 'stwi',
    userDesc: 'stwi'
  },
  {
    username: 'rodrigi3',
    userDesc: 'rodrigi3'
  },
  {
    username: 'santosj',
    userDesc: 'santosj'
  },
  {
    username: 'barnardw',
    userDesc: 'barnardw'
  },
  {
    username: 'gerretzj',
    userDesc: 'gerretzj'
  },
  {
    username: 'nc3441',
    userDesc: 'nc3441'
  },
  {
    username: 'whitlodf',
    userDesc: 'whitlodf'
  },
  {
    username: 'maatj',
    userDesc: 'maatj'
  },
  {
    username: 'adm_ciochind',
    userDesc: 'adm_ciochind'
  },
  {
    username: 'rigoloc1',
    userDesc: 'rigoloc1'
  },
  {
    username: 'detervie',
    userDesc: 'detervie'
  },
  {
    username: 'toernqvl',
    userDesc: 'toernqvl'
  },
  {
    username: 'nietiedw',
    userDesc: 'nietiedw'
  },
  {
    username: 'bloemenr',
    userDesc: 'bloemenr'
  },
  {
    username: 'Maand',
    userDesc: 'Maand'
  },
  {
    username: 'lajo',
    userDesc: 'lajo'
  },
  {
    username: 'hooksd',
    userDesc: 'hooksd'
  },
  {
    username: 'xbesj',
    userDesc: 'xbesj'
  },
  {
    username: 'johanssd',
    userDesc: 'johanssd'
  },
  {
    username: 'scholtw1',
    userDesc: 'scholtw1'
  },
  {
    username: 'hano',
    userDesc: 'hano'
  },
  {
    username: 'MalesicA',
    userDesc: 'MalesicA'
  },
  {
    username: 'castillr',
    userDesc: 'castillr'
  },
  {
    username: 'jacksoa8',
    userDesc: 'jacksoa8'
  },
  {
    username: 'BarlundP',
    userDesc: 'BarlundP'
  },
  {
    username: 'enzn',
    userDesc: 'enzn'
  },
  {
    username: 'joas',
    userDesc: 'joas'
  },
  {
    username: 'lepe',
    userDesc: 'lepe'
  },
  {
    username: 'hailed',
    userDesc: 'hailed'
  },
  {
    username: 'anjoh',
    userDesc: 'anjoh'
  },
  {
    username: 'qianl3',
    userDesc: 'qianl3'
  },
  {
    username: 'imb',
    userDesc: 'imb'
  },
  {
    username: 'lindea',
    userDesc: 'lindea'
  },
  {
    username: 'sjoebog1',
    userDesc: 'sjoebog1'
  },
  {
    username: 'TornebrS',
    userDesc: 'TornebrS'
  },
  {
    username: 'nilssonn',
    userDesc: 'nilssonn'
  },
  {
    username: 'gallowr1',
    userDesc: 'gallowr1'
  },
  {
    username: 'deckerf',
    userDesc: 'deckerf'
  },
  {
    username: 'fernanda',
    userDesc: 'fernanda'
  },
  {
    username: 'lindstj3',
    userDesc: 'lindstj3'
  },
  {
    username: 'neumannp',
    userDesc: 'neumannp'
  },
  {
    username: 'mistr',
    userDesc: 'mistr'
  },
  {
    username: 'EdlundM1',
    userDesc: 'EdlundM1'
  },
  {
    username: 'holmdahl',
    userDesc: 'holmdahl'
  },
  {
    username: 'viggeboa',
    userDesc: 'viggeboa'
  },
  {
    username: 'zanettae',
    userDesc: 'zanettae'
  },
  {
    username: 'kluttk04',
    userDesc: 'kluttk04'
  },
  {
    username: 'gustavha',
    userDesc: 'gustavha'
  },
  {
    username: 'UljoH',
    userDesc: 'UljoH'
  },
  {
    username: 'hambergn',
    userDesc: 'hambergn'
  },
  {
    username: 'adm_abeds',
    userDesc: 'adm_abeds'
  },
  {
    username: 'poolej',
    userDesc: 'poolej'
  },
  {
    username: 'liljaj1',
    userDesc: 'liljaj1'
  },
  {
    username: 'skurj',
    userDesc: 'skurj'
  },
  {
    username: 'maraisj',
    userDesc: 'maraisj'
  },
  {
    username: 'williav',
    userDesc: 'williav'
  },
  {
    username: 'tst_EPI',
    userDesc: 'tst_EPI'
  },
  {
    username: 'rouleauv',
    userDesc: 'rouleauv'
  },
  {
    username: 'mawe',
    userDesc: 'mawe'
  },
  {
    username: 'axelssom',
    userDesc: 'axelssom'
  },
  {
    username: 'lopezora',
    userDesc: 'lopezora'
  },
  {
    username: 'holmlune',
    userDesc: 'holmlune'
  },
  {
    username: 'lindskos',
    userDesc: 'lindskos'
  },
  {
    username: 'uptonf',
    userDesc: 'uptonf'
  },
  {
    username: 'crutchm',
    userDesc: 'crutchm'
  },
  {
    username: 'OstenssP',
    userDesc: 'OstenssP'
  },
  {
    username: 'nystroem',
    userDesc: 'nystroem'
  },
  {
    username: 'MalkerJ',
    userDesc: 'MalkerJ'
  },
  {
    username: 'oliver17',
    userDesc: 'oliver17'
  },
  {
    username: 'heidek01',
    userDesc: 'heidek01'
  },
  {
    username: 'sahayv',
    userDesc: 'sahayv'
  },
  {
    username: 'adm_matthait',
    userDesc: 'adm_matthait'
  }
];
