var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedUnit !== null
    ? _c("div", { staticClass: "variable-selector" }, [
        _c("div", { staticClass: "variable-list-header" }, [
          _vm._v(" " + _vm._s(_vm.$t("sidebar.labels.variables")) + " "),
          _c(
            "div",
            { staticClass: "variable-list-icons" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm",
                  class: {
                    "btn-tag-active": _vm.isTagManagerActive,
                    "btn-tag-inactive": !_vm.isTagManagerActive
                  },
                  attrs: { title: "Show Tag Manager" },
                  on: { click: _vm.toggleTagManager }
                },
                [_c("font-awesome-icon", { attrs: { icon: "cogs" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm",
                  class: {
                    "btn-tag-active": _vm.isTagsActive,
                    "btn-tag-inactive": !_vm.isTagsActive
                  },
                  attrs: { title: "Show Tag" },
                  on: { click: _vm.toggleTags }
                },
                [_c("font-awesome-icon", { attrs: { icon: "tags" } })],
                1
              ),
              _c(
                "JsonExcel",
                {
                  staticClass: "btn btn-sm btn-tag-inactive",
                  attrs: {
                    data: _vm.exportVariables,
                    fields: _vm.fields,
                    name: "variable-tags.xlsx",
                    worksheet: "Variable Tags"
                  }
                },
                [_c("font-awesome-icon", { attrs: { icon: "download" } })],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          [
            _vm._l(_vm.variablesOfUnit, function(variable) {
              return [
                _c(
                  "label",
                  {
                    staticClass: "container-label",
                    class: { "with-alarm": _vm.hasAlarm(variable) },
                    style: { marginBottom: _vm.isTagsActive ? "0" : "13px" }
                  },
                  [
                    _vm._v(_vm._s(variable.label)),
                    _c("br"),
                    _c("input", {
                      attrs: {
                        "data-variable-id": variable.id,
                        type: "checkbox"
                      },
                      domProps: { checked: variable.selected },
                      on: {
                        click: function($event) {
                          return _vm.clicked($event, variable)
                        }
                      }
                    }),
                    _c("span", {
                      staticClass: "checkmark",
                      class: _vm.checkMarkColor(variable)
                    })
                  ]
                ),
                _vm.isTagsActive
                  ? _c("div", { staticClass: "tag" }, [
                      _vm._v(" " + _vm._s(variable.Tag) + " "),
                      _c(
                        "div",
                        { staticClass: "variable-list-icons" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pointer",
                            attrs: { icon: "copy" },
                            on: {
                              click: function($event) {
                                return _vm.copyToCliboard(variable)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }