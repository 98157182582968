var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "functional-location": _vm.functionalLocation } },
    [
      _vm.isButtonVisible
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary small-margin",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.toggleTable()
                  }
                }
              },
              [
                _c("i", {
                  class: {
                    collapsed: _vm.isCollapsed,
                    expanded: !_vm.isCollapsed
                  }
                }),
                _vm._v(" PEM (" + _vm._s(_vm.lossCount) + ") ")
              ]
            )
          ])
        : _vm._e(),
      !_vm.isCollapsed
        ? _c(
            "div",
            { staticClass: "loss-table table-responsive" },
            [
              _c("LossTableConfigurationModal", {
                ref: "LossTableConfigurationModal"
              }),
              _c(
                "table",
                {
                  staticClass:
                    "\n        table\n        b-table\n        table-striped table-hover table-bordered table-sm\n        border\n      ",
                  attrs: { "aria-busy": "false", "aria-colcount": "3" }
                },
                [
                  _c("thead", { attrs: { role: "rowgroup" } }, [
                    _c(
                      "tr",
                      { staticClass: "t-header", attrs: { role: "row" } },
                      [
                        _c("th"),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("StartTimeString", "date")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.startTime")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("StartTimeString") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("EndTimeString", "date")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.endTime")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("EndTimeString") }
                            })
                          ],
                          1
                        ),
                        _vm.selectedUnit.isBatch
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "5%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("Event_Num")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lossTableAUM.headings.event_Num")
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.getIcon("Event_Num") }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedUnit.isBatch
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("FinalProduct_Desc")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "lossTableAUM.headings.finalProduct_Desc"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: _vm.getIcon("FinalProduct_Desc")
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("StepName")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.stepName")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("StepName") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "5%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("LossSource")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.lossSource")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("StepName") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("RawMaterial_Desc")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.rawMaterial")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("StepName") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "5%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("LossQuantity", "number")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.lossQuantity")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("LossQuantity") }
                            })
                          ],
                          1
                        ),
                        _vm.selectedUnit.type === "AUM"
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("LossMinutes", "number")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "lossTableAUM.headings.lossMinutes"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.getIcon("LossMinutes") }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "5%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("Cost", "number")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lossTableAUM.headings.cost")) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("Cost") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("OAMCategory")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.OAMCategory")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("OAMCategory") }
                            })
                          ],
                          1
                        ),
                        _vm.selectedUnit.MaxReasonLevel >= 1
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("OAMFactor")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lossTableAUM.headings.OAMFactor")
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.getIcon("OAMFactor") }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedUnit.MaxReasonLevel >= 2
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("LocalReason1")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "lossTableAUM.headings.localReason"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.getIcon("LocalReason1") }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedUnit.MaxReasonLevel >= 3
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("LocalReason2")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "lossTableAUM.headings.localReason2"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.getIcon("LocalReason2") }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedUnit.MaxReasonLevel >= 4
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort("LocalReason3")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "lossTableAUM.headings.localReason3"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.getIcon("LocalReason3") }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedUnit
                          .requireMaintenanceNotificationNumberForEquipmentFailure
                          ? _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getSort(
                                      "maintenanceNotificationNumber"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "lossTableAUM.headings.maintenanceNotificationNumber"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: _vm.getIcon(
                                      "maintenanceNotificationNumber"
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("FunctionalLocation")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "lossTableAUM.headings.functionalLocation"
                                  )
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("FunctionalLocation") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.comment")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSort("RCPSStatus")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.RCPSStatus")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              attrs: { icon: _vm.getIcon("RCPSStatus") }
                            })
                          ],
                          1
                        ),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "10%"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.RCPSComment")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm._l(_vm.extraRows, function(item, index) {
                          return _c(
                            "th",
                            {
                              key: index,
                              attrs: {
                                role: "columnheader",
                                scope: "col",
                                "aria-colindex": "1",
                                width: "10%"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.label || item.key) + " ")]
                          )
                        }),
                        _c(
                          "th",
                          {
                            attrs: {
                              role: "columnheader",
                              scope: "col",
                              "aria-colindex": "1",
                              width: "5%"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lossTableAUM.headings.actions")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("th", [
                          _vm.selectedUnit.HideConfirmedEvents
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-export",
                                  attrs: { title: "Click to show all events" },
                                  on: { click: _vm.toggleHideConfirmedEvents }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "toggle-on" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.selectedUnit.HideConfirmedEvents
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-export",
                                  attrs: {
                                    title: "Click to hide confirmed events"
                                  },
                                  on: { click: _vm.toggleHideConfirmedEvents }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "toggle-off" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      2
                    ),
                    _vm.searchIsActive
                      ? _c(
                          "tr",
                          { attrs: { role: "row" } },
                          [
                            _c("th"),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "date", size: "sm" },
                                  model: {
                                    value: _vm.filter.startTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "startTime", $$v)
                                    },
                                    expression: "filter.startTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "date", size: "sm" },
                                  model: {
                                    value: _vm.filter.endTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "endTime", $$v)
                                    },
                                    expression: "filter.endTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.selectedUnit.isBatch
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "15%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.event_Num,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filter, "event_Num", $$v)
                                        },
                                        expression: "filter.event_Num"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedUnit.isBatch
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "15%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.finalProduct_Desc,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "finalProduct_Desc",
                                            $$v
                                          )
                                        },
                                        expression: "filter.finalProduct_Desc"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "5%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.stepName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "stepName", $$v)
                                    },
                                    expression: "filter.stepName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "5%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.LossSource,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "LossSource", $$v)
                                    },
                                    expression: "filter.LossSource"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "5%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.RawMaterial_Desc,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "RawMaterial_Desc",
                                        $$v
                                      )
                                    },
                                    expression: "filter.RawMaterial_Desc"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.lossQuantity,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "lossQuantity", $$v)
                                    },
                                    expression: "filter.lossQuantity"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.selectedUnit.type === "AUM"
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "10%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.lossMinutes,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "lossMinutes",
                                            $$v
                                          )
                                        },
                                        expression: "filter.lossMinutes"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "5%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.cost,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "cost", $$v)
                                    },
                                    expression: "filter.cost"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.OAMCategory,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "OAMCategory", $$v)
                                    },
                                    expression: "filter.OAMCategory"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.selectedUnit.MaxReasonLevel >= 1
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "10%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.OAMFactor,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filter, "OAMFactor", $$v)
                                        },
                                        expression: "filter.OAMFactor"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedUnit.MaxReasonLevel >= 2
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "10%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.localReason,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "localReason",
                                            $$v
                                          )
                                        },
                                        expression: "filter.localReason"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedUnit.MaxReasonLevel >= 3
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "10%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.localReason2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "localReason2",
                                            $$v
                                          )
                                        },
                                        expression: "filter.localReason2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedUnit.MaxReasonLevel >= 4
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "10%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _vm.filter.localReason3,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "localReason3",
                                            $$v
                                          )
                                        },
                                        expression: "filter.localReason3"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedUnit
                              .requireMaintenanceNotificationNumberForEquipmentFailure
                              ? _c(
                                  "th",
                                  {
                                    attrs: {
                                      role: "columnheader",
                                      scope: "col",
                                      "aria-colindex": "1",
                                      width: "15%"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value:
                                          _vm.filter
                                            .maintenanceNotificationNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "maintenanceNotificationNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filter.maintenanceNotificationNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "10%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.functionalLocation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "functionalLocation",
                                        $$v
                                      )
                                    },
                                    expression: "filter.functionalLocation"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.comment,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "comment", $$v)
                                    },
                                    expression: "filter.comment"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.RCPSStatus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "RCPSStatus", $$v)
                                    },
                                    expression: "filter.RCPSStatus"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "th",
                              {
                                attrs: {
                                  role: "columnheader",
                                  scope: "col",
                                  "aria-colindex": "1",
                                  width: "15%"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.filter.RCPSComment,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "RCPSComment", $$v)
                                    },
                                    expression: "filter.RCPSComment"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._l(_vm.extraRows, function(item, index) {
                              return _c(
                                "th",
                                {
                                  key: index,
                                  attrs: {
                                    role: "columnheader",
                                    scope: "col",
                                    "aria-colindex": "1",
                                    width: "15%"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type:
                                        item.type === "date" ? "date" : "text",
                                      size: "sm"
                                    },
                                    model: {
                                      value: _vm.filter[item.key],
                                      callback: function($$v) {
                                        _vm.$set(_vm.filter, item.key, $$v)
                                      },
                                      expression: "filter[item.key]"
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            _c("th"),
                            _c("th")
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "tbody",
                    { attrs: { role: "rowgroup" } },
                    [
                      _vm._l(_vm.selectedLossData, function(lossData, index) {
                        return [
                          !lossData.noResults
                            ? _c(
                                "tr",
                                {
                                  class: _vm.getRowColor(lossData),
                                  style: {
                                    display: _vm.containsSameLossSource(index)
                                      ? "none"
                                      : "table-row"
                                  },
                                  attrs: { role: "row" }
                                },
                                [
                                  _c("td", { attrs: { role: "row" } }, [
                                    _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        disabled: _vm.containsSameLossSource(
                                          index
                                        )
                                      },
                                      domProps: {
                                        checked: _vm.selectedAdditionalLossReasonIndex.includes(
                                          index
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleAdditionalLossIndex(
                                            index
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.StartTimeString) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.EndTimeString) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.selectedUnit.isBatch
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lossData.Event_Num) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedUnit.isBatch
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                lossData.FinalProduct_Desc
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(lossData.StepName) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(lossData.LossSource) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.RawMaterial_Desc) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.LossQuantity) +
                                          " " +
                                          _vm._s(lossData.LossUoM) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.selectedUnit.type === "AUM"
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lossData.LossMinutes) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.Cost) +
                                          " " +
                                          _vm._s(lossData.CostUoM) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(lossData.OAMCategory) + " "
                                      )
                                    ]
                                  ),
                                  _vm.selectedUnit.MaxReasonLevel >= 1
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lossData.OAMFactor) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedUnit.MaxReasonLevel >= 2
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lossData.LocalReason1) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedUnit.MaxReasonLevel >= 3
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lossData.LocalReason2) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedUnit.MaxReasonLevel >= 4
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lossData.LocalReason3) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedUnit
                                    .requireMaintenanceNotificationNumberForEquipmentFailure
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            role: "cell",
                                            "aria-colindex": "1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getTreeReasonData(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                lossData.maintenanceNotificationNumber
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.FunctionalLocation) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(lossData.Comment) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(lossData.RCPSStatus) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        role: "cell",
                                        "aria-colindex": "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getTreeReasonData(index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(lossData.RCPSComments) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.extraRows, function(item, index) {
                                    return _c(
                                      "td",
                                      {
                                        key: index,
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.getTreeReasonData(index)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.type === "number"
                                                ? _vm.formatNumber(
                                                    lossData[item.key],
                                                    item.formatter
                                                  )
                                                : item.type === "date"
                                                ? _vm.formatDate(
                                                    lossData[item.key],
                                                    item.formatter
                                                  )
                                                : lossData[item.key]
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  }),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "ellipsis-btn",
                                          attrs: {
                                            "no-caret": "",
                                            dropleft: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function() {
                                                  return [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: "ellipsis-h"
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.getAuditTrail(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lossTableAUM.headings.auditTrail"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.getTreeReasonData(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lossTableAUM.headings.categoriseEvent"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  index === 0
                                    ? _c(
                                        "td",
                                        {
                                          staticClass: "th-black",
                                          attrs: {
                                            width: "2%",
                                            rowspan: _vm.selectedLossData.length
                                          }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-export",
                                              on: { click: _vm.showTableConfig }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "plus" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-export",
                                              on: { click: _vm.toggleSearch }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "filter" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "JsonExcel",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-export",
                                              attrs: {
                                                worksheet: "Loss Table",
                                                name: _vm.excelName,
                                                data: _vm.selectedLossData,
                                                fields: _vm.fields
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "file-excel" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "JsonExcel",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-export",
                                              attrs: {
                                                worksheet: "Loss Table",
                                                name: _vm.excelName,
                                                type: "csv",
                                                data: _vm.selectedLossData,
                                                fields: _vm.fields
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "file-csv" }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e(),
                          lossData.noResults
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "15" } }, [
                                  _vm._v("No Results")
                                ])
                              ])
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("LossModal", {
        ref: "modal",
        attrs: {
          "loss-data": _vm.lossDataForPopup,
          "tree-data": _vm.lossTree,
          "functional-location": _vm.functionalLocation,
          "additional-loss-data": _vm.additionalLossDataForPopup
        },
        on: {
          onClose: function($event) {
            return _vm.lossModalClosed()
          }
        }
      }),
      _c("AuditModal", {
        ref: "auditModal",
        attrs: {
          "loss-data": _vm.lossDataForPopup,
          "audit-data": _vm.auditData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }