













































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { MetricsService } from '../../services/metricsService';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  computed: {
    ...mapGetters({
      favoritesViews: 'getFavoritesViews'
    })
  }
})
export default class SettingsModal extends Vue {
  @Ref('favorites-settings-modal') readonly modal!: any;
  public favoritesViews!: any[];
  public editIndex: number = -1;
  public selectedUnit!: any;
  public modalData: any = null;

  setViewForUpdate(view, index) {
    this.modalData = _.clone(view);
    this.editIndex = index;
  }

  hideModal() {
    this.modal.hide();
    this.$emit('modal-closed');
  }

  deleteView() {
    this.$store.dispatch('deleteFavorite', this.editIndex);
    this.modal.hide();
    this.$emit('modal-closed');
  }

  sumbitBtn() {
    if (this.editIndex > -1) {
      this.$store.dispatch('updateFavorites', {
        index: this.editIndex,
        data: this.modalData
      });
    }
    this.hideModal();
  }

  openModal() {
    setTimeout(() => this.modal.show(), 0);
  }

  mounted() {
    console.log('mounted');
    this.editIndex = 0;
    this.modalData = _.clone(this.favoritesViews[0]);
  }

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  modalTeardown() {
    MetricsService.newAction({
      type: 'event',
      kind: 'cancel_favorites_modal_settings_event',
      typeFormated: 'Cancel favorites modal settings event',
      message: `Cancel favorites data modal settings event was fired`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.modalData = null;
    this.editIndex = -1;
    this.$emit('modal-closed');
  }
}
