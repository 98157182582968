import { StateCLS } from './../classes/stateCLS';
import { Unit } from './../classes/unit';
import { Alarm } from './../classes/alarm';
import { Variable } from './../classes/variable';
import { ShiftData } from './../classes/shiftData';
import { LossData } from './../classes/lossData';
import { Trend } from './../classes/trend';
import { Threshold } from './../classes/threshold';
import { Equipment } from './../classes/equipment';
import { MesDataPoint } from './../classes/mesDataPoint';
import { BatchAndStepData } from './../classes/batchAndStepData';
import { MaintenanceNotification } from "@/classes/maintenanceNotification";

export const storeGetters = {
  getAccount: (state: StateCLS): any => state.account,
  getInitialDataLoaded: (state: StateCLS): boolean => state.initialDataLoaded,
  isDataLoaded: (state: StateCLS): boolean =>
    !!(state.units.length && state.variables),
  loading: (state: StateCLS): boolean => state.loading,
  getChartPosition: (state: StateCLS): any => state.secondChartPosition,
  avgSidebarIsOpen: (state: StateCLS): boolean => state.avgSidebarIsOpen,
  getActiveRightSidebar: (state: StateCLS): string => state.activeRightSidebar,
  getUnits: (state: StateCLS): Unit[] => state.units,
  getSelectedUnit: (state: StateCLS): Unit => state.selectedUnit,
  getAlarms: (state: StateCLS): Alarm[] => state.alarms,
  getVariable: (state: StateCLS): Variable[] => state.variables,
  getSelectedVariables: (state: StateCLS, getters: any): Variable[] =>
    [...state.variables, ...getters.getCustomTags].filter((v) => v.selected),
  getSelectedLossData: (state: StateCLS): any => state.selectedLossData,
  getSelectedLossIndices: (state: StateCLS): any => state.selectedLossIndices,
  getSelectedLossRegion: (state: StateCLS): number => state.selectedLossRegion,
  getBtnActive: (state: StateCLS): string => state.btnActive,
  getShiftData: (state: StateCLS): ShiftData[] => state.shiftData,
  getLossData: (state: StateCLS): LossData[] => state.lossData,
  getSelectedShiftIndex: (state: StateCLS): number => state.selectedShiftIndex,
  getSelectedShift: (state: StateCLS): ShiftData => state.selectedShift,
  getTrendData: (state: StateCLS): Trend[] => state.trendData,
  getThresholdData: (state: StateCLS): Threshold[] => state.thresholdData,
  getTimeRange: (state: StateCLS): Date[] => state.timeRange,
  getFunctionalLocation: (state: StateCLS): any => state.functionalLocation,
  getLossTree: (state: StateCLS): any => state.lossTree,
  getLossTreeRows: (state: StateCLS): any[] => state.lossTreeRows,
  getPath: (state: StateCLS): any => state.path,
  getConfiguration: (state: StateCLS): any => state.configuration,
  getTimezoneOffset: (state: StateCLS): number => state.timezoneOffset,
  getInspectBatchOrStep: (state: StateCLS): any => state.inspectBatchOrStep,
  getSelectedShiftRange: (state: StateCLS): any => state.selectedShiftRange,
  getHistoryDays: (state: StateCLS): number => state.historyDays,
  isSidebarCollapsed: (state: StateCLS): boolean => state.isSidebarCollapsed,
  getDragMode: (state: StateCLS): string => state.dragMode,
  getSelectedTrendAreaTimeStamps: (state: StateCLS): Date[] =>
    state.selectedTrendAreaTimeStamps,
  getEquipmentList: (state: StateCLS): Equipment[] => state.equipmentList,
  getMaintenanceNotificationList: (state: StateCLS): MaintenanceNotification[] => state.maintenanceNotificationList,
  getSelectedMaintenanceNotification: (state: StateCLS): MaintenanceNotification[] => state.selectedMaintenanceNotification,
  getReplot: (state: StateCLS): boolean => state.rePlot,
  getTargetVariables: (state: StateCLS): Variable[] => state.targetVariables,
  getLastUpdated: (state: StateCLS): any => state.lastUpdated,
  getUpdateScheduled: (state: StateCLS): boolean => state.updateScheduled,
  getRefreshRate: (state: StateCLS): number => state.refreshRate,
  getIsAutoRefresh: (state: StateCLS): boolean => state.isAutoRefresh,
  getAccess: (state: StateCLS): boolean => state.access,
  getIsFromMetricAction: (state: StateCLS): boolean => state.isFromMetricAction,
  getTrendChartYAxisLock: (state: StateCLS): boolean =>
    state.trendChartYAxisLock,
  getZoomEvent: (state: StateCLS): any => state.zoomEvent,
  getShiftChartVisible: (state: StateCLS): boolean => state.shiftChartVisible,
  getChartWidth: (state: StateCLS): number => state.chartWidth,
  getIsTagsActive: (state: StateCLS): boolean => state.isTagsActive,
  isAutoRefreshEnabled: (state: StateCLS): boolean => state.autoRefreshEnabled,
  getIsProductChartActive: (state: StateCLS): boolean =>
    state.isProductChartActive,
  isFromTimerange: (state: StateCLS): boolean => state.fromTimerange,
  getLossTableConfiguration: (state: StateCLS): any =>
    state.lossTableConfiguration,
  getVariablesOfSelectedUnit: (state: StateCLS, getters: any): Variable[] => {
    const unitVars = state.variables.filter(
      (v) => v.unit === state.selectedUnit.label
    );
    const color_template = [
      'blue',
      'red',
      'orange',
      'purple',
      'green',
      'black',
      'pink',
      'sandybrown',
      'brown',
      'darkgoldenrod',
      'lightgreen',
      'grey',
      'greenyellow',
      'turquoise',
      'olive'
    ].splice(unitVars.length, 99);

    return unitVars
      .map((v, i) => {
        v.index = i;
        return v;
      })
      .concat(
        getters.getCustomTags
          .filter(
            (tag) =>
              tag.active && (!tag.unit || tag.unit == state.selectedUnit.label)
          )
          .map((c, i) => {
            if (!c.unit && !state.selectedUnit.label) {
              c.selected = true;
            }
            c.color = color_template[i];
            c.var = c.id = 'var99' + i;
            return c;
          })
      );
  },
  getMesVariablesOfSelectedUnit: (
    state: StateCLS,
    getters: any
  ): Variable[] => {
    const unitVars = state.variables.filter(
      (v) => v.unit === state.selectedUnit.label && v.VarType === 'MES'
    );
    const color_template = [
      'blue',
      'red',
      'orange',
      'purple',
      'green',
      'black',
      'pink',
      'sandybrown',
      'brown',
      'darkgoldenrod',
      'lightgreen',
      'grey',
      'greenyellow',
      'turquoise',
      'olive'
    ].splice(unitVars.length, 99);

    return unitVars
      .map((v, i) => {
        v.index = i;
        return v;
      })
      .concat(
        getters.getCustomTags
          .filter(
            (tag) =>
              tag.active &&
              tag.VarType === 'MES' &&
              (!tag.unit || tag.unit == state.selectedUnit.label)
          )
          .map((c, i) => {
            if (!c.unit && !state.selectedUnit.label) {
              c.selected = true;
            }
            c.color = color_template[i];
            c.var = c.id = 'var999' + i;
            return c;
          })
      );
  },
  getMesTrends: (state: StateCLS): MesDataPoint[] => state.mesTrends,
  getDataByDate: (state: StateCLS): boolean => state.databyDate,
  getIp: (state: StateCLS): string => state.ip,
  getUserName: (state: StateCLS): string => state.userName,
  getShowGrid: (state: StateCLS): string => state.showGrid,
  getStepsAndBatches: (state: StateCLS): BatchAndStepData[] =>
    state.stepsAndBatches,
  isAccessDenied: (state: StateCLS): boolean => state.isAccessDenied,
  getFunctionalLocationOriginal: (state: StateCLS): any[] =>
    state.functionalLocationOriginal,
  getShouldUpdateLosses: (state: StateCLS): boolean => state.shouldUpdateLosses,
  getFavoritesSelectedView: (state: StateCLS): any =>
    state.favoritesSelectedView,
  getFavoritesViews: (state: StateCLS): any[] => state.favoritesViews,
  getSavedState: (state: StateCLS): any[] => state.savedState,
  getDrawMode: (state: StateCLS): boolean => state.drawModeActive,
  getConversations: (state: StateCLS): any[] => state.conversations,
  getWishlist: (state: StateCLS): any[] => state.wishlist,
  getChatSearch: (state: StateCLS): string => state.chatSearch,
  getWishlistSearch: (state: StateCLS): string => state.wishlistSearch,
  getUnreadMessagesTotal: (state: StateCLS): number =>
    state.unreadMessagesTotal,
  getUnreadMessages: (state: StateCLS): any => state.unreadMessages,
  getHistorianTags: (state: StateCLS): string[] => state.historianTags,
  getTagManagerTags: (state: StateCLS): any => state.tagManagerData,
  getWidgetTitle: (state: StateCLS): string =>
    state.configuration?.[state.path]?.Title,
  getDefaultTimeframe: (state: StateCLS): string =>
    state.configuration?.[state.path]?.defaultTimeframe,
  getIsPlantMapActive: (state: StateCLS): boolean => state.isPlantMapActive,
  getIsTagManagerActive: (state: StateCLS): boolean => state.isTagManagerActive,
  getIsControlChartsActive: (state: StateCLS): boolean =>
    state.isControlChartsActive,
  getCustomTags: (state: StateCLS): any[] => state.customTags,
  getMaterialInformation: (state: StateCLS): any[] => state.materialInformation,
  getMaterializedPath: (state: StateCLS): string => state.materializedPath
};
