var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-h" }, [
    _c("div", { staticClass: "app-wrapper" }, [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c("div", { staticClass: "cyzag-watermark" }),
          _c("Loader"),
          _c("AppHeader", { on: { selected: _vm.signOut } }),
          !_vm.isAccessDenied ? _c("AppSidebar") : _vm._e(),
          _vm.initialLoadDone ? _c("ToggleReports") : _vm._e(),
          _vm.initialLoadDone ? _c("ToggleAvg") : _vm._e(),
          !_vm.isAccessDenied
            ? _c("div", { staticClass: "app-main" }, [
                _c(
                  "div",
                  { staticClass: "inner" },
                  [
                    _vm.initialLoadDone
                      ? _c(
                          "div",
                          { ref: "trendChart", staticClass: "mb-3 card" },
                          [
                            _c(
                              "div",
                              { staticClass: "card-header-tab card-header" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-actions-pane-right text-capitalize"
                                  },
                                  [_c("TimeRangeButtons")],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "no-gutters row" },
                              [
                                _vm.isPlantMapActive
                                  ? _c("PlantMap")
                                  : _vm._e(),
                                _vm.isTagManagerActive
                                  ? _c("TagManager")
                                  : _vm._e(),
                                _vm.isControlChartsActive
                                  ? _c("ControlCharts")
                                  : _vm._e(),
                                _c("TrendChart", {
                                  on: { click: _vm.onClickGraph }
                                }),
                                _vm.initialLoadDone
                                  ? _c("AccumulatedChart")
                                  : _vm._e(),
                                _vm.shiftData.length &&
                                !_vm.selectedUnit.isBatch
                                  ? _c("ShiftTimeline")
                                  : _vm._e(),
                                _vm.stepsAndBatches.length &&
                                _vm.selectedUnit.isBatch
                                  ? _c("BatchStepTimeline", {
                                      key: _vm.batchStepKey
                                    })
                                  : _vm._e(),
                                _vm.isLossTableAumVisible
                                  ? _c("LossTableAUM", {
                                      staticStyle: { width: "100%" }
                                    })
                                  : _vm._e(),
                                _vm.isLossTablePemVisible
                                  ? _c("LossTablePEM", {
                                      staticStyle: { width: "100%" }
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.lossTypes) + " ")
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.initialLoadDone ? _c("AvgSidebar") : _vm._e(),
                    _vm.initialLoadDone ? _c("ReportsSidebar") : _vm._e(),
                    _vm.initialLoadDone
                      ? _c("ChatSidebar", {
                          on: {
                            newWish: _vm.getWishlist,
                            "refresh-list": _vm.getConversations
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }