import to from '@/services/to';
import axios from 'axios';

export async function getProductionEvents(
  startTime: string,
  endTime: string,
  assetId: number
) {
  const [err, productionEvents] = await to(
    axios.get(`api/events/productionEvents`, {
      params: {
        startTime,
        endTime,
        assetId
      }
    })
  );

  if (err) {
    throw new Error(err);
  }

  return productionEvents.data;
}

export async function getRawMaterials(assetId: number, productCode: string) {
  const [err, rawMaterials] = await to(
    axios.get(`api/portalEvent/rawMaterial`, {
      params: {
        productCode,
        assetId
      }
    })
  );

  if (err) {
    throw new Error(err);
  }

  return rawMaterials.data;
}

export async function createEvent(path: string, eventType: string, entity) {
  const [err] = await to(
    axios.post(`api/portalEvent`, entity, {
      params: {
        path,
        eventType
      }
    })
  );

  if (err) {
    throw new Error(err);
  }
}

export async function createProductionEvent(entity) {
  const [err] = await to(axios.post(`api/portalEvent/productionEvent`, entity));

  if (err) {
    throw new Error(err);
  }
}

export async function deleteEvent(path: string, eventType: string, id: number) {
  const [err] = await to(
    axios.delete(`api/portalEvent/${id}`, {
      params: {
        path,
        eventTypeEnum: eventType
      }
    })
  );

  if (err) {
    throw new Error(err);
  }
}

export async function getMaterials(assetId: number) {
  const [err, materials] = await to(
    axios.get(`api/products/materials`, {
      params: {
        assetId
      }
    })
  );

  if (err) {
    throw new Error(err);
  }

  return materials.data;
}
