



















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Utils } from './../utils';
import { debounce } from 'underscore';
import { Variable } from '../classes/variable';
import { MetricsService } from '../services/metricsService';
import * as moment from 'moment';

@Component({
  components: {},
  computed: {
    ...mapGetters({
      allVars: 'getVariable',
      customTags: 'getCustomTags',
      unit: 'getSelectedUnit',
      siteUnits: 'getUnits',
      tagData: 'getTagManagerTags',
      variablesOfUnit: 'getVariablesOfSelectedUnit',
      historyDays: 'getHistoryDays'
    })
  },
  methods: {
    genDebounce: function (i, that) {
      return debounce(async function (value) {
        const path = that.$store.state.path;
        const splitFilter = value.split(' -> ');
        const sanitizedFilter = splitFilter[splitFilter.length - 1];
        let response = await Utils.customVariableApi(
          that,
          path,
          `*${sanitizedFilter}*`
        );
        // @ts-ignore
        that.$store.state.tagManagerData[i] = response;
        that.$forceUpdate();
      }, 1000);
    }
  }
})
export default class TagManager extends Vue {
  public customTags!: any[];
  public allVars!: any[];
  public variablesCopy: any[] = [];
  public modified: any[];
  public isModified: boolean = false;
  public unit: any;
  public siteUnits!: any[];
  public tagData!: any[];
  public debounceInput: any = {};
  public isValidInputs: boolean = false;
  public variablesOfUnit: Variable[];
  public historyDays!: number;

  get units() {
    const emptyUnit = {
      Cell: ''
    };
    return [emptyUnit, ...this.siteUnits];
  }

  sanitize(value) {
    const splitFilter = value.split(' -> ');
    return splitFilter[splitFilter.length - 1];
  }

  deepCopy(objArr) {
    return objArr.map((obj) => {
      let obj2 = {};
      Object.keys(obj).forEach((key) => {
        obj2[key] = obj[key];
      });
      return obj2;
    });
  }

  mounted() {
    this.resetVariablesCopy();
    // @ts-ignore
    window.tagManager = this;
    this.validate();
  }

  @Watch('variables', { deep: true })
  variablesChanged() {
    this.resetVariablesCopy();
  }

  resetVariablesCopy() {
    this.variablesCopy = this.deepCopy(this.variables);
    this.variablesCopy.forEach((v, i) => {
      // @ts-ignore
      this.debounceInput[i] = this.genDebounce(i, this);
    });
    this.modified = Array(this.variablesCopy.length)
      .fill(0)
      .map((a) => new Object());
  }

  @Watch('variablesCopy', { deep: true })
  variablesCopyChanged() {
    console.log('variablesCopy changed');
    this.isModified = false;
    this.variablesCopy.forEach((varCopy, i) => {
      Object.keys(varCopy).forEach((key) => {
        if (
          i > this.variables.length - 1 ||
          this.variables[i][key] != varCopy[key]
        ) {
          this.modified[i][key] = true;
          this.isModified = true;
          if (key === 'Tag') {
            let index = i;
            this.debounceInput[index](varCopy[key]);
          }
        } else {
          this.modified[i][key] = false;
        }
      });
    });
  }

  validate() {
    this.isValidInputs = this.variablesCopy.every((v, i) => {
      return (
        v.label &&
        (v.origin === 'unit' ||
          !this.modified[i].Tag ||
          (this.tagData[i] &&
            this.tagData[i][0] &&
            this.sanitize(v.Tag) === this.tagData[i][0].Var_Desc))
      );
    });
    setTimeout(() => {
      this.validate();
    }, 1000);
  }

  get variables() {
    const systemVarsOfUnit = this.allVars.filter(
      (v) => v.origin === 'unit' && v.unit === this.unit.label
    );
    return [...systemVarsOfUnit, ...this.customTags];
  }

  closeTagManager() {
    MetricsService.newAction({
      type: 'click',
      kind: 'tagmanager_closed',
      typeFormated: 'Tag Manager Closed',
      message: 'Tag Manager closed from tag manager',
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.state.isTagManagerActive = false;
    if (this.variablesOfUnit.length) {
      for (let promise of [
        ...Utils.refreshUnit(this, 1),
        ...Utils.refreshUnit(this, this.historyDays)
      ]) {
        Utils.triggerBackend(promise, this);
      }
    }
  }

  addTag() {
    this.modified.push({});
    this.variablesCopy.push({
      UoM: '',
      alarm: false,
      color: 'black',
      precision: 2,
      label: '',
      Tag: '',
      VarType: 'HISTORIAN',
      unit: this.$store.state.selectedUnit.label,
      origin: 'custom',
      selected: false,
      active: true
    });
    // @ts-ignore
    this.debounceInput[this.variablesCopy.length - 1] = this.genDebounce(
      this.variablesCopy.length - 1,
      this
    );
  }

  async deleteTag(index) {
    const newCustomTags = this.variablesCopy.filter(
      (v, i) => v.origin === 'custom' && i !== index
    );
    newCustomTags.forEach((tag) => {
      tag.selected = false;
    });
    await Utils.triggerBackend(
      {
        method: 'insertOrUpdateTagData',
        parameters: {
          username: this.$store.state.userName,
          tagData: JSON.stringify(newCustomTags)
        }
      },
      this
    );
  }

  async save() {
    const newCustomTags = this.variablesCopy.filter(
      (v) => v.origin === 'custom'
    );
    newCustomTags.forEach((tag) => {
      tag.selected = false;
      const splitTag = tag.Tag.split(':');
      if (splitTag.length > 1) {
        tag.VarType = 'MES';
        tag.Input_Tag = splitTag[0];
      }
    });
    await Utils.triggerBackend(
      {
        method: 'insertOrUpdateTagData',
        parameters: {
          username: this.$store.state.userName,
          tagData: JSON.stringify(newCustomTags)
        }
      },
      this
    );
  }

  discard() {
    this.variablesCopy = this.deepCopy(this.variables);
  }
}
