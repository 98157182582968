







import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
  },
  computed: {
    ...mapGetters({
    })
  }
})


export default class SidebarToolbar extends Vue {

}
