var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "listing-card card" },
    [
      _c("div", { staticClass: "listing-card-header" }, [
        _c("ul", { staticClass: "nav flex-column" }, [
          _c("li", { staticClass: "pt-4 pl-3 pr-3 pb-3 nav-item search" }, [
            _c("div", { staticClass: "input-group" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c(
                  "div",
                  { staticClass: "input-group-text" },
                  [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                  1
                )
              ]),
              _c("input", {
                staticClass: "form-control",
                attrs: { placeholder: "Search...", type: "text" },
                on: {
                  input: function($event) {
                    return _vm.debounceInput($event)
                  }
                }
              })
            ])
          ]),
          _c("li", { staticClass: "nav-item-header nav-item" }, [
            _c("div", { staticClass: "flex-ds" }, [
              _c("span", { staticClass: "left-ds" }, [
                _vm.people.length
                  ? _c(
                      "div",
                      _vm._l(_vm.people, function(pep) {
                        return _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-selected-people",
                            on: {
                              click: function($event) {
                                return _vm.removePeople(pep)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(pep.username) + " ")]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                !_vm.people.length
                  ? _c("div", { staticClass: "text-center" }, [
                      _vm._v(" Please Select People ")
                    ])
                  : _vm._e()
              ]),
              _c("span", { staticClass: "right-ds" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    on: { click: _vm.back }
                  },
                  [_vm._v(" Back ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    staticStyle: { "margin-left": "5px" },
                    on: { click: _vm.newGroup }
                  },
                  [_vm._v(" Create ")]
                )
              ])
            ])
          ])
        ])
      ]),
      _c(
        "ul",
        { staticClass: "nav flex-column" },
        _vm._l(_vm.users, function(pep) {
          return _c("li", { staticClass: "nav-item subject-list" }, [
            _c(
              "button",
              {
                staticClass: "list-btn",
                class: _vm.hasActive(pep),
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.addToPeople(pep)
                  }
                }
              },
              [
                _c("div", { staticClass: "list-wrapper p-0" }, [
                  _c("div", { staticClass: "list-content" }, [
                    _c("div", [
                      _c("div", { staticClass: "list-heading" }, [
                        _vm._v(_vm._s(pep.displayName))
                      ])
                    ])
                  ])
                ])
              ]
            )
          ])
        }),
        0
      ),
      _c(
        "b-modal",
        {
          ref: "modalGroup",
          attrs: {
            id: "group-title",
            "no-fade": true,
            title: "Add Group Title",
            size: "md",
            "ok-title": _vm.$t("lossModal.submit")
          },
          on: {
            shown: _vm.modalShown,
            hidden: _vm.modalTeardown,
            ok: _vm.sumbitBtn
          }
        },
        [
          _c(
            "div",
            { staticClass: "widget-rootwidget" },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.titleState,
                    label: "Title",
                    "label-for": "title-input",
                    "invalid-feedback": "Title is required"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title-input",
                      state: _vm.titleState,
                      required: ""
                    },
                    model: {
                      value: _vm.subject,
                      callback: function($$v) {
                        _vm.subject = $$v
                      },
                      expression: "subject"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }