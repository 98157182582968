



import moment from 'moment';
import Plotly from 'plotly.js-dist/plotly.js';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { LossData } from '../classes/lossData';
import { ShiftData } from '../classes/shiftData';
import { Utils } from '../utils';
import { BatchAndStepData } from '../classes/batchAndStepData';
import { Unit } from '../classes/unit';
import warningIcon from './../images/icons8-warning-shield-16.png';
import ChartsUtils from './TrendChart/utils';

@Component({
  computed: {
    ...mapGetters({
      timezoneOffset: 'getTimezoneOffset',
      lossData: 'getLossData',
      inspectBatchOrStep: 'getInspectBatchOrStep',
      selectedShiftIndex: 'getSelectedShiftIndex',
      stepsAndBatches: 'getStepsAndBatches',
      selectedShift: 'getSelectedShift',
      timeRange: 'getTimeRange',
      selectedUnit: 'getSelectedUnit'
    })
  }
})
export default class BatchStepTimeline extends Vue {
  public timezoneOffset!: number;
  public lossData!: LossData[];
  public timeRange!: Date[];
  public selectedShiftIndex!: number;
  public selectedShift!: ShiftData;
  public inspectBatchOrStep!: any;
  public stepsAndBatches!: any;
  public dataset: any;
  public clickedDate: any;
  public selectedUnit!: Unit;
  public dataIndex: any;
  public shouldReLayout: boolean = true;
  public shouldCreate: boolean = true;
  public currentLayout: any = null;
  public smallSet: number = 0;
  public bigSet: number = 99999;

  get plotData(): any {
    let data: any = [];

    let arrSteps = [0, 1, 2];

    this.batchData.forEach((l: BatchAndStepData, i: number) => {
      let color = Utils.getShiftBackgroundColour(arrSteps[0]);

      if (
        this.selectedShiftIndex !== null &&
        this.selectedShiftIndex === i &&
        this.inspectBatchOrStep === 'batch'
      ) {
        return;
      }
      arrSteps.splice(arrSteps.length - 1, 0, arrSteps.splice(0, 1)[0]);

      data.push({
        fill: 'tozeroy',
        hoverinfo: 'none',
        opacity: 1,
        activeShift:
          this.selectedShiftIndex === i && this.inspectBatchOrStep === 'batch',
        typeShift: 'batch',
        indexShift: i,
        diffColor: Utils.getShiftBackgroundColour(arrSteps[0]),
        line: {
          color
        },
        mode: 'lines',
        type: 'scattergl',
        showlegend: false,
        x: [new Date(l.StartTime), new Date(l.EndTime)],
        y: [-1, -1]
      });

      data.push({
        hoverinfo: 'none',
        line: {
          color: 'rgb(0, 0, 0)'
        },
        mode: 'lines',
        type: 'scattergl',
        showlegend: false,
        x: [new Date(l.EndTime), new Date(l.EndTime)],
        y: [0, -1]
      });
    });

    this.lossData.forEach((row) => {
      let color: string = '',
        borderColor: string = '';

      if (row.LossSource === 'Downtime') {
        color = '#ffb3b3';
        borderColor = '#ad3131';
      } else if (row.LossSource === 'SpeedLoss') {
        color = '#ffffb3';
        borderColor = '#b7b700';
      } else if (row.LossSource === 'PEM') {
        if (row.LossQuantity >= 0) {
          color = '#ffb3b3';
          borderColor = '#ad3131';
        } else {
          color = '#b3d9b3';
          borderColor = '#366b36';
        }
      }
      if (row.StepName === 'Continuous' || row.StepName === 'Downtime') {
        data.push({
          fill: 'tozeroy',
          layer: 'below',
          hoverinfo: 'none',
          opacity: 1,
          fillcolor: color,
          line: {
            color: borderColor
          },
          mode: 'lines',
          type: 'scattergl',
          showlegend: false,
          x: [new Date(row.StartTime), new Date(row.EndTime)],
          y: [-1, -1]
        });
      }
    });

    const batchActive = this.batchData.find(
      (bd, i) =>
        this.selectedShiftIndex !== null &&
        this.selectedShiftIndex === i &&
        this.inspectBatchOrStep === 'batch'
    );
    if (batchActive) {
      data.push({
        fill: 'tozeroy',
        hoverinfo: 'none',
        opacity: 1,
        activeShift: true,
        typeShift: 'batch',
        indexShift: this.selectedShiftIndex,
        line: {
          color: 'green'
        },
        mode: 'lines',
        type: 'scattergl',
        showlegend: false,
        x: [new Date(batchActive.StartTime), new Date(batchActive.EndTime)],
        y: [-1, -1]
      });

      data.push({
        hoverinfo: 'none',
        line: {
          color: 'rgb(0, 0, 0)'
        },
        mode: 'lines',
        type: 'scattergl',
        showlegend: false,
        x: [new Date(batchActive.EndTime), new Date(batchActive.EndTime)],
        y: [0, -1]
      });
    }

    this.batchData.forEach((l: BatchAndStepData, i: number) => {
      data.push({
        hoverinfo: 'none',
        line: {
          color: 'rgb(153, 192, 255)'
        },
        mode: 'text',
        textposition: 'middle center',
        text: [l.Event_Num ? `${l.Event_Num}` : 'none'],
        type: 'scattergl',
        showlegend: false,
        x: [
          new Date(
            new Date(l.StartTime).getTime() + new Date(l.EndTime).getTime()
          ).getTime() / 2
        ],

        y: [-0.5]
      });
    });

    this.stepData.forEach((s: BatchAndStepData, i: number) => {
      let diff = moment(s.EndTime).diff(s.StartTime, 'seconds');
      let color;

      if (
        this.selectedShiftIndex !== null &&
        this.selectedShiftIndex === i &&
        this.inspectBatchOrStep === 'step'
      ) {
        color = 'green';
      } else {
        color = diff < s.StepMAC ? '#9bde9b' : '#ffb3b3';
      }

      data.push({
        hoverinfo: 'none',
        line: {
          color: 'rgb(0, 0, 0)'
        },
        mode: 'lines',
        type: 'scattergl',
        showlegend: false,
        x: [new Date(s.EndTime), new Date(s.EndTime)],
        y: [0, 1]
      });

      data.push({
        fillcolor: color,
        fill: 'tozeroy',
        hoverinfo: 'none',
        activeShift:
          this.selectedShiftIndex === i && this.inspectBatchOrStep === 'step',
        diffColor: diff < s.StepMAC ? '#9bde9b' : '#ffb3b3',
        typeShift: 'step',
        indexShift: i,
        line: {
          color
        },
        opacity: 1,
        mode: 'lines',
        type: 'scattergl',
        showlegend: false,
        x: [new Date(s.StartTime), new Date(s.EndTime)],
        y: [1, 1]
      });

      // data.push({
      //     hoverinfo: 'none',
      //     line: {
      //         color: 'rgb(153, 192, 255)'
      //     },
      //     mode: 'text',
      //     textposition: 'middle center',
      //     text: [s.ProdStatus_Desc],
      //     type: 'scattergl',
      //     showlegend: false,
      //     x: [
      //         new Date(new Date(s.StartTime).getTime() + new Date(s.EndTime).getTime()).getTime() / 2
      //     ],

      //     y: [0.5]
      // });
    });

    data.push(this.generateClickHelperPoints(1, 'step'));
    data.push(this.generateClickHelperPoints(0.5, 'step'));
    data.push(this.generateClickHelperPoints(-0.5, 'batch'));
    data.push(this.generateClickHelperPoints(-1, 'batch'));

    return data.filter((dt) => dt);
  }

  get batchData(): BatchAndStepData[] {
    return this.stepsAndBatches
      .filter((x: any) => x.ProdStatus_Desc === 'Batch')
      .filter((x, i) => i <= this.bigSet && i >= this.smallSet);
  }

  get stepData(): BatchAndStepData[] {
    // return this.stepsAndBatches.filter((x: any) => x.ProdStatus_Desc !== 'Batch').filter((x: LossData) => {
    //     return this.batchData.findIndex(ba => moment(x.StartTime).isBetween(ba.StartTime, ba.EndTime) || moment(x.EndTime).isBetween(ba.StartTime, ba.EndTime)) > -1;
    // });
    return [];
  }

  onPlotlyClick(data: any) {
    this.currentLayout = (this.$el as any).layout;
    const clickedDate = new Date(data.points[0].x),
      isBatch = data.points[0]['y'] < 0,
      dataset = isBatch ? this.batchData : this.stepData;

    this.dataset = dataset;
    this.clickedDate = clickedDate;

    let dataIndex = this.dataset.findIndex(
      (x) =>
        new Date(x.StartTime) <= this.clickedDate &&
        new Date(x.EndTime) >= this.clickedDate
    );

    if (dataIndex === -1) {
      dataIndex = this.lossData.findIndex(
        (x) =>
          new Date(x.StartTime) <= this.clickedDate &&
          new Date(x.EndTime) >= this.clickedDate
      );

      if (dataIndex > -1) {
        return this.$store.dispatch('setSelectedLossIndices', [dataIndex]);
      }
    }

    if (dataIndex !== null && dataIndex > -1) {
      this.dataIndex = dataIndex;
    } else {
      return;
    }

    const rangeStart = moment(dataset[this.dataIndex].StartTime)
        .subtract(1, 'hours')
        .toDate(),
      rangeEnd = moment(dataset[this.dataIndex].EndTime)
        .add(1, 'hours')
        .toDate(),
      shiftStart: Date = this.dataset[this.dataIndex].StartTime,
      shiftEnd: Date = this.dataset[this.dataIndex].EndTime,
      selectedLossIndices: any[] = [];

    this.lossData.forEach((lossData: LossData, i: number) => {
      if (
        moment(lossData.EndTime).isBetween(
          moment(shiftStart),
          moment(shiftEnd).add(10, 'minutes')
        )
      ) {
        selectedLossIndices.push(i);
      }
    });

    this.shouldReLayout = false;
    this.$store.dispatch('setAutoRefresh', false);
    this.$store.dispatch('batchStepOnPlotyClick', {
      rangeStart,
      rangeEnd,
      index: dataIndex,
      dataset: dataset[dataIndex],
      inspectBatchOrStep: isBatch ? 'batch' : 'step',
      selectedLossIndices
    });
  }

  generateClickHelperPoints(yValue, datatype): any {
    // Number of helper points to generate
    const n = 10000,
      dataset = datatype === 'step' ? this.stepData : this.batchData;

    if (!dataset.length) {
      return;
    }

    const startTime: Date = new Date(dataset[dataset.length - 1].StartTime),
      endTime: Date = new Date(dataset[0].EndTime);

    const clickEventHelperPointsX: any[] = [],
      clickEventHelperPointsY: any[] = [],
      interval: number = (endTime.getTime() - startTime.getTime()) / n;

    for (let i = 0; i < n + 1; i++) {
      const newpoint: Date = new Date(startTime.getTime() + interval * i);
      clickEventHelperPointsX.push(newpoint);
      clickEventHelperPointsY.push(yValue);
    }

    return {
      x: clickEventHelperPointsX,
      y: clickEventHelperPointsY,
      name: '',
      hoverinfo: 'none',
      mode: 'lines',
      line: {
        width: 0
      },
      type: 'scattergl',
      showlegend: false
    };
  }

  batchDt(keepRange = null) {
    if (!this.batchData.length) {
      return;
    }

    const range = keepRange
      ? keepRange
      : this.batchData.length && this.batchData[2]
      ? [
          moment(this.batchData[2].StartTime).toDate(),
          moment(this.batchData[0].EndTime).toDate()
        ]
      : [
          moment(this.batchData[this.batchData.length - 1].StartTime).toDate(),
          moment(this.batchData[0].EndTime).toDate()
        ];

    const annoatations: any[] = [];
    this.batchData.forEach((l: BatchAndStepData, i: number) => {
      annoatations.push({
        x:
          new Date(
            new Date(l.StartTime).getTime() + new Date(l.EndTime).getTime()
          ).getTime() / 2,
        y: 0,
        xref: 'x',
        yref: 'paper',
        text: `${l.Prod_Code} / ${l.Prod_Desc}`,
        font: {
          // color: "black",
          size: 10
        },
        showarrow: false,
        arrowhead: 7,
        textangle: 0,
        xanchor: 'center',
        yanchor: 'bottom',
        ax: 0,
        ay: 0
      });
    });

    const layout = {
      hovermode: 'closest',
      yaxis: {
        autorange: false,
        range: [-1, 0],
        showgrid: false,
        zeroline: false,
        showline: false,
        autotick: false,
        ticks: '',
        showticklabels: false,
        fixedrange: true,
        dragmode: 'pan'
      },
      images: ChartsUtils.getLossImagesForBatch(this),
      annotations: annoatations,

      xaxis: {
        range: range
      },

      height: 70,

      margin: {
        t: 0, //top margin
        l: 10, //left margin
        r: 10, //right margin
        b: 30 //bottom margin
      },

      dragmode: 'pan'
    };

    if (this.shouldCreate) {
      this.shouldCreate = false;
      Plotly.newPlot(this.$el, this.plotData, layout, {
        displaylogo: false,
        displayModeBar: false,
        doubleClick: false
      });

      (this.$el as any).on('plotly_click', this.onPlotlyClick);
      (this.$el as any).on('plotly_relayout', (e) => {
        if (e && (e['xaxis.range[0]'] || e['xaxis.range'])) {
          const wholeBatch = this.lossData.filter(
            (x: LossData) => x.LossSource === 'SpeedLoss' && !x.StepMAC
          );
          if (!wholeBatch[this.bigSet] || !wholeBatch[this.smallSet]) {
            return;
          }
          const smallBigDiff = moment(wholeBatch[this.smallSet].EndTime).diff(
            moment(wholeBatch[this.bigSet].StartTime),
            'milliseconds'
          );
          const diff = moment(wholeBatch[this.smallSet].EndTime).diff(
            moment(e['xaxis.range[0]']),
            'milliseconds'
          );

          if (smallBigDiff - diff < smallBigDiff / 3) {
            if (wholeBatch[this.bigSet]) {
              this.bigSet += wholeBatch.findIndex(
                (bt: LossData) =>
                  moment(bt.StartTime).isBetween(
                    moment(e['xaxis.range[0]']),
                    moment(e['xaxis.range[1]'])
                  ) ||
                  moment(bt.EndTime).isBetween(
                    moment(e['xaxis.range[0]']),
                    moment(e['xaxis.range[1]'])
                  )
              );
              if (this.bigSet === -1 || !wholeBatch[this.bigSet + 2]) {
                this.bigSet = wholeBatch.length - 1;
              }
              this.batchDt([e['xaxis.range[0]'], e['xaxis.range[1]']]);
            } else if (this.bigSet !== wholeBatch.length - 1) {
              this.bigSet = wholeBatch.length - 1;
              this.batchDt([e['xaxis.range[0]'], e['xaxis.range[1]']]);
            }
          }
        }
      });
    } else {
      Plotly.react(
        this.$el,
        this.plotData,
        !this.shouldReLayout ? this.currentLayout : layout
      );
    }
  }

  mounted() {
    this.$store.dispatch('shiftTimelineShiftDataChanged', -1);
    this.$store.commit('setTimeRange', [
      moment().subtract(12, 'hours').toDate(),
      moment().add(2, 'hours').toDate()
    ]);
  }

  @Watch('selectedUnit')
  selectedUnitChanged() {
    this.bigSet = 5;
  }

  @Watch('selectedShiftIndex')
  @Watch('lossData')
  lossDataChanged() {
    if (this.lossData !== undefined) {
      this.batchDt();
    }
  }
}
