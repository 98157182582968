











import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  }
})
export default class ToggleAvg extends Vue {
  public loading!: boolean;
}
