var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-end",
      class: {
        expanded: _vm.sidebarOpen,
        active: _vm.activeRightSidebar === "reports"
      }
    },
    [
      _c(
        "button",
        { staticClass: "toggle-settings", on: { click: _vm.toggleSidebar } },
        [_vm._v("Links")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }