









import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SmallLoader extends Vue {
  get innerStyles() {
    return {
      transform: 'scale(' + 40 / 80 + ')'
    };
  }

  get styles() {
    return {
      width: '40px',
      height: '40px',
      margin: '0 auto'
    };
  }
}
