export class Unit {
  public DPPEMQ: string = '';
  public FunctionalLocationRoot: string = '';
  public FunctionalLocationRootId: any;
  public index: number = -1;
  public isBatch: boolean = false;
  public label: string = '';
  public puid: number = 0;
  public PUID: number = 0;
  public type: string = '';
  public Type: string = '';
  public unspecifiedLosses: number = null;
  public Cell: string = '';
  public IsVirtualUnit: boolean = false;
  public showProductOnChart: boolean = false;
  public allowManualLosses: boolean = false;
  public requireMaintenanceNotificationNumberForEquipmentFailure: boolean = false;
  public HideConfirmedEvents: boolean = false;
  public DefaultControlChartDuration: number = 8;
  public splitLossTimeUoT: string = 'min';
  public externalId: number = 0;

  constructor() {}
}
