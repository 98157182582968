var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-form" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-due",
                    label: _vm.$t("addLoss.eventNumber"),
                    "label-for": "input-due"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      small: "",
                      size: "sm",
                      state: !!_vm.eventNumber ? null : false,
                      "aria-describedby": "input-live-help input-live-feedback",
                      trim: ""
                    },
                    model: {
                      value: _vm.eventNumber,
                      callback: function($$v) {
                        _vm.eventNumber = $$v
                      },
                      expression: "eventNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-due",
                    label: _vm.$t("addLoss.material"),
                    "label-for": "input-due"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      state: !!_vm.material ? null : false,
                      options: _vm.options,
                      size: "sm"
                    },
                    model: {
                      value: _vm.material,
                      callback: function($$v) {
                        _vm.material = $$v
                      },
                      expression: "material"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-due",
                    label: _vm.$t("addLoss.producedQuantity"),
                    "label-for": "input-due"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      small: "",
                      size: "sm",
                      type: "number",
                      state:
                        _vm.producedQuantity === undefined ||
                        _vm.producedQuantity === null
                          ? false
                          : null,
                      "aria-describedby": "input-live-help input-live-feedback",
                      trim: ""
                    },
                    model: {
                      value: _vm.producedQuantity,
                      callback: function($$v) {
                        _vm.producedQuantity = $$v
                      },
                      expression: "producedQuantity"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("addLoss.startTime"),
                    "label-for": "input-due"
                  }
                },
                [
                  _c("DateTimePicker", {
                    attrs: {
                      size: "sm",
                      value: _vm.startTime,
                      state: !!_vm.startTime ? null : false,
                      required: true,
                      "start-weekday": 1
                    },
                    on: { "on-change": _vm.onStartChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("addLoss.endTime"),
                    "label-for": "input-due"
                  }
                },
                [
                  _c("DateTimePicker", {
                    attrs: {
                      size: "sm",
                      min: _vm.startTime,
                      disabled: !_vm.startTime,
                      value: _vm.endTime,
                      state: !!_vm.endTime ? null : false,
                      required: true,
                      "start-weekday": 1
                    },
                    on: { "on-change": _vm.onEndChange }
                  })
                ],
                1
              ),
              _vm.isBeforeStart
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.$t("addLoss.endTimeBeforeStart")))
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "b-button",
            {
              staticClass: "cancel-btn",
              attrs: { size: "sm", variant: "secondary" },
              on: {
                click: function($event) {
                  return _vm.cancelEvent()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("addLoss.cancelEvent")))]
          ),
          _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.saveEvent()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("addLoss.createEvent")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }