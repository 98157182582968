



































































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import DateTimePicker from '@/components/AddLossModal/DateTimePicker.vue';
import EventsTable from '@/components/AddLossModal/EventsTable.vue';
import { ProductionEvent } from '@/classes/productionEvent';
import {
  createEvent,
  getProductionEvents,
  getRawMaterials
} from '@/services/api/AddLossApi';
import { mapGetters } from 'vuex';
import moment from 'moment';
import RawMaterialTable from '@/components/AddLossModal/RawMaterialTable.vue';
import { Utils } from '@/utils';

@Component({
  components: { DateTimePicker, EventsTable, RawMaterialTable },
  computed: {
    ...mapGetters({
      selectedUnit: 'getSelectedUnit',
      path: 'getPath',
      configuration: 'getConfiguration',
      historyDays: 'getHistoryDays'
    })
  }
})
export default class AddLossModal extends Vue {
  @Ref('add-loss-modal') public readonly modal!: any;
  @Prop({ required: true }) lossType: string = '';
  public selectedUnit!: any;
  public path!: string;
  public lossModalKey: number = 1;
  public productionEvents: ProductionEvent[] = null;
  public rawMaterial: any[] = [];
  public startTime: string = '';
  public endTime: string = '';
  public configuration!: any;
  public eventSelected: ProductionEvent = null;
  public rawMaterialSelected: any = null;
  public lossQuantity = 1;
  public historyDays!: number;

  public get endMax() {
    return this.lossType !== 'downtime'
      ? false
      : moment().endOf('day').format();
  }

  public get isBeforeStart() {
    return moment(this.endTime).isBefore(this.startTime);
  }

  public get startMax() {
    return this.lossType === 'downtime' ? moment().format() : false;
  }

  public modalShown(): void {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  public onStartChange(val): void {
    this.startTime = val;
    this.endTime = '';
  }

  public onEndChange(val): void {
    this.endTime = val;
    this.fetchProductionEvents();
  }

  @Emit('onClose')
  public modalTeardown(): void {
    this.rawMaterial = null;
    this.productionEvents = null;
    this.startTime = '';
    this.endTime = '';
    this.eventSelected = null;
    this.lossQuantity = 1;
    this.lossModalKey += 1;
  }

  public openModal(): void {
    this.modal.show();
  }

  public async onEventSelected(event) {
    this.eventSelected = event;
    this.rawMaterial = await getRawMaterials(
      this.selectedUnit.puid,
      event.code
    );
  }

  public onRawMaterialSelected(rawMaterial): void {
    this.rawMaterialSelected = rawMaterial;
  }

  public async save() {
    if (!this.validate()) {
      return;
    }

    switch (this.lossType) {
      case 'downtime':
        await createEvent(this.path, this.lossType, {
          assetId: this.selectedUnit.puid,
          startTime: moment(this.startTime).utc().format(),
          endTime: moment(this.endTime).utc().format(),
          duration: moment
            .duration(moment.utc(this.endTime).diff(moment.utc(this.startTime)))
            .asMinutes()
        });
        break;

      case 'performance':
        await createEvent(this.path, this.lossType, {
          assetId: this.selectedUnit.puid,
          peEventId: this.eventSelected.id,
          startTime: moment.utc(this.eventSelected.startTime).format(),
          endTime: moment.utc(this.eventSelected.endTime).format(),
          duration: Number(this.lossQuantity)
        });
        break;
      case 'quality':
        await createEvent(this.path, this.lossType, {
          assetId: this.selectedUnit.puid,
          peEventId: this.eventSelected.id,
          timestamp: this.eventSelected.endTime,
          amount: Number(this.lossQuantity)
        });
        break;
      case 'pem':
        await createEvent(this.path, this.lossType, {
          assetId: this.selectedUnit.puid,
          peEventId: this.eventSelected.id,
          prodId: this.rawMaterialSelected?.rmProdCode,
          formulation: this.rawMaterialSelected?.formulationName,
          startTime: moment.utc(this.eventSelected.startTime).format(),
          endTime: moment.utc(this.eventSelected.endTime).format(),
          amount: Number(this.lossQuantity)
        });
        break;
    }

    for (const request of Utils.refreshUnit(this, this.historyDays, true)) {
      Utils.triggerBackend(request, this);
    }

    this.modalTeardown();
  }

  public validate(): boolean {
    return !!(
      (this.lossType === 'downtime' &&
        this.startTime &&
        this.endTime &&
        !this.isBeforeStart) ||
      ((this.lossType === 'performance' || this.lossType === 'quality') &&
        this.eventSelected?.id &&
        this.lossQuantity) ||
      (this.lossType === 'pem' &&
        this.eventSelected?.id &&
        this.lossQuantity &&
        this.rawMaterialSelected?.formulationName)
    );
  }

  public async fetchProductionEvents(): Promise<void> {
    if (
      !this.startTime ||
      !this.endTime ||
      !this.selectedUnit ||
      this.lossType === 'downtime'
    ) {
      return;
    }
    this.productionEvents = await getProductionEvents(
      this.startTime,
      this.endTime,
      this.selectedUnit.puid
    );
  }

  public async refreshData(): Promise<void> {
    await this.fetchProductionEvents();
  }

  @Watch('lossType')
  public async lossTypeChanged(): Promise<void> {
    if (this.lossType !== 'downtime') {
      this.startTime = moment().subtract(3, 'days').format();
      this.endTime = moment().format();
      await this.fetchProductionEvents();
    }
  }
}
