

















import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class QualityPemAuditTable extends Vue {
  @Prop({ required: true, default: null }) items: any[];
  public fields = [
    {
      label: this.$t('auditTrail.updateTime'),
      key: 'updateTime',
      sortable: true,
      formatter(value) {
        return moment(value).format('YYYY-MM-DD HH:mm');
      }
    },
    {
      label: this.$t('auditTrail.updateUser'),
      key: 'updateUser',
      sortable: true
    },
    {
      label: this.$t('auditTrail.timestamp'),
      key: 'timestamp',
      sortable: true,
      formatter(value) {
        return moment(value).format('YYYY-MM-DD HH:mm');
      }
    },
    {
      label: this.$t('auditTrail.productionEvent'),
      key: 'productionEvent',
      sortable: true
    },
    {
      label: this.$t('auditTrail.amountParameter'),
      key: 'amountParameter',
      sortable: true
    },
    {
      label: this.$t('auditTrail.enteredIn'),
      key: 'enteredIn',
      sortable: true
    },
    {
      label: this.$t('auditTrail.location'),
      key: 'location',
      sortable: true
    },
    {
      label: this.$t('auditTrail.fault'),
      key: 'fault',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause1'),
      key: 'cause1',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause2'),
      key: 'cause2',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause3'),
      key: 'cause3',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause4'),
      key: 'cause4',
      sortable: true
    }
  ];
}
