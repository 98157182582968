


































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { debounce } from 'underscore';

const methods: any = {
  methods: {
    debounceInput: debounce(function (this: any, e: any) {
      this.search = e.target.value;
    }, 300)
  }
};

@Component({
  ...methods
})
export default class NewConversationList extends Vue {
  @Ref('modalGroup') readonly modal!: any;
  @Prop() readonly usersList: any;
  public titleState: any = null;
  public people: any[] = [];
  public subject: string = null;
  public search: string = null;

  checkFormValidity() {
    let isValid = true;
    if (!this.subject) {
      this.titleState = false;
      isValid = false;
    }
    return isValid;
  }

  get users() {
    if (this.search) {
      return this.usersList.filter((user) =>
        user.displayName.toLowerCase().includes(this.search.toLowerCase())
      );
    }

    return this.usersList;
  }

  addToPeople(people) {
    const index = this.people.findIndex(
      (pep) => pep.user_id === people.user_id
    );

    if (index > -1) {
      return this.people.splice(index, 1);
    }

    this.people.push(people);
  }

  newGroup() {
    if (this.people.length < 1) {
      return;
    }
    if (this.people.length >= 2) {
      return this.openModal();
    }
    this.$emit('newGroup', { people: this.people, subject: null });
  }

  sumbitBtn() {
    if (!this.checkFormValidity()) {
      return this.openModal();
    }
    this.$emit('newGroup', { people: this.people, subject: this.subject });
  }

  openModal() {
    setTimeout(() => this.modal.show(), 0);
  }

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  modalTeardown() {
    this.subject = null;
  }

  back() {
    this.$emit('back');
  }

  hasActive(people) {
    if (this.people.findIndex((pep) => pep.user_id === people.user_id) > -1) {
      return 'active';
    }
    return '';
  }

  removePeople(people) {
    const index = this.people.findIndex(
      (pep) => pep.user_id === people.user_id
    );
    if (index > -1) {
      this.people.splice(index, 1);
    }
  }
}
