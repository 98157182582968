








































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import DowntimeAuditTable from '@/components/AuditModal/DowntimeAuditTable.vue';
import PerformanceAuditTable from '@/components/AuditModal/PerformanceAuditTable.vue';
import QualityPemAuditTable from '@/components/AuditModal/QualityPemAuditTable.vue';

@Component({
  components: {
    DowntimeAuditTable,
    PerformanceAuditTable,
    QualityPemAuditTable
  }
})
export default class AuditModal extends Vue {
  @Ref('auditModal') public readonly modal!: any;
  @Prop({ default: {} }) readonly lossData: any;
  @Prop({ default: [] }) readonly auditData: any;

  public openModal(): void {
    this.modal.show();
  }

  public modalShown(): void {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }
}
