var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-sidebar",
      on: { mouseover: _vm.sidebarHoverIn, mouseleave: _vm.sidebarHoverOut }
    },
    [
      _c("div", { staticClass: "logo" }, [
        _c("div", {
          staticClass: "logo-img",
          class: { "logo-small": _vm.isSidebarCollapsed && !_vm.hover },
          style: { backgroundImage: "url(" + _vm.logo + ")" }
        }),
        _c("div", { staticClass: "btn-hamburger" }, [
          _c(
            "button",
            { staticClass: "ham", on: { click: _vm.toggleSidebar } },
            [_vm._m(0)]
          )
        ])
      ]),
      _c("div", { staticClass: "side-content" }, [
        _c(
          "section",
          { staticClass: "sidebar-section" },
          [
            _c(
              "perfect-scrollbar",
              { staticStyle: { "max-height": "50% !important" } },
              [
                _c("sidebar-menu", {
                  attrs: {
                    collapsed: _vm.isSidebarCollapsed && !_vm.hover,
                    menu: _vm.menu
                  },
                  on: { "item-click": _vm.onItemClick }
                })
              ],
              1
            ),
            _c(
              "perfect-scrollbar",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.isSidebarCollapsed ||
                      (_vm.isSidebarCollapsed && _vm.hover),
                    expression:
                      "!isSidebarCollapsed || (isSidebarCollapsed && hover)"
                  }
                ],
                staticStyle: { "max-height": "45% !important" }
              },
              [_c("VariableSelector")],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ham-box" }, [
      _c("span", { staticClass: "ham-box-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }