import moment from 'moment';
import { MathService } from './mathService';
import { Variable } from './../classes/variable';
import { Trend } from './../classes/trend';
import { Threshold } from './../classes/threshold';

export class ChartService {
  static getSelectedVariablesStats(ctx: any): any[] {
    const output: any[] = [];

    let index, selectedShift;

    if (ctx.selectedShiftIndex === -1 && ctx.selectedShift) {
      index = 0;
    } else if (ctx.selectedShiftIndex === -1) {
      index = -1;
    } else {
      index = ctx.selectedShiftIndex;
    }

    if (!ctx.selectedShift && index > -1 && ctx.shiftData[index]) {
      selectedShift = [
        ctx.shiftData[index].Start_Time,
        ctx.shiftData[index].End_Time
      ];
    } else if (ctx.selectedShift) {
      selectedShift = [
        ctx.selectedShift.Start_Time,
        ctx.selectedShift.End_Time
      ];
    } else {
      selectedShift = [];
    }

    ctx.selectedVariables.map((variable: Variable, i) => {
      const minusTimeRange =
        ctx.shiftData[index + 1] && index > -1
          ? [
              ctx.shiftData[index + 1].Start_Time,
              ctx.shiftData[index + 1].End_Time
            ]
          : [];

      const plusTimeRange = ctx.shiftData[index - 1]
        ? [
            ctx.shiftData[index - 1].Start_Time,
            ctx.shiftData[index - 1].End_Time
          ]
        : [];

      output.push({
        name: variable.label,
        color: variable.color,
        visible: ChartService.getStats(ctx, variable, ctx.timeRange, false, i),
        minus: ChartService.getStats(ctx, variable, minusTimeRange, false, i),
        current: ChartService.getStats(ctx, variable, selectedShift, false, i),
        plus: ChartService.getStats(ctx, variable, plusTimeRange, false, i),
        selected: ChartService.getStats(
          ctx,
          variable,
          ctx.selectedTrendAreaTimeStamps,
          true,
          i
        )
      });
    });

    return output;
  }

  static getAccumulatedlossValues(
    p: any[],
    isPositiveLoss: boolean = true
  ): any {
    let accumulatedLoss = 0;
    if (!p.length) {
      return {
        x: [new Date()],
        y: [0]
      };
    }
    const output = {
      x: [p[0].x],
      y: [0]
    };
    let positiveLoss = isPositiveLoss ? 1 : -1;

    p.map((dt) => {
      accumulatedLoss += positiveLoss * dt.y;
      output.y.push(accumulatedLoss);
      output.x.push(dt.x);
    });
    return output;
  }

  static getShiftProductionData(
    ctx: any,
    timeRange = null,
    trendData = null
  ): any {
    const shiftProductionData: any = [];
    const xyDataOutput: any = [];
    if (!ctx.thresholdData) {
      return [];
    }

    const thresholdDataObject: any = ctx.thresholdData.reduce(
      (acc: Threshold, cur: any) => {
        acc[moment(cur.Timestamp).format('YYYY-MM-DD')] = cur;
        return acc;
      },
      {}
    );

    let timeRanges;
    if (!timeRange) {
      timeRanges = ctx.selectedShift
        ? [
            ctx.selectedShift.Start_Time || ctx.selectedShift.StartTime,
            ctx.selectedShift.End_Time || ctx.selectedShift.EndTime
          ]
        : [ctx.timeRange[0], ctx.timeRange[1]];
    } else {
      timeRanges = timeRange;
    }
    let trendValues;
    if (timeRange && trendData) {
      trendValues = trendData.map((trend: any) => {
        let threshold =
          thresholdDataObject[
            moment(new Date(trend.TimeStamp)).format('YYYY-MM-DD')
          ];
        if (!threshold) {
          threshold = ctx.thresholdData[ctx.thresholdData.length - 1];
        }

        const value = trend.y;

        if (ctx.selectedUnit.type === 'PEM') {
          return {
            value: Number(threshold.MAC - value),
            yReference: threshold.MAC
          };
        } else {
          return {
            value: Number(threshold.MarketDemand - value),
            yReference: threshold.MarketDemand
          };
        }
      });

      const dataPoints = [];

      timeRange.map((dt, i) => {
        dataPoints.push({
          x: dt,
          y: trendValues[i].value,
          yReference: trendValues[i].yReference
        });
      });

      const xyData = ChartService.getAccumulatedlossValues(dataPoints);

      xyDataOutput.push(xyData);
    } else {
      ctx.selectedVariables.map((variable, i) => {
        if (variable.VarType !== 'MES') {
          trendValues = ctx.trendData.filter(
            (trend: Trend) =>
              variable.id in trend &&
              moment(new Date(trend.TimeStamp)).isBetween(
                moment(new Date(timeRanges[0])),
                moment(new Date(timeRanges[1]))
              )
          );
        } else {
          trendValues = ctx.mesTrends.filter(
            (t) =>
              t.Var_ID.toString() === variable.Input_Tag &&
              moment(t.Result_On).isBetween(
                moment(new Date(timeRanges[0])),
                moment(new Date(timeRanges[1]))
              )
          );
        }
        trendValues = trendValues.map((trend: Trend) => {
          let threshold =
            thresholdDataObject[
              moment(new Date(trend.TimeStamp)).format('YYYY-MM-DD')
            ];
          if (!threshold) {
            threshold = ctx.thresholdData[ctx.thresholdData.length - 1];
          }
          const value = trend[variable.id];

          if (ctx.selectedUnit.type === 'PEM') {
            return {
              value: Number(threshold.MAC - value),
              yReference: threshold.MAC
            };
          } else {
            return {
              value: Number(threshold.MarketDemand - value),
              yReference: threshold.MarketDemand
            };
          }
        });

        const dataPoints = [];

        const filteredDates: any[] = ctx.selectedTrendDates[i].filter(
          (thres: any) =>
            moment(new Date(thres)).isBetween(
              moment(new Date(timeRanges[0])),
              moment(new Date(timeRanges[1]))
            )
        );

        filteredDates.map((dt, i) => {
          dataPoints.push({
            x: dt,
            y: trendValues[i].value,
            yReference: trendValues[i].yReference
          });
        });

        const xyData = ChartService.getAccumulatedlossValues(dataPoints);

        xyDataOutput.push(xyData);

        shiftProductionData.push({
          x: xyData.x,
          y: xyData.y,
          yaxis: `y${i + 1}`,
          mode: 'lines',
          name: ctx.selectedVariables[i].label,
          text: ctx.selectedVariables[i].UoM,
          showlegend: true,
          fill: 'tonexty',
          type: 'scatter',
          line: {
            width: 1,
            color: '#333'
          },
          marker: {
            color: 'blue',
            size: 1,
            symbol: 'circle'
          }
        });
      });
    }
    if (timeRange) {
      return xyDataOutput;
    }

    return shiftProductionData;
  }

  static variableComparison(ctx: any): any {
    const variableComparisonData: any = [],
      trendValues: any = MathService.normalizedDifferenceTwoValue(
        ctx.selectedTrendValues[0],
        ctx.selectedTrendValues[1]
      );

    variableComparisonData.push({
      x: ctx.selectedTrendDates[0],
      y: trendValues,
      yaxis: `y${0}`,
      mode: 'lines',
      showlegend: true,
      fill: 'tonexty',
      type: 'scatter',
      line: {
        width: 1,
        color: '#333'
      },
      marker: {
        color: 'blue',
        size: 1,
        symbol: 'circle'
      }
    });

    return variableComparisonData;
  }

  static secondChartData(ctx): any {
    if (ctx.selectedTrendValues.length === 1) {
      return ChartService.getShiftProductionData(ctx);
    } else if (ctx.selectedTrendValues.length === 2) {
      return ChartService.variableComparison(ctx);
    } else {
      return null;
    }
  }

  private static getStats(
    ctx: any,
    variable: any,
    timeRange: any[] = [],
    isSelection = false,
    index = null
  ) {
    if ((timeRange.length < 2 && !isSelection) || !timeRange) {
      return {
        avg: 0,
        max: 0,
        min: 0,
        std: 0,
        first: 0,
        last: 0,
        delta: 0,
        acl: 0,
        hr: 0
      };
    }

    let trendData: any;

    if (isSelection) {
      trendData = timeRange
        .filter((tm: any) => tm.data.name === variable.label)
        .map((tm: any) => tm.y);
    } else {
      if (variable.VarType !== 'MES') {
        trendData = ctx.trendData
          .filter(
            (trend: Trend) =>
              variable.id in trend &&
              moment(new Date(trend.TimeStamp)).isBetween(
                moment(new Date(timeRange[0])),
                moment(new Date(timeRange[1]))
              )
          )
          .map((trend: Trend) => trend[variable.id]);
      } else {
        trendData = ctx.mesTrends
          .filter(
            (t) =>
              t.Var_ID.toString() === variable.Input_Tag &&
              moment(t.Result_On).isBetween(
                moment(new Date(timeRange[0])),
                moment(new Date(timeRange[1]))
              )
          )
          .map((v) => parseFloat(v.Result));
      }
    }

    let value = trendData.length ? trendData : [0];

    if (!value.length) {
      value = [0];
    }

    let selectionTrendData;
    let accuData = [];
    if (isSelection) {
      if (timeRange.length) {
        selectionTrendData = timeRange
          .filter((tm: any) => tm.data.name === variable.label)
          .map((time) => {
            return {
              y: time.y,
              TimeStamp: time.x
            };
          });
        timeRange = timeRange
          .filter((tm: any) => tm.data.name === variable.label)
          .map((time) => time.x);
      }
      try {
        accuData = ChartService.getShiftProductionData(
          ctx,
          timeRange,
          isSelection ? selectionTrendData : null
        )[0];
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        accuData = ChartService.getShiftProductionData(ctx, timeRange, null)[
          index
        ];
      } catch (e) {
        console.log(e);
      }
    }

    const hr = moment(timeRange[timeRange.length - 1]).diff(timeRange[0], 'm'),
      hours = hr / 60,
      rhours = Math.floor(hours),
      minutes = (hours - rhours) * 60,
      rminutes = Math.round(minutes);

    value = value.map((val) => Number(val));

    var avgTemp = Number(MathService.average(value).toFixed(2));
    var hrTemp = Number(`${rhours}.${rminutes}`).toFixed(2);

    return {
      name: variable.label,
      avg: avgTemp,
      max: Number(Math.max(...value).toFixed(2)),
      min: Number(Math.min(...value).toFixed(2)),
      std: Number(MathService.standardDeviation(value).toFixed(2)),
      first: Number(value[0].toFixed(2)),
      last: Number(value[value.length - 1].toFixed(2)),
      delta: Number((value[value.length - 1] - value[0]).toFixed(2)),
      //@ts-ignore
      // acl: accuData.length ? Number(accuData.y[accuData.y.length - 1].toFixed(2)) : -1,
      acl: Number((avgTemp * hrTemp).toFixed(2)),
      hr: hrTemp
    };
  }
}
