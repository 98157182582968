import to from './../to';
import axios from 'axios';
import { Config } from './../../config';
import { TransformChat } from './../../helpers/transformChat';

export class ChatApi {
  private static conversationId: number = null;

  static async getConversations(args: any) {
    let err, conversations: any;

    if (process.env.NODE_ENV === 'development') {
      [err, conversations] = await to(
        axios.post(
          `${Config.prefixUrl}conversations${
            args.search ? '?search=' + args.search : ''
          }`,
          {
            username: args.username,
            path: args.path
          }
        )
      );
      conversations = conversations.data;
    } else {
      [err, conversations] = await to(
        axios.post(
          `${Config.prefixUrl}`,
          { InputJson: JSON.stringify(args) },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
            }
          }
        )
      );
      console.log(conversations);
      conversations = JSON.parse(conversations.data.rows[0].result).rows;
      const convModified = [];
      conversations.map((conv) => {
        const index = convModified.findIndex(
          (convM) => convM.conversation_id === conv.Group_ID
        );

        if (index > -1) {
          convModified[index].conversationUsers.push({
            conversation_id: conv.Group_ID,
            username: conv.Username
          });
        } else {
          convModified.push(TransformChat.transformToConversationGroup(conv));
        }
      });
      console.log(convModified);
      conversations = convModified;
    }

    if (err) {
      throw new Error(err);
    }

    return conversations;
  }

  static async createConversation(data, conversationList?) {
    let err, conversation, groupId;

    if (process.env.NODE_ENV === 'development') {
      if (data.type && data.type === 'wish') {
        [err, conversation] = await to(
          axios.post(`${Config.prefixUrl}create-wishlist`, data)
        );
      } else {
        [err, conversation] = await to(
          axios.post(`${Config.prefixUrl}create-conversation`, data)
        );
      }

      groupId = conversation.data.conversationId;
    } else {
      [err, conversation] = await to(
        axios.post(
          `${Config.prefixUrl}`,
          {
            InputJson: JSON.stringify({
              method: 'chatCreateGroup',
              parameters: data
            })
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
            }
          }
        )
      );

      const result = JSON.parse(conversation.data.rows[0].result).rows[0];
      console.log(result);
      groupId = result.Group_ID;

      console.log(TransformChat.transformToConversationGroup(result));
      conversationList.push(TransformChat.transformToConversationGroup(result));

      if (err) {
        throw new Error(err);
      }

      const usrData = {
        method: 'chatInsertGroupUsers',
        parameters: {
          UserList: data.people,
          Group_ID: groupId
        }
      };

      [err] = await to(
        axios.post(`${Config.prefixUrl}`, {
          InputJson: JSON.stringify(usrData)
        })
      );

      if (err) {
        throw new Error(err);
      }
    }

    return await this.setConversationId(
      groupId,
      data.username,
      data.path,
      conversationList
    );
  }

  static async setConversationId(id, username, path, conversationList?) {
    this.conversationId = id;
    return await this.getConversation({ username, path }, conversationList);
  }

  static async getConversation(args, conversations?) {
    let err, conversation;

    if (process.env.NODE_ENV === 'development') {
      [err, conversation] = await to(
        axios.post(`${Config.prefixUrl}conversation/${this.conversationId}`, {
          username: args.username,
          path: args.path
        })
      );
    } else {
      [err, conversation] = await to(
        axios.post(
          `${Config.prefixUrl}`,
          {
            InputJson: JSON.stringify({
              method: 'chatGetMessages',
              parameters: {
                Group_ID: this.conversationId
              }
            })
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
            }
          }
        )
      );
      conversation = JSON.parse(conversation.data.rows[0].result).rows;
      console.log(conversations);
      const findConversation = conversations.find(
        (conv) => conv.conversation_id === this.conversationId
      );
      findConversation.messages = conversation.map((conv) => {
        return {
          id: conv.Message_ID,
          message: conv.Content,
          username: conv.Username,
          createdAt: conv.Created_On
        };
      });
      conversation = findConversation;
      console.log(conversation);
    }
    if (err) {
      throw new Error(err);
    }

    return conversation;
  }

  static async getUnreadMessages(username, path) {
    let err, messages;

    [err, messages] = await to(
      axios.post(`${Config.prefixUrl}unread-messages`, { username, path })
    );

    if (err) {
      throw new Error(err);
    }

    return messages.data;
  }

  static async lastAccessed() {
    let err;

    if (!this.conversationId) {
      return;
    }

    [err] = await to(
      axios.post(
        `${Config.prefixUrl}`,
        {
          InputJson: JSON.stringify({
            method: 'chatStoreLastAccessed',
            parameters: {
              Group_ID: this.conversationId
            }
          })
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
          }
        }
      )
    );

    if (err) {
      throw new Error(err);
    }
  }

  static async longPollGroups() {
    let err, groups;

    [err, groups] = await to(
      axios.post(
        `${Config.prefixUrl}`,
        {
          InputJson: JSON.stringify({
            method: 'chatLongPollGroups',
            parameters: {
              foo: 'bar'
            }
          })
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
          }
        }
      )
    );

    if (err) {
      throw new Error(err);
    }

    groups = JSON.parse(groups.data.rows[0].result).rows;
    const convModified = [];
    groups.map((conv) => {
      const index = convModified.findIndex(
        (convM) => convM.conversation_id === conv.Group_ID
      );

      if (index > -1) {
        convModified[index].conversationUsers.push({
          conversation_id: conv.Group_ID,
          username: conv.Username
        });
      } else {
        convModified.push(TransformChat.transformToConversationGroup(conv));
      }
    });
    groups = convModified;

    return groups;
  }

  static async longPollMessages(lastMessageId: number) {
    let err, newMessages;

    [err, newMessages] = await to(
      axios.post(
        `${Config.prefixUrl}`,
        {
          InputJson: JSON.stringify({
            method: 'chatLongPollMessages',
            parameters: {
              Group_ID: this.conversationId,
              lastMessageID: lastMessageId
            }
          })
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
          }
        }
      )
    );
    newMessages = JSON.parse(newMessages.data.rows[0].result).rows;

    if (err) {
      throw new Error(err);
    }

    return newMessages.map((msg) => {
      return {
        id: msg.Message_ID,
        message: msg.Content,
        username: msg.Username,
        createdAt: msg.Created_On
      };
    });
  }

  static async sendMessage(msg, path) {
    let err;

    msg.conversation_id = this.conversationId;

    if (process.env.NODE_ENV === 'development') {
      [err] = await to(
        axios.post(`${Config.prefixUrl}send-message`, { message: msg, path })
      );
    } else {
      [err] = await to(
        axios.post(
          `${Config.prefixUrl}`,
          {
            InputJson: JSON.stringify({
              method: 'chatInsertMessage',
              parameters: {
                Content: msg.message,
                Group_ID: this.conversationId
              }
            })
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              appKey: '48e0126a-25d1-48e9-ad7c-d59617a1525d'
            }
          }
        )
      );
    }

    if (err) {
      throw new Error(err);
    }
  }
}
