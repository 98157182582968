

















import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class DowntimeAuditTable extends Vue {
  @Prop({ required: true, default: null }) items: any[];
  public fields = [
    {
      label: this.$t('auditTrail.updateTime'),
      key: 'updateTime',
      sortable: true,
      formatter(value) {
        return moment(value).format('YYYY-MM-DD HH:mm');
      }
    },
    {
      label: this.$t('auditTrail.updateUser'),
      key: 'updateUser',
      sortable: true
    },
    {
      label: this.$t('auditTrail.startTime'),
      key: 'startTime',
      sortable: true,
      formatter(value) {
        return moment(value).format('YYYY-MM-DD HH:mm');
      }
    },
    {
      label: this.$t('auditTrail.endTime'),
      key: 'endTime',
      sortable: true,
      formatter: (value) => {
        return value ? moment(value).format('YYYY-MM-DD HH:mm') : this.$t('auditTrail.noEndTime');
      }
    },
    {
      label: this.$t('auditTrail.duration'),
      key: 'duration',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause1'),
      key: 'cause1',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause2'),
      key: 'cause2',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause3'),
      key: 'cause3',
      sortable: true
    },
    {
      label: this.$t('auditTrail.cause4'),
      key: 'cause4',
      sortable: true
    }
  ];
}
