import to from '@/services/to';
import axios from 'axios';

export async function getDowntimeAudits(eventId: string, path: string) {
  const [err, audit] = await to(
    axios.get(`api/audit/downtime`, {
      params: {
        eventId,
        path
      }
    })
  );

  if (err) {
    throw new Error(err);
  }

  return audit.data;
}

export async function getPerformanceAudits(eventId: string, path: string) {
  const [err, audit] = await to(
    axios.get(`api/audit/performance`, {
      params: {
        eventId,
        path
      }
    })
  );

  if (err) {
    throw new Error(err);
  }

  return audit.data;
}

export async function getQualityPemAudits(eventId: string, path: string) {
  const [err, audit] = await to(
    axios.get(`api/audit/quality-pem`, {
      params: {
        eventId,
        path
      }
    })
  );

  if (err) {
    throw new Error(err);
  }

  return audit.data;
}
