var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-header" }, [
    _c("div", { staticClass: "header-content" }, [
      _c("div", { staticClass: "header-content-left" }, [
        _c("div", [
          _c("div", { staticClass: "btn-toolbar pull-right" }, [
            _c("h1", { staticClass: "widget-title" }, [
              _vm._v(_vm._s(_vm.widgetTitle))
            ])
          ]),
          _c(
            "div",
            {
              staticStyle: {
                float: "left",
                color: "#ede9d0",
                "padding-left": "1rem"
              }
            },
            [_vm._v(" " + _vm._s(_vm.environment) + _vm._s(_vm.database) + " ")]
          )
        ])
      ]),
      _c("div", { staticClass: "header-content-right" }, [
        _c("div", { staticClass: "text-right" }, [
          _c("p", { staticClass: "header-details" }, [
            _c("span", { staticClass: "version" }, [
              _vm._v(_vm._s(_vm.version))
            ]),
            _c("br"),
            !_vm.isEnglish
              ? _c("span", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.useEnglish }
                    },
                    [_vm._v("English")]
                  ),
                  _vm._v(" | ")
                ])
              : _vm._e(),
            _vm._v("Week: "),
            _c("b", [_vm._v(_vm._s(_vm.currentWeek))]),
            _vm._v(" | Last updated: "),
            _c("b", [_vm._v(_vm._s(_vm.lastUpdatedString))]),
            _vm._v(" | "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.selected }
              },
              [_vm._v("Sign out")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }