



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { isEmpty } from 'underscore';

@Component({
  computed: {
    ...mapGetters({
      functionalLocation: 'getFunctionalLocation',
      selectedUnit: 'getSelectedUnit'
    })
  }
})
export default class Rcps extends Vue {
  @Prop({ default: null }) readonly lossData: any;
  @Prop({ default: true }) readonly showSplitLoss: any;
  public rcpsComments: any = null;
  public functionalLocation!: any;
  public RCPSStatus: any = '';
  public splitDuration: any = null;
  public splitQuantity: any = null;
  public showSplitEvent: boolean = false;
  public isEmpty: any = isEmpty;
  public splitType: string = 'time';

  get splitDurationState() {
    return !(Number(this.lossData.LossMinutes) <= Number(this.splitDuration))
  }

  get splitQuantityState() {
    if (Number(this.lossData.LossQuantity) <= Number(this.splitQuantity))
      return false;
    else if (this.lossData.isEmptyEndDate)
      return false;
    return true;
    //return !(Number(this.lossData.LossQuantity) <= Number(this.splitQuantity)) || !(this.lossData.EndTime === null) 
  }

  mounted() {
    this.RCPSStatus = this.lossData.RCPSStatus;
    this.rcpsComments = this.lossData.RCPSComments || null;
  }

  @Watch('lossData')
  lossDataChange() {
    this.RCPSStatus = this.lossData.RCPSStatus;
  }
  @Watch('splitType')
  splitTypeChanged() {
    if (this.splitType === 'time') {
      this.splitQuantity = null;
    } else {
      this.splitDuration = null;
    }
  }
}
