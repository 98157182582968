



import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Plotly from 'plotly.js-dist/plotly.js';
// import config from './config.js'

@Component({
  components: {
    Plotly
  },
  computed: {
    ...mapGetters({
      configuration: 'getConfiguration'
    })
  }
})
export default class PlantMap extends Vue {
  public configuration!: any;

  mounted() {
    function unpack(rows, key) {
      return rows.map(function (row) {
        return row[key];
      });
    }

    const rows = Object.keys(this.configuration).map(
      (r) => this.configuration[r]
    );
    // const rows = Object.keys(config).map(r => config[r])

    var data = [
      {
        type: 'scattergeo',
        lon: unpack(rows, 'long'),
        lat: unpack(rows, 'lat'),
        hoverinfor: unpack(rows, 'Title'),
        hoverinfo: 'text',
        text: unpack(rows, 'Title'),
        mashup: unpack(rows, 'Mashup'),
        mode: 'markers',
        marker: {
          size: 8,
          opacity: 0.8,
          reversescale: true,
          autocolorscale: false,
          symbol: 'circle',
          line: {
            width: 1,
            color: 'blue'
          },
          color: 'green'
        }
      }
    ];

    var layout = {
      height: 600,
      width: 1100,
      colorbar: false,
      geo: {
        showland: true,
        landcolor: 'rgb(255, 222, 190)',
        subunitcolor: 'rgb(217,217,217)',
        countrycolor: 'rgb(217,217,217)',
        countrywidth: 0.5,
        subunitwidth: 0.5
      },
      margin: {
        t: 0,
        l: 0,
        r: 0,
        b: 0
      }
    };

    const plotConfig = {
      scrollZoom: true,
      modeBarButtonsToRemove: [
        'toggleSpikelines',
        'resetScale2d',
        'toImage',
        'sendDataToCloud',
        'hoverCompareCartesian',
        'hoverClosestCartesian',
        'lasso2d',
        'select2d',
        'zoomIn2d',
        'zoomOut2d',
        'autoScale2d',
        'zoom2d',
        'pan2d'
      ],
      displaylogo: false,
      doubleClick: false,
      displayModeBar: true,
      showLink: false
    };

    Plotly.newPlot('plant-map-container', data, layout, plotConfig);

    // @ts-ignore
    this.$refs.myPlot.on('plotly_click', function (data) {
      let mashup = data.points[0].data.mashup[data.points[0].pointIndex];
      window.open(
        `https://webclient.epi.intra/Thingworx/Runtime/index.html#mashup=${mashup}`,
        '_blank'
      );
    });
  }
}
