var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeRightSidebar === "avgs"
    ? _c(
        "div",
        {
          staticClass: "settings-sidebar fixed end",
          class: { expanded: _vm.sidebarOpen, collapsed: !_vm.sidebarOpen }
        },
        [
          _c(
            "div",
            { staticClass: "main-container main-container-fixed" },
            [
              _c(
                "perfect-scrollbar",
                { staticStyle: { "max-height": "100% !important" } },
                [
                  _c(
                    "div",
                    { staticClass: "scrollable" },
                    _vm._l(_vm.stats, function(stat) {
                      return _c("div", { staticClass: "wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "var-name",
                            style: { color: _vm.varColor(stat.color) }
                          },
                          [_vm._v(" " + _vm._s(stat.name) + " ")]
                        ),
                        _vm.stats.length
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "\n              table\n              b-table\n              table-striped table-hover table-avg table-bordered table-sm\n              border\n            ",
                                attrs: {
                                  "aria-busy": "false",
                                  "aria-colcount": "3"
                                }
                              },
                              [
                                _c("thead", { attrs: { role: "rowgroup" } }, [
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c("th", {
                                      attrs: {
                                        role: "columnheader",
                                        scope: "col",
                                        "aria-colindex": "1",
                                        colspan: "2"
                                      }
                                    }),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "bd-bottom",
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1",
                                          colspan: "3"
                                        }
                                      },
                                      [_vm._v(" Shifts ")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-primary",
                                            on: { click: _vm.setSelectMode }
                                          },
                                          [_vm._v(" Select ")]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "rowgroup" } }, [
                                    _c("th", {
                                      attrs: {
                                        role: "columnheader",
                                        scope: "col",
                                        "aria-colindex": "1"
                                      }
                                    }),
                                    _c(
                                      "th",
                                      {
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(" Visible ")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("-1")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(" Selected ")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("+1")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        attrs: {
                                          role: "columnheader",
                                          scope: "col",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(" Selection ")]
                                    )
                                  ])
                                ]),
                                _c("tbody", { attrs: { role: "rowgroup" } }, [
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "text-decoration": "overline"
                                            }
                                          },
                                          [_c("i", [_vm._v("x")])]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.avg))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.avg))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.avg))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.avg))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.selected.avg))]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("max")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.max))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.max))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.max))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.max))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.selected.max))]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("min")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.min))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.min))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.min))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.min))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.selected.min))]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("σ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.std))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.std))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.std))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.std))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.selected.std))]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("1st")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(stat.visible.first) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.first))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(stat.current.first) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.first))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(stat.selected.first) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("last")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.last))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.last))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.last))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.last))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(stat.selected.last) + " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("Δ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(stat.visible.delta) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.delta))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(stat.current.delta) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.delta))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(stat.selected.delta) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("Σ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.acl))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.acl))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.acl))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.acl))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.selected.acl))]
                                    )
                                  ]),
                                  _c("tr", { attrs: { role: "row" } }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "border-none",
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v("hr")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.visible.hr))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.minus.hr))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.current.hr))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [_vm._v(_vm._s(stat.plus.hr))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "border-none",
                                        attrs: {
                                          role: "cell",
                                          "aria-colindex": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(stat.selected.hr) + " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }