var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      key: _vm.lossModalKey,
      ref: "add-loss-modal",
      attrs: {
        id: "add-loss-modal",
        "no-fade": true,
        "ok-title": _vm.$t("addLoss.save"),
        title: "Add a " + _vm.lossType + " loss",
        size: "xl"
      },
      on: { hidden: _vm.modalTeardown, ok: _vm.save, shown: _vm.modalShown }
    },
    [
      _c(
        "b-form",
        { attrs: { novalidate: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-due",
                        label: _vm.$t(
                          _vm.lossType !== "downtime"
                            ? "addLoss.startTimeSearch"
                            : "addLoss.startTime"
                        ),
                        "label-for": "input-due"
                      }
                    },
                    [
                      _c("DateTimePicker", {
                        attrs: {
                          max: _vm.startMax,
                          state: !_vm.startTime ? false : null,
                          value: _vm.startTime
                        },
                        on: { "on-change": _vm.onStartChange }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-due",
                        label: _vm.$t(
                          _vm.lossType !== "downtime"
                            ? "addLoss.endTimeSearch"
                            : "addLoss.endTime"
                        ),
                        "label-for": "input-due"
                      }
                    },
                    [
                      _c("DateTimePicker", {
                        attrs: {
                          disabled:
                            !_vm.startTime && _vm.lossType !== "downtime",
                          max: _vm.endMax,
                          min: _vm.startTime,
                          state:
                            _vm.isBeforeStart || !_vm.endTime ? false : null,
                          value: _vm.endTime
                        },
                        on: { "on-change": _vm.onEndChange }
                      }),
                      _vm.isBeforeStart
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$t("addLoss.endTimeBeforeStart")))
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.lossType !== "downtime"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("EventsTable", {
                        attrs: { items: _vm.productionEvents },
                        on: {
                          onRefresh: _vm.refreshData,
                          onSelect: _vm.onEventSelected
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.lossType === "pem"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("RawMaterialTable", {
                        attrs: { items: _vm.rawMaterial },
                        on: { onSelect: _vm.onRawMaterialSelected }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.lossType !== "downtime"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "2" } },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("addLoss.lossQuantity")))
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.lossQuantity === 0 ? false : null,
                          "aria-describedby":
                            "input-live-help input-live-feedback",
                          required: "",
                          size: "sm",
                          small: "",
                          trim: "",
                          type: "number"
                        },
                        model: {
                          value: _vm.lossQuantity,
                          callback: function($$v) {
                            _vm.lossQuantity = $$v
                          },
                          expression: "lossQuantity"
                        }
                      }),
                      _vm.lossQuantity === 0
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$t("addLoss.biggerOrSmallerThanZero"))
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }