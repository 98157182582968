
















































import { Component, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class WishAddNew extends Vue {
  @Ref('wish-modal') readonly modal!: any;
  public titleState: any = null;
  public descState: any = null;
  public title: string = null;
  public description: string = null;

  checkFormValidity() {
    let isValid = true;
    if (!this.title) {
      this.titleState = false;
      isValid = false;
    }

    if (!this.description) {
      this.descState = false;
      isValid = false;
    }

    return isValid;
  }

  mounted() {
    const modal: any = document.querySelector('.modal-dialog');
    if (modal) {
      modal.classList.add('small');
    }
  }

  sumbitBtn() {
    if (!this.checkFormValidity()) {
      return this.openModal();
    }
    this.$emit('modal-closed', {
      title: this.title,
      description: this.description
    });
  }

  openModal() {
    setTimeout(() => this.modal.show(), 0);
  }

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  modalTeardown() {
    this.title = null;
    this.description = null;
  }
}
