import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import LocalisedMessages from './localisations';
import Toasted from 'vue-toasted';
// @ts-ignore
import Plotly from 'plotly.js-dist';
// @ts-ignore
import localePt from 'plotly.js-locales/pt-br';
import localeFr from 'plotly.js-locales/fr';
import BootstrapVue from 'bootstrap-vue';
import * as jquery from 'jquery'

import('./../sass/style.scss');
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'primevue/resources/themes/nova-vue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faCalendarAlt,
  faCamera,
  faChartArea,
  faChartLine,
  faCircle,
  faCogs,
  faComments,
  faCopy,
  faDownload,
  faRetweet,
  faExpandArrowsAlt,
  faFileCsv,
  faFileExcel,
  faFilter,
  faIndustry,
  faLock,
  faPlus,
  faSave,
  faSearch,
  faSearchPlus,
  faSort,
  faSortDown,
  faFlask,
  faSortUp,
  faSync,
  faTags,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faToggleOff,
  faToggleOn,
  faEllipsisH
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import PrimeVue from 'primevue/config';
import TreeTable from 'primevue/treetable/TreeTable';
import Column from 'primevue/column/Column';
import('./assets/' + process.env.VUE_APP_ASSET_PATH + '/main.scss');
import AsyncComputed from 'vue-async-computed';

// @ts-ignore
window.$ = jquery
declare const TW: any;

// let TW_INSTANCE: any;
library.add(
  faIndustry,
  faComments,
  faCogs,
  faThumbsUp,
  faRetweet,
  faEllipsisH,
  faThumbsDown,
  faLock,
  faFlask,
  faSearchPlus,
  faExpandArrowsAlt,
  faSave,
  faSync,
  faFileExcel,
  faFileCsv,
  faSort,
  faChartLine,
  faCopy,
  faSortUp,
  faSortDown,
  faSearch,
  faArrowLeft,
  faCalendarAlt,
  faTimes,
  faSignInAlt,
  faCamera,
  faFilter,
  faPlus,
  faTags,
  faDownload,
  faCircle,
  faChartArea,
  faToggleOff,
  faToggleOn
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueI18n);
Vue.use(Vuex);
Vue.use(Toasted);
Vue.use(BootstrapVue);
Vue.use(PrimeVue);
Vue.use(AsyncComputed);
Vue.component('TreeTable', TreeTable);
Vue.component('Column', Column);
Vue.use(VueSweetalert2);

Plotly.register(localePt);
Plotly.register(localeFr);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: LocalisedMessages
});

Vue.config.productionTip = false;
Vue.config.silent = true;

new Vue({
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#cyzag-root-widget');

/*

TW.Runtime.Widgets.rootwidget = function () {
    require('./../sass/style.scss')
    TW_INSTANCE = this;
    this.init = function () {
        (window as any).rootwidgetInstance = this;

        Vue.nextTick(() => {
            if (!this.vueInstance) {
                this.afterRender();
            }
        });

    };
    this.renderHtml = function () {
        this.init();
        return `
        <div class="widget-content widget-rootwidget">
            <main id="cyzag-root-widget" >                
            </main>
        </div>`;
    };

    this.getPropRowsOrNull = function (key: any) {
        return this.getProperty(key) ? this.getProperty(key).rows : null;
    };

    this.afterRender = function () {
        //TODO: figure out why this doesn't run first time around

        this.vueInstance = new Vue({
            store,
            i18n,
            render: (h) => h(App)
        }).$mount('#cyzag-root-widget');
    };


    this.updateProperty = function (updatePropertyInfo: any) {
        if (!this.vueInstance) {
            return;
        }
        if (updatePropertyInfo.TargetProperty) {
            if (updatePropertyInfo.TargetProperty === 'Path') {
                this.vueInstance.$store.dispatch('setPath', { path: updatePropertyInfo.SinglePropertyValue, ctx: this.vueInstance });
            }
            this.vueInstance.$forceUpdate();
        }
    };
};

export {TW_INSTANCE};
*/
