export class MathService {
  static standardDeviation(values): number {
    if (!values.length) {
      return 0;
    }
    const avg: number = MathService.average(values),
      squareDiffs: number = values.map((value) => {
        const diff = value - avg;
        return diff * diff;
      }),
      avgSquareDiff: number = MathService.average(squareDiffs);

    return Math.sqrt(avgSquareDiff);
  }

  static average(data): number {
    if (!data.length) {
      return 0;
    }
    return data.reduce((sum, value) => sum + value, 0) / data.length;
  }

  static normalizedDifferenceTwoValue(values1, values2): any[] {
    const avg1 = MathService.average(values1);
    const standardDeviation1 = MathService.standardDeviation(values1);

    const avg2 = MathService.average(values2);
    const standardDeviation2 = MathService.standardDeviation(values2);

    return values1.map((val, i) => {
      if (val === 0 || values2[i] === 0) {
        return 0;
      }
      return Number(
        Math.abs(
          (val - avg1) / standardDeviation1 -
            (values2[i] - avg2) / standardDeviation2
        ).toFixed(2)
      );
    });
  }
}
