import Vue from 'vue';
import Vuex from 'vuex';
import { StateCLS } from './classes/stateCLS';
import { storeGetters } from './store/getters';
import { storeMutations } from './store/mutations';
import { storeActions } from './store/actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: new StateCLS(),
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions
});
