var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "auditModal",
      attrs: { id: "audit-modal", "no-fade": true, size: "xl" },
      on: { shown: _vm.modalShown },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(ref) {
            var ok = ref.ok
            var cancel = ref.cancel
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      return cancel()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("auditTrail.close")) + " ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("auditTrail.modalTitle")) +
            " " +
            _vm._s(_vm.lossData.StepName) +
            " " +
            _vm._s(_vm.lossData.externalId) +
            " "
        )
      ]),
      _c(
        "div",
        { staticClass: "audit-modal widget-rootwidget" },
        [
          _vm.lossData.LossSource === "Downtime"
            ? _c("DowntimeAuditTable", { attrs: { items: _vm.auditData } })
            : _vm._e(),
          _vm.lossData.LossSource === "Performance" ||
          _vm.lossData.LossSource === "SpeedLoss"
            ? _c("PerformanceAuditTable", { attrs: { items: _vm.auditData } })
            : _vm._e(),
          _vm.lossData.LossSource === "Pem" ||
          _vm.lossData.LossSource === "PEM" ||
          _vm.lossData.LossSource === "Quality"
            ? _c("QualityPemAuditTable", { attrs: { items: _vm.auditData } })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }