






































import { debounce, isEmpty } from 'underscore';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import SmallLoader from './SmallLoader.vue';
import { MetricsService } from '../../services/metricsService';
import * as moment from 'moment';

const methods: any = {
  methods: {
    debounceInput: debounce(function (this: any, e: any) {
      const that: any = this as any;
      that.filter = e;
      that.loading = false;
    }, 1000)
  }
};

@Component({
  components: {
    SmallLoader
  },
  computed: {
    ...mapGetters({
      treeData: 'getLossTree',
      functionalLocation: 'getFunctionalLocation'
    })
  },
  ...methods
})
export default class Reason extends Vue {
  @Ref('treeTable') readonly treeTable!: any;
  @Prop({ default: null }) readonly reasonSelected: any;
  @Prop({ default: null }) readonly lossData: any;
  public lossReasonComment = null;
  public expandedKeys = {};
  public selectedKey1 = {};
  public loading = false;
  public treeData!: any;
  public functionalLocation!: any;
  public isEmpty: any = isEmpty;
  public filter: string = '';

  get reasonTree(): any {
    // recursive function to format nodes according to VTreeView
    this.loading = true;
    const reasonLast: any[] = [];
    const genNode = (node: any, parentsTitle = '') => {
      const newNode: any = {
        key: `${node.id}_${node.ERTD_Id}`,
        id: node.id,
        ERTD_Id: node.ERTD_Id,
        data: {
          text: node.text
        },
        hasParent: !!parentsTitle,
        type: node.type,
        count: node.count,
        leaf: isEmpty(node.children)
      };
      if (!isEmpty(node.children)) {
        if (!parentsTitle) {
          parentsTitle = `${node.text}`;
        } else {
          parentsTitle += ` => ${node.text}`;
        }
        newNode.children = [];
        for (const child in node.children) {
          newNode.children.push(genNode(node.children[child], parentsTitle));
        }
      } else {
        reasonLast.push(
          Object.assign({}, newNode, {
            textRaw: newNode.data.text,
            text: `${parentsTitle} => ${newNode.data.text}`
          })
        );
      }
      return newNode;
    };

    const reasonTree: any[] = [];
    for (const category in this.treeData) {
      reasonTree.push(genNode(this.treeData[category]));
    }

    this.loading = false;

    if (this.filter) {
      return reasonLast.filter(
        (ls) =>
          ls.ERTD_Id.toString().includes(this.filter.toLowerCase()) ||
          ls.textRaw.toLowerCase().includes(this.filter.toLowerCase())
      );
    }

    return reasonTree;
  }

  onNodeSelect(node) {
    MetricsService.newAction({
      data: node,
      type: 'click',
      kind: 'reason_row_clicked',
      typeFormated: 'Reason row was clicked',
      message: `A reason row was clicked`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    setTimeout(() => {
      this.selectedKey1 = {};
      this.selectedKey1[node.key] = node.leaf;
    }, 0);

    if (!isEmpty(node.children)) {
      return;
    }

    this.$emit('on-select', node);
  }

  onNodeUnselect() {
    this.selectedKey1 = {};
  }

  showLoader() {
    this.loading = true;
  }

  findMaterializePath(array, key, value, materializedPath = '') {
    for (const arr of array) {
      if (!arr.hasParent) {
        materializedPath = arr.key;
      } else {
        materializedPath += `.${arr.key}`;
      }
      if (arr?.children?.length) {
        const path = this.findMaterializePath(
          arr.children,
          key,
          value,
          materializedPath
        );

        if (path) {
          return path;
        }
      } else {
        if (arr[key] === value) {
          return materializedPath;
        }
      }
    }
  }

  mounted() {
    this.lossReasonComment = this.lossData.Comment || null;
    if (this.lossData.OAMCategoryId) {
      const materializePath = this.findMaterializePath(
        this.reasonTree,
        'ERTD_Id',
        this.lossData.Event_Reason_Tree_Data_Id
      );
      const materializePathSplit = materializePath
        ? materializePath.split('.')
        : [];
      materializePathSplit
        .slice(0, materializePathSplit.length - 1)
        .forEach((path) => (this.expandedKeys[path] = true));
      this.selectedKey1[materializePathSplit[materializePathSplit.length - 1]] =
        true;
      this.scrollToSelected();
    }
  }

  scrollToSelected() {
    setTimeout(() => {
      const reasonElem = document.querySelector('.reason-table-tree');
      reasonElem.querySelector('.p-highlight').scrollIntoView(false);
    }, 0);
  }

  @Watch('filter')
  filterChanged() {
    if (this.filter) {
      return;
    }
    const selected = Object.keys(this.selectedKey1);
    if (selected && selected[0]) {
      const materializePath = this.findMaterializePath(
        this.reasonTree,
        'key',
        Number(selected[0])
      );
      this.expandedKeys = {};
      const materializePathSplit = materializePath
        ? materializePath.split('.')
        : [];
      materializePathSplit
        .slice(0, materializePathSplit.length - 1)
        .forEach((path) => (this.expandedKeys[path] = true));
      this.scrollToSelected();
    }
  }
  @Emit('on-submit')
  submitLossReason() {}
}
