var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    attrs: {
      fields: _vm.fields,
      items: _vm.items,
      hover: "",
      "show-empty": "",
      small: "",
      "sticky-header": "",
      striped: ""
    },
    scopedSlots: _vm._u([
      {
        key: "empty",
        fn: function() {
          return [
            _c("p", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.$t("auditTrail.noResultsFound")) + " ")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }