var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v(_vm._s(_vm.$t("lossModal.reason")))]),
    _c(
      "div",
      { staticClass: "search-tree" },
      [
        _c("label", { staticClass: "search-tree-label" }, [
          _vm._v(_vm._s(_vm.$t("lossModal.search")))
        ]),
        _c("b-form-input", {
          staticClass: "search-tree-input",
          attrs: { type: "search", size: "sm" },
          on: {
            input: function($event) {
              return _vm.debounceInput($event)
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tree-table-wrapper" },
      [
        _c(
          "TreeTable",
          {
            ref: "treeTable",
            staticClass: "reason-table-tree",
            attrs: {
              expandedKeys: _vm.expandedKeys,
              selectionMode: "single",
              loading: _vm.loading,
              selectionKeys: _vm.selectedKey1,
              value: _vm.reasonTree,
              lazy: true
            },
            on: {
              "update:selectionKeys": function($event) {
                _vm.selectedKey1 = $event
              },
              "update:selection-keys": function($event) {
                _vm.selectedKey1 = $event
              },
              "node-select": _vm.onNodeSelect,
              "node-unselect": _vm.onNodeUnselect
            }
          },
          [_c("Column", { attrs: { field: "text", expander: true } })],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("label", [_vm._v(" " + _vm._s(_vm.$t("lossModal.comment")) + ":")]),
        _c("b-form-textarea", {
          staticClass: "loss-reason-comment",
          attrs: { size: "sm" },
          model: {
            value: _vm.lossReasonComment,
            callback: function($$v) {
              _vm.lossReasonComment = $$v
            },
            expression: "lossReasonComment"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }