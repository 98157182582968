

































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import FavoritesModal from './FavoritesModal.vue';
import SettingsModal from './SettingsModal.vue';
import { Variable } from '../..//classes/variable';
import { Unit } from '../../classes/unit';
import * as _ from 'underscore';

@Component({
  components: {
    FavoritesModal,
    SettingsModal
  },
  computed: {
    ...mapGetters({
      favoritesViews: 'getFavoritesViews',
      selectedVariables: 'getSelectedVariables',
      selectedUnit: 'getSelectedUnit'
    })
  }
})
export default class FavoritesSelect extends Vue {
  @Ref('modal') readonly modal!: any;
  @Ref('modalSettings') readonly modalSettings!: any;
  public favoritesViews!: any[];
  public selectedVariables!: Variable[];
  public selectedUnit!: Unit;
  public openSettingsModal: boolean = false;
  public modalData: any = null;

  get favoritesViewsFiltered() {
    return this.favoritesViews.filter(
      (view) => view.data.unit.puid === this.selectedUnit.puid
    );
  }

  setSelected(view) {
    this.$store.dispatch('setFavoritesSelectedView', view);
  }

  modalClosed() {
    this.modalData = null;
  }

  settingsModalClosed() {
    this.openSettingsModal = false;
  }

  saveCurrentView() {
    const chart: any = document.getElementById('trend-graph-container');
    const layout: any = chart.layout;
    const vars = _.clone(this.selectedVariables);
    this.modalData = _.clone({
      data: {
        variables: _.clone(
          vars.map((variable: Variable, i: number) => {
            const axis =
              i === 0
                ? _.clone(layout.yaxis)
                : _.clone(layout[`yaxis${i + 1}`]);
            const vr = _.clone(variable);
            return {
              ...vr,
              yaxis: 'manual',
              range: axis.range.map((rn) => Number(rn.toFixed(2)))
            };
          })
        ),
        unit: _.clone(this.selectedUnit)
      }
    });
    this.modal.openModal();
  }

  openSettings() {
    this.openSettingsModal = true;
    setTimeout(() => this.modalSettings.openModal(), 0);
  }
}
