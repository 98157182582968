





































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { SidebarMenu } from 'vue-sidebar-menu';
import VariableSelector from './VariableSelector.vue';
// @ts-ignore
import { Unit } from '../classes/unit';
import { Alarm } from '../classes/alarm';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { MetricsService } from '../services/metricsService';
import moment from 'moment';
import SidebarToolbar from "@/components/SidebarToolbar.vue";

@Component({
  components: {
    SidebarMenu,
    VariableSelector,
    PerfectScrollbar
  },
  computed: {
    ...mapGetters({
      units: 'getUnits',
      savedState: 'getSavedState',
      alarms: 'getAlarms',
      isSidebarCollapsed: 'isSidebarCollapsed'
    })
  }
})
export default class Sidebar extends Vue {
  public units!: Unit[];
  public savedState!: any;
  public logo: any = '';
  public originalLogo: any = '';
  public originalLogoSmall: any = '';
  public hover: boolean = true;
  public selectedButton: Unit = new Unit();
  public alarms!: Alarm[];
  public isSidebarCollapsed!: boolean;

  get menu() {
    const menuHeader: any = [
      {
        header: true,
        title: this.$t('sidebar.labels.productionUnit'),
        hiddenOnCollapse: true
      }
    ];

    const menuComponent: any = [
      {
        component: SidebarToolbar,
        hiddenOnCollapse: true
      }
    ];

    const menu: any[] = this.units.map((unit: Unit) => {
      return {
        title: unit.Cell,
        attr: {
          'data-puid': unit.PUID
        },
        icon: {
          element: 'font-awesome-icon',
          attributes: {
            icon: unit.Type === 'AUM' ? 'industry' : 'cogs'
          }
        },
        class: this.getClasses(unit),
        hasAlarm: this.hasVariableWithAlarm(unit),
        badge:
          unit.unspecifiedLosses > 0
            ? {
                text: unit.unspecifiedLosses,
                class: 'vsm--badge vsm--badge_default'
              }
            : null
      };
    });

    return [
      ...menuHeader,
      ...menu.filter((unit: any) => unit.hasAlarm),
      ...menu.filter((unit: any) => !unit.hasAlarm),
      ...menuComponent,
    ];
  }

  toggleSidebar() {
    this.$store.dispatch('toggleSidebar');
  }

  getClasses(unit) {
    let output = '';
    if (unit.PUID === this.selectedButton.PUID) {
      output += 'vsm--link_active ';
    }

    if (this.hasVariableWithAlarm(unit)) {
      output += 'alert-link ';
    }

    return output;
  }

  @Watch('isSidebarCollapsed')
  isSidebarCollapsedChanged() {
    if (this.isSidebarCollapsed) {
      document.body.classList.add('closed-sidebar');
      this.logo = this.originalLogoSmall;
    } else {
      document.body.classList.remove('closed-sidebar');
      this.logo = this.originalLogo;
    }

    MetricsService.newAction({
      type: 'click',
      kind: 'menu_burger_button_clicked',
      typeFormated: 'Menu Burger Button Clicked',
      message: `Sidebar is ${this.isSidebarCollapsed ? 'Collapsed' : 'Open'}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
  }

  sidebarHoverIn() {
    if (this.isSidebarCollapsed) {
      document.body.classList.add('closed-sidebar-open');
      this.logo = this.originalLogo;
      this.hover = true;
    }
  }

  sidebarHoverOut() {
    if (this.isSidebarCollapsed) {
      document.body.classList.remove('closed-sidebar-open');
      this.logo = this.originalLogoSmall;
      this.hover = false;
    }
  }

  onItemClick(event, item: any) {
    const unitIndex: number = this.units.findIndex(
      (un: Unit) => un.PUID === item.attr['data-puid']
    );
    MetricsService.newAction({
      type: 'click',
      kind: 'unit_clicked',
      typeFormated: 'Unit Clicked',
      message: `Unit ${this.units[unitIndex].Cell} clicked`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.clicked(unitIndex, this.units[unitIndex]);
  }

  clicked(index: number, unit: Unit): void {
    if (this.selectedButton.PUID === unit.PUID) {
      return;
    }
    this.selectedButton = unit;
    this.$store.dispatch('unitClicked', { index, unit });
    this.$store.dispatch('setAutoRefresh', false);
  }

  hasVariableWithAlarm(unit: Unit) {
    if (this.alarms) {
      return this.alarms.some(
        (a: any) => a.Unit === unit.Cell && JSON.parse(a.Alarm)
      );
    }
  }

  async mounted() {
    const logo = await import('../assets/' + process.env.VUE_APP_ASSET_PATH + '/logo.png')
    this.logo = logo.default;
    this.originalLogo = logo.default;
    const logoSmall = await import('../assets/' + process.env.VUE_APP_ASSET_PATH + '/logo-small.png')
    this.originalLogoSmall = logoSmall.default;
  }

  @Watch('savedState')
  savedStateChanged() {
    if (!this.savedState) {
      return;
    }
    this.selectedButton = this.savedState.unit;
    this.selectedButton.PUID = this.selectedButton.puid;
  }
}
