var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "lossTableConfModal",
      attrs: {
        id: "loss-table-conf-modal",
        "no-fade": true,
        "ok-title": _vm.$t("lossModal.submit"),
        size: "xl",
        title: "Loss Table Configuration"
      },
      on: { ok: _vm.save, shown: _vm.modalShown }
    },
    [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c("div", [
          _c("dt", [_vm._v("Number Format")]),
          _c("dd", [
            _vm._v(
              " Use zero as format placeholder, example 0.00 or 0.000 for two or three decimal places respectively. "
            )
          ])
        ]),
        _c("div", [
          _c("dt", [_vm._v("Date Format")]),
          _c("dd", [
            _vm._v(
              " Use a combination of the format symbols listed below e.g. YYYY-MMM-dd HH:mm is 2021-Oct-29 13:00 "
            )
          ])
        ])
      ]),
      _c(
        "DataTable",
        {
          staticClass: "p-datatable-sm",
          attrs: {
            scrollable: true,
            value: _vm.configuration.data,
            responsiveLayout: "scroll",
            scrollHeight: "550px"
          },
          on: { "row-reorder": _vm.onRowReorder }
        },
        [
          _c("Column", {
            attrs: {
              rowReorder: true,
              headerStyle: { maxWidth: "3rem" },
              bodyStyle: { maxWidth: "3rem" }
            }
          }),
          _c("Column", {
            attrs: {
              bodyStyle: {
                maxWidth: "35px",
                verticalAlign: "middle",
                textAlign: "center"
              },
              header: "",
              headerStyle: { maxWidth: "35px" }
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(slotProps) {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: slotProps.data.selected,
                          expression: "slotProps.data.selected"
                        }
                      ],
                      attrs: {
                        id: "checkbox" + slotProps.index,
                        disabled: slotProps.data.disabled,
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(slotProps.data.selected)
                          ? _vm._i(slotProps.data.selected, null) > -1
                          : slotProps.data.selected
                      },
                      on: {
                        change: function($event) {
                          var $$a = slotProps.data.selected,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  slotProps.data,
                                  "selected",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  slotProps.data,
                                  "selected",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(slotProps.data, "selected", $$c)
                          }
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("Column", {
            attrs: { header: "Field" },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(slotProps) {
                  return [
                    _c("b-form-input", {
                      attrs: { readonly: true, size: "sm" },
                      model: {
                        value: slotProps.data.key,
                        callback: function($$v) {
                          _vm.$set(slotProps.data, "key", $$v)
                        },
                        expression: "slotProps.data.key"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("Column", {
            attrs: { header: "Alias" },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(slotProps) {
                  return [
                    _c("b-form-input", {
                      attrs: { readonly: slotProps.data.disabled, size: "sm" },
                      model: {
                        value: slotProps.data.label,
                        callback: function($$v) {
                          _vm.$set(slotProps.data, "label", $$v)
                        },
                        expression: "slotProps.data.label"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("Column", {
            attrs: { header: "Date/Number Format" },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(slotProps) {
                  return [
                    slotProps.data.type === "date"
                      ? _c("b-form-select", {
                          attrs: {
                            readonly:
                              (slotProps.data.disabled &&
                                !slotProps.data.overrideFormat) ||
                              (slotProps.data.type !== "number" &&
                                slotProps.data.type !== "date"),
                            options: _vm.dateOptions,
                            size: "sm"
                          },
                          model: {
                            value: slotProps.data.formatter,
                            callback: function($$v) {
                              _vm.$set(slotProps.data, "formatter", $$v)
                            },
                            expression: "slotProps.data.formatter"
                          }
                        })
                      : _vm._e(),
                    slotProps.data.type === "number"
                      ? _c("b-form-input", {
                          attrs: {
                            readonly:
                              (slotProps.data.disabled &&
                                !slotProps.data.overrideFormat) ||
                              (slotProps.data.type !== "number" &&
                                slotProps.data.type !== "date"),
                            size: "sm"
                          },
                          model: {
                            value: slotProps.data.formatter,
                            callback: function($$v) {
                              _vm.$set(slotProps.data, "formatter", $$v)
                            },
                            expression: "slotProps.data.formatter"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }