





































































































































































































import Reason from './Reason.vue';
import Rcps from './Rcps.vue';
import FunctionalLocation from './FunctionalLocation.vue';
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import EquipmentTable from './EquipmentTable.vue';
import { Equipment } from '../../classes/equipment';
import { isEmpty } from 'underscore';
import { MetricsService } from '../../services/metricsService';
import * as moment from 'moment';
import { Utils } from './../../utils';
import { deleteEvent } from '@/services/api/AddLossApi';
import Dropdown from 'primevue/dropdown';
import { MaintenanceNotification } from "@/classes/maintenanceNotification";

@Component({
  components: {
    Reason,
    Rcps,
    FunctionalLocation,
    EquipmentTable,
    Dropdown
  },
  computed: {
    ...mapGetters({
      treeData: 'getLossTree',
      treeDataRows: 'getLossTreeRows',
      functionalLocation: 'getFunctionalLocation',
      functionalLocationOriginal: 'getFunctionalLocationOriginal',
      path: 'getPath',
      equipmentList: 'getEquipmentList',
      maintenanceNotificationList: 'getMaintenanceNotificationList',
      getSelectedMaintenanceNotification: 'getSelectedMaintenanceNotification',
      configuration: 'getConfiguration',
      selectedUnit: 'getSelectedUnit',
      historyDays: 'getHistoryDays'
    })
  }
})
export default class LossModal extends Vue {
  @Ref('lossModal') readonly modal!: any;
  @Prop({ default: null }) readonly lossData: any;
  @Ref('reason') readonly reason!: any;
  @Ref('rcps') readonly rcps!: any;
  @Prop({ default: [] }) public additionalLossData: any[];
  public treeData!: any;
  public functionalLocation!: any;
  public treeDataRows!: any[];
  public path!: string;
  public equipmentList!: Equipment[];
  public maintenanceNotificationList!: MaintenanceNotification[];
  public getSelectedMaintenanceNotification!: MaintenanceNotification[];
  public lossReasonId: any = null;
  public ERTD_Id: number = null;
  public isEmpty: any = isEmpty;
  public lossModalKey: number = 0;
  public selectedFunctionalLocation: any = null;
  public selectedNode: any = null;
  public selectedEquipment: any = null;
  public functionalLocationsLast: any[] = [];
  public isUpdateRequired: boolean = false;
  public configuration!: any;
  public selectedUnit!: any;
  public historyDays!: number;
  public selectedMaintenanceNotification: MaintenanceNotification = null;
  public maintenanceNotificationFilterLastXDays = 7;
  public maintenanceNotificationFilterDateFrom = null;
  public maintenanceNotificationFilterDateTo = null;
  public maintenanceNotificationFilterNumber = null;
  public maintenanceNotificationFilterType = null;
  public maintenanceNotificationFilterFunctionalLocation = null;
  public maintenanceNotificationFilterDescription = null;
  public maintenanceNotificationFilterEquipmentTechnicalId = null;


  get fields() {
    return [
      {
        key: 'eventNumber',
        label: this.$t("lossModal.maintenanceNotification.tableNumber"),
        sortable: false
      },
      {
        key: 'endTime',
        label: this.$t("lossModal.maintenanceNotification.tableDate"),
        sortable: false
      },
      {
        key: 'maintenanceNotificationType',
        label: this.$t("lossModal.maintenanceNotification.tableType"),
        sortable: false
      },
      {
        key: 'description',
        label: this.$t("lossModal.maintenanceNotification.tableDescription"),
        sortable: false
      },
      {
        key: 'descriptionLongText',
        label: this.$t("lossModal.maintenanceNotification.tableDescriptionLong"),
        sortable: false,
        formatter: (value) => {
          return value && value.length > 100 ? value.substring(0, 99) + '...' : value
        }
      },
      {
        key: 'functionalLocationName',
        label: this.$t("lossModal.maintenanceNotification.tableFunctionalLocationName"),
        sortable: false
      },
      {
        key: 'equipmentName',
        label: this.$t("lossModal.maintenanceNotification.tableEquipmentName"),
        sortable: false
      },
      {
        key: 'equipmentTechnicalId',
        label: this.$t("lossModal.maintenanceNotification.tableEquipmentTechnicalId"),
        sortable: false
      },
    ];
  }

  onRowSelected(items) {
    if (items.length > 0)
    this.selectedMaintenanceNotification = items[0]
    else
      this.selectedMaintenanceNotification = null;
  }
  clearSelected() {
    //@ts-ignore
    this.$refs.selectableTable.clearSelected()
  }
  resetFilterFields() {
      this.maintenanceNotificationFilterLastXDays = 7;
      this.maintenanceNotificationFilterDateFrom = null;
      this.maintenanceNotificationFilterDateTo = null;
      this.maintenanceNotificationFilterNumber = null;
      this.maintenanceNotificationFilterType = null;
      this.maintenanceNotificationFilterFunctionalLocation = null;
      this.maintenanceNotificationFilterDescription = null;
      this.maintenanceNotificationFilterEquipmentTechnicalId = null;
  }
  async applyFilter() {
    const promises = [
      {
        method: "getMaintenanceNotification",
        parameters: {
          Path: this.$store.getters.getPath,
          functionalLocationRoot:
          this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
          functionalLocationRootId:
          this.$store.getters.getSelectedUnit.FunctionalLocationRootId,
          maintenanceNotificationFilterLastXDays: this.maintenanceNotificationFilterLastXDays ? this.maintenanceNotificationFilterLastXDays : null,
          maintenanceNotificationFilterDateFrom: this.maintenanceNotificationFilterDateFrom ? this.maintenanceNotificationFilterDateFrom : null,
          maintenanceNotificationFilterDateTo: this.maintenanceNotificationFilterDateTo ? this.maintenanceNotificationFilterDateTo + 'T23:59:59.999' : null,
          maintenanceNotificationFilterNumber: this.maintenanceNotificationFilterNumber ? this.maintenanceNotificationFilterNumber : null,
          maintenanceNotificationFilterType: this.maintenanceNotificationFilterType ? this.maintenanceNotificationFilterType : null,
          maintenanceNotificationFilterFunctionalLocation: this.maintenanceNotificationFilterFunctionalLocation ? this.maintenanceNotificationFilterFunctionalLocation : null,
          maintenanceNotificationFilterDescription: this.maintenanceNotificationFilterDescription ? this.maintenanceNotificationFilterDescription : null,
          maintenanceNotificationFilterEquipmentTechnicalId: this.maintenanceNotificationFilterEquipmentTechnicalId ? this.maintenanceNotificationFilterEquipmentTechnicalId : null,
        }
      }
    ];
    for (let promise of promises) {
      await Utils.triggerBackend(promise, this);
    }
  }

  selected(node) {
    this.selectedNode = node;
    this.lossReasonId = node.id;
    this.ERTD_Id = this.treeDataRows.filter(
      (row) => row.id === node.id
    )[0].Event_Reason_Tree_Data_Id;
  }

  async deleteLoss() {
    const val = await this.$bvModal.msgBoxConfirm(
      this.$t('lossModal.confirmDelete') as string,
      {
        title: this.$t('lossModal.confirmTitle') as string,
        size: 'sm',
        bodyClass: 'widget-rootwidget',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('lossModal.delete') as string,
        cancelTitle: this.$t('lossModal.cancel') as string,
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }
    );

    if (val) {
      await deleteEvent(
        this.path,
        this.lossData.LossSource === 'SpeedLoss'
          ? 'performance'
          : this.lossData.LossSource.toLowerCase(),
        this.lossData.id
      );

      for (let i = 0; i < this.additionalLossData.length; i++){
        // already processed
        if (this.lossData.id === this.additionalLossData[i].id){
          continue;
        }
        await deleteEvent(
          this.path,
          this.lossData.LossSource === 'SpeedLoss'
            ? 'performance'
            : this.lossData.LossSource.toLowerCase(),
          this.additionalLossData[i].id
        );
      }

      const promises = [
        {
          method: 'getLossesForUnit',
          parameters: {
            Path: this.path,
            PUID: this.selectedUnit.puid,
            LanguageID: this.configuration[this.path].LanguageID,
            historyDays: this.historyDays
          }
        },
        {
          method: 'getUnspecifiedLosses',
          parameters: {
            Path: this.path,
            historyDays: 1
            // 'historyDays': this.historyDays
          }
        }
      ];

      for (let promise of promises) {
        await Utils.triggerBackend(promise, this);
      }


      this.modalTeardown(true);
    }
  }

  async submitUpdateLoss(e) {

    if (this.rcps && this.rcps.splitDuration && (Number(this.lossData.LossMinutes) <= Number(this.rcps.splitDuration))) {
      alert(this.$t('lossModal.splitDurationInvalidInput'))
      e.preventDefault();
      return;
    }

    if (this.rcps && this.rcps.splitQuantity && (Number(this.lossData.LossQuantity) <= Number(this.rcps.splitQuantity))) {
      alert(this.$t('lossModal.splitQuantityInvalidInput'))
      e.preventDefault();
      return;
    }

    if (this.rcps && this.rcps.splitQuantity && (this.lossData.isEmptyEndDate) ) {
      alert(this.$t('lossModal.splitQuantityNoEndTimeInvalidInput'))
      e.preventDefault();
      return;
    }

    let parameters: any = {
      Path: this.$store.getters.getPath,
      LossSource: this.lossData.LossSource,
      EventID: this.lossData.OAMEventId
    };

    await this.updateLossParameters(parameters);
    if (this.isUpdateRequired) {
      const promises = [
        {
          method: 'updateLoss',
          parameters
        }
      ];
      for (let promise of promises) {
        await Utils.triggerBackend(promise, this);
      }
    }

    for (let i = 0; i < this.additionalLossData.length; i++) {
      // already processed
      if (this.lossData.id === this.additionalLossData[i].id) {
        continue;
      }
      parameters.LossSource = this.additionalLossData[i].LossSource;
      parameters.EventID = this.additionalLossData[i].OAMEventId;
      if (this.isUpdateRequired) {
        const promises = [
          {
            method: 'updateLoss',
            parameters
          }
        ];
        for (let promise of promises) {
          await Utils.triggerBackend(promise, this);
        }
      }
    }

    if (this.isUpdateRequired) {
      const promises = [
        // {
        //   method: 'updateLoss',
        //   parameters
        // },
        {
          method: 'getLossesForUnit',
          parameters: {
            Path: this.path,
            PUID: this.selectedUnit.puid,
            LanguageID: this.configuration[this.path].LanguageID,
            historyDays: this.historyDays
          }
        },
        {
          method: 'getUnspecifiedLosses',
          parameters: {
            Path: this.path,
            historyDays: 1
            // 'historyDays': this.historyDays
          }
        }
      ];

      for (let promise of promises) {
        await Utils.triggerBackend(promise, this);
      }
    }
    MetricsService.newAction({
      data: parameters,
      type: 'click',
      kind: 'submit_loss_clicked',
      typeFormated: 'Submit loss was clicked',
      message: `Submit Loss data modal clicked`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.modalTeardown(true);
  }

  async updateLossParameters(parameters) {

    if (this.ERTD_Id) {
      parameters.ERTD_Id = this.ERTD_Id;
      this.isUpdateRequired = true;
    }
    if (this.reason && this.reason.lossReasonComment && this.reason.lossReasonComment !== this.lossData.Comment) {
      parameters.Reason_comment = this.reason.lossReasonComment;
      this.isUpdateRequired = true;
    }
    if (this.selectedFunctionalLocation || this.selectedFunctionalLocation === 'empty') {
      // set to empty if empty, the adapter call the clear SP to clear the value. If not sent as empty, the FLOC will remain.
      // If the user does not interact with the FLOC tree, this.selectedFunctionalLocation is undefined, hence this block does not run to clear or set the FLOC.
      parameters.FL_Code =  this.selectedFunctionalLocation === 'empty' ? 'empty' : this.selectedFunctionalLocation.data.id;
      parameters.FL_Description = this.selectedFunctionalLocation === 'empty' ? null :
        this.selectedFunctionalLocation.data.description;
      this.isUpdateRequired = true;
    }
    if (
      this.rcps && this.rcps.RCPSStatus &&
      this.rcps.RCPSStatus !== this.lossData.RCPSStatus
    ) {
      parameters.RCPS_status = this.rcps.RCPSStatus === 'Open' ? '1' : '2';
      this.isUpdateRequired = true;
    }

    if (this.rcps && this.rcps.rcpsComments) {
      parameters.RCPS_comment = this.rcps.rcpsComments;
      this.isUpdateRequired = true;
    }
    if (this.rcps && this.rcps.splitDuration && this.rcps.splitDuration > 0) {
      if (
        this.selectedUnit.splitLossTimeUoT &&
        this.selectedUnit.splitLossTimeUoT.toLowerCase() === 'hour'
      ) {
        parameters.splitDuration = (this.rcps.splitDuration * 60).toFixed(0);
      } else {
        parameters.splitDuration = this.rcps.splitDuration;
      }
      this.isUpdateRequired = true;
    }
    if (this.rcps && this.rcps.splitQuantity && this.rcps.splitQuantity !== 0) {
      parameters.splitQuantity = this.rcps.splitQuantity;
      this.isUpdateRequired = true;
    }
    if (this.selectedEquipment) {
      if (!this.selectedFunctionalLocation) {
        parameters.FL_Code = this.lossData.FunctionalLocation;
      }
      parameters.FL_Equipment = this.selectedEquipment.Equipment;
      this.isUpdateRequired = true;
    }
    if (this.selectedMaintenanceNotification) {
      parameters.maintenanceNotificationId = this.selectedMaintenanceNotification.id;
      // the selected maintenance notification overrides ui settings, ui is hidden in this case.
      parameters.FL_Code =  this.selectedMaintenanceNotification.functionalLocationName;
      parameters.FL_Description = this.selectedMaintenanceNotification.functionalLocationDescription;
      parameters.FL_Equipment = this.selectedMaintenanceNotification.equipmentName;
      this.isUpdateRequired = true;
    }
  }

  openModal() {
    if (!this.treeData) {
      return setTimeout(() => this.openModal(), 300);
    }
    let filteredList = this.getSelectedMaintenanceNotification.filter(e => e.id == this.lossData.maintenanceNotificationId);
    if (filteredList && filteredList.length > 0){
      this.selectedMaintenanceNotification = filteredList[0];
    } else {
      this.selectedMaintenanceNotification = null;
    }
    this.selectedFunctionalLocation = null;
    this.modal.show();
  }

  @Emit('onClose')
  modalTeardown(isSubmitClicked = false) {
    this.resetFilterFields();
    this.selectedMaintenanceNotification = null;
    this.functionalLocationsLast = [];
    this.selectedNode = null;
    this.lossReasonId = null;
    this.ERTD_Id = null;
    this.lossModalKey += 1;
    this.$store.dispatch('setEquipmentList', { rows: [] });
    this.$store.dispatch('setMaintenanceNotificationList', { rows: [] });
    if (!isSubmitClicked) {
      MetricsService.newAction({
        type: 'event',
        kind: 'cancel_loss_modal_event',
        typeFormated: 'Cancel loss modal event',
        message: `Cancel loss data modal event was fired`,
        time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
      });
    }
  }

  async rowClickedEvent(row) {
    this.selectedFunctionalLocation = row;
    if(row && row !== 'empty') {
      await Utils.triggerBackend(
        {
          method: 'getEquipment',
          parameters: {
            Path: this.path,
            FL_Code: this.selectedFunctionalLocation?.data?.id
          }
        },
        this
      );
    }
  }

  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (!modalId) {
        setTimeout(() => {
          document
            .querySelectorAll('.modal')[1]
            .parentElement.classList.add('widget-rootwidget');
        });
      }
    });
  }

  equipmentClicked(item: Equipment) {
    this.selectedEquipment = item;
  }

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  modalShown2() {
    document
      .querySelector('#modal-12')
      .parentElement.classList.add('widget-rootwidget');
  }
}
