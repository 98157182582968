var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-end",
      class: {
        expanded: _vm.sidebarOpen,
        active: _vm.activeRightSidebar === "chat"
      }
    },
    [
      _c(
        "button",
        { staticClass: "toggle-settings", on: { click: _vm.toggleSidebar } },
        [
          _vm._v(" Chat "),
          !_vm.sidebarOpen && _vm.unreadMessagesTotal > 0
            ? _c("span", { staticClass: "badge badge-pill" }, [
                _vm._v(_vm._s(_vm.unreadMessagesTotal))
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }