var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conversation-card card" }, [
    _c("div", { staticClass: "table-responsive" }, [
      _c("div", { staticClass: "conv-top-header" }, [
        _c("div", { staticClass: "left-header" }, [
          _c("div", { staticClass: "back-btn-wrapper" }, [
            _c(
              "button",
              { staticClass: "btn btn-back", on: { click: _vm.goToList } },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            )
          ]),
          _c("h4", { staticClass: "mb-0 text-nowrap" }, [
            _vm._v(_vm._s(_vm.getTitle(_vm.conversation)))
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "chat-scroll" },
        [
          _c(
            "perfect-scrollbar",
            {
              ref: "scrollbar",
              staticStyle: { "max-height": "100% !important" }
            },
            [
              _c(
                "div",
                { staticClass: "comment-wrapper" },
                _vm._l(_vm.conversation.messages, function(message) {
                  return _c("div", { staticClass: "comment-box-wrapper" }, [
                    _c("div", { class: _vm.commentUser(message.username) }, [
                      _c("small", { staticStyle: { margin: "0" } }, [
                        _vm._v(_vm._s(message.username))
                      ]),
                      _c("div", { staticClass: "comment-box" }, [
                        !message.isLink
                          ? _c(
                              "span",
                              { staticStyle: { "max-width": "100%" } },
                              [_vm._v(_vm._s(message.message))]
                            )
                          : _vm._e(),
                        message.isLink && message.linkType === "state"
                          ? _c(
                              "a",
                              {
                                staticClass: "state-comment",
                                on: {
                                  click: function($event) {
                                    return _vm.goToState(_vm.comment)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(message.message) + " ")]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "small",
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "calendar-alt" }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.getHumanDate(message.createdAt)) +
                              " "
                          )
                        ],
                        1
                      )
                    ])
                  ])
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "card-footer" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message"
            }
          ],
          staticClass: "form-control-sm form-control",
          attrs: {
            id: "message-box",
            placeholder: "Write a new message",
            type: "text"
          },
          domProps: { value: _vm.message },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onEnter($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.message = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "actions-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary",
              attrs: { title: "Send State" },
              on: { click: _vm.sendState }
            },
            [_c("font-awesome-icon", { attrs: { icon: "save" } })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary",
              attrs: { title: "Send Message" },
              on: { click: _vm.onEnter }
            },
            [_c("font-awesome-icon", { attrs: { icon: "sign-in-alt" } })],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }