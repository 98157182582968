

































































import { AuthModule } from '@/AuthModule';
import axios from 'axios';
import moment from 'moment';
import AccumulatedChart from './components/AccumulatedChart.vue';
import LossTableAUM from './components/LossTableAUM.vue';
import LossTablePEM from './components/LossTablePEM.vue';
import TrendChart from './components/TrendChart/component.vue';
import ShiftTimeline from './components/ShiftTimeline.vue';
import BatchStepTimeline from './components/BatchStepTimeline.vue';
import AvgSidebar from './components/AvgSidebar.vue';
import ReportsSidebar from './components/ReportsSidebar.vue';
import ToggleReports from './components/ToggleReports.vue';
import AppHeader from './components/Header.vue';
import AppSidebar from './components/Sidebar.vue';
import TimeRangeButtons from './components/TimeRangeButtons.vue';
import ToggleAvg from './components/ToggleAvg.vue';
import ToggleChat from './components/ToggleChat.vue';
import FavoritesSelect from './components/Favorites/FavoritesSelect.vue';
import Loader from './components/Loader.vue';
import ChatSidebar from './components/ChatSidebar.vue';
import PlantMap from './components/PlantMap.vue';
import TagManager from './components/TagManager.vue';
import ControlCharts from './components/ControlCharts.vue';
import { Utils } from './utils';
import Plotly from 'plotly.js-dist/plotly.js';

import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Variable } from './classes/variable';
import { Threshold } from './classes/threshold';
import { Alarm } from './classes/alarm';
import { Unit } from './classes/unit';
import { ShiftData } from './classes/shiftData';
import { Trend } from './classes/trend';
import { LossData } from './classes/lossData';
// import {TW_INSTANCE} from './main';
import { MetricsService } from './services/metricsService';
import to from './services/to';
import { ChatApi } from './services/api/chatApi';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

if (process.env.NODE_ENV === 'production') {

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_INSIGHTS_CONNECTION_STRING,
      enableAutoRouteTracking: true
      /* ...Other Configuration Options... */
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

}

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const isIE = msie > 0 || msie11 > 0;

const authModule: AuthModule = new AuthModule();

@Component({
  components: {
    TimeRangeButtons,
    ShiftTimeline,
    ReportsSidebar,
    BatchStepTimeline,
    TrendChart,
    PlantMap,
    TagManager,
    ControlCharts,
    ToggleAvg,
    AccumulatedChart,
    FavoritesSelect,
    LossTableAUM,
    LossTablePEM,
    ChatSidebar,
    AppHeader,
    ToggleChat,
    AppSidebar,
    ToggleReports,
    Loader,
    AvgSidebar
  },
  computed: {
    ...mapGetters({
      account: 'getAccount',
      isSidebarCollapsed: 'isSidebarCollapsed',
      variables: 'getVariable',
      thresholdData: 'getThresholdData',
      alarms: 'getAlarms',
      lossTree: 'getLossTree',
      units: 'getUnits',
      activeRightSidebar: 'getActiveRightSidebar',
      path: 'getPath',
      historyDays: 'getHistoryDays',
      selectedUnit: 'getSelectedUnit',
      shiftData: 'getShiftData',
      trendData: 'getTrendData',
      lossData: 'getLossData',
      timezoneOffset: 'getTimezoneOffset',
      configuration: 'getConfiguration',
      initialLoadDone: 'getInitialDataLoaded',
      avgSidebarOpen: 'avgSidebarIsOpen',
      selectedLossIndices: 'getSelectedLossIndices',
      updateScheduled: 'getUpdateScheduled',
      refreshRate: 'getRefreshRate',
      isAutoRefresh: 'getIsAutoRefresh',
      access: 'getAccess',
      stepsAndBatches: 'getStepsAndBatches',
      lastUpdated: 'getLastUpdated',
      shiftChartVisible: 'getShiftChartVisible',
      isFromMetricAction: 'getIsFromMetricAction',
      mesVariablesOfUnit: 'getMesVariablesOfSelectedUnit',
      variablesOfUnit: 'getVariablesOfSelectedUnit',
      autoRefreshEnabled: 'isAutoRefreshEnabled',
      fromTimerange: 'isFromTimerange',
      chatSearch: 'getChatSearch',
      wishlistSearch: 'getWishlistSearch',
      isAccessDenied: 'isAccessDenied',
      widgetTitle: 'getWidgetTitle',
      isPlantMapActive: 'getIsPlantMapActive',
      isTagManagerActive: 'getIsTagManagerActive',
      isControlChartsActive: 'getIsControlChartsActive'
    })
  }
})
export default class App extends Vue {
  @Ref('cell-container') readonly cellContainer!: any;
  public isSidebarCollapsed!: boolean;
  public account: any;
  public shiftChartVisible!: boolean;
  public fromTimerange!: boolean;
  public autoRefreshEnabled!: boolean;
  public isAccessDenied!: boolean;
  public variables!: Variable[];
  public activeRightSidebar!: string;
  public chatSearch!: string;
  public wishlistSearch!: string;
  public thresholdData!: Threshold[];
  public alarms!: Alarm[];
  public selectedLossIndices!: any[];
  public lossTree!: any;
  public units!: Unit;
  public path!: any;
  public stepsAndBatches!: any[];
  public historyDays!: number;
  public selectedUnit!: Unit;
  public shiftData!: ShiftData[];
  public trendData!: Trend[];
  public lossData!: LossData[];
  public timezoneOffset!: number;
  public configuration!: any;
  public initialLoadDone!: boolean;
  public isFromMetricAction!: boolean;
  public isAutoRefresh!: boolean;
  public access!: boolean;
  public avgSidebarOpen!: boolean;
  public localeID: any = {
    '1': 'en',
    '12': 'pt',
    '2': 'fr',
    '6': 'sv',
    '3': 'de',
    '4': 'da',
    '18': 'fi'
  };
  public currentWeek: any = moment().format('w');
  public lastUpdated: any;
  public updateScheduled!: boolean;
  public refreshRate!: number;
  public isBackendRunning!: boolean;
  public executionQueue!: any[];
  public mesVariablesOfUnit: any[];
  public variablesOfUnit: Variable[];
  public widgetTitle!: string;
  // BatchStepTimeline chart not refreshing correctly, force refresh of component when changing unit
  public batchStepKey: number = 0;

  public get configKeys() {
    const common = [
      { key: 'Event_ID' },
      { key: 'Event_Reason_Tree_Data_Id' },
      { key: 'FinalProduct_Code' },
      { key: 'FinalProduct_Id' },
      { key: 'MACQuantity', type: 'number' },
      { key: 'OAMCategoryId' },
      { key: 'OAMEventId' },
      { key: 'PUID' },
      { key: 'PU_Desc' },
      { key: 'ShiftTime', type: 'date' },
      { key: 'Shifttime2', type: 'date' },
      { key: 'externalId' },
      { key: 'productionUnitId' },
      { key: 'functionalLocationId' },
      { key: 'functionalLocationEquipment' },
      { key: 'functionalLocationEquipmentId' }
    ];

    return this.selectedUnit.type === 'PEM'
      ? [{ key: 'RawMaterial_Code' }, { key: 'RawMaterial_Id' }, ...common]
      : [{ key: 'LossSource' }, { key: 'LossUoM' }, ...common];
  }

  public get requiredKeys() {
    const common = [
      {
        key: 'StartTime',
        label: this.$t('lossTableAUM.headings.startTime')
      },
      {
        key: 'EndTime',
        label: this.$t('lossTableAUM.headings.endTime')
      },
      {
        key: 'StepName',
        label: this.$t('lossTableAUM.headings.stepName')
      },
      {
        key: 'LossQuantity',
        label: this.$t('lossTableAUM.headings.lossQuantity')
      },
      {
        key: 'LossMinutes',
        label: this.$t('lossTableAUM.headings.lossMinutes')
      },
      {
        key: 'Cost',
        label: this.$t('lossTableAUM.headings.cost')
      },
      {
        key: 'OAMCategory',
        label: this.$t('lossTableAUM.headings.OAMCategory')
      },
      {
        key: 'OAMFactor',
        label: this.$t('lossTableAUM.headings.OAMFactor')
      },
      {
        key: 'LocalReason1',
        label: this.$t('lossTableAUM.headings.localReason')
      },
      {
        key: 'LocalReason2',
        label: this.$t('lossTableAUM.headings.localReason2')
      },
      {
        key: 'LocalReason3',
        label: this.$t('lossTableAUM.headings.localReason3')
      }
    ];
    const output =
      this.selectedUnit.type === 'PEM'
        ? [
            {
              key: 'LossSource',
              label: this.$t('lossTableAUM.headings.lossSource')
            },

            {
              key: 'RawMaterial_Desc',
              label: this.$t('lossTableAUM.headings.rawMaterial')
            },
            ...common
          ]
        : common;

    return output.map((req: any) => {
      req.disabled = true;
      req.selected = true;
      return req;
    });
  }

  get isLossTableAumVisible() {
    if (!this.initialLoadDone || !this.selectedLossIndices) {
      return false;
    }
    return this.lossData.some((loss) => loss.LossSource === 'SpeedLoss' || loss.LossSource === 'Downtime');
  }

  get isLossTablePemVisible() {
    if (!this.initialLoadDone || !this.selectedLossIndices) {
      return false;
    }
    return this.lossData.some((loss) => loss.LossSource === 'PEM');
  }

  get getChartClasses() {
    let widthNum: number = 10,
      tmpStr = ' avg-siderbar-open-';
    if (this.isSidebarCollapsed) {
      widthNum += 1;
      tmpStr += '11';
    } else {
      tmpStr += '10';
    }

    if (!this.avgSidebarOpen) {
      tmpStr = '';
    }
    return `small-${widthNum}${tmpStr}`;
  }

  togglePlantMap() {
    this.$store.state.isPlantMapActive = !this.$store.state.isPlantMapActive;
  }

  @Watch('configuration')
  configurationChanged() {
    if (this.configuration && this.configuration[this.path]) {
      this.$i18n.locale =
        this.localeID[this.configuration[this.path].LanguageID];
      if (this.$i18n.locale === 'pt') {
        Plotly.setPlotConfig({ locale: 'pt-br' });
      } else {
        Plotly.setPlotConfig({ locale: this.$i18n.locale });
      }
      moment.locale(this.$i18n.locale);
    }
  }

  beforeCreate() {
    authModule.setCtx(this);
    axios.interceptors.request.use(
      async (request) => {
        await authModule.setToken(request);
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async mounted() {
    await authModule.loadAuthModule();

    // this is not from original code
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    var pathFromParam = params.get('path');
    if (pathFromParam) {
      this.$store.dispatch('setPath', { path: pathFromParam, ctx: this });
    } else {
      this.$store.dispatch('setPath', { path: '', ctx: this });
    }

    const body: any = document.querySelector('body');
    const html: any = document.querySelector('html');
    body.classList.add('body');
    html.classList.add('html');

    const inner: any = document.querySelector('.inner');
    this.$store.dispatch('setChartWidth', inner.clientWidth - 30);
    MetricsService.init(this);
    window.onbeforeunload = (e) => {
      MetricsService.sessionEnded();
    };
    document.addEventListener('click', (e: any) => {
      if (!this.isFromMetricAction) {
        if (e && e.target && e.target.className === 'download-js-link') {
          const value: any = e.target.attributes.download.nodeValue;
          const type: any = value.substring(value.indexOf('.') + 1);
          MetricsService.newAction({
            type: 'click',
            kind: `export_btn_${type}_click`,
            typeFormated: `Export Button ${type} Click`,
            message: `The loss table export to ${type} was clicked`,
            time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
          });
        } else {
          MetricsService.newAction(
            {
              data: e,
              type: 'click',
              kind: 'generic_click',
              typeFormated: 'Generic body tag click',
              message: `Generic body tag click`,
              time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
            },
            true
          );
        }
      } else {
        MetricsService.passContext(e);
      }
      this.$store.dispatch('setIsFromMetricAction', false);
    });
    // this.initApp();
  }

  async initApp() {
    if (!this.$store.getters.getUserName || !this.$store.getters.getPath) {
      return setTimeout(async () => await this.initApp(), 1000);
    }
    await this.getConversations();
    await this.longPollGroups();
    // await this.getWishlist();
  }

  async longPollGroups() {
    let err, conversations;

    [err, conversations] = await to(ChatApi.longPollGroups());

    if (err) {
      console.log(err);
    }

    this.$store.dispatch('setConversations', conversations);
    this.longPollGroups();
  }

  async getWishlist() {
    let err, wishlist;

    [err, wishlist] = await to(ChatApi.getConversations({}));

    console.log(wishlist);

    if (err) {
      console.log(err);
    }

    this.$store.dispatch('setWishlist', wishlist);
  }

  async getConversations() {
    let err, conversations;

    const args =
      process.env.NODE_ENV === 'development'
        ? {
            username: this.$store.getters.getUserName,
            path: this.$store.getters.getPath,
            search: this.chatSearch
          }
        : {
            method: 'chatGetGroups',
            parameters: {
              foo: 'bar'
            }
          };

    [err, conversations] = await to(ChatApi.getConversations(args));

    if (err) {
      console.log(err);
    }

    console.log(conversations);

    this.$store.dispatch('setConversations', conversations);
  }

  @Watch('avgSidebarOpen')
  @Watch('isSidebarCollapsed')
  isSidebarCollapsedChanged() {
    setTimeout(() => {
      let width;
      const inner: any = document.querySelector('.inner');

      if (!this.avgSidebarOpen) {
        inner.style.width = `100%`;
        width = inner.clientWidth - 30;
      } else {
        inner.style.width = `calc(100% - 27rem)`;
        width = inner.clientWidth - 30;
      }
      this.$store.dispatch('setChartWidth', width);

      // Plotly.relayout('timeline-container', {width: width});
      Plotly.relayout('trend-graph-container', { width: width });
      if (document.getElementById('shift-production-container')) {
        Plotly.relayout('shift-production-container', { width: width });
      }
    }, 400);
  }

  @Watch('selectedUnit')
  async selectedUnitChanged() {
    // for (let promise of [...Utils.refreshUnit(this, 1), ...Utils.refreshUnit(this, this.historyDays)]) {
    for (let promise of [...Utils.refreshUnit(this, this.historyDays)]) {
      Utils.triggerBackend(promise, this);
    }
    if (this.selectedUnit.IsVirtualUnit) {
      this.$store.state.isControlChartsActive = true;
    } else {
      this.$store.state.isControlChartsActive = false;
    }
    this.batchStepKey++;

    const { data } = await axios.get(
      `/api/lossTableConfiguration/${this.selectedUnit.puid}`
    );

    if (!data || !data.data) {
      this.$store.dispatch('setLossTableConfiguration', {
        id: null,
        data: [
          ...this.requiredKeys.map(({ key, label }) => {
            if (key === 'StartTime' || key === 'EndTime') {
              return {key, label, overrideFormat: true, type: 'date', disabled: true}
            }
            return { key, label, disabled: true };
          }),
          ...this.configKeys.map(({ key, type }: any) => {
            return {
              key: key,
              label: '',
              disabled: false,
              selected: false,
              type
            };
          })
        ]
      });
    } else {
      const originalData = [
        ...this.requiredKeys.map(({ key, label }) => {
          if (key === 'StartTime' || key === 'EndTime') {
            return {key, label, overrideFormat: true, type: 'date', disabled: true}
          }
          return { key, label, disabled: true };
        }),
        ...this.configKeys.map(({ key, type }: any) => {
          return {
            key: key,
            label: '',
            disabled: false,
            selected: false,
            type
          };
        })
      ];
      const parsedData = JSON.parse(data.data);

      originalData.forEach((dt) => {
        const findKey = parsedData.find((ps) => ps.key === dt.key);
        if (!findKey) {
          parsedData.push(dt);
        }
      });



      this.$store.dispatch('setLossTableConfiguration', {
        id: data.id,
        data: parsedData
      });
    }
  }

  @Watch('variablesOfUnit')
  async variablesOfUnitChanged() {
    if (
      !this.selectedUnit.label &&
      this.variablesOfUnit.every((v) => !v.selected)
    ) {
      const url = window.location.href;
      if (url.includes('tags=')) {
        const urlTags = url.split('tags=')[1].split(',');
        urlTags.forEach((tag) => {
          this.$store.state.customTags.push({
            UoM: '',
            alarm: false,
            color: 'black',
            precision: 2,
            label: tag,
            Tag: tag,
            VarType: 'HISTORIAN',
            unit: '',
            origin: 'custom',
            selected: true,
            active: true
          });
        });
      }
      if (this.variablesOfUnit.some((v) => v.active && !v.unit)) {
        for (let promise of [
          ...Utils.refreshUnit(this, 1),
          ...Utils.refreshUnit(this, this.historyDays)
        ]) {
          Utils.triggerBackend(promise, this);
        }
      }
    }
  }

  @Watch('autoRefreshEnabled')
  autoRefreshEnabledWatcher() {
    if(this.autoRefreshEnabled) {
      for (let request of Utils.refreshUnit(this, this.historyDays, false)) {
        Utils.triggerBackend(request, this);
      }
    }
  }

  @Watch('chatSearch')
  chatSearchChanged() {
    this.getConversations();
  }

  @Watch('wishlistSearch')
  wishListSearch() {
    this.getWishlist();
  }

  signOut(): void {
    authModule.logout();
  }
}
