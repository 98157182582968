






























































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { getMaterials } from '@/services/api/AddLossApi';
import DateTimePicker from '@/components/AddLossModal/DateTimePicker.vue';
import moment from 'moment';

@Component({
  components: { DateTimePicker },
  computed: {
    ...mapGetters({
      selectedUnit: 'getSelectedUnit'
    })
  }
})
export default class EventForm extends Vue {
  public selectedUnit!: any;
  public options = [{ value: '', text: this.$t('addLoss.materialEmpty') }];
  public eventNumber = null;
  public material = '';
  public producedQuantity = null;
  public startTime = null;
  public endTime = null;
  public create = null;

  public get isBeforeStart() {
    return moment(this.endTime).isBefore(this.startTime)
  }

  public onStartChange(val): void {
    this.startTime = val;
    this.endTime = '';
  }

  public onEndChange(val): void {
    this.endTime = val;
  }

  public async mounted(): Promise<void> {
    const options = await getMaterials(this.selectedUnit.puid);
    this.options.push(
      ...options.map((option) => ({
        value: option.code,
        text: `${option.name} | ${option.code}`
      }))
    );
  }

  @Emit('onCancel')
  public cancelEvent(): boolean {
    return false;
  }

  @Emit('onSave')
  public saveEvent(): any {
    return {
      assetId: this.selectedUnit.puid,
      startTime: moment(this.startTime).format(),
      endTime: moment(this.endTime).format(),
      prodCode: this.material,
      quantity: Number(this.producedQuantity),
      eventNum: this.eventNumber
    };
  }
}
