var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "wish-modal",
          attrs: {
            id: "new-wish",
            "no-fade": true,
            title: "Add Wish",
            size: "md",
            "ok-title": _vm.$t("lossModal.submit")
          },
          on: {
            shown: _vm.modalShown,
            hidden: _vm.modalTeardown,
            ok: _vm.sumbitBtn
          }
        },
        [
          _c(
            "div",
            { staticClass: "widget-rootwidget" },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.titleState,
                    label: "Title",
                    "label-for": "title-input",
                    "invalid-feedback": "Title is required"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title-input",
                      state: _vm.titleState,
                      required: ""
                    },
                    model: {
                      value: _vm.title,
                      callback: function($$v) {
                        _vm.title = $$v
                      },
                      expression: "title"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.descState,
                    label: "Description",
                    "label-for": "description-input",
                    "invalid-feedback": "Description is required"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "description-input",
                      state: _vm.descState,
                      placeholder: "Description",
                      rows: "3",
                      "max-rows": "6",
                      required: ""
                    },
                    model: {
                      value: _vm.description,
                      callback: function($$v) {
                        _vm.description = $$v
                      },
                      expression: "description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }