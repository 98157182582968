








































































































import axios from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

@Component({
  components: { DataTable, Column },
  computed: {
    ...mapGetters({
      selectedUnit: 'getSelectedUnit',
      lossTableConfiguration: 'getLossTableConfiguration'
    })
  }
})
export default class LossTableConfigurationModal extends Vue {
  @Ref('lossTableConfModal') readonly modal!: any;
  public lossTableConfiguration!: any;
  public selectedUnit!: any;
  public lossId = null;
  public configuration: any = {};
  public dateOptions: any[] = [
    'YYYY/MM/DD HH:mm:ss',
    'MM/DD/YYYY HH:mm:ss',
    'DD/MM/YYYY HH:mm:ss',
    'YYYY/MM/DD HH:mm',
    'MM/DD/YYYY HH:mm',
    'DD/MM/YYYY HH:mm',
    'YYYY/MM/DD HH',
    'MM/DD/YYYY HH',
    'DD/MM/YYYY HH',
    'YYYY/MM/DD',
    'MM/DD/YYYY',
    'DD/MM/YYYY'
  ];

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  showModal() {
    console.log(this.$refs['lossTableConfModal']);
    (this.$refs['lossTableConfModal'] as any).show();
  }

  onRowReorder(event) {
    if (event.dragIndex <= 12 || event.dropIndex <= 12) {
      return;
    }
    this.configuration.data = event.value;
  }

  async save() {
    const data: any = {
      assetId: this.selectedUnit.puid,
      data: JSON.stringify(this.configuration.data)
    };

    if (this.configuration.id) {
      data.id = this.configuration.id;
    }
    await axios.post(
      `/api/lossTableConfiguration/${this.selectedUnit.puid}`,
      data
    );

    const { data: editData } = await axios.get(
      `/api/lossTableConfiguration/${this.selectedUnit.puid}`
    );

    this.$store.dispatch('setLossTableConfiguration', {
      id: editData.id,
      data: JSON.parse(editData.data)
    });
  }

  @Watch('lossTableConfiguration', { immediate: true, deep: true })
  onLossTableConfigurationChange() {
    this.configuration = {
      id: this.lossTableConfiguration.id,
      data: this.lossTableConfiguration.data.map((loss) => ({ ...loss }))
    };
  }
}
