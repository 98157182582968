var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v(_vm._s(_vm.$t("lossModal.rcps")))]),
      _c(
        "b-form-group",
        [
          _c(
            "b-form-radio",
            {
              attrs: { value: "Open", id: "open", name: "rcps" },
              model: {
                value: _vm.RCPSStatus,
                callback: function($$v) {
                  _vm.RCPSStatus = $$v
                },
                expression: "RCPSStatus"
              }
            },
            [_vm._v(_vm._s(_vm.$t("lossModal.open")) + " ")]
          ),
          _c(
            "b-form-radio",
            {
              attrs: { name: "rcps", value: "Closed", id: "closed" },
              model: {
                value: _vm.RCPSStatus,
                callback: function($$v) {
                  _vm.RCPSStatus = $$v
                },
                expression: "RCPSStatus"
              }
            },
            [_vm._v(_vm._s(_vm.$t("lossModal.closed")) + " ")]
          )
        ],
        1
      ),
      _c("label", [_vm._v(_vm._s(_vm.$t("lossModal.rcpsComment")) + ":")]),
      _c("b-form-textarea", {
        staticClass: "rcps-comment",
        attrs: { size: "sm" },
        model: {
          value: _vm.rcpsComments,
          callback: function($$v) {
            _vm.rcpsComments = $$v
          },
          expression: "rcpsComments"
        }
      }),
      _vm.showSplitLoss
        ? _c("div", { staticClass: "split-duration" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("lossModal.splitDuration")) + ":")]),
            _c(
              "div",
              [
                _c(
                  "b-form-radio",
                  {
                    staticClass: "custom-label",
                    attrs: { value: "time", id: "one", name: "one" },
                    model: {
                      value: _vm.splitType,
                      callback: function($$v) {
                        _vm.splitType = $$v
                      },
                      expression: "splitType"
                    }
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "inline-cl",
                      attrs: {
                        id: "splitEvent",
                        type: "number",
                        disabled: _vm.splitType !== "time"
                      },
                      model: {
                        value: _vm.splitDuration,
                        callback: function($$v) {
                          _vm.splitDuration = $$v
                        },
                        expression: "splitDuration"
                      }
                    }),
                    _c("span", { staticClass: "min-cl" }, [
                      _vm._v(_vm._s(_vm.selectedUnit.splitLossTimeUoT))
                    ]),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { state: _vm.splitDurationState } },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("lossModal.splitDurationInvalidInput"))
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.showSplitLoss
              ? _c(
                  "div",
                  [
                    _c(
                      "b-form-radio",
                      {
                        staticClass: "custom-label",
                        attrs: { name: "two", value: "quantity", id: "two" },
                        model: {
                          value: _vm.splitType,
                          callback: function($$v) {
                            _vm.splitType = $$v
                          },
                          expression: "splitType"
                        }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "inline-cl",
                          attrs: {
                            id: "splitQuantity",
                            type: "number",
                            disabled: _vm.splitType !== "quantity"
                          },
                          model: {
                            value: _vm.splitQuantity,
                            callback: function($$v) {
                              _vm.splitQuantity = $$v
                            },
                            expression: "splitQuantity"
                          }
                        }),
                        _c("span", { staticClass: "min-cl" }, [
                          _vm._v(_vm._s(_vm.lossData.LossUoM))
                        ]),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { state: _vm.splitQuantityState } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.lossData.isEmptyEndDate
                                    ? _vm.$t(
                                        "lossModal.splitQuantityNoEndTimeInvalidInput"
                                      )
                                    : _vm.$t(
                                        "lossModal.splitQuantityInvalidInput"
                                      )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }