import moment from 'moment';
import { Equipment } from './../classes/equipment';
import { Utils } from '../utils';
import { Variable } from '@/classes/variable';
import { MaintenanceNotification } from "@/classes/maintenanceNotification";

export const storeActions = {
  setAccount({ commit, state }, value) {
    commit('setAccount', value);
  },
  trendChartMoved({ commit, state }, value) {
    commit('trendChartMoved', value);
  },
  toggleProductChart({ commit, state }) {
    commit('toggleProductChart');
  },
  setLoading({ commit, state }, value) {
    commit('setLoading', value);
  },
  setConfiguration({ commit, state }, value) {
    commit('setConfiguration', value);
  },
  setLossTableConfiguration({ commit }, value) {
    commit('setLossTableConfiguration', value);
  },
  unitClicked({ commit, state }, { index, unit }) {
    commit('setLoading', true);
    if (unit.IsContinuous === undefined) {
      if (Utils.showLogs(state.userName)) {
        console.warn(
          `[OAM UX Portal] IsContinuous parameter undefined, assuming ${unit.Cell} is a continuous unit`
        );
      }
      unit.IsContinuous = true;
    }
    commit('setSelectedUnit', {
      index,
      puid: unit.PUID,
      // Unspecified losses included in cell label as "Unit Whatever [2]"
      // .split(' [')[0] cleans the label "Unit Whatever [2] -> "Unit Whatever"
      label: unit.Cell.split(' [')[0],
      type: unit.Type,
      isBatch: !JSON.parse(unit.IsContinuous),
      DPPEMQ: unit.DPPEMQ,
      FunctionalLocationRoot: unit.FunctionalLocationRoot,
      FunctionalLocationRootId: unit.FunctionalLocationRootId,
      showProductOnChart: unit.ShowProductOnChart,
      allowManualLosses: unit.allowManualLosses,
      requireMaintenanceNotificationNumberForEquipmentFailure: unit.requireMaintenanceNotificationNumberForEquipmentFailure,
      IsVirtualUnit: unit.IsVirtualUnit,
      HideConfirmedEvents: unit.HideConfirmedEvents,
      DefaultControlChartDuration: unit.DefaultControlChartDuration,
      splitLossTimeUoT: unit.splitLossTimeUoT,
      MaxReasonLevel: unit.MaxReasonLevel || 2
    });
    commit('setSelectedVariables', []);
    commit('setBtnActive', 'days');
    commit(
      'setSelectedShift',
      !JSON.parse(unit.IsContinuous) ? null : state.shiftData[0]
    );
    commit('setSelectedShiftIndex', !JSON.parse(unit.IsContinuous) ? -1 : 0);
    commit('setDatabyDate', false);
    commit('setFromTimerange', false);

    (window as any).refresh = false;
  },
  setRange({ commit }, { timeRange }) {
    commit('setTimeRange', timeRange);
    commit('setSelectedShiftIndex', -1);
    commit('setSelectedShift', null);
    commit('setSelectedLossIndices', 'all');
    commit('setSelectedLossRegion', -1);
    commit('setZoomEvent', []);
  },
  toggleAvgSidebar({ commit }, type: string) {
    commit('toggleAvgSidebar', type);
  },
  toggleShiftChartArea({ commit }) {
    commit('toggleShiftChartArea');
  },
  zoomEvent({ commit }, range) {
    commit('setZoomEvent', range);
  },
  toggleSidebar({ commit }) {
    commit('toggleSidebar');
  },
  toggleTrendChartYAxisLock({ commit }) {
    commit('toggleTrendChartYAxisLock');
  },
  shiftTimelineShiftDataChanged({ commit, state }, value) {
    if (state.isAutoRefresh) {
      return;
    }
    commit('setSelectedShiftIndex', value);
    if (value > -1) {
      commit('setSelectedShift', state.shiftData[value]);
    }
  },
  setSelectedShiftIndex({ commit, state }, value) {
    commit('setSelectedShiftIndex', value);
  },
  setSelectedShift({ commit, state }, value) {
    commit('setSelectedShift', value);
  },
  variableClicked({ commit, state }, selectedVariablesCopy) {
    if (state.selectedLossData) {
      commit('setSelectedLossData', null);
    }
    if (state.selectedLossIndices) {
      commit('setSelectedLossIndices', null);
    }
    if (state.selectedLossRegion) {
      commit('setSelectedLossRegion', null);
    }
    commit(
      'setSelectedVariables',
      selectedVariablesCopy.sort((a, b) => (a.index < b.index ? -1 : 1))
    );
  },
  shiftTimelineOnPlotlyClick(
    { commit, state },
    { rangeStart, rangeEnd, shiftIndex, selectedLossIndices }
  ) {
    commit('setTimeRange', [rangeStart, rangeEnd]);
    commit('setSelectedShiftIndex', shiftIndex);
    if (shiftIndex > -1) {
      commit('setSelectedShift', state.shiftData[shiftIndex]);
    }
    commit('setSelectedLossIndices', selectedLossIndices);
  },
  batchStepOnPlotyClick(
    { commit, state },
    {
      rangeStart,
      rangeEnd,
      inspectBatchOrStep,
      index,
      dataset,
      selectedLossIndices
    }
  ) {
    commit('setInspectBatchOrStep', inspectBatchOrStep);
    commit('setTimeRange', [rangeStart, rangeEnd]);
    commit('setSelectedShiftIndex', index);
    if (index > -1) {
      commit('setSelectedShift', dataset);
    }
    commit('setSelectedLossIndices', selectedLossIndices);
  },
  setPath({ commit, state }, { path, ctx }) {
    // Only runs when app loaded
    console.warn(ctx);
    commit('setPath', path);
    for (let promise of [
      ...Utils.initData(ctx, path),
      Utils.getAlarms(path),
      Utils.getUnspecifiedLosses(path, 1)
    ]) {
      Utils.triggerBackend(promise, ctx);
    }
    Utils.autoRefresh(
      ctx,
      path,
      ctx.$store.state.refreshRate,
      ctx.$store.state.historyDays
    );
  },

  setSelectedLossIndices({ commit }, value) {
    commit('setSelectedLossIndices', value);
  },
  setFunctionalLocation({ commit }, value) {
    commit('setFunctionalLocation', value);
  },
  trendDataOnPlotyClick(
    { commit },
    { selectedPlottedLossIndex, selectedLossIndex }
  ) {
    commit('setSelectedLossRegion', selectedPlottedLossIndex);
    if (selectedLossIndex > -1) {
      commit('setSelectedLossIndices', [selectedLossIndex]);
    }
  },
  newInputJSON({ commit, state, dispatch }, { stringifiedData, ctx }) {
    let input = stringifiedData;
    if (input.hasError && Utils.showLogs(state.userName)) {
      console.warn(`ERROR ${input.target}: ${input.error}`);
    }

    try {
      switch (input.target) {
        case 'getSession':
          commit('getIpAndUsernameFromSession', stringifiedData);
          break;
        case 'readConfiguration':
          const config = input.configuration;
          commit('setConfiguration', config);
          if (state.path === 'trend') {
            commit('setInitialDataLoaded', true);
          }
          break;
        case 'getAccess':
          const access = stringifiedData.rows[0];
          if (!access) {
            ctx.$store.dispatch('setAccessDenied', true);
            const options: any = {
              type: 'error',
              title: ctx.$t('app.access_denied'),
              text: `${ctx.$t('app.no_access')} ${ctx.widgetTitle}`,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            };
            ctx.$swal(options);
          }
          commit('setAccess', access);
          break;
        case 'readTagData':
          commit('setCustomTags', JSON.parse(input.rows[0].TagData));
          break;
        case 'insertOrUpdateTagData':
          commit('setCustomTags', JSON.parse(input.rows[0].TagData));
          break;
        case 'getUnitTypes':
          commit('setUnits', stringifiedData);
          commit('setLoading', false);
          break;
        case 'getTrendsForVariables':
          commit('setTrendData', stringifiedData);
          commit('setLastUpdated', moment().toDate());
          break;
        case 'getShifts':
          let shifts = input.rows;
          if (!shifts.length) {
            commit('setShiftData', []);
            let endtime = new Date();
            let starttime = new Date(endtime.getTime() - 30 * 24 * 3.6e6);
            commit('setTimeRange', [starttime, endtime]);
            break;
          }
          shifts.forEach((row: any) => {
            row.Start_Time = Utils.dst(row.Start_Time, state.timezoneOffset);
            row.End_Time = Utils.dst(row.End_Time, state.timezoneOffset);
          });
          shifts = moment(shifts[0].Start_Time).isAfter(new Date())
            ? shifts.slice(1)
            : shifts;
          if (
            !ctx.isAutoRefresh &&
            shifts.length &&
            !ctx.selectedUnit.isBatch &&
            !ctx.fromTimerange
          ) {
            if (ctx.configuration[ctx.path].defaultTimeframe) {
              commit('setTimeRange', [
                moment(shifts[0].End_Time)
                  .subtract(ctx.configuration[ctx.path].defaultTimeframe, 'h')
                  .toDate(),
                moment(shifts[0].End_Time).toDate()
              ]);
              commit('setSelectedShift', null);
              commit('setSelectedShiftIndex', -1);
            } else {
              commit('setTimeRange', [
                new Date(shifts[0].Start_Time.getTime() - 3.6e6),
                shifts[0].End_Time
              ]);
            }
          }
          commit('setShiftData', shifts);
          break;
        case 'getStepsAndBatches':
          commit('setStepsAndBatches', input.rows);
          let batches = input.rows;
          if (!state.isAutoRefresh && batches.length && !state.fromTimerange) {
            const batchData = batches.filter(
              (x: any) => x.ProdStatus_Desc === 'Batch'
            );
            dispatch('batchStepOnPlotyClick', {
              rangeStart: moment(batchData[0].StartTime)
                .subtract(1, 'hours')
                .toDate(),
              rangeEnd: moment(batchData[0].EndTime).add(1, 'hours').toDate(),
              index: 0,
              dataset: batchData[0],
              inspectBatchOrStep: 'batch',
              selectedLossIndices: null
            });
          }
          break;
        case 'getThresholdsForUnit':
          commit('setThresholdData', stringifiedData);
          break;
        case 'getMaterialInformation':
          commit('setMaterialInformation', stringifiedData);
          break;
        case 'getVariables':
          const allvars = input.rows;
          // filter out threshold target variables
          const targetVariables = allvars.filter((variable: any) =>
            variable.name.includes('_target')
          );
          commit('setTargetVariables', targetVariables);
          const historianVariables = allvars.filter(
            (variable: any) => !variable.name.includes('_target')
          );
          const variables = historianVariables.map((v: Variable, i) => {
            let output = v;
            output.label = v.name;
            output.id = v.var;
            output.precision = Number(v.Var_Precision);
            output.origin = 'unit';
            output.active = true;
            return output;
          });
          commit('setVariables', variables);
          break;
        case 'getLossesForUnit':
          let losses = input.rows;
          losses.forEach((row: any) => {
            row.StartTime = Utils.dst(row.StartTime, state.timezoneOffset);
            if (!row.EndTime) {
              row.isEmptyEndDate = true
            }
            row.EndTime = Utils.dst(row.EndTime, state.timezoneOffset);
          });
          // if (!state.isAutoRefresh && losses.length && state.selectedUnit.isBatch && !state.fromTimerange) {
          //     const batchData = losses.filter((x: LossData) => x.LossSource === 'SpeedLoss' && !x.StepMAC);
          //     dispatch('batchStepOnPlotyClick', {
          //         rangeStart: moment(batchData[0].StartTime).subtract(1, 'hours').toDate(),
          //         rangeEnd: moment(batchData[0].EndTime).add(1, 'hours').toDate(),
          //         index: 0,
          //         dataset: batchData[0],
          //         inspectBatchOrStep: 'batch',
          //         selectedLossIndices: null
          //     });
          // }
          commit('setLossData', losses);
          if (state.shouldUpdateLosses) {
            commit('setSelectedLossIndices', 'all');
            commit('setShouldUpdateLosses', false);
          }
          break;
        case 'getAlarms':
          commit('setAlarms', stringifiedData);
          break;
        case 'getUtcTime':
          commit('setUtcTime', stringifiedData);
          break;
        case 'getFunctionalLocation':
        case 'getFunctionalLocationExpanded':
          commit('setFunctionalLocation', stringifiedData);
          break;
        case 'getUnspecifiedLosses':
          commit('setUnspecifiedLosses', stringifiedData);
          break;
        case 'getTree':
          commit('setLossTree', stringifiedData);
          break;
        case 'getEquipment':
          commit('setEquipmentList', stringifiedData);
          break;
        case 'getMaintenanceNotification':
          commit('setMaintenanceNotificationList', stringifiedData);
          break;
        case 'getSelectedMaintenanceNotification':
          commit('setSelectedMaintenanceNotification', stringifiedData);
          break;
        case 'getMesTrend':
          commit('setMesTrends', stringifiedData);
          break;
        case 'getHistorianTags':
          commit('setHistorianTags', stringifiedData.rows);
          break;
      }
    } catch (e) {
      console.warn(`Failed ${input.target}: ${e}`);
    }
    if (!state.initialDataLoaded && state.units.length && state.variables) {
      commit('setInitialDataLoaded', true);
    }
  },
  setDragMode({ commit }, value) {
    commit('setDragMode', value);
  },
  setSelectedTrendAreaTimeStamps({ commit }, value) {
    commit('setSelectedTrendAreaTimeStamps', value);
  },
  setEquipmentList({ commit }, value: Equipment[]) {
    commit('setEquipmentList', value);
  },
  setMaintenanceNotificationList({ commit }, value: MaintenanceNotification[]) {
    commit('setMaintenanceNotificationList', value);
  },
  setUpdateScheduled({ commit }, value: boolean) {
    commit('setUpdateScheduled', value);
  },
  setAutoRefresh({ commit }, value: boolean) {
    commit('setAutoRefresh', value);
  },
  setAutoRefreshEnabled({ commit }, value: boolean) {
    commit('setAutoRefreshEnabled', value);
  },
  setFromTimerange({ commit }, value: boolean) {
    commit('setFromTimerange', value);
  },
  setAccessDenied({ commit }, value: boolean) {
    commit('setAccessDenied', value);
  },
  setChartWidth({ commit }, value: number) {
    commit('setChartWidth', value);
  },
  setIsFromMetricAction({ commit }, value: boolean) {
    commit('setIsFromMetricAction', value);
  },
  setDatabyDate({ commit }, value: boolean) {
    commit('setDatabyDate', value);
  },
  setShowGrid({ commit }, value: string) {
    commit('setShowGrid', value);
  },
  setDrawMode({ commit }, value: boolean) {
    commit('setDrawMode', value);
  },
  toggleTags({ commit }, value: boolean) {
    commit('toggleTags', value);
  },
  setShouldUpdateLosses({ commit }, value: boolean) {
    commit('setShouldUpdateLosses', value);
  },
  setFunctionalLocationOriginal({ commit }, value: any) {
    commit('setFunctionalLocationOriginal', value);
  },
  setFavoritesSelectedView({ commit }, value: any) {
    commit('setFavoritesSelectedView', value);
  },
  setFavoritesViews({ commit }, value: any) {
    commit('setFavoritesViews', value);
  },
  setFromSavedState({ commit }, args: any) {
    commit('setFromSavedState', args);
  },
  updateFavorites({ commit }, args: any) {
    commit('updateFavorites', args);
  },
  deleteFavorite({ commit }, index: number) {
    commit('deleteFavorite', index);
  },
  setConversations({ commit }, conversations: any[]) {
    commit('setConversations', conversations);
  },
  setChatSearch({ commit }, search: string) {
    commit('setChatSearch', search);
  },
  setWishlistSearch({ commit }, search: string) {
    commit('setWishlistSearch', search);
  },
  setWishlist({ commit }, value: any) {
    commit('setWishlist', value);
  },
  setUnreadMessages({ commit }, value: any) {
    commit('setUnreadMessages', value);
  },
  selectVariable({ state, commit }, variable: any) {
    if (variable.origin === 'unit') {
      const newVars = state.variables.map((v) => {
        v.selected =
          v.Tag === variable.Tag &&
          v.name === variable.name &&
          v.unit === variable.unit
            ? true
            : v.selected;
        return v;
      });
      commit('setVariables', newVars);
    } else {
      const newCustomVars = state.customTags.map((v) => {
        v.selected =
          v.label === variable.label && (!v.unit || v.unit === variable.unit)
            ? true
            : v.selected;
        return v;
      });
      commit('setCustomTags', newCustomVars);
    }
  },
  unselectVariable({ state, commit }, variable: any) {
    if (variable.origin === 'unit') {
      const newVars = state.variables.map((v) => {
        v.selected =
          v.Tag === variable.Tag &&
          v.name === variable.name &&
          v.unit === variable.unit
            ? false
            : v.selected;
        return v;
      });
      commit('setVariables', newVars);
    } else {
      const newCustomVars = state.customTags.map((v) => {
        v.selected =
          v.label === variable.label && (!v.unit || v.unit === variable.unit)
            ? false
            : v.selected;
        return v;
      });
      commit('setCustomTags', newCustomVars);
    }
  },
  selectOnlyThisVariable({ state, commit }, variable: any) {
    if (variable.origin === 'unit') {
      const newVars = state.variables.map((v) => {
        v.selected =
          v.Tag === variable.Tag &&
          v.name === variable.name &&
          v.unit === variable.unit
            ? true
            : false;
        return v;
      });
      commit('setVariables', newVars);
      const newCustomVars = state.customTags.map((v) => {
        v.selected = false;
        return v;
      });
      commit('setCustomTags', newCustomVars);
    } else {
      const newVars = state.variables.map((v) => {
        v.selected = false;
        return v;
      });
      commit('setVariables', newVars);
      const newCustomVars = state.customTags.map((v) => {
        v.selected =
          v.label === variable.label && (!v.unit || v.unit === variable.unit)
            ? true
            : false;
        return v;
      });
      commit('setCustomTags', newCustomVars);
    }
  },
  addCustomVariable({ state, commit }, tag: string) {
    const color_template = [
      'blue',
      'red',
      'orange',
      'purple',
      'green',
      'black',
      'pink',
      'sandybrown',
      'brown',
      'darkgoldenrod',
      'lightgreen',
      'grey',
      'greenyellow',
      'turquoise',
      'olive'
    ];
    const newVar = {
      UoM: '',
      alarm: false,
      color:
        color_template[
          state.variables.filter((v) => v.unit === state.selectedUnit.label)
            .length
        ],
      name: tag,
      Var_Precision: 2,
      var: `var${state.variables.length}`,
      id: `var${state.variables.length}`,
      index: null,
      label: tag,
      unit: state.selectedUnit.label,
      precision: 2,
      Input_Tag: tag,
      Tag: tag,
      VarType: 'HISTORIAN',
      origin: 'custom',
      selected: true
    };
    commit('setVariables', [...state.variables, newVar]);
  }
};
