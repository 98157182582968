





































































import { Component, Ref, Vue } from 'vue-property-decorator';
import WishAddNew from './WishAddNew.vue';
import { debounce } from 'underscore';
import { mapGetters } from 'vuex';
import to from './../../services/to';
import { WishlistApi } from '../../services/api/wishlistApi';
import { ChatApi } from '../../services/api/chatApi';

const methods: any = {
  methods: {
    debounceInput: debounce(function (this: any, e: any) {
      const that: any = this as any;
      that.$store.dispatch('setWishlistSearch', e.target.value);
    }, 300)
  }
};

@Component({
  components: {
    WishAddNew
  },
  computed: {
    ...mapGetters({
      wishlist: 'getWishlist'
    })
  },
  ...methods
})
export default class WishListing extends Vue {
  @Ref('modal') readonly modal!: any;
  public wishlist!: any[];
  public filter: string = '';

  goToNewWish() {
    setTimeout(() => this.modal.openModal(), 0);
  }

  async createWish(args) {
    let err;

    [err] = await to(
      ChatApi.createConversation({
        username: this.$store.getters.getUserName,
        path: this.$store.getters.getPath,
        subject: args.title,
        message: args.description,
        upvote: 0,
        downvote: 0,
        type: 'wish'
      })
    );

    if (err) {
      console.log(err);
    }

    this.$emit('newWish');
  }

  async vote(e, wish, vote) {
    console.log('here');
    e.preventDefault();
    // let err;

    // [err] = await to(WishlistApi.vote(wish.conversation_id, vote, this.$store.getters.getUserName, this.$store.getters.getPath));

    // if (err) {
    //     console.log(err);
    // }

    if (vote === -1) {
      wish.downvote = 1;
    } else {
      wish.upvote = 1;
    }
    console.log(wish);
  }

  goToConversation(conversation) {
    this.$emit('goToConversation', conversation);
  }
}
