import to from './../to';
import axios from 'axios';
import { Config } from './../../config';
import { users } from './../../js/mockData';

export class UserApi {
  static async checkIfExist(username, path) {
    let err;

    [err] = await to(
      axios.post(`${Config.prefixUrl}check-user-exist`, { username, path })
    );

    if (err) {
      throw new Error(err);
    }
  }

  static async fetchUsers(path) {
    // let err, users;
    //
    // [err, users] = await to(axios.get(`${Config.prefixUrl}users/${path}`));
    //
    // if (err) {
    //     throw new Error(err);
    // }
    //
    // return users;
    return {
      data: users.map((user: any, i) => {
        user.user_id = i + 1;
        user.displayName = user.userDesc.includes('@nouryon.com')
          ? user.userDesc.split('@nouryon.com')[0].split('.').join(' ') +
            ' (' +
            user.username +
            ')'
          : user.username;
        return user;
      })
    };
  }
}
