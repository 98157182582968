





























import { Component, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class CommentDialog extends Vue {
  @Ref('form') readonly form!: any;
  @Ref('modalb') readonly modal!: any;
  public comment: string = null;
  public commentState: any = null;

  checkFormValidity() {
    const valid = this.form.checkValidity();
    this.commentState = !!valid;
    return valid;
  }

  openModal() {
    if (!this.modal) {
      return setTimeout(() => this.openModal(), 300);
    }
    this.modal.show();
  }

  resetModal() {
    this.comment = '';
    this.commentState = null;
  }

  modalShown() {
    document
      .querySelector('.modal')
      .parentElement.classList.add('widget-rootwidget');
  }

  handleOk(bvModalEvt) {
    bvModalEvt.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }
    this.$emit('modal-submitted', this.comment);
    this.$nextTick(() => this.modal.hide());
  }
}
