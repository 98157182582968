var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.createNewEvent
        ? _c("EventForm", {
            on: {
              onCancel: function($event) {
                return _vm.cancel()
              },
              onSave: function($event) {
                return _vm.save($event)
              }
            }
          })
        : _vm._e(),
      !_vm.createNewEvent
        ? _c(
            "div",
            { staticClass: "events-table" },
            [
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.createNewEvent = true
                        }
                      }
                    },
                    [_vm._v("Create New Event ")]
                  )
                ],
                1
              ),
              _c("b-table", {
                attrs: {
                  fields: _vm.fields,
                  items: _vm.items,
                  "select-mode": "single",
                  hover: "",
                  selectable: "",
                  "show-empty": "",
                  small: "",
                  striped: "",
                  "sticky-header": ""
                },
                on: { "row-selected": _vm.onRowSelected },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "empty",
                      fn: function() {
                        return [
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.items
                                    ? _vm.$t("addLoss.noResultsFound")
                                    : _vm.$t("addLoss.pleaseSelectDates")
                                ) +
                                " "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2899777552
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }