import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  LogLevel,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  SsoSilentRequest
} from '@azure/msal-browser';
// import { UIManager } from "./UIManager";
// import {SsoSilentRequest} from "@azure/msal-browser/dist/src/request/SsoSilentRequest";
import { GRAPH_CONFIG } from '@/Constants';
import { FetchManager } from '@/FetchManager';

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: process.env.VUE_APP_AUTHORITY,
    // redirectUri: process.env.VUE_APP_REDIRECT_URI,
    redirectUri: window.location.origin + process.env.VUE_APP_PUBLIC_PATH
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

const networkModule: FetchManager = new FetchManager();

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  // use for vue context
  private ctx: any;

  private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
  private account?: any; //AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
  private loginRedirectRequest?: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
  private loginRequest?: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
  private profileRedirectRequest?: RedirectRequest;
  private profileRequest?: PopupRequest;
  private mailRedirectRequest?: RedirectRequest;
  private mailRequest?: PopupRequest;
  private silentProfileRequest?: SilentRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html
  private silentMailRequest?: SilentRequest;
  private silentLoginRequest?: SsoSilentRequest;

  constructor() {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
    this.setRequestObjects();
  }

  /**
   * Initialize request objects used by this AuthModule.
   */
  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: []
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href
    };

    this.profileRequest = {
      scopes: ['User.Read']
    };

    this.profileRedirectRequest = {
      ...this.profileRequest,
      redirectStartPage: window.location.href
    };

    // Add here scopes for access token to be used at MS Graph API endpoints.
    this.mailRequest = {
      scopes: ['Mail.Read']
    };

    this.mailRedirectRequest = {
      ...this.mailRequest,
      redirectStartPage: window.location.href
    };

    this.silentProfileRequest = {
      scopes: ['openid', 'profile', 'User.Read'],
      // @ts-ignore
      account: null,
      forceRefresh: false
    };

    this.silentMailRequest = {
      scopes: ['openid', 'profile', 'Mail.Read'],
      // @ts-ignore
      account: null,
      forceRefresh: false
    };

    this.silentLoginRequest = {
      loginHint: 'IDLAB@msidlab0.ccsctp.net'
    };
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   * TODO: Add account chooser code
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private getAccount(): AccountInfo {
    // need to call getAccount here?
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      console.log('No accounts detected');
      // @ts-ignore
      return null;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log(
        'Multiple accounts detected, need to add choose account code.'
      );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
    // @ts-ignore
    return null;
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  async loadAuthModule(): Promise<void> {
    if (!this.ctx) {
      console.warn('call setCtx before calling loadAuthModule');
    }
    // @ts-ignore
    // this.myMSALObj.handleRedirectPromise().then((resp: AuthenticationResult) => {
    //     this.handleResponse(resp);
    // }).catch(console.error);
    const resp: AuthenticationResult =
      await this.myMSALObj.handleRedirectPromise();
    await this.handleResponse(resp);
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  async handleResponse(response: AuthenticationResult) {
    if (response !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      await this.ctx.$store.dispatch('setAccount', this.account);
      // UIManager.showWelcomeMessage(this.account);
    } else {
      this.login('loginRedirect');
    }
  }

  /**
   * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error, it will prompt the user to login using popup.
   * @param request
   */
  attemptSsoSilent() {
    // @ts-ignore
    this.myMSALObj
      .ssoSilent(this.silentLoginRequest)
      .then(() => {
        this.account = this.getAccount();
        // UIManager.showWelcomeMessage(this.account);
      })
      .catch((error: any) => {
        console.error('Silent Error: ' + error);
        if (error instanceof InteractionRequiredAuthError) {
          this.login('loginPopup');
        }
      });
  }

  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  login(signInType: string): void {
    if (signInType === 'loginPopup') {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          this.handleResponse(resp);
        })
        .catch(console.error);
    } else if (signInType === 'loginRedirect') {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    const logOutRequest: EndSessionRequest = {
      account: this.account
    };

    this.myMSALObj.logout(logOutRequest);
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(): Promise<string> {
    // @ts-ignore
    this.silentProfileRequest.account = this.account;
    // @ts-ignore
    return this.getTokenRedirect(
      this.silentProfileRequest,
      this.profileRedirectRequest
    );
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  async getProfileTokenPopup(): Promise<string> {
    // @ts-ignore
    this.silentProfileRequest.account = this.account;
    // @ts-ignore
    return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
  }

  /**
   * Gets the token to read mail data from MS Graph silently, or falls back to interactive redirect.
   */
  async getMailTokenRedirect(): Promise<string> {
    // @ts-ignore
    this.silentMailRequest.account = this.account;
    // @ts-ignore
    return this.getTokenRedirect(
      this.silentMailRequest,
      this.mailRedirectRequest
    );
  }

  /**
   * Gets the token to read mail data from MS Graph silently, or falls back to interactive popup.
   */
  async getMailTokenPopup(): Promise<string> {
    // @ts-ignore
    this.silentMailRequest.account = this.account;
    // @ts-ignore
    return this.getTokenPopup(this.silentMailRequest, this.mailRequest);
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  // @ts-ignore
  private async getTokenPopup(
    silentRequest: SilentRequest,
    interactiveRequest: PopupRequest
  ): Promise<string> {
    try {
      const response: AuthenticationResult =
        await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      console.log('silent token acquisition fails.');
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect');
        // @ts-ignore
        return this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .then((resp: AuthenticationResult) => {
            return resp.accessToken;
          })
          .catch((err: any) => {
            console.error(err);
            return null;
          });
      } else {
        console.error(e);
      }
    }
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      console.log('silent token acquisition fails.');
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect');
        this.myMSALObj
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
      } else {
        console.error(e);
      }
    }
    // @ts-ignore
    return null;
  }

  public async setToken(request: any) {
    const requestObj = {
      scopes: [process.env.VUE_APP_SCOPE],
      account: this.getAccount()
    };

    const res = await this.myMSALObj.acquireTokenSilent(requestObj);

    // const res = await this.getAuthModule.getAuthResponse() as AuthResponse
    request.headers.common['Authorization'] = `Bearer ${res.accessToken}`; // This fails if MSAL requested a new token
  }

  // set ctx before calling loadAuthModule
  public setCtx(_ctx: any) {
    this.ctx = _ctx;
  }
}
