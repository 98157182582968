var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader-container", class: { display: _vm.loading } },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "la-line-scale la-3x", staticStyle: { color: "#1a4780" } },
      [_c("div"), _c("div"), _c("div"), _c("div"), _c("div")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }