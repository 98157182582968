

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  components: {}
})
export default class DateTimePicker extends Vue {
  @Prop({ default: false }) required;
  @Prop() min: number;
  @Prop() max: number;
  @Prop() disabled: boolean;
  @Prop({default: 'md'}) size: string;
  @Prop() state;
  @Prop({ default: '' }) value;
  public date = '';
  public time = '';

  public onChange(): void {
    if (this.date && this.time) {
      this.$emit(
        'on-change',
        moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD HH:mm'
        )
      );
    }
  }

  @Watch('value', { immediate: true })
  public onValueChange(): void {
    if (!this.value) {
      this.date = '';
      this.time = '';
    }
    if (
      this.value ===
      moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DD HH:mm'
      )
    )
      return;

    const momentDate = moment(this.value, 'YYYY-MM-DD HH:mm');

    if (!momentDate.isValid()) return;

    this.date = momentDate.clone().format('YYYY-MM-DD');
    this.time = momentDate.clone().format('HH:mm');
  }
}
