var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-wrapper" }, [
    _c("table", [
      _c("thead", [
        _c("tr", { staticClass: "header-row" }, [
          _c("th", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.$t("lossModal.equipment")))
          ]),
          _c("th", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.$t("lossModal.description")))
          ]),
          _c("th", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.$t("lossModal.technicalId")))
          ]),
          _c("th", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.$t("lossModal.sortField")))
          ])
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.equipmentList, function(item) {
          return _c(
            "tr",
            {
              staticClass: "body-row",
              class: {
                selected: _vm.selectedItem.Equipment === item.Equipment
              },
              on: {
                click: function($event) {
                  return _vm.setSelected(item)
                }
              }
            },
            [
              _c("td", { staticClass: "cell" }, [
                _vm._v(_vm._s(item.Equipment))
              ]),
              _c("td", { staticClass: "cell" }, [
                _vm._v(_vm._s(item.Description))
              ]),
              _c("td", { staticClass: "cell" }, [
                _vm._v(_vm._s(item.Technical_ID))
              ]),
              _c("td", { staticClass: "cell" }, [
                _vm._v(_vm._s(item.Sortfield))
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }