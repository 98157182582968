







































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { Utils } from './../utils';
import DateRangePicker from 'vue2-daterange-picker';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import { MetricsService } from '../services/metricsService';
import { Variable } from '../classes/variable';
import AddLossModal from '@/components/AddLossModal/AddLossModal.vue';

@Component({
  components: {
    DateRangePicker,
    AddLossModal
  },
  computed: {
    ...mapGetters({
      dragMode: 'getDragMode',
      path: 'getPath',
      historyDays: 'getHistoryDays',
      timeRange: 'getTimeRange',
      isAutoRefreshEnabled: 'isAutoRefreshEnabled',
      trendChartYAxisLock: 'getTrendChartYAxisLock',
      configuration: 'getConfiguration',
      shiftChartVisible: 'getShiftChartVisible',
      lastUpdated: 'getLastUpdated',
      selectedUnit: 'getSelectedUnit',
      mesVariablesOfUnit: 'getMesVariablesOfSelectedUnit',
      variablesOfUnit: 'getVariablesOfSelectedUnit',
      fromTimerange: 'isFromTimerange',
      dataByDate: 'getDataByDate',
      isProductChartActive: 'getIsProductChartActive',
      showGrid: 'getShowGrid',
      drawMode: 'getDrawMode'
    })
  }
})
export default class TimeRangeButtons extends Vue {
  @Ref() readonly modal!: any;
  @Ref('dataRangePicker') readonly dataRangePicker!: any;
  public dragMode!: string;
  public isAutoRefreshEnabled!: boolean;
  public shiftChartVisible!: boolean;
  public drawMode!: boolean;
  public timeRange!: Date[];
  public trendChartYAxisLock!: boolean;
  public fromTimerange!: boolean;
  public dataByDate!: boolean;
  public isProductChartActive!: boolean;
  public path!: string;
  public selectedUnit!: any;
  public lastUpdated!: string;
  public showGrid!: string;
  public historyDays!: number;
  public mesVariablesOfUnit!: any[];
  public variablesOfUnit!: Variable[];
  public configuration!: any;
  public range: any = {};
  public lossType: string = '';

  get localeData() {
    return {
      //format: moment.localeData().longDateFormat('L'),
      applyLabel: this.$t('timeRangeButtons.apply'),
      cancelLabel: this.$t('timeRangeButtons.cancel')
      //daysOfWeek: moment.weekdaysMin(),
      //monthNames: moment.monthsShort(),
      //firstDay: moment.localeData().firstDayOfWeek()
    };
  }

  get ranges() {
    const today: any = this.$t('timeRangeButtons.today'),
      last3days: any = this.$t('timeRangeButtons.last3days'),
      last7days: any = this.$t('timeRangeButtons.last7days'),
      last30days: any = this.$t('timeRangeButtons.last30days');

    return {
      [today]: [moment(), moment()],
      [last3days]: [moment().subtract(3, 'days'), moment()],
      [last7days]: [moment().subtract(7, 'days'), moment()],
      [last30days]: [moment().subtract(30, 'days'), moment()]
    };
  }

  openModal(type: string) {
    this.lossType = type;
    this.modal.openModal();
  }

  toggleAutoRefresh() {
    this.$store.dispatch('setAutoRefreshEnabled', !this.isAutoRefreshEnabled);
  }

  toggleProductChart() {
    this.$store.dispatch('toggleProductChart');
  }

  setGrid(type: string) {
    this.$store.dispatch('setShowGrid', type);
  }

  toggleShiftChartArea() {
    MetricsService.newAction({
      type: 'click',
      kind: 'toggle_shift_chart_click',
      typeFormated: 'Toggle Shift Chart Click',
      message: `Shift Chart is ${
        this.shiftChartVisible ? 'Hidden' : 'Visible'
      }`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.dispatch('toggleShiftChartArea');
  }

  toggleTrendChartYAxisLock() {
    MetricsService.newAction({
      type: 'click',
      kind: 'yAxis_lock',
      typeFormated: 'Trend Chart YAxis lock Clicked',
      message: `YAxis lock is ${
        !this.trendChartYAxisLock ? 'Locked' : 'Unlocked'
      }`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.dispatch('toggleTrendChartYAxisLock');
  }

  setDragMode(mode) {
    if (!mode) {
      return;
    }

    MetricsService.newAction({
      type: 'click',
      kind: 'drag_mode_changed',
      typeFormated: 'Drag mode changed',
      message: `Drag mode was set to ${mode}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });

    this.$store.dispatch('setDragMode', mode);
  }

  drawModeToggle() {
    if (!this.drawMode) {
      this.$store.dispatch('setDragMode', 'select');
      this.$store.dispatch('setDrawMode', true);
    } else {
      this.$store.dispatch('setDragMode', 'pan');
      this.$store.dispatch('setDrawMode', false);
    }
  }

  takeImage() {
    const selectedDateRangeString = `${moment(this.timeRange[0]).format(
      'YYYY-MM-DD HH:mm'
    )} ${moment(this.timeRange[1]).format('YYYY-MM-DD HH:mm')}`;
    const title: string = `${moment(this.lastUpdated).format(
      'YYYY-MM-DD HH:mm'
    )}_${this.configuration[this.path].Title}_${
      this.selectedUnit.puid
    }_${selectedDateRangeString}.jpg`;
    domtoimage
      .toJpeg(document.getElementsByTagName('body')[0], { quality: 0.95 })
      .then((dataUrl) => saveAs(dataUrl, title.split(' ').join('_')));

    MetricsService.newAction({
      type: 'click',
      kind: 'take_image_clicked',
      typeFormated: 'Take Image Clicked',
      message: `The take image button was clicked`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
  }

  pickerToggled(status) {
    MetricsService.newAction({
      type: 'click',
      kind: 'date_range_picker_clicked',
      typeFormated: 'Date Range Picker Clicked',
      message: `Date Range picker status is ${status ? 'Open' : 'Closed'}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
  }

  async refresh(direct = false) {
    MetricsService.newAction({
      type: 'click',
      kind: 'trend_chart_refresh',
      typeFormated: 'Trend chart was refreshed',
      message: `Trend chart was refreshed`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    if (direct) {
      this.$store.dispatch('setFromTimerange', false);
    }

    for (let request of Utils.refreshUnit(this, this.historyDays)) {
      Utils.triggerBackend(request, this);
    }
  }

  async setRange(dates: any): Promise<void> {
    MetricsService.newAction({
      type: 'event',
      kind: 'picker_dates_changed',
      typeFormated: 'Picker Date Changed',
      message: `Picker Daters Are ${moment(dates.startDate).format(
        'YYYY-MM-DD'
      )} and ${moment(dates.endDate).format('YYYY-MM-DD')}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });

    this.$store.dispatch('setFromTimerange', true);

    if (
      moment(dates.startDate).isBefore(
        moment().subtract(1, 'month').subtract(1, 'day')
      )
    ) {
      for (let request of Utils.getDataByDate(
        this,
        dates.startDate,
        dates.endDate
      )) {
        await Utils.triggerBackend(request, this);
      }
      this.$store.dispatch('setShouldUpdateLosses', true);
    } else if (this.dataByDate) {
      this.$store.dispatch('setShouldUpdateLosses', true);
      this.refresh();
    }
    const isOneDay =
      moment
        .duration(moment(dates.endDate).diff(moment(dates.startDate)))
        .asDays() === 0;

    this.$store.dispatch('setRange', {
      timeRange: isOneDay
        ? [
            moment(dates.startDate).startOf('day').toDate(),
            moment(dates.endDate).endOf('day').toDate()
          ]
        : [dates.startDate, dates.endDate]
    });
  }

  @Watch('timeRange')
  timeRangeChanged() {
    if (this.timeRange.length) {
      this.range = {};
      setTimeout(() => {
        this.range = {
          startDate: moment(this.timeRange[0]).format('YYYY-MM-DD'),
          endDate: moment(this.timeRange[1]).format('YYYY-MM-DD')
        };
      }, 0);
    }
  }
}
