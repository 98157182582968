var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "raw-material-table" },
    [
      _c("b-table", {
        attrs: {
          fields: _vm.fields,
          items: _vm.items,
          "select-mode": "single",
          hover: "",
          selectable: "",
          "show-empty": "",
          small: "",
          "sticky-header": "",
          striped: ""
        },
        on: { "row-selected": _vm.onRowSelected },
        scopedSlots: _vm._u([
          {
            key: "empty",
            fn: function() {
              return [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$t("addLoss.noResultsFound")) + " ")
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }