export class Metrics {
  public type: string = '';
  public kind: string = '';
  public typeFormated: string = '';
  public message: string = '';
  public time: string = '';
  public sessionStart: string = '';
  public sessionEnd: string = '';
  public IP: string = '';
  public scrollY: number = -1;
  public scrollX: number = -1;
  public devToolsOpen: boolean = false;
  public resolutionWidth: number = -1;
  public resolutionHeight: number = -1;
  public userName: string = '';
  public PUID: string = '';
  public unit: string = '';
  public data: any = undefined;
  public sessionId: any = undefined;
  public path: string = undefined;
  public selectedVars: string = undefined;
  public clickX: any = undefined;
  public clickY: string = undefined;

  constructor(args = {}) {
    for (const key in args) {
      if (args.hasOwnProperty(key) && this.hasOwnProperty(key)) {
        this[key] = args[key];
      }
    }
  }
}
