var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "listing-card card" }, [
    _c("div", { staticClass: "listing-card-header" }, [
      _c("ul", { staticClass: "nav flex-column" }, [
        _c("li", { staticClass: "pt-4 pl-3 pr-3 pb-3 nav-item search" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("div", { staticClass: "input-group-prepend" }, [
              _c(
                "div",
                { staticClass: "input-group-text" },
                [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                1
              )
            ]),
            _c("input", {
              staticClass: "form-control",
              attrs: { placeholder: "Search...", type: "text" },
              on: {
                input: function($event) {
                  return _vm.debounceInput($event)
                }
              }
            })
          ])
        ]),
        _c("li", { staticClass: "nav-item-header nav-item" }, [
          _c("div", { staticClass: "flex-ds" }, [
            _c("span", { staticClass: "left-ds" }, [
              _vm._v("Open Conversations")
            ]),
            _c("span", { staticClass: "right-ds" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.goToNewGroup()
                    }
                  }
                },
                [_vm._v(" Add New ")]
              )
            ])
          ])
        ])
      ])
    ]),
    _c(
      "ul",
      { staticClass: "nav flex-column" },
      _vm._l(_vm.conversationList, function(list) {
        return _c("li", { staticClass: "nav-item subject-list" }, [
          _c(
            "button",
            {
              staticClass: "list-btn",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.goToConversation(list)
                }
              }
            },
            [
              _c("div", { staticClass: "list-wrapper p-0" }, [
                _c("div", { staticClass: "list-content" }, [
                  _c("div", [
                    _c("div", { staticClass: "list-heading" }, [
                      _vm._v(_vm._s(_vm.getTitle(list)))
                    ]),
                    list.messages.length
                      ? _c("div", { staticClass: "list-subheading" }, [
                          _vm._v(" " + _vm._s(list.messages[0].message) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                list.unread
                  ? _c("div", { staticClass: "badge-wrapper" }, [
                      _c("span", { staticClass: "badge badge-pill" }, [
                        _vm._v(_vm._s(list.unread))
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }