






































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { mapGetters } from 'vuex';
import EventForm from '@/components/AddLossModal/EventForm.vue';
import { createProductionEvent } from '@/services/api/AddLossApi';

@Component({
  components: { EventForm },
  computed: {
    ...mapGetters({
      selectedUnit: 'getSelectedUnit',
      path: 'getPath'
    })
  }
})
export default class EventsTable extends Vue {
  @Prop({ required: true, default: null }) items: any[];
  public path!: any;
  public selectedUnit!: any;
  public createNewEvent = false;
  public selectedField = null;

  get fields() {
    return [
      {
        label: this.$t('addLoss.eventNumber'),
        key: 'eventNumber',
        sortable: true
      },
      {
        label: this.$t('addLoss.startTime'),
        key: 'startTime',
        sortable: true,
        formatter(value) {
          return moment(moment(value).toDate()).format('YYYY-MM-DD HH:mm');
        }
      },
      {
        label: this.$t('addLoss.endTime'),
        key: 'endTime',
        sortable: true,
        formatter(value) {
          return moment(moment(value).toDate()).format('YYYY-MM-DD HH:mm');
        }
      },
      {
        label: this.$t('addLoss.materialCode'),
        key: 'code',
        sortable: true
      },
      {
        label: this.$t('addLoss.materialDescription'),
        key: 'name',
        sortable: true
      }
    ]
  }

  @Emit('onRefresh')
  public async save(entity) {
    await createProductionEvent(entity)
    this.createNewEvent = false;
  }

  public cancel() {
    this.createNewEvent = false;
  }

  @Emit('onSelect')
  public onRowSelected(row): any {
    this.selectedField = row?.[0] ? row[0] : null;
    return this.selectedField;
  }
}
