var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row d-flex justify-content-center" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "table",
        { staticClass: "table table-cyzag-sm table-striped table-hover" },
        [
          _vm._m(0),
          _c(
            "tbody",
            [
              _vm._l(_vm.variablesCopy, function(tag, i) {
                return [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(i))]),
                    _c(
                      "td",
                      {
                        staticClass: "c-padding medium-width",
                        class: { "bg-success": _vm.modified[i].active }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: tag.active,
                              expression: "tag.active"
                            }
                          ],
                          staticClass: "big-checkbox",
                          attrs: {
                            type: "checkbox",
                            disabled: tag.origin === "unit"
                          },
                          domProps: {
                            checked: Array.isArray(tag.active)
                              ? _vm._i(tag.active, null) > -1
                              : tag.active
                          },
                          on: {
                            change: function($event) {
                              var $$a = tag.active,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(tag, "active", $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      tag,
                                      "active",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(tag, "active", $$c)
                              }
                            }
                          }
                        })
                      ]
                    ),
                    _c("td", { staticClass: "no-padding medium-width" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: tag.label,
                            expression: "tag.label"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "text-success": _vm.modified[i].label,
                          "is-invalid": !tag.label
                        },
                        attrs: {
                          type: "text",
                          disabled: tag.origin === "unit"
                        },
                        domProps: { value: tag.label },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(tag, "label", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("td", { staticClass: "no-padding medium-width" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: tag.Tag,
                            expression: "tag.Tag"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "text-success": _vm.modified[i].Tag,
                          "is-invalid":
                            _vm.modified[i].Tag &&
                            _vm.tagData[i] &&
                            _vm.tagData[i][0] &&
                            (!_vm.tagData[i] ||
                              _vm.tagData[i][0].Var_Desc !==
                                _vm.sanitize(_vm.variablesCopy[i].Tag))
                        },
                        attrs: {
                          list: "historian-tags" + i,
                          type: "text",
                          disabled: tag.origin === "unit"
                        },
                        domProps: { value: tag.Tag },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(tag, "Tag", $event.target.value)
                          }
                        }
                      }),
                      _vm.tagData[i] &&
                      _vm.tagData[i] &&
                      _vm.tagData[i][0] &&
                      _vm.tagData[i][0].Var_Desc !== _vm.variablesCopy[i].Tag
                        ? _c(
                            "datalist",
                            { attrs: { id: "historian-tags" + i } },
                            _vm._l(_vm.tagData[i], function(t) {
                              return _c("option", { key: t.Var_Id }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      t.Pu_Desc
                                        ? t.Var_Id +
                                            ": " +
                                            t.Pu_Desc +
                                            " -> " +
                                            t.Var_Desc
                                        : t.Var_Desc
                                    ) +
                                    " "
                                )
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "no-padding medium-width" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: tag.precision,
                            expression: "tag.precision"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "text-success": _vm.modified[i].precision },
                        attrs: {
                          type: "number",
                          disabled: tag.origin === "unit"
                        },
                        domProps: { value: tag.precision },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(tag, "precision", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("td", { staticClass: "no-padding medium-width" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: tag.UoM,
                            expression: "tag.UoM"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "text-success": _vm.modified[i].UoM },
                        attrs: {
                          type: "text",
                          disabled: tag.origin === "unit"
                        },
                        domProps: { value: tag.UoM },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(tag, "UoM", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("td", { staticClass: "no-padding medium-width" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: tag.unit,
                              expression: "tag.unit"
                            }
                          ],
                          staticClass: "form-control",
                          class: { "text-success": _vm.modified[i].unit },
                          attrs: { disabled: tag.origin === "unit" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                tag,
                                "unit",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.units, function(unit) {
                          return _c(
                            "option",
                            { domProps: { value: unit.Cell } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(unit.Cell || "Show in all units") +
                                  " "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]),
                    _c("td", { staticClass: "no-padding medium-width" }, [
                      !_vm.isModified && tag.origin === "custom"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function($event) {
                                  return _vm.deleteTag(i)
                                }
                              }
                            },
                            [_vm._v(" Delete ")]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              })
            ],
            2
          )
        ]
      ),
      _c("div", { staticClass: "row d-flex justify-content-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.addTag()
              }
            }
          },
          [_vm._v(" + Add new variable ")]
        ),
        !_vm.isModified
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger",
                on: {
                  click: function($event) {
                    return _vm.closeTagManager()
                  }
                }
              },
              [_vm._v(" Close Tag Manager ")]
            )
          : _vm._e(),
        _vm.isModified && _vm.isValidInputs
          ? _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [_vm._v(" Save Changes ")]
            )
          : _vm._e(),
        _vm.isModified
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger",
                on: {
                  click: function($event) {
                    return _vm.discard()
                  }
                }
              },
              [_vm._v(" Discard ")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Active")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Label")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Tag")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Precision")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("UoM")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Display in this unit")]),
        _c("th", { attrs: { scope: "col" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }