import Plotly from 'plotly.js-dist';
import { ThresholdColourMap } from './../../classes/thresholdColourMap';
import { LossData } from './../../classes/lossData';
import { ShiftData } from './../../classes/shiftData';
import { BatchAndStepData } from './../../classes/batchAndStepData';
import warningIcon from './../../images/icons8-warning-shield-16.png';
import moment from 'moment';
import { Variable } from './../../classes/variable';
import { Threshold } from './../../classes/threshold';
import { DynamicThreshold } from './../../classes/dynamicThreshold';
import { Trend } from './../../classes/trend';
import { Utils } from './../../utils';
import { throwError } from 'rxjs';
import ChartFunctions from './functions';

export default class ChartsUtils {
  public static thresholdColourMap: ThresholdColourMap = {
    GainTolerence: 'green',
    MAC: 'blue',
    CurrentAssetCapability: 'purple',
    MarketDemand: 'orange',
    LossTolerence: 'green',
    RCPS: 'red'
  };
  public static ThresholdKeys = Object.keys(ChartsUtils.thresholdColourMap);

  public static getYRange(
    timeStamps: any[],
    yValues: number[],
    xrange: any[],
    returnIndexes = false
  ): number[] {
    const StartTime: Date = new Date(xrange[0]),
      EndTime: Date = new Date(xrange[1]);

    let min_index_found: boolean = false,
      max_index_found: boolean = false,
      minindex: number = 0,
      maxindex: number = timeStamps.length;

    for (let i: number = 1; i < timeStamps.length; i++) {
      let ts = new Date(timeStamps[i]);
      if (ts > StartTime && !min_index_found) {
        minindex = i - 1;
        min_index_found = true;
      }
      if (ts > EndTime && !max_index_found) {
        maxindex = i;
        max_index_found = true;
      }
      if (min_index_found && max_index_found) {
        break;
      }
    }
    if (returnIndexes) {
      return [minindex, maxindex];
    } else {
      return Plotly.d3.extent(
        yValues.slice(minindex, maxindex).map((yv) => Number(yv))
      );
    }
  }

  public static getLossImages(ctx: any): any {
    let lossImagesForPlot: any = [];
    ctx.lossData.forEach((lossData: LossData) => {
      const existInShidtData = ctx.shiftData.find((shift: ShiftData) => {
        return moment(lossData.EndTime).isBetween(
          moment(shift.Start_Time),
          moment(shift.End_Time).add(1, 'm')
        );
      });
      if (lossData.OAMCategory === 'Unspecified' && existInShidtData) {
        const defaultObj: any = {
          source: warningIcon,
          xref: 'x',
          yref: 'paper',
          layer: 'top',
          y: 0.5,
          sizey: 1,
          xanchor: 'middle',
          yanchor: 'middle'
        };

        lossImagesForPlot.push({
          x:
            new Date(
              new Date(existInShidtData.Start_Time).getTime() +
                new Date(existInShidtData.End_Time).getTime()
            ).getTime() /
              2 +
            5000000,
          sizex: 4000000,
          ...defaultObj
        });
      }
    });
    return lossImagesForPlot;
  }

  public static getLossImagesForBatch(ctx: any): any {
    let lossImagesForPlot: any = [];
    ctx.lossData.forEach((lossData: LossData) => {
      const existInShidtData = ctx.batchData.find((shift: BatchAndStepData) => {
        return moment(lossData.EndTime).isBetween(
          moment(shift.StartTime),
          moment(shift.EndTime).add(1, 'm')
        );
      });
      if (lossData.OAMCategory === 'Unspecified' && existInShidtData) {
        const defaultObj: any = {
          source: warningIcon,
          xref: 'x',
          yref: 'paper',
          layer: 'top',
          y: 0.5,
          sizey: 0.8,
          xanchor: 'right',
          yanchor: 'middle'
        };

        lossImagesForPlot.push({
          x: new Date(existInShidtData.EndTime).getTime() - 100000,
          sizex: 4000000,
          ...defaultObj
        });
      }
    });
    return lossImagesForPlot;
  }

  public static getLossData(ctx: any): any {
    let lossDataForPlot: any = [];

    ctx.selectedLossData.forEach((lossData: LossData, i: number) => {
      let color: string = '',
        borderColor: string = '';

      const opacity = i === ctx.selectedLossRegion ? 1 : 0.3;

      if (lossData.LossSource === 'Downtime') {
        color = 'red';
        borderColor = '#ad3131';
      } else if (lossData.LossSource === 'SpeedLoss') {
        color = 'yellow';
        borderColor = '#b7b700';
      } else if (lossData.LossSource === 'PEM') {
        if (lossData.LossQuantity >= 0) {
          color = 'red';
          borderColor = '#ad3131';
        } else {
          color = 'green';
          borderColor = '#366b36';
        }
      }
      const defaultLossData: any = {
        type: 'rect',
        xref: 'x', // x-reference is assigned to the x-values
        yref: 'paper', // y-reference is assigned to the plot paper [0,1]
        layer: 'below',
        y0: 0,
        y1: 1,
        fillcolor: color,
        opacity
      };

      lossDataForPlot.push({
        x0: lossData.StartTime,
        x1: lossData.EndTime,
        line: {
          width: 0
        },
        ...defaultLossData
      });

      lossDataForPlot.push({
        x0: lossData.EndTime,
        x1: lossData.EndTime,
        line: {
          width: 1,
          color: borderColor
        },
        ...defaultLossData
      });
      lossDataForPlot.push({
        x0: lossData.StartTime,
        x1: lossData.StartTime,
        line: {
          width: 1,
          color: borderColor
        },
        ...defaultLossData
      });
    });

    return lossDataForPlot;
  }

  public static getStepData(ctx: any): any {
    let stepDataForPlot: any = [];

    ctx.stepData.forEach((stepData: LossData, i: number) => {
      let color: string = '',
        borderColor: string = '';

      const opacity = i === ctx.selectedLossRegion ? 1 : 0.3;

      // color = '#90EE90';
      borderColor = 'green';
      const defaultStepData: any = {
        type: 'rect',
        xref: 'x', // x-reference is assigned to the x-values
        yref: 'paper', // y-reference is assigned to the plot paper [0,1]
        layer: 'below',
        y0: 0,
        y1: 1,
        fillcolor: color,
        opacity
      };

      stepDataForPlot.push({
        x0: stepData.StartTime,
        x1: stepData.EndTime,
        line: {
          width: 0,
          dash: 'dash'
        },
        ...defaultStepData
      });

      stepDataForPlot.push({
        x0: stepData.EndTime,
        x1: stepData.EndTime,
        line: {
          width: 1,
          color: borderColor,
          dash: 'dash'
        },
        ...defaultStepData
      });
      stepDataForPlot.push({
        x0: stepData.StartTime,
        x1: stepData.StartTime,
        line: {
          width: 1,
          color: borderColor,
          dash: 'dash'
        },
        ...defaultStepData
      });
    });

    return stepDataForPlot;
  }

  public static getTrendData(ctx: any): any {
    let trendDataForPlot: any = [];

    ctx.selectedTrendValues.forEach((trendValues: any[], i: number) => {
      const data: any = {
        x: ctx.selectedTrendDates[i],
        y: trendValues,
        yaxis: `y${i + 1}`,
        mode: 'markers+lines',
        refIdData: true,
        name: ctx.selectedVariables[i].label,
        text: ctx.selectedVariables[i].UoM,
        showlegend: true,
        type: 'scattergl',
        line: {
          width: 1,
          color: ctx.selectedVariables[i].color
        },
        marker: {
          size: 1
        }
      };

      if (ctx.selectedVariables[i].isDiscrete) {
        data.line.shape = 'hv';
      }
      trendDataForPlot.push(data);
    });

    return trendDataForPlot;
  }

  public static getProductValues(ctx: any) {
    const output = { shapes: [], annotations: [] };
    ctx.materialInformation.forEach((product) => {
      output.shapes.push({
        x0: moment(product.endTime + "Z").toDate(),
        x1: moment(product.endTime  + "Z").toDate(),
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        y0: 0,
        y1: 1,
        opacity: 1,
        fillcolor: 'gray',
        line: {
          width: 1,
          dash: 'dash',
          color: '#c56cf7'
        }
      });
      output.annotations.push({
        x: new Date(product.endTime + "Z").getTime(),
        y: 0,
        xref: 'x',
        yref: 'paper',
        text: `${product.code} / ${product.name}`,
        showarrow: false,
        arrowhead: 7,
        ax: 0,
        ay: 0,
        textangle: -90,
        xanchor: 'left',
        yanchor: 'bottom',
        font: {
          color: '#c56cf7',
          size: 12
        }
      });
    });

    return output;
  }

  public static getTrendText(ctx: any): any {
    const variables = ctx.variables.filter(
      (v: Variable) => v.unit === ctx.selectedUnit.label
    );

    if (
      !variables.length ||
      ctx.selectedVariables.findIndex((sv) => sv.id === variables[0].var) === -1
    ) {
      return [];
    }

    const output = [],
      timeRanges = ctx.zoomEvent.length ? ctx.zoomEvent : ctx.timeRange,
      difference =
        new Date(timeRanges[1]).getTime() - new Date(timeRanges[0]).getTime(),
      globalChartDate = new Date(
        new Date(timeRanges[1]).getTime() - (difference * 0.25) / 100
      ),
      thresholds = ChartsUtils.getThresholdData(ctx, false, true);

    thresholds.map((thres, i) => {
      const indexes = [];

      thres.timeRanges.find((date, i) => {
        if (
          moment(new Date(date)).isBetween(
            moment(timeRanges[0]).startOf('d'),
            moment(timeRanges[1]).endOf('d')
          )
        ) {
          indexes.push(i);
        }
      });

      let yValue = thres.values[indexes[indexes.length - 1]];

      output.push({
        fillcolor: 'gray',
        y: [yValue],
        layer: 'top',
        mode: 'text',
        ref: 'chartText',
        textfont: {
          color: thres.color,
          size: 12,
          weight: 'bold'
        },
        textposition: 'left bottom',
        text: [thres.name],
        showlegend: true,
        hoverinfo: 'y',
        x: [globalChartDate]
      });
    });

    return output;
  }

  public static isTargetThreshold(ctx): boolean {
    return !!ctx.targetVariables.find(
      (tg) => ctx.selectedUnit.label === tg.unit
    );
  }

  public static getUpperAndLowerLimitTargetThreshold(ctx) {
    const indexes = [];

    ctx.trendData.map((row, i) => {
      const rowTimeStamp = moment(new Date(row.TimeStamp));
      const from = moment(ctx.timeRange[0]);
      const to = moment(ctx.timeRange[1]);

      if (rowTimeStamp.isBetween(from, to)) {
        indexes.push(i);
      }
    });

    let varnumber = ctx.targetVariables.find(
      (tg) => ctx.selectedUnit.label === tg.unit
    );
    if (varnumber) {
      const target: Trend[] = ctx.trendData.map((row) => {
        return {
          value: row[varnumber.var],
          TimeStamp: row.TimeStamp
        };
      });

      const dynamicThresholds: DynamicThreshold[] =
        ChartFunctions.getDynamicThresholdTrends(
          target,
          ctx.thresholdData
        ).filter((dn) =>
          moment(dn.timestamp).isBetween(
            moment(ctx.timeRange[0]),
            moment(ctx.timeRange[1])
          )
        );

      const macValues = ChartsUtils.getThresholdData(ctx, true);

      const macValuesIndex = [];
      macValues.timeRanges.map((time, i) => {
        const rowTimeStamp = moment(new Date(time));
        const from = moment(ctx.timeRange[0]);
        const to = moment(ctx.timeRange[1]);

        if (rowTimeStamp.isBetween(from, to)) {
          macValuesIndex.push(i);
        }
      });
      return {
        upper: dynamicThresholds.map((v) => v.upper),
        lower: dynamicThresholds.map((v) => v.lower),
        macValues: macValues.values.filter(
          (v, i) => macValuesIndex.indexOf(i) > -1
        )
      };
    }

    return null;
  }

  public static getThresholdData(
    ctx: any,
    onlyMacValue = false,
    onlyValues = false
  ): any {
    if (ctx.selectedUnit.isBatch) {
      return [];
    }
    const thresholdDataForPlot: any = [],
      target_threshold: boolean = !!ctx.targetVariables.find(
        (tg) => ctx.selectedUnit.label === tg.unit
      );

    let macValues: any = null,
      allValues: any = [];

    ChartsUtils.ThresholdKeys.forEach((key: any) => {
      let timestamps: any[] = [];
      const yValues: any[] = [],
        step_timestamps: any[] = [],
        step_yValues: any[] = [];
      ctx.thresholdData.forEach((row) => {
        if (row[key] !== undefined) {
          yValues.push(row[key]);
          timestamps.push(row.Timestamp);
        }
      });
      // Don't plot threholds without values
      // If target_threshold, only plot MAC
      if (yValues[0] !== undefined && (!target_threshold || key === 'MAC')) {
        for (let i = 0; i < yValues.length; i++) {
          step_timestamps.push(timestamps[i]);
          step_yValues.push(yValues[i]);
          if (i < yValues.length - 1 && yValues[i] !== yValues[i + 1]) {
            step_timestamps.push(timestamps[i + 1]);
            step_yValues.push(yValues[i]);
          }
        }

        if (key === 'MAC') {
          macValues = {
            timeRanges: step_timestamps.concat(new Date()),
            values: step_yValues.concat(step_yValues[step_yValues.length - 1])
          };
        }

        // allValues.push({
        //   key,
        //   timeRanges: step_timestamps.concat(new Date()),
        //   values: step_yValues.concat(step_yValues[step_yValues.length - 1]),
        //   color: ChartsUtils.thresholdColourMap[key],
        //   name:
        //     key === 'GainTolerence'
        //       ? ctx.$t(`trendChart.thresholdNames.LossTolerence`)
        //       : ctx.$t(`trendChart.thresholdNames.${key}`),
        //   position: key === 'LossTolerence' || key === 'MAC' ? 'bottom' : 'top'
        // });

        thresholdDataForPlot.push({
          x: step_timestamps.concat(new Date()),
          y: step_yValues.concat(step_yValues[step_yValues.length - 1]),
          mode: 'lines',
          name: ctx.$t(`trendChart.thresholdNames.${key}`),
          showlegend: key !== 'GainTolerence',
          hoverinfo: 'y',
          line: {
            color: ChartsUtils.thresholdColourMap[key],
            dash: 'dash',
            width: 1
          }
        });
      }
    });

    if (onlyMacValue) {
      return macValues;
    }

    let varnumber = ctx.targetVariables.find(
      (tg) => ctx.selectedUnit.label === tg.unit
    );
    if (target_threshold && ctx.selectedUnit && varnumber) {
      let timestamps = ctx.trendData.map((row) => new Date(row.TimeStamp));

      const target: Trend[] = ctx.trendData.map((row) => {
        return {
          value: row[varnumber.var],
          TimeStamp: row.TimeStamp
        };
      });

      const dynamicThresholds: DynamicThreshold[] =
        ChartFunctions.getDynamicThresholdTrends(target, ctx.thresholdData);
      let upperLimitTrend = dynamicThresholds.map((v) => v.upper);
      let lowerLimitTrend = dynamicThresholds.map((v) => v.lower);

      thresholdDataForPlot.push({
        x: timestamps,
        y: target.map((t) => t.value),
        mode: 'lines',
        name: ctx.$t(`trendChart.thresholdNames.Target`),
        showlegend: true,
        hoverinfo: 'y',
        line: {
          color: ChartsUtils.thresholdColourMap['MarketDemand'],
          dash: 'dash',
          width: 1
        }
      });
      thresholdDataForPlot.push({
        x: timestamps,
        y: upperLimitTrend,
        mode: 'lines',
        name: ctx.$t(`trendChart.thresholdNames.GainTolerence`),
        showlegend: true,
        hoverinfo: 'y',
        line: {
          color: ChartsUtils.thresholdColourMap['GainTolerence'],
          dash: 'dash',
          width: 1
        }
      });
      thresholdDataForPlot.push({
        x: timestamps,
        y: lowerLimitTrend,
        mode: 'lines',
        name: 'none',
        showlegend: false,
        hoverinfo: 'y',
        line: {
          color: ChartsUtils.thresholdColourMap['LossTolerence'],
          dash: 'dash',
          width: 1
        }
      });

      // allValues.push({
      //   key: 'MarketDemand',
      //   timeRanges: timestamps,
      //   values: target.map((t) => t.value),
      //   color: ChartsUtils.thresholdColourMap['MarketDemand'],
      //   name: ctx.$t(`trendChart.thresholdNames.Target`),
      //   position: 'top'
      // });
      //
      // allValues.push({
      //   key: 'CAC',
      //   timeRanges: timestamps,
      //   values: target.map((t) => t.value),
      //   color: ChartsUtils.thresholdColourMap['CAC'],
      //   name: ctx.$t(`trendChart.thresholdNames.cac`),
      //   position: 'top'
      // });
      //
      // allValues.push({
      //   key: 'GainTolerence',
      //   timeRanges: timestamps,
      //   values: upperLimitTrend,
      //   color: ChartsUtils.thresholdColourMap['GainTolerence'],
      //   name: ctx.$t(`trendChart.thresholdNames.LossTolerence`),
      //   position: 'top'
      // });
      //
      // allValues.push({
      //   key: 'LossTolerence',
      //   timeRanges: timestamps,
      //   values: lowerLimitTrend,
      //   color: ChartsUtils.thresholdColourMap['LossTolerence'],
      //   name: ctx.$t(`trendChart.thresholdNames.LossTolerence`),
      //   position: 'top'
      // });
    }

    if (onlyValues) {
      return allValues;
    }

    return thresholdDataForPlot;
  }
}
