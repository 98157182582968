











































































































































































































































































































































































































































































































































































































































































































import Plotly from 'plotly.js-dist/plotly.js';
// @ts-ignore
import LossModal from './LossModal/LossModal';
// @ts-ignore
import JsonExcel from 'vue-json-excel';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { LossData } from '../classes/lossData';
import moment from 'moment';
import { MetricsService } from '../services/metricsService';
import { Utils } from './../utils';
import {
  getDowntimeAudits,
  getPerformanceAudits,
  getQualityPemAudits
} from '@/services/api/auditApi';
import AuditModal from '@/components/AuditModal/AuditModal.vue';
import LossTableConfigurationModal from '@/components/LossTableConfigurationModal/LossTableConfigurationModal.vue';
import * as numeral from 'numeral'
import * as  d3 from 'd3';

@Component({
  components: {
    LossTableConfigurationModal,
    LossModal,
    AuditModal,
    JsonExcel
  },
  computed: {
    ...mapGetters({
      selectedLossIndices: 'getSelectedLossIndices',
      lossData: 'getLossData',
      functionalLocation: 'getFunctionalLocation',
      lossTree: 'getLossTree',
      selectedUnit: 'getSelectedUnit',
      lossTableConfiguration: 'getLossTableConfiguration'
    })
  }
})
export default class LossTablePEM extends Vue {
  @Ref() readonly modal!: any;
  @Ref('auditModal') readonly auditModal!: any;
  public lossTableConfiguration!: any;
  public selectedLossIndices!: any;
  public lossData!: LossData[];
  public functionalLocation!: any;
  public selectedUnit!: any;
  public lossTree!: any;
  public selectedLossReasonIndex: number = 0;
  public selectedAdditionalLossReasonIndex: number[] = [];
  public lossReasonId: any = null;
  public lossModalKey: number = 0;
  public searchIsActive: boolean = false;
  public sort: any = 'StartTimeString';
  public sortType: any = 'ASC';
  public auditData: any[] = [];
  public sortTypeOF: any = 'date';
  public notIncludeInRestKeys = [
    'StepName',
    'Cost',
    'OAMCategory',
    'OAMFactor',
    'LocalReason1',
    'LocalReason2',
    'LocalReason3',
    'EndTimeString',
    'Comment'
  ];
  public filter: any = {
    stepName: '',
    cost: '',
    lossMinutes: '',
    lossQuantity: '',
    OAMCategory: '',
    OAMFactor: '',
    localReason: '',
    localReason2: '',
    localReason3: '',
    startTime: '',
    endTime: ''
  };
  public isCollapsed: boolean = false;
  public isButtonVisible: boolean = false;
  public lossTypesToShow: string[] = ['PEM'];

  mounted() {
    if (
      this.lossData.some((loss) => loss.LossSource === 'PEM') &&
      this.lossData.some((loss) => loss.LossSource === 'SpeedLoss')
    ) {
      this.isButtonVisible = this.isCollapsed = true;
    } else if (this.lossData.some((loss) => loss.LossSource === 'PEM')) {
      this.isButtonVisible = false;
      this.isCollapsed = false;
    } else {
      this.lossTypesToShow.push('Downtime');
      this.isButtonVisible = false;
      this.isCollapsed = true;
    }
  }


  @Watch('selectedLossIndices')
  selectedLossIndicesChanged() {
    if (
      this.selectedLossIndices.length === 1 &&
      this.lossTypesToShow.includes(
        this.lossData[this.selectedLossIndices[0]].LossSource
      )
    ) {
      this.isCollapsed = false;
    }
  }

  toggleAdditionalLossIndex(rowIndex) {
    const index = this.selectedAdditionalLossReasonIndex.indexOf(rowIndex);
    if (index > -1) {
      this.selectedAdditionalLossReasonIndex.splice(index, 1);
    } else {
      this.selectedAdditionalLossReasonIndex.push(rowIndex);
    }
  }

  containsSameLossSource(rowIndex) {
    if (this.selectedAdditionalLossReasonIndex) {
      for (let i = 0; i < this.selectedAdditionalLossReasonIndex.length; i++) {
        const row = this.selectedLossData[this.selectedAdditionalLossReasonIndex[i]];
        const selectedRow = this.selectedLossData[rowIndex];
        if (row.LossSource !== selectedRow.LossSource){
          return true;
        }
      }
    }
    return false;
  }

  toggleTable() {
    this.isCollapsed = !this.isCollapsed;
  }

  get lossCount() {
    if (this.selectedLossIndices) {
      return this.selectedLossIndices.filter((i) =>
        this.lossTypesToShow.includes(this.lossData[i].LossSource)
      ).length;
    } else {
      return 0;
    }
  }

  get extraRows() {
    return this.lossTableConfiguration.data.filter(
      (loss) => !loss.disabled && loss.selected
    );
  }

  get excelName() {
    return `loss-table-${this.selectedUnit.label.replace(
      / /g,
      '_'
    )}-${moment().format('YYYY-MM-DD_HH:mm')}.xls`;
  }

  get fields() {
    const stepName: any = this.$t('lossTableAUM.headings.stepName'),
      cost: any = this.$t('lossTableAUM.headings.cost'),
      lossMinutes: any = this.$t('lossTableAUM.headings.lossMinutes'),
      lossQuantity: any = this.$t('lossTableAUM.headings.lossQuantity'),
      OAMCategory: any = this.$t('lossTableAUM.headings.OAMCategory'),
      OAMFactor: any = this.$t('lossTableAUM.headings.OAMFactor'),
      localReason: any = this.$t('lossTableAUM.headings.localReason'),
      localReason2: any = this.$t('lossTableAUM.headings.localReason2'),
      localReason3: any = this.$t('lossTableAUM.headings.localReason3'),
      startTime: any = this.$t('lossTableAUM.headings.startTime'),
      endTime: any = this.$t('lossTableAUM.headings.endTime'),
      comment: any = this.$t('lossTableAUM.headings.comment');

    const restOfKeys: any = {};

    Object.keys(this.lossData[0]).map((key) => {
      if (this.notIncludeInRestKeys.indexOf(key) === -1) {
        restOfKeys[
          key.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
        ] = key;
      }
    });

    return {
      [stepName]: 'StepName',
      [cost]: 'Cost',
      [lossMinutes]: 'LossMinutes',
      [lossQuantity]: 'LossQuantity',
      [OAMCategory]: 'OAMCategory',
      [OAMFactor]: 'OAMFactor',
      [localReason]: 'LocalReason1',
      [localReason2]: 'LocalReason2',
      [localReason3]: 'LocalReason3',
      [startTime]: 'StartTimeString',
      [endTime]: 'EndTimeString',
      [comment]: 'Comment',
      ...restOfKeys
    };
  }

  get selectedLossData() {
    if (this.selectedLossIndices) {
      let lossData: any = this.selectedLossIndices
        .filter((i) => ['PEM'].includes(this.lossData[i].LossSource))
        .map((index) => {
          let lossData: any = { ...this.lossData[index] };
          lossData.Cost = d3.format('.2f')(lossData.Cost);
          lossData.LossMinutes = d3.format('.2f')(lossData.LossMinutes);
          lossData.LossQuantity = d3.format('.2f')(
            lossData.LossQuantity
          );
          lossData.StartTimeString = moment(lossData.StartTime).format(
            'YYYY/MM/DD, HH:mm:ss'
          );
          lossData.EndTimeString = moment(lossData.EndTime).format(
            'YYYY/MM/DD, HH:mm:ss'
          );
          return lossData;
        });

      if (this.filter.event_Num) {
        lossData = lossData.filter(
          (loss) =>
            loss.Event_Num &&
            loss.Event_Num.toLowerCase().includes(
              this.filter.event_Num.toLowerCase()
            )
        );
      }

      if (this.filter.finalProduct_Desc) {
        lossData = lossData.filter(
          (loss) =>
            loss.FinalProduct_Desc &&
            loss.FinalProduct_Desc.toLowerCase().includes(
              this.filter.finalProduct_Desc.toLowerCase()
            )
        );
      }

      if (this.filter.stepName) {
        lossData = lossData.filter((loss) =>
          loss.StepName && loss.StepName.toLowerCase().includes(
            this.filter.stepName.toLowerCase()
          )
        );
      }

      if (this.filter.cost) {
        lossData = lossData.filter((loss) =>
          loss.Cost.toString().includes(this.filter.cost)
        );
      }

      if (this.filter.lossMinutes) {
        lossData = lossData.filter((loss) =>
          loss.LossMinutes.toString().includes(this.filter.lossMinutes)
        );
      }

      if (this.filter.lossQuantity) {
        lossData = lossData.filter((loss) =>
          loss.LossQuantity.toString().includes(this.filter.lossQuantity)
        );
      }

      if (this.filter.OAMCategory) {
        lossData = lossData.filter((loss) =>
          loss.OAMCategory.toLowerCase().includes(
            this.filter.OAMCategory.toLowerCase()
          )
        );
      }

      if (this.filter.OAMFactor) {
        lossData = lossData.filter((loss) =>
          loss.OAMFactor.toLowerCase().includes(
            this.filter.OAMFactor.toLowerCase()
          )
        );
      }

      if (this.filter.localReason) {
        lossData = lossData.filter((loss) =>
          loss.LocalReason1.toLowerCase().includes(
            this.filter.localReason.toLowerCase()
          )
        );
      }

      if (this.filter.localReason2) {
        lossData = lossData.filter((loss) =>
          loss.LocalReason2.toLowerCase().includes(
            this.filter.localReason2.toLowerCase()
          )
        );
      }

      if (this.filter.localReason3) {
        lossData = lossData.filter((loss) =>
          loss.LocalReason3.toLowerCase().includes(
            this.filter.localReason3.toLowerCase()
          )
        );
      }

      if (this.filter.startTime) {
        const date = moment(this.filter.startTime, 'YYYY-MM-DD').startOf('d');
        lossData = lossData.filter((loss) =>
          date.isSame(
            moment(loss.StartTimeString, 'YYYY-MM-DD HH:mm:ss').startOf('d')
          )
        );
      }

      if (this.filter.endTime) {
        const date = moment(this.filter.endTime, 'YYYY-MM-DD').startOf('d');
        lossData = lossData.filter((loss) =>
          date.isSame(
            moment(loss.EndTimeString, 'YYYY-MM-DD HH:mm:ss').startOf('d')
          )
        );
      }

      if (this.filter.comment) {
        lossData = lossData.filter((loss) =>
          loss.Comment && loss.Comment.toLowerCase().includes(this.filter.comment.toLowerCase())
        );
      }
      if (this.filter.RCPSStatus) {
        lossData = lossData.filter((loss) =>
          loss.RCPSStatus && loss.RCPSStatus.toLowerCase().includes(this.filter.RCPSStatus.toLowerCase())
        );
      }

      if (this.filter.RCPSComment) {
        lossData = lossData.filter((loss) =>
          loss.RCPSComments && loss.RCPSComments.toLowerCase().includes(this.filter.RCPSComment.toLowerCase())
        );
      }

      if (this.filter.functionalLocation) {
        lossData = lossData.filter(
          (loss) =>
            loss.FunctionalLocation &&
            loss.FunctionalLocation.toLowerCase().includes(
              this.filter.functionalLocation.toLowerCase()
            )
        );
      }

      this.extraRows.forEach((row) => {
        if (this.filter[row.key]) {
          if (row.type === 'date') {
            const date = moment(this.filter[row.key], 'YYYY-MM-DD').startOf('d');
            lossData = lossData.filter((loss) =>
              date.isSame(
                moment(loss[row.key], 'YYYY-MM-DD HH:mm:ss').startOf('d')
              )
            );
          } else if (row.type === 'number') {
            lossData = lossData.filter((loss) =>
              loss[row.key].toString().includes(this.filter[row.key])
            )
          } else {
            lossData = lossData.filter((loss) =>
              loss[row.key]
                .toLowerCase()
                .includes(this.filter[row.key].toLowerCase())
            );
          }
        }
      });

      if (!lossData.length) {
        return [
          {
            noResults: true
          }
        ];
      }

      if (this.sort && this.sortType && this.sortTypeOF) {
        if (this.sortTypeOF === 'string') {
          lossData.sort((a, b) =>
            this.sortType === 'ASC'
              ? ('' + a[this.sort]).localeCompare(b[this.sort])
              : ('' + b[this.sort]).localeCompare(a[this.sort])
          );
        } else if (this.sortTypeOF === 'number') {
          lossData.sort((a, b) =>
            this.sortType === 'ASC'
              ? b[this.sort] - a[this.sort]
              : a[this.sort] - b[this.sort]
          );
        } else if (this.sortTypeOF === 'date') {
          lossData.sort((a, b) =>
            this.sortType === 'ASC'
              ? ('' + b[this.sort]).localeCompare(a[this.sort])
              : ('' + a[this.sort]).localeCompare(b[this.sort])
          );
        }
      }

      if (this.selectedUnit.HideConfirmedEvents) {
        lossData = lossData.filter(
          (loss) => loss.OAMCategory === 'Unspecified'
        );
      }

      return lossData;
    }
  }

  get lossDataForPopup(): any {
    return this.selectedLossData[this.selectedLossReasonIndex];
  }

  get additionalLossDataForPopup(): any {
    const additionalLossData = [];
    for (let i = 0; i < this.selectedAdditionalLossReasonIndex.length; i++) {
      additionalLossData.push(this.selectedLossData[this.selectedAdditionalLossReasonIndex[i]]);
    }
    return additionalLossData;
  }

  lossModalClosed() {
    this.selectedAdditionalLossReasonIndex = [];
  }

  formatNumber(value, format = '0.00') {
    return numeral(value || 0).format(format);
  }

  formatDate(value, format = '0.00') {
    return moment(value).format(format);
  }

  toggleHideConfirmedEvents() {
    MetricsService.newAction({
      type: 'click',
      kind: 'loss_table_toggle_hide_confirmed_events_click',
      typeFormated: 'Loss Table Toggle Hide Confirmed Events Click',
      message: `The production unit hide confirmed events is  ${this.selectedUnit.HideConfirmedEvents}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.selectedUnit.HideConfirmedEvents =
      !this.selectedUnit.HideConfirmedEvents;
  }
  showTableConfig() {
    (this.$refs.LossTableConfigurationModal as any).showModal();
  }

  toggleSearch() {
    MetricsService.newAction({
      type: 'click',
      kind: 'loss_table_toggle_search_click',
      typeFormated: 'Loss Table Toggle Search Click',
      message: `The loss table search is  ${
        this.searchIsActive ? 'Hidden' : 'Visible'
      }`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.searchIsActive = !this.searchIsActive;
  }

  getIcon(propertyName) {
    if (propertyName === this.sort) {
      if (this.sortType === 'ASC') {
        return 'sort-down';
      } else {
        return 'sort-up';
      }
    }

    return 'sort';
  }

  getSort(propertyName, type = 'string') {
    this.sortTypeOF = type;
    if (propertyName === this.sort) {
      this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sort = propertyName;
      this.sortType = 'ASC';
    }

    MetricsService.newAction({
      type: 'click',
      kind: 'loss_table_sort_click',
      typeFormated: 'Loss Table Sort Click',
      message: `Loss table sort is ${this.sort} and type is ${this.sortType}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
  }

  getRowColor(row: any): string {
    if (!row.EndTimeString) {
      return 'row-green';
    }

    if (
      row.OAMCategory.toLowerCase() === 'unspecified' ||
      row.BottomOfTree === 0
    ) {
      return 'row-red';
    }

    if (
      this.selectedUnit.requireMaintenanceNotificationNumberForEquipmentFailure &&
      row.oamFactorEnglish &&
      row.oamFactorEnglish.toLowerCase().includes("equipment failure") &&
      !row.maintenanceNotificationNumber
    ) {
      return "row-red";
    }

    if (row.RCPSStatus && row.RCPSStatus.toLowerCase() === 'open') {
      return 'row-blue';
    }

    if (row.RCPSStatus && row.RCPSStatus.toLowerCase() === 'closed') {
      return 'row-black';
    }

    return '';
  }

  async getAuditTrail(index: number) {
    this.selectedLossReasonIndex = index;
    const lossData = this.selectedLossData[index];

    this.auditData =
      lossData.LossSource === 'Downtime'
        ? await getDowntimeAudits(
          lossData.externalId,
          this.$store.getters.getPath
        )
        : lossData.LossSource === 'Performance' ||
        lossData.LossSource === 'SpeedLoss'
          ? await getPerformanceAudits(
            lossData.externalId,
            this.$store.getters.getPath
          )
          : await getQualityPemAudits(
            lossData.externalId,
            this.$store.getters.getPath
          );

    Vue.nextTick(() => {
      setTimeout(() => {
        this.auditModal.openModal();
      }, 0);
    });
  }

  async getTreeReasonData(index: number): Promise<void> {
    this.selectedLossReasonIndex = index;

    MetricsService.newAction({
      type: 'click',
      kind: `loss_table_row_clicked`,
      typeFormated: `Loss table row clicked index: ${index}`,
      message: `Loss table row clicked with index: ${index}`,
      data: this.selectedLossData[index],
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    const lossData = this.selectedLossData[index];
    const promises: any[] = [
      {
        method: 'getTree',
        parameters: {
          Path: this.$store.getters.getPath,
          PUID: this.$store.getters.getSelectedUnit.puid,
          DPPQ_Ids: this.$store.getters.getSelectedUnit.DPPEMQ,
          LossSource: this.selectedLossData[index].LossSource,
          LanguageID:
            this.$store.getters.getConfiguration[this.$store.state.path]
              .LanguageID
        }
      }
    ];

    if (!lossData.FunctionalLocation) {
      promises.push({
        method: "getFunctionalLocation",
        parameters: {
          Path: this.$store.getters.getPath,
          functionalLocationRoot:
          this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
          functionalLocationId:
          this.$store.getters.getSelectedUnit.FunctionalLocationRootId,
          levels: "1"
        }
      });
      if (this.$store.getters.getSelectedUnit.requireMaintenanceNotificationNumberForEquipmentFailure) {
        promises.push(
          {
            method: "getMaintenanceNotification",
            parameters: {
              Path: this.$store.getters.getPath,
              functionalLocationRoot:
              this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
              functionalLocationRootId:
              this.$store.getters.getSelectedUnit.FunctionalLocationRootId
            }
          });
      }
    } else {
      promises.push(
        {
          method: 'getFunctionalLocationExpanded',
          parameters: {
            functionalLocationRoot:
            this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
            functionalLocationRootId:
            this.$store.getters.getSelectedUnit.FunctionalLocationRootId,
            functionalLocationId: lossData.functionalLocationId
          }
        },
        {
          method: 'getEquipment',
          parameters: {
            Path: this.$store.getters.getPath,
            FL_Code: lossData.FunctionalLocation
          }
        }
      );
      if (this.$store.getters.getSelectedUnit.requireMaintenanceNotificationNumberForEquipmentFailure) {
        promises.push(
          {
            method: 'getMaintenanceNotification',
            parameters: {
              Path: this.$store.getters.getPath,
              functionalLocationRoot:
              this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
              functionalLocationRootId:
              this.$store.getters.getSelectedUnit.FunctionalLocationRootId,
            }
          });
      }
    }

    await this.$store.dispatch('setFunctionalLocationOriginal', []);
    for (let promise of promises) {
      await Utils.triggerBackend(promise, this);
    }

    Vue.nextTick(() => {
      setTimeout(() => {
        this.modal.openModal();
      }, 0);
    });
  }
}
