




































































































































































import { ChartService } from '../services/chartService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Trend } from '../classes/trend';
import { Variable } from '../classes/variable';
import { ShiftData } from '../classes/shiftData';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { MetricsService } from '../services/metricsService';
import * as moment from 'moment';
import { Threshold } from '../classes/threshold';
import { Unit } from '../classes/unit';

@Component({
  components: {
    PerfectScrollbar
  },
  computed: {
    ...mapGetters({
      chartLocation: 'getChartPosition',
      sidebarOpen: 'avgSidebarIsOpen',
      trendData: 'getTrendData',
      selectedVariables: 'getSelectedVariables',
      timeRange: 'getTimeRange',
      selectedShift: 'getSelectedShift',
      selectedShiftIndex: 'getSelectedShiftIndex',
      shiftData: 'getShiftData',
      selectedTrendAreaTimeStamps: 'getSelectedTrendAreaTimeStamps',
      activeRightSidebar: 'getActiveRightSidebar',
      thresholdData: 'getThresholdData',
      selectedUnit: 'getSelectedUnit',
      mesTrends: 'getMesTrends'
    })
  }
})
export default class AvgSidebar extends Vue {
  public activeRightSidebar!: string;
  public chartLocation!: any;
  public sidebarOpen!: boolean;
  public trendData!: Trend[];
  public colorPaths: string[] = [
    'blue',
    'red',
    'orange',
    'purple',
    'green',
    'black',
    'pink',
    'sandybrown',
    'brown',
    'darkgoldenrod',
    'lightgreen',
    'grey',
    'greenyellow',
    'turquoise',
    'olive'
  ];
  public selectedVariables!: Variable[];
  public timeRange!: Date[];
  public thresholdData!: Threshold[];
  public selectedUnit!: Unit;
  public selectedTrendAreaTimeStamps!: any;
  public selectedShift!: ShiftData;
  public selectedShiftIndex!: number;
  public shiftData!: ShiftData[];
  public stats: any[] = [];

  setSelectMode(): void {
    MetricsService.newAction({
      type: 'click',
      kind: 'drag_mode_changed',
      typeFormated: 'Drag mode changed',
      message: `Drag mode was set to select`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.dispatch('setDragMode', 'select');
  }

  get selectedTrendDates(): any[] {
    if (this.selectedVariables.length && this.trendData) {
      return this.selectedVariables.map((variable: Variable) =>
        this.trendData
          .filter((trend: Trend) => variable.id in trend)
          .map((trend: Trend) => new Date(trend.TimeStamp))
      );
    } else {
      return [];
    }
  }

  varColor(color: string): string {
    return this.colorPaths.indexOf(color) > -1
      ? color
      : 'background: rgb(146, 31, 150);';
  }

  @Watch('sidebarOpen')
  @Watch('activeRightSidebar')
  @Watch('trendData')
  @Watch('timeRange')
  @Watch('chartLocation')
  @Watch('selectedVariables')
  @Watch('selectedTrendAreaTimeStamps')
  selectedVariablesChanged() {
    if (this.sidebarOpen && this.activeRightSidebar === 'avgs') {
      this.stats = ChartService.getSelectedVariablesStats(this);
    }
  }
}
