



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Equipment } from '../../classes/equipment';
import { MetricsService } from '../../services/metricsService';
import * as moment from 'moment';

@Component({
  computed: {
    ...mapGetters({
      functionalLocation: 'getFunctionalLocation'
    })
  }
})
export default class EquipmentTable extends Vue {
  @Prop(Array) readonly equipmentList!: Equipment[];
  @Prop({ default: null }) readonly lossData: any;
  public selectedItem: Equipment = new Equipment();

  setSelected(item: Equipment) {
    MetricsService.newAction({
      data: item,
      type: 'click',
      kind: 'equipment_table_click',
      typeFormated: 'Equipment table row was clicked',
      message: `Equipment table row was clicked`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.selectedItem = item;
    this.$emit('on-select', item);
  }

  mounted() {
    if (this.lossData?.functionalLocationEquipmentId) {
      this.selectedItem = this.equipmentList.find(
        (eq) => eq.Id === this.lossData?.functionalLocationEquipmentId
      );
    }
  }

  @Watch('equipmentList')
  equipmentListChanged() {
    this.selectedItem = new Equipment();
    this.$emit('on-select', null);
  }
}
