var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-row",
    [
      _c(
        "b-col",
        [
          _c("b-form-datepicker", {
            attrs: {
              required: _vm.required,
              "start-weekday": 1,
              disabled: _vm.disabled,
              state: _vm.state,
              min: _vm.min,
              size: _vm.size,
              max: _vm.max,
              placeholder: _vm.$t("addLoss.enterDate")
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          })
        ],
        1
      ),
      _c(
        "b-col",
        [
          _c("b-form-timepicker", {
            attrs: {
              placeholder: _vm.$t("addLoss.noTimeSelected"),
              required: _vm.required,
              size: _vm.size,
              hour12: false,
              locale: "en-GB",
              min: _vm.min,
              max: _vm.max,
              disabled: _vm.disabled,
              state: _vm.state
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.time,
              callback: function($$v) {
                _vm.time = $$v
              },
              expression: "time"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }