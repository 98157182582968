







import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Plotly from 'plotly.js-dist/plotly.js';
import { Unit } from '../classes/unit';
import { Variable } from '../classes/variable';
import { Trend } from '../classes/trend';
import moment from 'moment';
import { isBoolean } from 'util';

const widgetTitleHeight = 130;
const widgetPaddingWidth = 0;

@Component({
  computed: {
    ...mapGetters({
      units: 'getUnits',
      selectedUnit: 'getSelectedUnit',
      variables: 'getVariablesOfSelectedUnit',
      trendData: 'getTrendData',
      config: 'getConfiguration',
      path: 'getPath'
    })
  }
})
export default class ControlCharts extends Vue {
  public units!: Unit[];
  public selectedUnit: Unit;
  public variables: Variable[];
  public trendData: Trend[];
  public controlChartData: any[];
  public config: any;
  public path: string;
  public plotConfig: any = {
    scrollZoom: true,
    modeBarButtonsToRemove: [
      'toggleSpikelines',
      'resetScale2d',
      'toImage',
      'sendDataToCloud',
      'hoverCompareCartesian',
      'hoverClosestCartesian',
      'lasso2d',
      'select2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d'
    ],
    displaylogo: false,
    displayModeBar: true
  };

  @Watch('trendData')
  trendDataChanged() {
    if (this.selectedUnit.IsVirtualUnit) {
      this.plotControlCharts();
    }
  }

  plotControlCharts() {
    // Based on "Subplots with Shared Axes" example from the documentation (https://plotly.com/javascript/subplots/)
    // Copy of the documentation example: https://codepen.io/ramoncyzag/pen/ExPvKKd
    this.controlChartData = this.variables.map((v) => {
      return {
        label: v.label,
        x: this.trendData.map((t) => new Date(t.TimeStamp)),
        y: this.trendData.map((t) => t[v.var]),
        upperLimit: v.Upper_Alarm_Tolerance,
        lowerLimit: v.Lower_Alarm_Tolerance,
        MAC: v.MAC
      };
    });
    const COLUMNS = this.config[this.path].columns || 4;
    const UPPER_COLOR = 'red';
    const LOWER_COLOR = 'red';
    const MAC_COLOR = 'green';
    const TITLE_COLOR = 'black';
    const LIMITS_OPACITY = 0.5;
    const MAC_OPACITY = 0.5;
    const ALARM_COLOR = 'red';
    const DEFAULT_TIMERANGE_HOURS =
      this.selectedUnit.DefaultControlChartDuration || 8;
    const XAXIS_FONT = 'Arial Black';
    const x2 = new Date(
      this.controlChartData[0].x[this.controlChartData[0].x.length - 1]
    );
    const x1 = new Date(
      moment(x2).subtract(DEFAULT_TIMERANGE_HOURS, 'hours').toISOString()
    );

    const el = this.$refs.controlChartsPlot as HTMLElement;

    const width = el.parentElement.clientWidth - widgetPaddingWidth;
    const height = window.innerHeight - widgetTitleHeight;
    // const height = el.parentElement.clientHeight - widgetTitleHeight;

    let traces = [];
    let layout = {
      showlegend: false,
      width: width,
      height: height,
      margin: {
        t: 0
      },
      xaxis: {
        title: '',
        range: [x1, x2],
        titlefont: {
          family: XAXIS_FONT,
          color: 'black'
        }
      },
      yaxis: {
        range: []
      },
      grid: {
        rows:
          this.controlChartData.length % COLUMNS
            ? Math.floor(this.controlChartData.length / COLUMNS) + 1
            : Math.floor(this.controlChartData.length / COLUMNS),
        columns: COLUMNS,
        subplots: [],
        roworder: 'top to bottom'
      }
    };
    let i = 0;
    this.controlChartData.forEach((data, index) => {
      let trend = {
        x: data.x,
        y: data.y,
        name: data.label,
        hoverinfo: 'y',
        type: 'scatter'
      };
      traces.push(
        index
          ? { ...trend, ...{ xaxis: `x${index + 1}`, yaxis: `y${index + 1}` } }
          : trend
      );

      let upperLimit = {
        x: [data.x[0], data.x[data.x.length - 1]],
        y: [data.upperLimit, data.upperLimit],
        hoverinfo: 'none',
        mode: 'lines',
        opacity: LIMITS_OPACITY,
        line: {
          color: UPPER_COLOR,
          width: 2,
          dash: 'dash'
        },
        color: UPPER_COLOR,
        type: 'scatter'
      };
      traces.push(
        index
          ? {
              ...upperLimit,
              ...{ xaxis: `x${index + 1}`, yaxis: `y${index + 1}` }
            }
          : upperLimit
      );

      let lowerLimit = {
        x: [data.x[0], data.x[data.x.length - 1]],
        y: [data.lowerLimit, data.lowerLimit],
        hoverinfo: 'none',
        mode: 'lines',
        opacity: LIMITS_OPACITY,
        line: {
          color: LOWER_COLOR,
          width: 2,
          dash: 'dash'
        },
        color: LOWER_COLOR,
        type: 'scatter'
      };
      traces.push(
        index
          ? {
              ...lowerLimit,
              ...{ xaxis: `x${index + 1}`, yaxis: `y${index + 1}` }
            }
          : lowerLimit
      );

      let target = {
        x: [data.x[0], data.x[data.x.length - 1]],
        y: [data.MAC, data.MAC],
        hoverinfo: 'none',
        mode: 'lines',
        opacity: MAC_OPACITY,
        line: {
          color: MAC_COLOR,
          width: 2,
          dash: 'dash'
        },
        color: MAC_COLOR,
        type: 'scatter'
      };
      traces.push(
        index
          ? { ...target, ...{ xaxis: `x${index + 1}`, yaxis: `y${index + 1}` } }
          : target
      );

      let alarm =
        data.y[data.y.length - 1] > data.upperLimit ||
        data.y[data.y.length - 1] < data.lowerLimit;

      if (index) {
        layout[`xaxis${index + 1}`] = {
          title: data.label,
          titlefont: {
            family: XAXIS_FONT
          },
          matches: 'x'
        };
        layout[`yaxis${index + 1}`] = {
          range: this.getYrange(
            data.x,
            data.y,
            data.upperLimit,
            data.lowerLimit,
            layout.xaxis.range[0]
          )
        };
        if (alarm) {
          layout[`xaxis${index + 1}`].titlefont.color = ALARM_COLOR;
        }
        if ((index + 1) % COLUMNS === 1) {
          layout.grid.subplots.push([`x${index + 1}y${index + 1}`]);
        } else {
          layout.grid.subplots[i].push(`x${index + 1}y${index + 1}`);
        }
        if ((index + 1) % COLUMNS === 0) {
          i += 1;
        }
      } else {
        layout.grid.subplots.push(['xy']);
        layout.xaxis.title = data.label;
        layout.yaxis.range = this.getYrange(
          data.x,
          data.y,
          data.upperLimit,
          data.lowerLimit,
          layout.xaxis.range[0]
        );
        if (alarm) {
          layout.xaxis.titlefont.color = ALARM_COLOR;
        }
      }
    });
    Plotly.newPlot('control-charts-container', traces, layout, this.plotConfig);
  }


  resizePlot() {
    const el = this.$refs.controlChartsPlot as HTMLElement;
    Plotly.relayout(el, {
      height: window.innerHeight - widgetTitleHeight,
      // height: el.parentElement.clientHeight - widgetTitleHeight,
      width: el.parentElement.clientWidth - widgetPaddingWidth
    });
  }

  created() {
    window.addEventListener('resize', this.resizePlot);
  }
  destroyed() {
    window.removeEventListener("resize", this.resizePlot);
  }

  getYrange(
    x: Date[],
    y: number[],
    upperLimit: number,
    lowerLimit: number,
    x1: Date
  ): number[] {
    const UP_PERCENT = 1.2;
    const LOW_PERCENT = 2 - UP_PERCENT;
    let index = 0;
    for (let i = x.length - 1; i < x.length; i--) {
      if (x[i].getTime() < x1.getTime()) {
        index = i;
        break;
      }
    }
    return Plotly.d3.extent([
      ...y.slice(index ? index - 1 : 0, y.length),
      upperLimit + (upperLimit - lowerLimit) * UP_PERCENT,
      lowerLimit - (upperLimit - lowerLimit) * LOW_PERCENT
    ]);
  }
}
