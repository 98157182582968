














import Plotly from 'plotly.js-dist/plotly.js';
import TimeRangeButtons from '../TimeRangeButtons.vue';
import ChartsUtils from './utils';
import { mapGetters } from 'vuex';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Trend } from '../../classes/trend';
import { Variable } from '../../classes/variable';
import { LossData } from '../../classes/lossData';
import CommentDialog from './CommentDialog.vue';
import { Threshold } from '../../classes/threshold';
import { ShiftData } from '../../classes/shiftData';
import { Unit } from '../../classes/unit';
import moment from 'moment';
import { MetricsService } from '../../services/metricsService';
import { MesDataPoint } from '../../classes/mesDataPoint';
import * as _ from 'lodash';
import { BatchAndStepData } from '../../classes/batchAndStepData';

@Component({
  components: {
    Plotly,
    CommentDialog,
    TimeRangeButtons
  },

  computed: {
    ...mapGetters({
      timezoneOffset: 'getTimezoneOffset',
      trendData: 'getTrendData',
      shiftData: 'getShiftData',
      thresholdData: 'getThresholdData',
      lossData: 'getLossData',
      selectedVariables: 'getSelectedVariables',
      selectedUnit: 'getSelectedUnit',
      selectedShiftIndex: 'getSelectedShiftIndex',
      selectedShift: 'getSelectedShift',
      timeRange: 'getTimeRange',
      variables: 'getVariable',
      favoritesSelectedView: 'getFavoritesSelectedView',
      selectedLossRegion: 'getSelectedLossRegion',
      targetVariables: 'getTargetVariables',
      rePlot: 'getReplot',
      dragMode: 'getDragMode',
      drawMode: 'getDrawMode',
      chartLocation: 'getChartPosition',
      trendChartYAxisLock: 'getTrendChartYAxisLock',
      materialInformation: 'getMaterialInformation',
      isAutoRefreshEnabled: 'isAutoRefreshEnabled',
      isAutoRefresh: 'getIsAutoRefresh',
      zoomEvent: 'getZoomEvent',
      mesTrends: 'getMesTrends',
      isProductChartActive: 'getIsProductChartActive',
      configuration: 'getConfiguration',
      defaultTimeframe: 'getDefaultTimeframe',
      dataByDate: 'getDataByDate',
      userName: 'getUserName',
      savedState: 'getSavedState',
      showGrid: 'getShowGrid',
      stepsAndBatches: 'getStepsAndBatches'
    })
  }
})
export default class TrendChartCMP extends Vue {
  public userName: string;
  @Ref('commentDialog') readonly commentDialog!: any;
  public timezoneOffset!: number;
  public chartLocation!: any;
  public showGrid!: string;
  public zoomEvent!: any;
  public dataByDate!: any;
  public trendData!: Trend[];
  public shiftData!: ShiftData[];
  public rePlot!: boolean;
  public drawMode!: boolean;
  public isAutoRefreshEnabled!: boolean;
  public isProductChartActive!: boolean;
  public isAutoRefresh!: boolean;
  public trendChartYAxisLock!: boolean;
  public selectedShift!: any;
  public savedState!: any;
  public materialInformation!: any[];
  public thresholdData!: Threshold[];
  public lossData!: LossData[];
  public selectedVariables!: Variable[];
  public selectedUnit!: Unit;
  public selectedShiftIndex!: number;
  public timeRange!: Date[];
  public favoritesSelectedView!: any;
  public variables!: Variable[];
  public dragMode!: string;
  public commentsBox: any = [];
  public commentsData: any = [];
  public selectedLossRegion!: number;
  public graphAxisRanges: any = {};
  public zoomXOnly: boolean = false;
  public zoomYOnly: boolean = false;
  public defaultTimeframe!: any;
  public configuration!: any;
  public mesTrends: MesDataPoint[];
  public stepsAndBatches!: any;
  public plotConfig: any = {
    scrollZoom: true,
    modeBarButtonsToRemove: [
      'toggleSpikelines',
      'resetScale2d',
      'toImage',
      'sendDataToCloud',
      'hoverCompareCartesian',
      'hoverClosestCartesian',
      'lasso2d',
      'select2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'zoom2d',
      'pan2d'
    ],
    displaylogo: false,
    doubleClick: false,
    displayModeBar: true
  };
  public currentRangesOfFixedLayout: any = [];

  get selectedTrendValues() {
    if (this.selectedVariables.length && this.trendData.length) {
      return this.selectedVariables.map((variable) => {
        if (variable.VarType !== 'MES') {
          return this.trendData
            .filter((trend) => {
              return variable.id in trend;
            })
            .map((trend) => {
              return trend[variable.id];
            });
        } else {
          return this.mesTrends
            .filter((t) => t.Var_ID.toString() === variable.Input_Tag)
            .map((v) => parseFloat(v.Result));
        }
      });
    }
    return [];
  }

  get currentProduct() {
    const materials = [...this.materialInformation];
    materials.sort(
      (a, b) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );
    const products = materials.filter((prd) =>
      moment(
        this.chartLocation?.['xaxis.range[1]'] || this.timeRange[1]
      ).isBetween(moment(prd.startTime), moment(prd.endTime))
    );

    const product = !products.length
      ? materials[materials.length - 1]
      : products[products.length - 1];

    return `${product?.code} / ${product?.name}`;
  }

  get thresholdLastValues() {
    if (this.thresholdData) {
      let lastValues: any[] = [];
      let lastRow: Threshold =
        this.thresholdData[this.thresholdData.length - 1];
      for (let key in lastRow) {
        if (ChartsUtils.ThresholdKeys.includes(key)) {
          lastValues.push(lastRow[key.toString()]);
        }
      }
      return lastValues;
    }
    return [];
  }

  get selectedTrendDates(): any[] {
    if (this.selectedVariables.length && this.trendData) {
      return this.selectedVariables.map((variable: Variable) => {
        if (variable.VarType !== 'MES') {
          return this.trendData
            .filter((trend: Trend) => variable.id in trend)
            .map((trend: Trend) => new Date(trend.TimeStamp));
        } else {
          return this.mesTrends
            .filter((t) => t.Var_ID.toString() === variable.Input_Tag)
            .map((v) => v.Result_On);
        }
      });
    } else {
      return [];
    }
  }

  get selectedLossData() {
    if (this.lossData) {
      return this.lossData.filter(
        (data) =>
          data.PUID === this.selectedUnit.puid &&
          (data.StepName === 'Continuous' || data.LossSource === 'Downtime')
      );
    }
    return [];
  }

  get lossDataForPlot() {
    // return ChartsUtils.getLossData(this).concat(this.stepDataForPlot);
    return ChartsUtils.getLossData(this);
    // return [];
  }

  get stepDataForPlot() {
    return ChartsUtils.getStepData(this);
  }

  get stepData(): BatchAndStepData[] {
    return this.stepsAndBatches.filter(
      (x: any) => x.ProdStatus_Desc !== 'Batch'
    );
  }

  get trendDataForPlot() {
    return ChartsUtils.getTrendData(this);
  }

  get thresholdDataForPlot() {
    if (this.thresholdData) {
      return ChartsUtils.getThresholdData(this);
    }
  }

  get plotData() {
    let plotData: any[] = [];
    if (!this.selectedUnit) {
      return;
    }
    plotData = plotData.concat(this.trendDataForPlot);
    if (!this.selectedUnit.isBatch) {
      let isMainVarSelected = this.selectedVariables.some((v) => v.index === 0);

      if (isMainVarSelected) {
        plotData = plotData.concat(this.thresholdDataForPlot);
      }
    }

    plotData = plotData.concat(ChartsUtils.getTrendText(this));


    return plotData;
  }

  get plotLayout() {
    let showGridY =
        this.showGrid === 'vertical_horizontal' ||
        this.showGrid === 'horizontal',
      showGridX =
        this.showGrid === 'vertical_horizontal' || this.showGrid === 'vertical';

    const xRange =
      this.isAutoRefresh && (this.$el as any).layout
        ? (this.$el as any).layout.xaxis.range
        : this.timeRange;

    let layout: any = {
      // hovermode:"x",
      // hoverdistance:100,
      // spikedistance:1000,
      xaxis: {
        domain: [0.05 * (this.selectedVariables.length - 1), 1],
        range: xRange,
        showgrid: showGridX
        // to be implemented at a later time - JP
        // showspikes: true,
        // spikethickness:1,
        // spikedash:"dot",
        // spikecolor:"#999999",
        // spikemode:"toaxis+across+marker",
        // spikesnap:"data",
        // rangeselector: {
        //         y:1,
        //         x:0,
        //         // xanchor:'center',
        //         buttons: [
        //             {
        //                 count: 1,
        //                 label: '1d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },
        //             {
        //                 count: 3,
        //                 label: '3d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },
        //             {
        //                 count: 7,
        //                 label: '7d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },
        //             {
        //                 count: 30,
        //                 label: '30d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },],
        // },
      },
      yaxis: {
        fixedrange: true,
        showgrid: showGridY
      },
      annotations: [],
      showlegend: true,
      legend: { orientation: 'h', x: 1 ,  xanchor:"right"},
      height: 530,
      margin: {
        t: 20,
        l: 40,
        r: 10,
        b: 60
      },
      dragmode: this.dragMode
    };

    let multipliers = [1, 1];

    this.selectedTrendValues.forEach((trendValues, i) => {
      let yaxis, timestamps;
      if (this.selectedVariables[i].VarType !== 'MES') {
        timestamps = this.trendData.map((row) => row.TimeStamp);
      } else {
        timestamps = this.mesTrends
          .filter(
            (t) => t.Var_ID.toString() === this.selectedVariables[i].Input_Tag
          )
          .map((r) => r.Result_On);
      }
      let range = ChartsUtils.getYRange(
        timestamps,
        trendValues,
        this.timeRange
      );

      if (this.selectedVariables[i].index === 0) {
        yaxis = 'yaxis';
        if (!this.selectedUnit.isBatch) {
          let oldRange = [...range];
          if (!ChartsUtils.isTargetThreshold(this)) {
            range = Plotly.d3.extent(range.concat(this.thresholdLastValues));
          }

          if (ChartsUtils.isTargetThreshold(this)) {
            const limits =
              ChartsUtils.getUpperAndLowerLimitTargetThreshold(this);
            if (limits) {
              const lower = limits.lower.length
                ? Math.min(...limits.lower.filter((lim) => lim))
                : range[0];
              const upper = limits.upper.length
                ? Math.max(...limits.upper)
                : range[1];
              const macValue = limits.macValues.length
                ? Math.max(...limits.macValues)
                : 0;

              if (range[0] > lower) {
                range[0] = lower;
              }

              if (range[1] < macValue || range[1] < upper) {
                range[1] = macValue > upper ? macValue : upper;
              }
            }
          }

          if (this.currentRangesOfFixedLayout[i]) {
            range = this.currentRangesOfFixedLayout[i];
          }

          multipliers[0] = range[0] / oldRange[0];
          multipliers[1] = range[1] / oldRange[1];
        }

        range[0] = range[0] * 0.95;
        range[1] = range[1] * 1.05;
      } else {
        const yIndex = i > 0 ? i + 1 : null;
        yaxis = `yaxis${yIndex || ''}`;
        range = [range[0] * multipliers[0], range[1] * multipliers[1]];
        if (this.currentRangesOfFixedLayout[i]) {
          range = this.currentRangesOfFixedLayout[i];
        }
      }
      if (this.isAutoRefresh && (this.$el as any).layout) {
        layout[yaxis] = (this.$el as any).layout[yaxis];
      } else {
        layout[yaxis] = {
          color: this.selectedVariables[i].color,
          range,
          tag: this.selectedVariables[i].Tag,
          automargin: true,
          showgrid: showGridY,
          zeroline: false,
          tickformat: this.selectedUnit.isBatch
            ? `0f`
            : `.${this.selectedVariables[i].precision}f`,
          hoverformat: `.${this.selectedVariables[i].precision}f`
        };
      }

      if (i !== 0) {
        layout[yaxis]['anchor'] = 'free';
        layout[yaxis]['overlaying'] = 'y';
        layout[yaxis]['side'] = 'left';
        layout[yaxis]['position'] = 0.05 * (i - 1);
      }
    });

    const defaultObj: any = {
      type: 'rect',
      // x-reference is assigned to the x-values
      xref: 'x',
      // y-reference is assigned to the plot paper [0,1]
      yref: 'paper',
      y0: 0,
      y1: 1,
      fillcolor: '#000',
      opacity: 1,
      line: {
        width: 2,
        dash: 'dash'
      }
    };

    if (
      !this.selectedUnit.isBatch &&
      this.shiftData.length &&
      this.selectedShiftIndex >= 0
    ) {
      if (!this.defaultTimeframe || this.selectedShift) {
        layout.shapes = [
          {
            ...defaultObj,
            x0: this.shiftData[this.selectedShiftIndex].Start_Time,
            x1: this.shiftData[this.selectedShiftIndex].Start_Time
          },
          {
            ...defaultObj,
            x0: this.shiftData[this.selectedShiftIndex].End_Time,
            x1: this.shiftData[this.selectedShiftIndex].End_Time
          }
        ];
      }
    } else if (this.selectedUnit.isBatch && this.selectedShift) {
      let colorC =
        this.selectedShift.StepActual < this.selectedShift.StepMAC
          ? 'green'
          : 'red';
      const startTime = moment(
        this.selectedShift.Start_Time || this.selectedShift.StartTime
      ).toDate();
      const endTime = moment(
        this.selectedShift.End_Time || this.selectedShift.EndTime
      ).toDate();
      layout.shapes = [
        {
          ...defaultObj,
          x0: startTime,
          x1: startTime
        },
        {
          ...defaultObj,
          line: {
            width: 2,
            dash: 'dash',
            color: this.selectedShift.StepMAC ? colorC : '#000'
          },
          x0: endTime,
          x1: endTime
        }
      ];

      // find all steps for batch and add lines to chart.
      // do not add first and last as these are added by the code just below the if block
      if (
        this.selectedShift.ProdStatus_Desc &&
        this.selectedShift.ProdStatus_Desc === 'Batch'
      ) {
        const stepsforSelectedBatch = this.stepData
          .filter((st) => st.Event_Num === this.selectedShift.Event_Num)
          .filter((st) => st.StartTime !== this.selectedShift.Start_Time)
          .filter((st) => st.StartTime !== this.selectedShift.StartTime)
          .filter((st) => st.EndTime !== this.selectedShift.EndTime)
          .filter((st) => st.EndTime !== this.selectedShift.End_Time);

        for (const step of stepsforSelectedBatch) {
          const lossColor = step.LossSeconds <= 0 ? 'green' : 'red';

          layout.shapes.push({
            x0: moment(step.StartTime).toDate(),
            x1: moment(step.StartTime).toDate(),
            ...defaultObj,
            fillcolor: 'gray',
            line: {
              width: 1,
              dash: 'dash',
              color: lossColor
            }
          });

          layout.annotations.push({
            x: new Date(step.StartTime).getTime(),
            y: 0,
            xref: 'x',
            yref: 'paper',
            text: `${step.ProdStatus_Desc} -- MAC: ${(
              step.StepMac / 60
            ).toFixed(0)}, Actual: ${(step.ActualDuration / 60).toFixed(
              0
            )}, Loss: ${(step.LossSeconds / 60).toFixed(0)} (mins)`,
            showarrow: false,
            arrowhead: 7,
            ax: 0,
            ay: 0,
            textangle: -90,
            xanchor: 'left',
            yanchor: 'bottom',
            font: {
              color: lossColor,
              size: 12
            }
          });
        }
      }

      if (this.selectedShift.StepMAC) {
        layout.shapes.push({
          x0: moment(this.selectedShift.StartTime)
            .add(this.selectedShift.StepMAC, 'seconds')
            .toDate(),
          x1: moment(this.selectedShift.StartTime)
            .add(this.selectedShift.StepMAC, 'seconds')
            .toDate(),
          ...defaultObj,
          line: {
            width: 1,
            dash: 'dash'
          }
        });
      }
    }

    if (layout.shapes) {
      layout.shapes = layout.shapes.concat(this.lossDataForPlot);
    } else {
      layout.shapes = this.lossDataForPlot;
    }

    this.commentsBox.map((cm: any) => {
      layout.shapes.push({
        type: 'rect',
        xref: 'x',
        yref: 'y',
        ref: 'comment',
        x0: cm.range.x[0],
        y0: cm.range.y[0],
        x1: cm.range.x[1],
        y1: cm.range.y[1],
        line: {
          color: 'rgb(55, 128, 191)',
          width: 3
        },
        fillcolor: 'rgba(55, 128, 191, 0.6)'
      });

      const difference = moment(cm.range.x[1]).diff(cm.range.x[0], 'm');
      layout.annotations.push({
        x: moment(cm.range.x[0])
          .add(difference / 2, 'm')
          .toDate(),
        y: cm.range.y[1],
        xref: 'x',
        yref: 'y',
        text: cm.comment,
        showarrow: true,
        font: {
          family: 'Courier New, monospace',
          size: 14,
          color: '#ffffff'
        },
        align: 'left',
        arrowhead: 2,
        arrowsize: 1,
        arrowwidth: 2,
        arrowcolor: '#636363',
        ax: 20,
        ay: -30,
        bordercolor: '#c7c7c7',
        borderwidth: 2,
        borderpad: 4,
        bgcolor: '#ff7f0e',
        opacity: 0.8
      });
    });

    this.selectedVariables.map((variable, i) => {
      const yaxis = i === 0 ? 'yaxis' : `yaxis${i + 1}`;
      layout[yaxis].fixedrange = this.trendChartYAxisLock;
    });

    const productValues: any = this.isProductChartActive
      ? ChartsUtils.getProductValues(this)
      : { annotations: [], shapes: [] };

    return {
      ...layout,
      ...{
        annotations: [...productValues.annotations, ...layout.annotations],
        shapes: [...productValues.shapes, ...layout.shapes]
      }
    };
  }

  get hasThingworxPopulatedData() {
    return !!(
      this.trendData &&
      this.lossData &&
      this.thresholdData &&
      this.selectedVariables.length
    );
  }

  onPlotlyClick(data) {
    console.log('click')
    let clickedDate: any = new Date(data.points[0].x);

    let selectedLossIndex: any = this.lossData.findIndex(
      (lossData) =>
        lossData.StartTime <= clickedDate &&
        lossData.EndTime >= clickedDate &&
        (lossData.StepName === 'Continuous' ||
          lossData.LossSource === 'Downtime')
    );

    let selectedPlottedLossIndex: any = this.selectedLossData.findIndex(
      (lossData) =>
        lossData.StartTime <= clickedDate &&
        lossData.EndTime >= clickedDate &&
        (lossData.StepName === 'Continuous' ||
          lossData.LossSource === 'Downtime')
    );

    this.graphAxisRanges = (this.$el as any).layout;
    MetricsService.newAction({
      type: 'click',
      kind: 'trendData_chart_clicked',
      typeFormated: 'Trend Chart was clicked',
      message: `Trend Data x point which clicked was ${data.points[0].x} and y point was ${data.points[0].y}`,
      data: data.points,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
    this.$store.dispatch('trendDataOnPlotyClick', {
      selectedPlottedLossIndex,
      selectedLossIndex
    });
  }

  updateGraphLayout() {
    if (!(this.$el as any) || !(this.$el as any).data) {
      return;
    }
    let updates = {
      xaxis: {
        range: this.timeRange
      }
    };
    let multipliers = [1, 1];
    this.selectedTrendValues.forEach((trendValues, i) => {
      let timestamps;
      if (this.selectedVariables[i].VarType !== 'MES') {
        timestamps = this.trendData.map((row) => row.TimeStamp);
      } else {
        timestamps = this.mesTrends
          .filter(
            (t) => t.Var_ID.toString() === this.selectedVariables[i].Input_Tag
          )
          .map((r) => r.Result_On);
      }

      let range = ChartsUtils.getYRange(
        timestamps,
        trendValues,
        this.timeRange
      );
      let yaxis;
      if (this.selectedVariables[i].index === 0) {
        yaxis = 'yaxis';
        if (!this.selectedUnit.isBatch) {
          let oldRange = range;
          if (!ChartsUtils.isTargetThreshold(this)) {
            range = Plotly.d3.extent(range.concat(this.thresholdLastValues));
          }
          if (ChartsUtils.isTargetThreshold(this)) {
            const limits =
              ChartsUtils.getUpperAndLowerLimitTargetThreshold(this);
            if (limits) {
              const lower = limits.lower.length
                ? Math.min(...limits.lower.filter((lim) => lim))
                : range[0];
              const upper = limits.upper.length
                ? Math.max(...limits.upper)
                : range[1];
              const macValue = limits.macValues.length
                ? Math.max(...limits.macValues)
                : 0;

              range[0] = lower;
              range[1] = macValue > upper ? macValue : upper;
            }
          }

          if (this.currentRangesOfFixedLayout[i]) {
            range = this.currentRangesOfFixedLayout[i];
          }
          multipliers[0] = range[0] / oldRange[0];
          multipliers[1] = range[1] / oldRange[1];
        }

        range[0] = range[0] * 0.95;
        range[1] = range[1] * 1.05;
      } else {
        range = [range[0] * multipliers[0], range[1] * multipliers[1]];
        const yIndex = i > 0 ? i + 1 : null;
        yaxis = `yaxis${yIndex || ''}`;

        if (this.currentRangesOfFixedLayout[i]) {
          range = this.currentRangesOfFixedLayout[i];
        }
      }

      updates[yaxis] = {
        range: range
      };
    });

    this.selectedVariables.map((variable, i) => {
      const yaxis = i === 0 ? 'yaxis' : `yaxis${i + 1}`;
      updates[yaxis].fixedrange = this.trendChartYAxisLock;
    });

    if (this.rePlot) {
      const extData = this.getShiftChanges();
      let plotMethod = (this.$el as any).data ? 'react' : 'newPlot';
      // @ts-ignore
      if (window.pLayout) {
        for (let key in updates) {
          let keys = key.split('.');
          // @ts-ignore
          // window.pLayout._v.layout[keys[0]][keys[1]] = updates[key]
        }
      }
      // @ts-ignore
      window.pLayout = Plotly[plotMethod](
        this.$el,
        [...this.plotData, ...extData.graphAxisdata],
        _.merge(this.plotLayout, updates),
        this.plotConfig
      );
    }
  }

  getShiftChanges() {
    if (!(this.$el as any).layout) {
      return { graphAxisRanges: {}, graphAxisdata: [] };
    }

    let graphAxisRanges = (this.$el as any).layout;
    let graphAxisdata = [];
    let layout: any = {};
    const selectedRanges = [];

    (this.$el as any).data
      .filter((graph) => graph.refIdData)
      .map((graph: any) => {
        graph.x.map((tm, i) => {
          const tmDate = moment(tm),
            beforeDate = moment(this.timeRange[0]),
            afterDate = moment(this.timeRange[1]);

          if (tmDate.isBetween(beforeDate, afterDate)) {
            selectedRanges.push(graph.y[i]);
          }
        });
      });

    const max = Math.max(...selectedRanges);
    const defaultObj: any = {
      type: 'rect',
      // x-reference is assigned to the x-values
      xref: 'x',
      // y-reference is assigned to the plot paper [0,1]
      yref: 'paper',
      y0: 0,
      y1: 1,
      fillcolor: '#000',
      opacity: 1,
      line: {
        width: 2,
        dash: 'dash'
      }
    };

    const defaultObjText: any = {
      fillcolor: 'gray',
      y: [max - (max * 10) / 100],
      refId: new Date().getTime(),
      layer: 'top',
      mode: 'text',
      textfont: {
        color: '#333',
        size: 12,
        weight: 'bold'
      },
      textposition: 'left center'
    };

    if (
      !this.selectedUnit.isBatch &&
      this.shiftData.length &&
      this.shiftData &&
      this.shiftData[this.selectedShiftIndex]
    ) {
      if (!this.defaultTimeframe || this.selectedShift) {
        layout.shapes = [
          {
            x0: this.shiftData[this.selectedShiftIndex].Start_Time,
            x1: this.shiftData[this.selectedShiftIndex].Start_Time,
            ...defaultObj
          },
          {
            x0: this.shiftData[this.selectedShiftIndex].End_Time,
            x1: this.shiftData[this.selectedShiftIndex].End_Time,
            ...defaultObj
          }
        ];
      }
    } else if (this.selectedUnit.isBatch && this.selectedShift) {
      let colorC =
        this.selectedShift.StepActual < this.selectedShift.StepMAC
          ? 'green'
          : 'red';
      const startTime = moment(
        this.selectedShift.Start_Time || this.selectedShift.StartTime
      ).toDate();
      const endTime = moment(
        this.selectedShift.End_Time || this.selectedShift.EndTime
      ).toDate();
      layout.shapes = [
        {
          x0: startTime,
          x1: startTime,
          ...defaultObj
        },
        {
          x0: endTime,
          x1: endTime,
          line: {
            width: 2,
            dash: 'dash',
            color: this.selectedShift.StepMAC ? colorC : '#000'
          },
          ...defaultObj
        }
      ];
      layout.annotations = [];

      const difference =
        new Date(this.timeRange[1]).getTime() -
        new Date(this.timeRange[0]).getTime();

      // find all steps for batch and add lines to chart.
      // do not add first and last as these are added by the code just below the if block
      if (
        this.selectedShift.ProdStatus_Desc &&
        this.selectedShift.ProdStatus_Desc === 'Batch'
      ) {
        const stepsforSelectedBatch = this.stepData
          .filter((st) => st.Event_Num === this.selectedShift.Event_Num)
          .filter((st) => st.StartTime !== this.selectedShift.Start_Time)
          .filter((st) => st.StartTime !== this.selectedShift.StartTime)
          .filter((st) => st.EndTime !== this.selectedShift.EndTime)
          .filter((st) => st.EndTime !== this.selectedShift.End_Time);

        for (const step of stepsforSelectedBatch) {
          const lossColor = step.LossSeconds <= 0 ? 'green' : 'red';

          layout.shapes.push({
            x0: moment(step.StartTime).toDate(),
            x1: moment(step.StartTime).toDate(),
            ...defaultObj,
            fillcolor: 'gray',
            line: {
              width: 1,
              dash: 'dash',
              color: lossColor
            }
          });

          layout.annotations.push({
            x: new Date(step.StartTime).getTime(),
            y: 0,
            xref: 'x',
            yref: 'paper',
            text: `${step.ProdStatus_Desc} -- MAC: ${(
              step.StepMac / 60
            ).toFixed(0)}, Actual: ${(step.ActualDuration / 60).toFixed(
              0
            )}, Loss: ${(step.LossSeconds / 60).toFixed(0)} (mins)`,
            showarrow: false,
            arrowhead: 7,
            ax: 0,
            ay: 0,
            textangle: -90,
            xanchor: 'left',
            yanchor: 'bottom',
            font: {
              color: lossColor,
              size: 12
            }
          });
        }
      } else {
        graphAxisdata.push({
          ...defaultObjText,
          text: ['Start'],
          showlegend: true,
          x: [
            moment(startTime)
              .subtract(difference * 0.01, 'milliseconds')
              .toDate()
          ]
        });

        graphAxisdata.push({
          ...defaultObjText,
          text: ['End'],
          showlegend: true,
          x:
            this.selectedShift.StepActual < this.selectedShift.StepMAC
              ? [
                  moment(endTime)
                    .subtract(difference * 0.01, 'milliseconds')
                    .toDate()
                ]
              : [
                  moment(endTime)
                    .add(difference * 0.02, 'milliseconds')
                    .toDate()
                ]
        });
      }

      // graphAxisdata.push({
      //     ...defaultObjText,
      //     text: ['End Two'],
      //     showlegend: true,
      //     x: (this.selectedShift.StepActual < this.selectedShift.StepMAC) ?
      //         [moment(endTime).subtract(difference * 0.05, 'milliseconds').toDate()] :
      //         [moment(endTime).add(difference * 0.05, 'milliseconds').toDate()]
      // });

      if (this.selectedShift.StepMAC) {
        layout.shapes.push({
          x0: moment(this.selectedShift.StartTime)
            .add(this.selectedShift.StepMAC, 'seconds')
            .toDate(),
          x1: moment(this.selectedShift.StartTime)
            .add(this.selectedShift.StepMAC, 'seconds')
            .toDate(),
          ...defaultObj,
          fillcolor: 'gray',
          line: {
            width: 1,
            dash: 'dash'
          }
        });

        graphAxisdata.push({
          ...defaultObjText,
          showlegend: false,
          text: ['MAC'],
          x:
            this.selectedShift.StepActual < this.selectedShift.StepMAC
              ? [
                  moment(this.selectedShift.StartTime)
                    .add(
                      this.selectedShift.StepMAC +
                        this.selectedShift.StepMAC * 0.05,
                      'seconds'
                    )
                    .toDate()
                ]
              : [
                  moment(this.selectedShift.StartTime)
                    .add(
                      this.selectedShift.StepMAC -
                        this.selectedShift.StepMAC * 0.01,
                      'seconds'
                    )
                    .toDate()
                ]
        });
      }
    }

    // const difference2 = new Date(this.timeRange[1]).getTime() - new Date(this.timeRange[0]).getTime();

    // ChartsUtils.getStepData(this).forEach((stepData2: LossData, i: number) => {
    //     graphAxisdata.push({
    //         ...defaultObjText,
    //         text: ['Junk Text'],
    //         showlegend: true,
    //         x: [moment(stepData2.StartTime).subtract(difference2 * 0.01, 'milliseconds').toDate()]
    //     });
    // });

    if (layout.shapes) {
      graphAxisRanges.shapes = layout.shapes.concat(this.lossDataForPlot);
    } else {
      graphAxisRanges.shapes = this.lossDataForPlot;
    }

    graphAxisRanges.annotations = layout.annotations;

    return { graphAxisRanges, graphAxisdata };
  }

  @Watch('selectedTrendValues')
  selectedTrendValuesChanged() {
    if (this.selectedTrendValues.length && this.rePlot) {
      const extData: any = this.getShiftChanges();
      let plotMethod = (this.$el as any).data ? 'react' : 'newPlot';
      Plotly[plotMethod](
        this.$el,
        [...this.plotData, ...extData.graphAxisdata],
        this.plotLayout,
        this.plotConfig
      );

      if (plotMethod === 'newPlot') {
        (this.$el as any).on('plotly_click', this.onPlotlyClick);
        (this.$el as any).on('plotly_relayout', (e) => {
          if (e && !e.hasOwnProperty('yaxis.fixedrange')) {
            MetricsService.newAction({
              type: 'event',
              kind: `plotly_relayout_from_${this.dragMode}`,
              typeFormated: `Plotly relayout happened from ${this.dragMode}`,
              message: `Plotly relayout event`,
              data: e,
              time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
            });
          }
          if (e && (e['xaxis.range[0]'] || e['xaxis.range'])) {
            this.$store.dispatch('trendChartMoved', e);
          }
        });
        (this.$el as any).on('plotly_selected', (eventData: any) => {
          if (!eventData) {
            return;
          }
          this.$store.dispatch('setAutoRefreshEnabled', false);

          if (this.drawMode && this.dragMode === 'select') {
            return this.openCommentModal(eventData);
          }

          MetricsService.newAction({
            type: 'event',
            kind: `trendData_chart_selected_area_mode_${this.dragMode}`,
            typeFormated: `Trend Chart selected area event from ${this.dragMode}`,
            message: `Trend Chart selected area event from ${this.dragMode}`,
            data: eventData.points,
            time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
          });
          this.$store.dispatch(
            'setSelectedTrendAreaTimeStamps',
            eventData.points
          );
        });
      }
      this.$store.dispatch('setLoading', false);
    }
  }

  breakComment(comment) {
    let length = 0;
    let output = '';
    comment.split(' ').map((cm) => {
      if (length + cm.length > 20) {
        output += '<br>';
        length = 0;
      }

      output += `${cm.trim()} `;
      length += cm.length;
    });

    return output;
  }

  createComment(comment) {
    let shape, annotation;
    shape = {
      type: 'rect',
      xref: 'x',
      yref: 'y',
      ref: 'comment',
      x0: this.commentsData.x[0],
      y0: this.commentsData.y[0],
      x1: this.commentsData.x[1],
      y1: this.commentsData.y[1],
      line: {
        color: 'rgb(55, 128, 191)',
        width: 3
      },
      fillcolor: 'rgba(55, 128, 191, 0.6)'
    };

    const difference = moment(this.commentsData.x[1]).diff(
      this.commentsData.x[0],
      'm'
    );

    comment = this.breakComment(comment);

    annotation = {
      x: moment(this.commentsData.x[0])
        .add(difference / 2, 'm')
        .toDate(),
      y: this.commentsData.y[1],
      xref: 'x',
      yref: 'y',
      text: comment,
      showarrow: true,
      font: {
        family: 'Courier New, monospace',
        size: 14,
        color: '#ffffff'
      },
      align: 'left',
      arrowhead: 2,
      arrowsize: 1,
      arrowwidth: 2,
      arrowcolor: '#636363',
      ax: 20,
      ay: -30,
      bordercolor: '#c7c7c7',
      borderwidth: 2,
      borderpad: 4,
      bgcolor: '#ff7f0e',
      opacity: 0.8
    };

    this.commentsBox.push({
      range: this.commentsData,
      comment: comment
    });

    Plotly.relayout(this.$el, {
      annotations: [...(this.$el as any).layout.annotations, annotation],
      shapes: [...(this.$el as any).layout.shapes, shape]
    });

    this.$store.dispatch('setDragMode', 'pan');
    this.$store.dispatch('setDrawMode', false);
  }

  openCommentModal(eventData) {
    this.commentsData = eventData.range;
    Vue.nextTick(() => {
      setTimeout(() => this.commentDialog.openModal(), 0);
    });
  }

  @Watch('timeRange')
  timeRangeChanged() {
    if (this.hasThingworxPopulatedData) {
      this.updateGraphLayout();
      if (!this.dataByDate && this.isAutoRefreshEnabled) {
        this.$store.dispatch('setAutoRefreshEnabled', true);
      }
    }
  }

  @Watch('zoomYOnly')
  zoomYOnlyChanged() {
    try {
      Plotly.relayout(this.$el, {
        'yaxis.fixedrange': this.zoomYOnly
      });
    } catch (e) {
      console.info(e);
    }
  }

  @Watch('zoomXOnly')
  zoomXOnlyChanged() {
    try {
      Plotly.relayout(this.$el, {
        'xaxis.fixedrange': this.zoomXOnly
      });
    } catch (e) {
      console.info(e);
    }
  }

  @Watch('dragMode')
  dragModeChanged() {
    Plotly.relayout(this.$el, { dragmode: this.dragMode });
  }

  @Watch('showGrid')
  showGridChanged() {
    const changes = {};
    let showGridY =
        this.showGrid === 'vertical_horizontal' ||
        this.showGrid === 'horizontal',
      showGridX =
        this.showGrid === 'vertical_horizontal' || this.showGrid === 'vertical';
    this.selectedVariables.map((variable, i) => {
      const yaxis = i === 0 ? 'yaxis' : `yaxis${i + 1}`;
      changes[`${yaxis}.showgrid`] = showGridY;
    });
    changes['xaxis.showgrid'] = showGridX;

    Plotly.relayout(this.$el, changes);
  }

  @Watch('trendChartYAxisLock')
  trendChartYAxisLockChanged() {
    const changes = {};
    this.currentRangesOfFixedLayout = [];
    this.selectedVariables.map((variable, i) => {
      const yaxis = i === 0 ? 'yaxis' : `yaxis${i + 1}`;
      changes[`${yaxis}.fixedrange`] = this.trendChartYAxisLock;
      if (this.trendChartYAxisLock) {
        this.currentRangesOfFixedLayout.push(
          (this.$el as any).layout[yaxis].range
        );
      }
    });

    if (!this.trendChartYAxisLock) {
      this.currentRangesOfFixedLayout = [];
    }

    Plotly.relayout(this.$el, changes);
  }

  @Watch('favoritesSelectedView')
  favoritesSelectedViewChanged() {
    if (!this.favoritesSelectedView.data) {
      return;
    }
    const changes = {};
    this.favoritesSelectedView.data.variables.map(
      (variable: any, i: number) => {
        const yaxis = i === 0 ? 'yaxis' : `yaxis${i + 1}`;
        if (variable.yaxis === 'manual') {
          changes[`${yaxis}.range`] = _.clone(variable.range);
        }
      }
    );

    Plotly.relayout(this.$el, changes);

    this.$store.dispatch('setFavoritesSelectedView', {});
  }

  @Watch('savedState')
  savedStateChanged() {
    if (!this.savedState) {
      return;
    }
    const changes = {
      xaxis: {
        ...(this.$el as any).layout.xaxis,
        range: this.savedState.timeRange
      }
    };
    this.savedState.variables.map((variable: any, i: number) => {
      const yaxis = i === 0 ? 'yaxis' : `yaxis${i + 1}`;
      if (variable.yaxis === 'manual') {
        changes[`${yaxis}.range`] = _.clone(variable.range);
      }
    });

    setTimeout(() => {
      Plotly.relayout(this.$el, changes);
    }, 3000);

    this.$store.dispatch('setFromSavedState', null);
  }

  @Watch('isProductChartActive')
  @Watch('chartLocation')
  @Watch('selectedLossRegion')
  chartLocationChanged() {
    if (!(this.$el as any) || !(this.$el as any).data) {
      return;
    }

    const plotMethod = (this.$el as any).data ? 'react' : 'newPlot',
      plotData = this.plotData
        .filter((dt) => dt.ref !== 'chartText')
        .concat(ChartsUtils.getTrendText(this));

    Plotly[plotMethod](this.$el, plotData, this.plotLayout, this.plotConfig);

    if (!this.dataByDate && this.isAutoRefreshEnabled) {
      this.$store.dispatch('setAutoRefreshEnabled', true);
    }
  }

  mounted() {
    // jQuery injected by Thingworx
    //@ts-ignore
    $(document).on('keydown', (e) => {
      if (e.ctrlKey || e.metaKey) {
        //Only allow xrange to be zoomed
        this.zoomXOnly = true;
      }
    });
    //@ts-ignore
    $(document).on('keydown', (e) => {
      if (e.shiftKey) {
        //Only allow yrange to be zoomed
        this.zoomYOnly = true;
      }
    });
    //@ts-ignore
    $(document).on('keyup', (e) => {
      this.zoomXOnly = false;
      this.zoomYOnly = false;
    });
  }
}
