



import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Plotly from 'plotly.js-dist/plotly.js';
import { Utils } from '../utils';
import { Trend } from '../classes/trend';
import { LossData } from '../classes/lossData';
import { ShiftData } from '../classes/shiftData';
import ChartsUtils from './TrendChart/utils';
import { MetricsService } from '../services/metricsService';
import * as moment from 'moment';

@Component({
  computed: {
    ...mapGetters({
      shiftData: 'getShiftData',
      lossData: 'getLossData',
      selectedShiftIndex: 'getSelectedShiftIndex',
      selectedShift: 'getSelectedShift',
      trendData: 'getTrendData',
      configuration: 'getConfiguration',
      path: 'getPath'
    })
  }
})
export default class ShiftTimeline extends Vue {
  public shiftData!: ShiftData[];
  public lossData!: LossData[];
  public selectedShiftIndex!: number;
  public selectedShift!: ShiftData;
  public trendData!: Trend[];
  public shouldReLayout: boolean = true;
  public currentLayout: any = null;
  public path!: any;
  public configuration!: any;

  onPlotlyClick(data: any): void {
    this.currentLayout = (this.$el as any).layout;
    const clickedDate: Date = new Date(data.points[0].x),
      shiftIndex: number = this.shiftData.findIndex(
        (shift: ShiftData) =>
          shift.Start_Time <= clickedDate && shift.End_Time >= clickedDate
      ),
      shiftStart: Date = this.shiftData[shiftIndex].Start_Time,
      shiftEnd: Date = this.shiftData[shiftIndex].End_Time,
      rangeStart: Date = new Date(shiftStart.getTime() - 3.6e6),
      rangeEnd: Date = new Date(shiftEnd.getTime() + 3.6e6),
      selectedLossIndices: any[] = [];

    this.lossData.forEach((lossData: LossData, i: number) => {
      if (
        lossData.EndTime > shiftStart &&
        lossData.EndTime < new Date(shiftEnd.getTime() + 1000)
      ) {
        selectedLossIndices.push(i);
      }
    });

    this.shouldReLayout = false;

    MetricsService.newAction({
      type: 'click',
      kind: 'shift_clicked',
      typeFormated: 'Shift was clicked',
      message: `Shift ${this.shiftData[shiftIndex].Shift_Desc} was clicked`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });

    this.$store.dispatch('shiftTimelineOnPlotlyClick', {
      rangeStart,
      rangeEnd,
      shiftIndex,
      selectedLossIndices
    });
    this.plotShifts();
  }

  generateClickHelperPoints(yValue: number): any {
    // Number of helper points to generate
    const n: number = 1000,
      startTime: Date = new Date(
        this.shiftData[this.shiftData.length - 1].Start_Time
      ),
      endTime: Date = new Date(this.shiftData[0].End_Time),
      clickEventHelperPointsX: any[] = [],
      clickEventHelperPointsY: any[] = [],
      interval: number = (endTime.getTime() - startTime.getTime()) / n;

    for (let i = 0; i < n + 1; i++) {
      const newpoint: Date = new Date(startTime.getTime() + interval * i);
      clickEventHelperPointsX.push(newpoint);
      clickEventHelperPointsY.push(yValue);
    }

    return {
      x: clickEventHelperPointsX,
      y: clickEventHelperPointsY,
      name: '',
      hoverinfo: 'none',
      mode: 'lines',
      line: {
        width: 0
      },
      type: 'scatter',
      showlegend: false
    };
  }

  plotShifts() {
    const data: any[] = [];

    if (!this.shiftData.length) {
      return;
    }

    const shiftDataStep = [];

    this.shiftData
      .filter((s: ShiftData, i) => i !== this.selectedShiftIndex)
      .forEach((s: ShiftData, i: number) => {
        const findStep = shiftDataStep.findIndex(
          (step) => step === s.Shift_Desc
        );
        let stepIndex = i;

        if (findStep === -1) {
          shiftDataStep.push(s.Shift_Desc);
        } else {
          stepIndex = findStep;
        }

        data.push({
          fill: 'tozeroy',
          hoverinfo: 'none',
          line: {
            color: Utils.getShiftBackgroundColour(stepIndex)
          },
          mode: 'lines',
          type: 'scatter',
          showlegend: false,
          x: [new Date(s.Start_Time), new Date(s.End_Time)],
          y: [1, 1]
        });
      });

    this.lossData.forEach((row) => {
      let color: string = '',
        borderColor: string = '',
        lineColor: string = '';

      if (row.LossSource === 'Downtime') {
        color = '#ffb3b3';
        borderColor = '#ad3131';
        lineColor = '#bb8484';
      } else if (row.LossSource === 'SpeedLoss') {
        color = '#ffffb3';
        borderColor = '#b7b700';
        lineColor = '#caca46';
      } else if (row.LossSource === 'PEM') {
        if (row.LossQuantity >= 0) {
          color = '#ffb3b3';
          borderColor = '#ad3131';
          lineColor = '#bb8484';
        } else {
          color = '#b3d9b3';
          borderColor = '#366b36';
        }
      }
      if (row.StepName === 'Continuous' || row.StepName === 'Downtime') {
        data.push({
          fill: 'tozeroy',
          layer: 'below',
          hoverinfo: 'none',
          opacity: 1,
          fillcolor: color,
          line: {
            color: borderColor
          },
          mode: 'lines',
          type: 'scatter',
          showlegend: false,
          x: [new Date(row.StartTime), new Date(row.EndTime)],
          y: [1, 1]
        });
      }
    });

    this.shiftData.forEach((s: ShiftData, i: number) => {
      data.push({
        hoverinfo: 'none',
        line: {
          color: 'rgb(0, 0, 0)'
        },
        mode: 'lines',
        type: 'scatter',
        showlegend: false,
        x: [new Date(s.End_Time), new Date(s.End_Time)],
        y: [0, 1]
      });

      data.push({
        hoverinfo: 'none',
        line: {
          color: 'rgb(153, 192, 255)'
        },
        layer: 'top',
        mode: 'text',
        textposition: 'middle center',
        text: [s.Shift_Desc],
        type: 'scatter',
        showlegend: false,
        x: [
          new Date(
            new Date(s.Start_Time).getTime() + new Date(s.End_Time).getTime()
          ).getTime() / 2
        ],

        y: [0.5]
      });
    });

    const selectedShiftRange = this.selectedShift
      ? [
          new Date(this.selectedShift.Start_Time),
          new Date(this.selectedShift.End_Time)
        ]
      : [
          new Date(this.shiftData[0].Start_Time),
          new Date(this.shiftData[0].End_Time)
        ];

    if (this.selectedShift || this.selectedShiftIndex >= 0) {
      data.push({
        fill: 'tozeroy',
        hoverinfo: 'none',
        line: {
          color: 'green'
        },
        mode: 'lines',
        type: 'scatter',
        showlegend: false,
        x: selectedShiftRange,
        y: [1, 1]
      });
    }

    // These values not meaninful, just one is half of the other
    data.push(this.generateClickHelperPoints(0.25));
    data.push(this.generateClickHelperPoints(0.125));

    const layout = {
      hovermode: 'all',
      yaxis: {
        autorange: false,
        range: [0, 1],
        showgrid: false,
        zeroline: false,
        showline: false,
        autotick: true,
        ticks: '',
        showticklabels: false,
        fixedrange: true,
        dragmode: 'pan'
      },
      images: ChartsUtils.getLossImages(this),
      xaxis: {
        range: [
          new Date(
            this.shiftData[8]
              ? this.shiftData[8].Start_Time
              : this.shiftData[this.shiftData.length - 1].Start_Time
          ),
          new Date(this.shiftData[0].End_Time)
        ],
        tickmode: 'linear', //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
        tick0: this.shiftData[this.shiftData.length - 1].Start_Time,
        dtick:
          new Date(this.shiftData[0].End_Time).getTime() -
          new Date(this.shiftData[0].Start_Time).getTime()
        // To be implemented at a different time.
        // rangeselector: {
        //         y:1,
        //         x:0,
        //         // xanchor:'center',
        //         buttons: [
        //             {
        //                 count: 1,
        //                 label: '1d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },
        //             {
        //                 count: 3,
        //                 label: '3d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },
        //             {
        //                 count: 7,
        //                 label: '7d',
        //                 step: 'day',
        //                 stepmode: 'backward'
        //             },
        //             ],  },
      },

      height: 60,

      margin: {
        t: 0, //top margin
        l: 10, //left margin
        r: 10, //right margin
        b: 30 //bottom margin
      },

      dragmode: 'pan'
    };

    if (this.shouldReLayout) {
      Plotly.newPlot(this.$el, data, layout, {
        displaylogo: false,
        displayModeBar: false,
        doubleClick: false
      });

      (this.$el as any).on('plotly_click', this.onPlotlyClick);
    } else {
      Plotly.react(this.$el, data, this.currentLayout);
    }
  }

  mounted() {
    if (this.lossData !== undefined && this.shiftData !== undefined) {
      this.plotShifts();
    }
  }

  @Watch('lossData')
  @Watch('selectedShiftIndex')
  lossDataChanged() {
    if (this.shiftData !== undefined) {
      this.plotShifts();
    }
  }

  @Watch('shiftData')
  shiftDataChanged() {
    if (!(window as any).refresh) {
      this.$store.dispatch('shiftTimelineShiftDataChanged', 0);
    }
    this.shouldReLayout = true;
    if (this.lossData !== undefined) {
      this.plotShifts();
    }
  }
}
