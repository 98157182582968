




































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import ChatListing from './Chat/ChatListing.vue';
import NewConversationList from './Chat/NewConversationList.vue';
import WishListing from './Chat/WishListing.vue';
import ChatConversation from './Chat/ChatConversation.vue';
import to from './../services/to';
import { UserApi } from '../services/api/userApi';
import { ChatApi } from '../services/api/chatApi';

@Component({
  components: {
    PerfectScrollbar,
    WishListing,
    ChatConversation,
    NewConversationList,
    ChatListing
  },
  computed: {
    ...mapGetters({
      sidebarOpen: 'avgSidebarIsOpen',
      conversationList: 'getConversations',
      activeRightSidebar: 'getActiveRightSidebar'
    })
  }
})
export default class ChatSidebar extends Vue {
  public activeRightSidebar!: string;
  public sidebarOpen!: boolean;
  public btnSelected: string = 'chat';
  public chatView: string = 'list';
  public users: any[] = [];
  public conversationList!: any[];
  public conversation: any = {};

  setBtn(type) {
    if (this.chatView === 'chat') {
      ChatApi.lastAccessed();
    }
    this.btnSelected = type;
    this.chatView = 'list';
  }

  async newGroup(args) {
    let err;

    const dataArgs =
      process.env.NODE_ENV === 'development'
        ? {
            people: args.people.map((pep) => pep.username),
            username: this.$store.getters.getUserName,
            path: this.$store.getters.getPath,
            subject: args.subject
          }
        : {
            Subject:
              args.subject ||
              [
                ...args.people.map((pep) => pep.username),
                this.$store.getters.getUserName
              ]
                .sort()
                .join(', '),
            Username: this.$store.getters.getUserName,
            people: [
              ...args.people.map((pep) => pep.username),
              this.$store.getters.getUserName
            ].join(',')
          };

    [err, this.conversation] = await to(
      ChatApi.createConversation(dataArgs, this.conversationList)
    );

    if (err) {
      console.log(err);
    }

    this.$emit('refresh-list');

    this.chatView = 'chat';
  }

  newWish() {
    this.$emit('newWish');
  }

  back() {
    this.chatView = 'list';
  }

  async goToNewGroup() {
    let err, users;
    [err, users] = await to(UserApi.fetchUsers(this.$store.getters.getPath));

    if (err) {
      console.log(err);
    }

    this.users = users.data.filter(
      (user) => user.username !== this.$store.getters.getUserName
    );
    this.chatView = 'newConversation';
  }

  async onNewMessage(event) {
    console.log(event);
    if (event) {
      this.conversation.messages.push(event[0]);
    } else {
      this.conversation = await ChatApi.getConversation(
        {
          username: this.$store.getters.getUserName,
          path: this.$store.getters.getPath
        },
        this.conversationList
      );
    }
    this.$emit('refresh-list');
  }

  async goToConversation(conversation) {
    this.conversation = await ChatApi.setConversationId(
      conversation.conversation_id,
      this.$store.getters.getUserName,
      this.$store.getters.getPath,
      this.conversationList
    );
    console.log(this.conversation);
    this.chatView = 'chat';
  }

  goToList() {
    this.chatView = 'list';
  }
}
