var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.treeData
    ? _c(
        "div",
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-12",
                title: "Maintenance Notification",
                size: "xl",
                "ok-only": "",
                scrollable: ""
              },
              on: { shown: _vm.modalShown2 }
            },
            [
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "filterLastXDays" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "lossModal.maintenanceNotification.filterLastXDays"
                          )
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "filterLastXDays",
                          type: "text",
                          number: true
                        },
                        model: {
                          value: _vm.maintenanceNotificationFilterLastXDays,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterLastXDays = $$v
                          },
                          expression: "maintenanceNotificationFilterLastXDays"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "lossModal.maintenanceNotification.filterLastXDaysMessage"
                    )
                  )
                )
              ]),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "DateFrom" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "lossModal.maintenanceNotification.filterDateFrom"
                          )
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-datepicker", {
                        staticClass: "mb-2",
                        attrs: { id: "dateFrom-datepicker" },
                        model: {
                          value: _vm.maintenanceNotificationFilterDateFrom,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterDateFrom = $$v
                          },
                          expression: "maintenanceNotificationFilterDateFrom"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "DateTo" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "lossModal.maintenanceNotification.filterDateTo"
                          )
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-datepicker", {
                        staticClass: "mb-2",
                        attrs: { id: "dateTo-datepicker2" },
                        model: {
                          value: _vm.maintenanceNotificationFilterDateTo,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterDateTo = $$v
                          },
                          expression: "maintenanceNotificationFilterDateTo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c(
                      "label",
                      { attrs: { for: "MaintenanceNotificationNumber" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "lossModal.maintenanceNotification.filterNumber"
                            )
                          ) + ":"
                        )
                      ]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "MaintenanceNotificationNumber",
                          type: "text"
                        },
                        model: {
                          value: _vm.maintenanceNotificationFilterNumber,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterNumber = $$v
                          },
                          expression: "maintenanceNotificationFilterNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "Type" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("lossModal.maintenanceNotification.filterType")
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "Type", type: "text" },
                        model: {
                          value: _vm.maintenanceNotificationFilterType,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterType = $$v
                          },
                          expression: "maintenanceNotificationFilterType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "FunctionalLocation" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "lossModal.maintenanceNotification.filterFunctionalLocation"
                          )
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "FunctionalLocation", type: "text" },
                        model: {
                          value:
                            _vm.maintenanceNotificationFilterFunctionalLocation,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterFunctionalLocation = $$v
                          },
                          expression:
                            "maintenanceNotificationFilterFunctionalLocation"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "Description" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "lossModal.maintenanceNotification.filterDescription"
                          )
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "Description", type: "text" },
                        model: {
                          value: _vm.maintenanceNotificationFilterDescription,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterDescription = $$v
                          },
                          expression: "maintenanceNotificationFilterDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "my-1" },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c("label", { attrs: { for: "EquipmentTechnicalId" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "lossModal.maintenanceNotification.filterEquipmentTechnicalId"
                          )
                        ) + ":"
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "EquipmentTechnicalId", type: "text" },
                        model: {
                          value:
                            _vm.maintenanceNotificationFilterEquipmentTechnicalId,
                          callback: function($$v) {
                            _vm.maintenanceNotificationFilterEquipmentTechnicalId = $$v
                          },
                          expression:
                            "maintenanceNotificationFilterEquipmentTechnicalId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right my-1" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-1",
                      on: { click: _vm.resetFilterFields }
                    },
                    [_vm._v("Reset filter fields")]
                  ),
                  _c(
                    "b-button",
                    { staticClass: "mr-1", on: { click: _vm.clearSelected } },
                    [_vm._v("Clear selected")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.applyFilter }
                    },
                    [_vm._v("Filter")]
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "selectableTable",
                attrs: {
                  hover: "",
                  items: _vm.maintenanceNotificationList,
                  fields: _vm.fields,
                  "primary-key": "id",
                  "select-mode": "single",
                  responsive: "sm",
                  selectable: "",
                  "selected-variant": "success"
                },
                on: { "row-selected": _vm.onRowSelected }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              key: _vm.lossModalKey,
              ref: "lossModal",
              attrs: {
                id: "loss-modal",
                "no-fade": true,
                size: "xl",
                "ok-title": _vm.$t("lossModal.submit")
              },
              on: { shown: _vm.modalShown, ok: _vm.submitUpdateLoss },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function(ref) {
                      var ok = ref.ok
                      var cancel = ref.cancel
                      return [
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _vm.selectedUnit.allowManualLosses
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteLoss()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lossModal.delete")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "secondary" },
                            on: {
                              click: function($event) {
                                return cancel()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("lossModal.cancel")) + " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return ok()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("lossModal.submit")) + " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3505565536
              )
            },
            [
              _c(
                "div",
                { staticClass: "loss-reason-modal widget-rootwidget" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "categorization-cell reason-table col-sm-4"
                      },
                      [
                        _c("Reason", {
                          ref: "reason",
                          attrs: {
                            lossData: _vm.lossData,
                            "reason-selected": _vm.selectedNode,
                            "loss-reason-id": _vm.lossReasonId
                          },
                          on: { "on-select": _vm.selected }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rcps-cell col-sm-4" },
                      [
                        _c("Rcps", {
                          ref: "rcps",
                          attrs: {
                            "loss-data": _vm.lossData,
                            "show-split-loss": !_vm.additionalLossData.length
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-4 search-tree-container" },
                      [
                        _vm.selectedUnit
                          .requireMaintenanceNotificationNumberForEquipmentFailure
                          ? _c(
                              "div",
                              [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "lossModal.maintenanceNotification.title"
                                      )
                                    )
                                  )
                                ]),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-12",
                                        modifiers: { "modal-12": true }
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { variant: "outline-primary" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "lossModal.maintenanceNotification.findButton"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-12",
                                        modifiers: { "modal-12": true }
                                      }
                                    ],
                                    attrs: {
                                      variant: "outline-secondary",
                                      disabled: !_vm.selectedMaintenanceNotification
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.selectedMaintenanceNotification = null
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "lossModal.maintenanceNotification.clearSelectedButton"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.selectedMaintenanceNotification
                                  ? _c("dl", [
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableNumber"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .eventNumber
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableDate"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .endDate
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableType"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .maintenanceNotificationType
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableDescription"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .description
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableDescriptionLong"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .descriptionLongText
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableFunctionalLocationName"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .functionalLocationName
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableEquipmentName"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .equipmentName
                                          )
                                        )
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "lossModal.maintenanceNotification.tableEquipmentTechnicalId"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedMaintenanceNotification
                                              .equipmentTechnicalId
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("FunctionalLocation", {
                          style: {
                            display: _vm.selectedMaintenanceNotification
                              ? "none"
                              : null
                          },
                          attrs: {
                            lossData: _vm.lossData,
                            "selected-functional-location":
                              _vm.selectedFnLocation
                          },
                          on: { "tree-clicked": _vm.rowClickedEvent }
                        }),
                        _c("EquipmentTable", {
                          style: {
                            display: _vm.selectedMaintenanceNotification
                              ? "none"
                              : null
                          },
                          attrs: {
                            lossData: _vm.lossData,
                            "equipment-list": _vm.equipmentList
                          },
                          on: { "on-select": _vm.equipmentClicked }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }