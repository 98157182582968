import * as moment from 'moment';
import { Metrics } from './../classes/metrics';
import { uuid } from 'vue-uuid';
import { Utils } from './../utils';
declare const Buffer;

export class MetricsService {
  public static metrics: Metrics[] = [];
  public static sessionStart: string = null;
  public static sessionEnd: string = null;
  public static ctx: any = null;
  public static id: any = null;

  public static init(ctx) {
    this.sessionStart = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    this.id = uuid.v4();
    this.ctx = ctx;

    (window as any).metrics = [];
  }

  public static sessionEnded() {
    this.metrics = this.metrics.map((metric: Metrics) => {
      metric.sessionEnd = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      return metric;
    });
  }

  public static passContext(e) {
    this.metrics[this.metrics.length - 1].clickX = e.clientX;
    this.metrics[this.metrics.length - 1].clickY = e.clientY;

    if (process.env.NODE_ENV !== 'development') {
      setTimeout(() => {
        Utils.triggerBackend(
          {
            method: 'log',
            parameters: this.metrics[this.metrics.length - 1]
          },
          this.ctx
        );
      }, 10000);
    }
  }

  public static newAction(action: any, isGeneric = false) {
    if (!isGeneric) {
      this.ctx.$store.dispatch('setIsFromMetricAction', true);
    }

    let metricRaw = {
      ip: this.ctx.$store.getters.getIp,
      clickX: 0,
      clickY: 0,
      userName: new Buffer(this.ctx.$store.getters.getUserName).toString('hex'), //to decode new Buffer(encoded, 'hex').toString();
      devToolsOpen: 0,
      sessionId: this.id,
      type: action.type,
      kind: action.kind,
      typeFormated: action.typeFormated,
      message: action.message,
      time: action.time,
      sessionStart: this.sessionStart,
      sessionEnd: this.sessionEnd,
      scrollY: window.pageYOffset,
      scrollX: window.pageXOffset,
      resolutionWidth: window.outerWidth,
      resolutionHeight: window.outerHeight,
      data: '',
      path: this.ctx.$store.getters.getPath,
      selectedVars: this.ctx.$store.getters.getSelectedVariables.reduce(
        (acc, curr) => (acc += `${curr.label},`),
        ''
      ),
      PUID: this.ctx.$store.getters.getSelectedUnit.puid,
      unit: this.ctx.$store.getters.getSelectedUnit.label
    };

    this.metrics.push(new Metrics(metricRaw));
  }
}
