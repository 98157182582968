






















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import EventForm from '@/components/AddLossModal/EventForm.vue';

@Component({
  components: { EventForm }
})
export default class RawMaterialTable extends Vue {
  @Prop({ required: true, default: null }) items: any[];
  public selectedField = null;

  get fields() {
    return [
      {
        label: this.$t('addLoss.rawFormulation'),
        key: 'formulationName',
        sortable: true
      },
      {
        label: this.$t('addLoss.rawMaterialCode'),
        key: 'rmProdCode',
        sortable: true
      },
      {
        label: this.$t('addLoss.rawQuantity'),
        key: 'mac',
        sortable: true
      },
      {
        label: this.$t('addLoss.rawUnitOfMeasures'),
        key: 'uoM',
        sortable: true
      }
    ];
  }

  @Emit('onSelect')
  public onRowSelected(row): any {
    this.selectedField = row?.[0] ? row[0] : null;
    return this.selectedField;
  }
}
