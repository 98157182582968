









































import { debounce } from 'underscore';
import SmallLoader from './SmallLoader.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { MetricsService } from '../../services/metricsService';
import * as moment from 'moment';
import { Utils } from './../../utils';

const methods: any = {
  methods: {
    debounceInput: debounce(function (this: any, e: any) {
      const that: any = this as any;
      that.filter = e;
    }, 1000)
  }
};

@Component({
  components: {
    SmallLoader
  },
  computed: {
    ...mapGetters({
      functionalLocation: 'getFunctionalLocation',
      materializedPath: 'getMaterializedPath',
      selectedUnit: 'getSelectedUnit',
      path: 'getPath'
    })
  },
  ...methods
})
export default class FunctionalLocation extends Vue {
  @Prop({ default: null }) readonly selectedFunctionalLocation: any;
  @Prop({ default: null }) readonly lossData: any;
  public functionalLocation!: any;
  public selectedUnit!: any;
  public path!: any;
  public loading = false;
  public materializedPath!: string;
  public expandedKeys = {};
  public selectedKey1 = {};
  public columns: any[] = [
    {
      key: 'id',
      title: 'Functional Location Code'
    },
    {
      key: 'description',
      title: 'Description'
    }
  ];
  public filter: string = '';

  get treeTableRows(): any {
    this.loading = true;
    const rootNodes = this.functionalLocation.filter(
      (fn) => fn.parent === this.selectedUnit.FunctionalLocationRoot
    );
    const recursiveChildren = (obj) => {
      const output: any = {
        key: obj.key,
        data: {
          id: obj.label,
          description: obj.description
        },
        children: [],
        leaf: obj.leaf
      };

      const findChildren = obj.children?.length
        ? obj.children
        : this.functionalLocation.filter((fn) => fn.parent === obj.label);

      for (const row in findChildren) {
        output.children.push(recursiveChildren(findChildren[row]));
      }

      return output;
    };
    const output: any[] = [];
    const iterable = this.filter ? this.functionalLocation : rootNodes;

    for (const row in iterable) {
      output.push(recursiveChildren(iterable[row]));
    }

    this.loading = false;
    return output;
  }

  async onPlusIconClick(node) {
    if (node.children.length) {
      return;
    }
    await Utils.triggerBackend(
      {
        method: 'getFunctionalLocation',
        parameters: {
          Path: this.path,
          functionalLocationRoot: node.data.id,
          functionalLocationId: Number(node.key),
          levels: '1'
        }
      },
      this
    );
  }

  onNodeSelect(node) {
    if(this.selectedKey1[node.key]) {
      setTimeout(() => (this.selectedKey1[node.key] = false), 0);
      this.$emit('tree-clicked', 'empty');
    }else{
      setTimeout(() => (this.selectedKey1[node.key] = node.leaf), 0);
      this.$emit('tree-clicked', node);
    }

  }

  onNodeUnselect() {
    this.selectedKey1 = {};
  }

  setRowStyle(row) {
    const style: any = {
      height: '100%'
    };
    if (
      this.selectedFunctionalLocation &&
      this.selectedFunctionalLocation.id === row.id
    ) {
      style.background = '#7373f7';
      style.color = '#fff';
    }
    return style;
  }

  showLoader() {
    this.loading = true;
  }

  scrollToSelected() {
    setTimeout(() => {
      const reasonElem = document.querySelector(
        '.functionalTable .p-treetable-wrapper'
      );
      reasonElem.querySelector('.p-highlight').scrollIntoView(false);
    }, 0);
  }

  mounted() {
    this.materializedPathChanged();
  }

  destroyed() {
    this.$store.dispatch('setFunctionalLocation', null);
  }

  @Watch('functionalLocation')
  materializedPathChanged() {
    if (!this.materializedPath) {
      return;
    }
    const materialisedPath = this.materializedPath.split('.').filter(Boolean);

    materialisedPath.forEach((path) => (this.expandedKeys[path] = true));
    const selectedId = Object.keys(this.selectedKey1);
    if (!selectedId || !selectedId[0]) {
      this.selectedKey1[this.lossData.functionalLocationId] = true;
    } else {
      this.selectedKey1[selectedId[0]] = true;
    }
    this.scrollToSelected();
  }

  @Watch('filter')
  async filterChanged() {
    this.$store.dispatch('setFunctionalLocation', null);
    this.expandedKeys = {};
    if (!this.filter) {
      const selectedId = Object.keys(this.selectedKey1);
      if (selectedId && selectedId[0]) {
        return await Utils.triggerBackend(
          {
            method: 'getFunctionalLocationExpanded',
            parameters: {
              functionalLocationRoot:
                this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
              functionalLocationRootId: this.$store.getters.getSelectedUnit.FunctionalLocationRootId,
              functionalLocationId: Number(selectedId[0])
            }
          },
          this
        );
      }
      return await Utils.triggerBackend(
        {
          method: 'getFunctionalLocation',
          parameters: {
            Path: this.$store.getters.getPath,
            functionalLocationRoot:
              this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
            functionalLocationId:  this.$store.getters.getSelectedUnit.FunctionalLocationRootId,
            levels: '1'
          }
        },
        this
      );
    }
    await Utils.triggerBackend(
      {
        method: 'getFunctionalLocation',
        parameters: {
          functionalLocationRoot:
            this.$store.getters.getSelectedUnit.FunctionalLocationRoot,
          Path: this.path,
          search: this.filter
        }
      },
      this
    );
  }
}
