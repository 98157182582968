import { StateCLS } from './../classes/stateCLS';
import moment from 'moment';
import { Utils } from './../utils';
import { BatchAndStepData } from './../classes/batchAndStepData';
import { ChatApi } from './../services/api/chatApi';
import { Variable } from '@/classes/variable';

export const storeMutations = {
  setAccount(state: StateCLS, value: any) {
    state.account = value;
    // do this so that readTag data works.
    state.userName = value.username;
  },
  setHistoryDays(state: StateCLS, value: number) {
    state.historyDays = value;
  },
  setInitialDataLoaded(state: StateCLS, value: boolean) {
    state.initialDataLoaded = value;
  },
  setLossTableConfiguration(state: StateCLS, value: any) {
    state.lossTableConfiguration = value;
  },
  trendChartMoved(state: StateCLS, value: any) {
    state.secondChartPosition = value;
  },
  toggleAvgSidebar(state: StateCLS, active: string) {
    if (state.activeRightSidebar === 'chat' && state.avgSidebarIsOpen) {
      ChatApi.lastAccessed();
    }
    if (active !== state.activeRightSidebar && state.avgSidebarIsOpen) {
      state.activeRightSidebar = active;
    } else {
      state.avgSidebarIsOpen = !state.avgSidebarIsOpen;
      state.activeRightSidebar = !state.avgSidebarIsOpen ? '' : active;
    }
  },
  setLoading(state: StateCLS, value: boolean) {
    state.loading = value;
  },
  setConfiguration(state: StateCLS, value: any) {
    state.configuration = value;
  },
  toggleProductChart(state: StateCLS) {
    state.isProductChartActive = !state.isProductChartActive;
  },
  setTimeRange(state: StateCLS, timeRange: any[]) {
    state.timeRange = timeRange;
    state.secondChartPosition = null;
  },
  setAccess(state: StateCLS, value: boolean) {
    state.access = value;
  },
  setUnits(state: StateCLS, unitsJson: any) {
    state.units = unitsJson.rows.map((r) => {
      var r;
      r.unspecifiedLosses = null;
      return r;
    });
  },
  setPath(state: StateCLS, path: any) {
    // custom path example: overwrite+OAM - ELS+PA4DB+0+Custom title
    if (!path.includes('overwrite')) {
      state.path = path;
    } else {
      let dataString = path.replace('overwrite+', '');
      let values = dataString.split('+');
      if (values.length < 2) {
        console.warn(
          '[OAM UX Portal] Path & Database overwrite format is not correct. Format should be "Path=overwrite+path+database+LanguageID+title"'
        );
      } else {
        state.path = values[0];
        let database = values[1].includes('.epi.intra')
          ? values[1]
          : `${values[1]}.epi.intra`;
        let languageID = values.length > 2 ? values[2] : 0;
        let title = values.length > 3 ? values[3] : 'Custom configuration';
        console.log(
          `Overwriting... Path=${values[0]}, Database=${database}, LanguageID=${languageID}, Title=${title}`
        );
        state.configuration = {};
        state.configuration[state.path] = {
          Database: database,
          LanguageID: languageID,
          Title: title,
          showWarningInUnit: true
        };
      }
    }
    document.title = `${process.env.VUE_APP_TITLE}${path ? ' - ' + path : ''}`;
    state.loading = true;
  },
  setShiftData(state: StateCLS, shifts: any[]) {
    state.shiftData = shifts;
  },
  setTrendData(state: StateCLS, trendJson: any) {
    state.trendData = trendJson.rows;
    state.rePlot = true;
  },
  setThresholdData(state: StateCLS, thresholdJson: any) {
    let rows = thresholdJson.rows;
    state.thresholdData = rows.map((r) => {
      r.Timestamp = Utils.dst(r.Timestamp, state.timezoneOffset);
      return r;
    });
  },
  setVariables(state: StateCLS, variables: Variable[]) {
    state.variables = variables;
  },
  setTargetVariables(state: StateCLS, targetVariables: Variable[]) {
    state.targetVariables = targetVariables;
  },
  setLossData(state: StateCLS, losses: any[]) {
    state.lossData = losses;
  },
  setAlarms(state: StateCLS, alarmsJson: any) {
    state.alarms = alarmsJson.rows;
  },
  setUtcTime(state: StateCLS, utcTimeJson: any) {
    state.utcTime = utcTimeJson.rows[0].Date;
    state.timezoneOffset =
      moment(new Date()).hours() - moment(state.utcTime).hours();
  },
  setFunctionalLocation(state: StateCLS, inputJson: any) {
    if (!inputJson) {
      state.materializedPath = null;
      return (state.functionalLocation = []);
    }

    if (inputJson.search) {
      state.functionalLocation = inputJson.rows[0];
      return;
    }

    if (inputJson.materializedPath) {
      state.materializedPath = inputJson.materializedPath;
    }

    if (state.functionalLocation?.length) {
      state.functionalLocation.push(...inputJson.rows[0]);
    } else {
      state.functionalLocation = inputJson.rows[0];
    }
  },
  setLossTree(state: StateCLS, inputJson: any) {
    (window as any).trows = inputJson.rows;
    state.lossTreeRows = inputJson.rows;
    state.lossTree = Utils.buildReasonTree(state.lossTreeRows);
  },
  setUnspecifiedLosses(state: StateCLS, inputJson: any) {
    setTimeout(function () {
      let unspecifiedLosses: any = {};
      inputJson.rows.forEach((row: any) => {
        unspecifiedLosses[row.PU_Desc] = row.Unspecified;
      });
      state.unspecifiedLosses = unspecifiedLosses;
      let units = state.units;
      units.forEach((row: any) => {
        row.unspecifiedLosses = unspecifiedLosses[row.Cell];
      });
      state.units = units;
    }, 3000);
    //Unspecified losses is the last data to come before UI is available for the user
    // state.loading = false;
  },
  setSelectedUnit(state: StateCLS, value: any) {
    state.rePlot = false;
    state.selectedUnit = value;
    if (state.selectedUnit.showProductOnChart) {
      setTimeout(() => {
        state.isProductChartActive = true;
      }, 1000);
    } else {
      state.isProductChartActive = false;
    }
  },
  setSelectedVariables(state: StateCLS, value: any[]) {
    state.selectedVariables = value;
  },
  toggleSidebar(state: StateCLS) {
    state.isSidebarCollapsed = !state.isSidebarCollapsed;
  },
  setSelectedLossData(state: StateCLS, value: any) {
    state.selectedLossData = value;
  },
  setSelectedLossIndices(state: StateCLS, value: any) {
    if (value === 'all') {
      const timeRangeStart = moment(state.timeRange[0]).startOf('day');
      const timeRangeEnd = moment(state.timeRange[1]).endOf('day');
      const collector: number[] = [];
      for (let i = 0; i < state.lossData.length; i++) {
        const loss = state.lossData[i];
        if (
          moment(loss.StartTime).isSameOrAfter(timeRangeStart) &&
          (moment(loss.EndTime).isSameOrBefore(timeRangeEnd))
        ) {
          collector.push(i);
        }
      }
      state.selectedLossIndices = collector;
    } else {
      state.selectedLossIndices = value;
    }
  },
  setSelectedLossRegion(state: StateCLS, value: any) {
    state.selectedLossRegion = value;
  },
  setLastUpdated(state: StateCLS, value: any) {
    state.lastUpdated = value;
  },
  setBtnActive(state: StateCLS, value: string) {
    state.btnActive = value;
  },
  setSelectedShiftIndex(state: StateCLS, value: any) {
    state.selectedShiftIndex = value;
  },
  setSelectedShift(state: StateCLS, value: any) {
    state.selectedShift = value;
  },
  setInspectBatchOrStep(state: StateCLS, value: string) {
    state.inspectBatchOrStep = value;
  },
  setTrendValue(state: StateCLS, value: any) {
    state.trendData = value;
  },
  setDragMode(state: StateCLS, value: any) {
    state.dragMode = value;
  },
  setMaterialInformation(state: StateCLS, value: any) {
    state.materialInformation = value.rows;
  },
  setSelectedTrendAreaTimeStamps(state: StateCLS, value: any) {
    state.selectedTrendAreaTimeStamps = value;
  },
  setEquipmentList(state: StateCLS, value: any) {
    state.equipmentList = value.rows;
  },
  setMaintenanceNotificationList(state: StateCLS, value: any) {
    state.maintenanceNotificationList = value.rows;
  },
  setSelectedMaintenanceNotification(state: StateCLS, value: any) {
    state.selectedMaintenanceNotification = value.rows;
  },
  setUpdateScheduled(state: StateCLS, value: boolean) {
    state.updateScheduled = value;
  },
  setAutoRefresh(state: StateCLS, value: boolean) {
    state.isAutoRefresh = value;
  },
  setAccessDenied(state: StateCLS, value: boolean) {
    state.isAccessDenied = value;
  },
  setZoomEvent(state: StateCLS, value: any) {
    state.zoomEvent = value;
  },
  setShouldUpdateLosses(state: StateCLS, value: boolean) {
    state.shouldUpdateLosses = value;
  },
  setChartWidth(state: StateCLS, value: number) {
    state.chartWidth = value;
  },
  setIsFromMetricAction(state: StateCLS, value: boolean) {
    state.isFromMetricAction = value;
  },
  setAutoRefreshEnabled(state: StateCLS, value: boolean) {
    state.autoRefreshEnabled = value;
  },
  setFromTimerange(state: StateCLS, value: boolean) {
    state.fromTimerange = value;
  },
  setDatabyDate(state: StateCLS, value: boolean) {
    state.databyDate = value;
  },
  setShowGrid(state: StateCLS, value: string) {
    state.showGrid = value;
  },
  setStepsAndBatches(state: StateCLS, args: BatchAndStepData[]) {
    let rows: any[] = args.reverse();
    state.stepsAndBatches = rows.map((row: any): any => {
      row.StartTime = Utils.dst(row.StartTime, state.timezoneOffset);
      row.EndTime = Utils.dst(row.EndTime, state.timezoneOffset);
      return row;
    });
  },
  setFunctionalLocationOriginal(state: StateCLS, args: any[]) {
    state.functionalLocationOriginal = args;
  },
  setDrawMode(state: StateCLS, value: boolean) {
    state.drawModeActive = value;
  },
  toggleTrendChartYAxisLock(state: StateCLS) {
    state.trendChartYAxisLock = !state.trendChartYAxisLock;
  },
  toggleShiftChartArea(state: StateCLS) {
    state.shiftChartVisible = !state.shiftChartVisible;
  },
  toggleTags(state: StateCLS) {
    state.isTagsActive = !state.isTagsActive;
  },
  setFavoritesSelectedView(state: StateCLS, value: any) {
    if (value.data) {
      state.selectedVariables = value.data.variables;
    }
    state.favoritesSelectedView = value;
  },
  setFromSavedState(state: StateCLS, args: any) {
    if (!args) {
      state.savedState = null;
      return;
    }
    state.selectedUnit = args.unit;
    setTimeout(() => {
      state.selectedVariables = args.variables;
      state.timeRange = args.timeRange;
      state.selectedShift = args.selectedShift;
      state.selectedLossIndices = args.selectedLossIndices;
      state.selectedShiftIndex = args.selectedShiftIndex;
      state.savedState = args;
    }, 1000);
  },
  setFavoritesViews(state: StateCLS, value: any) {
    state.favoritesViews.push(value);
  },
  updateFavorites(state: StateCLS, { index, data }) {
    state.favoritesViews[index] = data;
  },
  deleteFavorite(state: StateCLS, index) {
    state.favoritesViews.splice(index, 1);
  },
  setMesTrends(state: StateCLS, value: any) {
    let rows: any[] = value.rows;
    rows.forEach((r) => {
      r.Result_On = Utils.dst(r.Result_On, state.timezoneOffset);
    });
    state.mesTrends = rows;
  },
  getIpAndUsernameFromSession(state: StateCLS, value: any) {
    let data: any = value.rows[0];
    state.ip = data.ip;
    state.userName = data.user;
  },
  setConversations(state: StateCLS, value: any[]) {
    state.conversations = value;
    state.unreadMessagesTotal = value.reduce(
      (acc, current) => (acc += current.unread),
      0
    );
  },
  setWishlist(state: StateCLS, value: any[]) {
    state.wishlist = value;
  },
  setChatSearch(state: StateCLS, value: string) {
    state.chatSearch = value;
  },
  setWishlistSearch(state: StateCLS, value: string) {
    state.wishlistSearch = value;
  },
  setHistorianTags(state: StateCLS, value: string[]) {
    state.historianTags = value;
  },
  setUnreadMessages(state: StateCLS, value: any) {
    state.unreadMessages = value;
    state.unreadMessagesTotal = value.reduce(
      (acc, current) => (acc += current.unread),
      0
    );
    state.conversations = state.conversations.map((conv) => {
      const findMessage = state.unreadMessages.find(
        (unread) => unread.conversation_id === conv.conversation_id
      );
      if (findMessage) {
        conv.unread = findMessage.unread;
      } else {
        conv.unread = false;
      }
      return conv;
    });
  },
  setCustomTags(state: StateCLS, rows: any[]) {
    state.customTags = rows;
  }
};
